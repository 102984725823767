import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getBankRates,
  getUserInfo,
  updateLogin,
  updatePassword,
} from "./asyncThunks";
import { ErrorResponse, PmpDto } from "api/auth";
import { logout } from "../auth";

interface BankRates {
  currencyCode: string;
  currencySymbol: string;
  currentRate: string;
  flag: string;
  isIncrease: boolean;
}

export type ProfileState = {
  isProfileLoading: boolean;
  isLoading: boolean;
  isLoginUpdated: boolean;
  isPasswordUpdated: boolean;
  isModalOpened: boolean;
  user: PmpDto | null;
  error: ErrorResponse | null;
  isBiometryConnected: string;
  isLoadingBankRates: boolean;
  bankRates: Array<BankRates>;
};

const initialState: ProfileState = {
  isProfileLoading: false,
  isPasswordUpdated: false,
  isLoading: false,
  isLoginUpdated: false,
  isModalOpened: false,
  user: null,
  error: null,
  isBiometryConnected: "",
  isLoadingBankRates: false,
  bankRates: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
    setBiometryConnected: (state, { payload }) => {
      state.isBiometryConnected = payload;
    },
    setIsLoginUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoginUpdated = payload;
    },
    setIsPasswordUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isPasswordUpdated = payload;
    },
    setIsModalOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalOpened = payload;
    },
    setIsllCardsShow: (state) => {
      if (state.user) {
        state.user.showAllCards = !state.user?.showAllCards;
      }
    },
  },
  extraReducers: {
    // Profile
    [getUserInfo.pending.type]: (state) => {
      state.isProfileLoading = true;
      if (state.error) {
        state.error = null;
      }
    },
    [getUserInfo.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PmpDto>
    ) => {
      state.isProfileLoading = false;
      state.user = payload;
    },
    [getUserInfo.rejected.type]: (state, { payload }) => {
      state.isProfileLoading = false;
      state.error = payload?.response?.data || undefined;
    },
    // Обновление логина
    [updateLogin.pending.type]: (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = null;
      }
    },
    [updateLogin.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PmpDto>
    ) => {
      state.isLoading = false;
      state.isLoginUpdated = true;
      state.user = payload;
    },
    [updateLogin.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response?.data || undefined;
    },
    // Обновление пароля
    [updatePassword.pending.type]: (state) => {
      if (state.error) {
        state.error = null;
      }
      state.isLoading = true;
    },
    [updatePassword.fulfilled.type]: (
      state,
      { payload }: PayloadAction<PmpDto>
    ) => {
      state.isLoading = false;
      state.isPasswordUpdated = true;
      state.user = payload;
    },
    [updatePassword.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload?.response?.data || undefined;
    },
    [getBankRates.pending.type]: (state) => {
      state.isLoadingBankRates = true;
    },
    [getBankRates.fulfilled.type]: (
      state,
      { payload }: PayloadAction<Array<BankRates>>
    ) => {
      state.isLoadingBankRates = false;
      state.bankRates = payload;
    },
    [getBankRates.rejected.type]: (state) => {
      state.isLoadingBankRates = false;
    },
    [logout.fulfilled.type]: () => initialState,
  },
});

export default profileSlice;
