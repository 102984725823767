import styled from "@emotion/styled/macro";
import {
  Dropdown,
  DropdownProps,
  DropdownStyled,
  InputText,
  Loader,
  TriggerStyled,
} from "components";
import { FC, useEffect, useRef, useState } from "react";
import { SearchFiltersValues } from "../../History";
import {
  OperationAmount,
  OperationReset,
  OperationType,
  OperationDate,
  OperationCard,
  ValueDateType,
} from "./components";
import theme from "theme";
import { ReactComponent as SearchIcon } from "../../assets/Search.svg";
import { ReactComponent as ClearIconInput } from "../../assets/Clear.svg";
import ClearIcon from "../../assets/Close.png";
import { CardDto } from "api/account";

const SearchFiltersStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SearchBarStyled = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 5px;
  & input {
    width: calc(100% - 80px);
  }
`;

const ClearButton = styled(ClearIconInput)`
  cursor: pointer;
`;

const FiltersBarStyled = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
  flex-wrap: ${(props) => (props.isMobile ? "nowrap" : "wrap")};
`;

export const DropdownAnchor = styled(Dropdown)<DropdownProps>`
  background: ${(props) =>
    props.isDropped ? theme.palette.gray.disabled : theme.palette.gray.b50};
  padding: 10px 12px;
  border-radius: 12px;
  display: flex;
  ${TriggerStyled} {
    color: ${(props) =>
      props.isDropped ? theme.palette.blue.b400 : theme.palette.gray.b600};
    & > svg > path {
      stroke: ${(props) =>
        props.isDropped ? theme.palette.blue.b400 : theme.palette.gray.b600};
    }
  }
  ${DropdownStyled} {
    width: 300px;
    top: calc(100% + 14px);
    left: 0;
  }
`;

export const AnchorStyled = styled.span`
  color: ${theme.palette.blue.b400};
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const OperationSearch = styled.div`
  background: ${theme.palette.gray.b50};
  padding: 10px 12px;
  border-radius: 12px;
  display: flex;
  align-items: center;
`;

interface SearchFiltersProps {
  filterValues: SearchFiltersValues;
  cards: CardDto[];
  isMobile: boolean;
  isChanged: boolean;
  onFilterChange: (values: SearchFiltersValues) => void;
  onSearch: () => void;
  onReset: (fullReset: boolean) => void;
  isLoading: boolean;
  isSearchVisible: boolean;
  setSearchVisible: (values: boolean) => void;
}

export const SearchFilters: FC<SearchFiltersProps> = ({
  filterValues,
  isMobile,
  cards,
  isChanged,
  onFilterChange,
  onSearch,
  onReset,
  isLoading,
  isSearchVisible,
  setSearchVisible,
}) => {
  const {
    search,
    from,
    to,
    operationFilterType,
    fromAmount,
    toAmount,
    cardId,
  } = filterValues;
  const floatRef = useRef<HTMLDivElement>(null);
  const [floatLeft, setFloatLeft] = useState<number>(0);
  const [appliedValues, setAppliedValues] = useState<ValueDateType>({
    from,
    to,
  });

  const handleChange = (filterUpdate: Partial<SearchFiltersValues>) => {
    onFilterChange({ ...filterValues, ...filterUpdate });
  };

  const handleClear = () => {
    onReset(false);
    setSearchVisible(false);
  };

  const handleReset = () => {
    const dateNow = new Date();
    const firstDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth(),
      1
    );

    onReset(true);
    setAppliedValues({
      from: firstDayMonth.toISOString(),
      to: dateNow.toISOString(),
    });
    setSearchVisible(false);
    setFloatLeft(0);
  };

  let rightNode: null | React.ReactNode = null;
  if (search) {
    rightNode = <ClearButton onClick={handleClear} />;
  }
  if (search && isLoading) {
    rightNode = <Loader size={18} />;
  }

  useEffect(() => {
    if (from !== appliedValues.from || to !== appliedValues.to) {
      setAppliedValues({ from, to });
    }
  }, [from, to, appliedValues.from, appliedValues.to]);

  return (
    <SearchFiltersStyled>
      {!isMobile ? (
        <SearchBarStyled>
          <InputText
            styles={{
              background: "#F7F8FA",
              border: "none",
              borderRadius: "20px",
            }}
            placeholder="Поиск по операциям"
            value={search}
            leftNode={!search && <SearchIcon />}
            rightNode={rightNode}
            onChange={(event) => {
              handleChange({ search: event.target.value });
            }}
          />
          {!isMobile && (
            <OperationType
              onChange={handleChange}
              value={operationFilterType}
              filterValues={filterValues}
            />
          )}
        </SearchBarStyled>
      ) : (
        <div />
      )}
      <FiltersBarStyled
        ref={floatRef}
        isMobile={isMobile}
        style={{
          left: floatLeft,
          overflow: isMobile ? "scroll" : "visible",
        }}
      >
        {isMobile && (
          <OperationSearch onClick={() => setSearchVisible(!isSearchVisible)}>
            <SearchIcon />
          </OperationSearch>
        )}
        <OperationReset onReset={handleReset} isChanged={isChanged} />
        {cards.length && (
          <OperationCard
            cards={cards}
            value={cardId}
            onChange={handleChange}
            onSearch={onSearch}
            isMobile={isMobile}
            filterValues={filterValues}
          />
        )}
        <OperationAmount
          value={{ fromAmount, toAmount }}
          onChange={handleChange}
          onSearch={onSearch}
          isMobile={isMobile}
          filterValues={filterValues}
        />
        <OperationDate
          value={{ from, to }}
          onChange={handleChange}
          onSearch={onSearch}
          isMobile={isMobile}
          filterValues={filterValues}
          appliedValues={appliedValues}
          setAppliedValues={setAppliedValues}
        />
        {isMobile && (
          <OperationType
            onChange={handleChange}
            value={operationFilterType}
            filterValues={filterValues}
          />
        )}
      </FiltersBarStyled>
      {isMobile && isSearchVisible ? (
        <div
          style={{
            marginBottom: "20px",
            marginTop: "-20px",
            display: "flex",
          }}
        >
          <InputText
            styles={{
              background: "#F7F8FA",
              border: "none",
              borderRadius: "20px",
            }}
            placeholder="Поиск по операциям"
            value={search}
            rightNode={
              search && (
                <img
                  src={ClearIcon}
                  style={{ width: "10px", height: "10px" }}
                  onClick={handleClear}
                />
              )
            }
            onChange={(event) => {
              handleChange({ search: event.target.value });
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </SearchFiltersStyled>
  );
};
