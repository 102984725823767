import { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

import { MobileTabItem } from "./components/MobileTabItem";
import { MobileTabVariants, mobileTabLabels } from "store/slices/system/slice";
import { systemActions } from "store/slices/system";
import {
  IconMainTab,
  IconTransfersTab,
  IconHistoryTab,
  IconLimitsTab,
  IconChatTab,
} from "./components/icons";

export type TabInfoItem = {
  id: MobileTabVariants;
  title: string;
  getIcon: (color: string) => ReactNode;
};

const tabsList = Object.keys(MobileTabVariants);
const TABS_INFO_LIST = [
  {
    id: MobileTabVariants.main,
    title: mobileTabLabels[MobileTabVariants.main],
    getIcon: (color: string) => <IconMainTab color={color} />,
  },
  {
    id: MobileTabVariants.transfers,
    title: mobileTabLabels[MobileTabVariants.transfers],
    getIcon: (color: string) => <IconTransfersTab color={color} />,
  },
  {
    id: MobileTabVariants.history,
    title: mobileTabLabels[MobileTabVariants.history],
    getIcon: (color: string) => <IconHistoryTab color={color} />,
  },
  {
    id: MobileTabVariants.limits,
    title: mobileTabLabels[MobileTabVariants.limits],
    getIcon: (color: string) => <IconLimitsTab color={color} />,
  },
  {
    id: MobileTabVariants.chat,
    title: mobileTabLabels[MobileTabVariants.chat],
    getIcon: (color: string, isUnread: () => boolean) => (
      <IconChatTab color={color} isUnread={isUnread} />
    ),
  },
];

const MobileTabsContainer = styled(Box)({
  display: "grid",
  gridAutoColumns: "1fr",
  gridAutoFlow: "column",
  justifyContent: "space-around",
  paddingTop: 20,
  paddingBottom: 20,
  position: "fixed",
  bottom: 0,
  width: "100%",
  zIndex: 1,
  backgroundColor: "#FFFFFF",
});

export const MobileTabs: React.FC = () => {
  const dispatch = useDispatch();

  const switchToTab = (tab: MobileTabVariants) =>
    dispatch(systemActions.setActiveTab({ tab }));

  return (
    <MobileTabsContainer>
      {TABS_INFO_LIST.map((tab) => (
        <MobileTabItem
          tabInfo={tab as TabInfoItem}
          key={tab.id}
          onClick={() => switchToTab(tab.id)}
        />
      ))}
    </MobileTabsContainer>
  );
};
