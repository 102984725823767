export const IconAlertGray = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      fill="none"
      viewBox="0 0 22 19"
    >
      <path
        fill="#475467"
        d="M11.866 1l9.526 16.5a1 1 0 01-.866 1.5H1.474a1 1 0 01-.866-1.5L10.134 1a1 1 0 011.732 0zM10 14v2h2v-2h-2zm0-7v5h2V7h-2z"
      ></path>
    </svg>
  );
};

export default IconAlertGray;
