import React from "react";
import { useTheme } from "@mui/material";

const IconEyeOpen = () => {
  const theme = useTheme();
  const { blue } = theme.palette;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3863 12.0009C15.3863 12.8113 15.0643 13.5884 14.4913 14.1615C13.9183 14.7345 13.1411 15.0564 12.3307 15.0564C11.5203 15.0564 10.7431 14.7345 10.1701 14.1615C9.59707 13.5884 9.27515 12.8113 9.27515 12.0009C9.27515 11.1905 9.59707 10.4133 10.1701 9.84026C10.7431 9.26724 11.5203 8.94531 12.3307 8.94531C13.1411 8.94531 13.9183 9.26724 14.4913 9.84026C15.0643 10.4133 15.3863 11.1905 15.3863 12.0009ZM13.9413 13.6115C14.3685 13.1843 14.6085 12.605 14.6085 12.0009C14.6085 11.3968 14.3685 10.8174 13.9413 10.3902C13.5142 9.96307 12.9348 9.72309 12.3307 9.72309C11.7266 9.72309 11.1472 9.96307 10.7201 10.3902C10.2929 10.8174 10.0529 11.3968 10.0529 12.0009C10.0529 12.605 10.2929 13.1843 10.7201 13.6115C11.1472 14.0387 11.7266 14.2786 12.3307 14.2786C12.9348 14.2786 13.5142 14.0387 13.9413 13.6115Z"
        stroke={blue.b400}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3307 4C17.3004 4 21.4773 7.39911 22.6613 12C21.4773 16.6009 17.3013 20 12.3307 20C7.36 20 3.184 16.6009 2 12C3.184 7.39911 7.36 4 12.3307 4ZM12.3307 18.2222C8.352 18.2222 4.98044 15.6071 3.848 12C4.98044 8.39289 8.352 5.77778 12.3307 5.77778C16.3093 5.77778 19.6809 8.39289 20.8133 12C19.6809 15.6071 16.3093 18.2222 12.3307 18.2222Z"
        fill={blue.b400}
      />
    </svg>
  );
};

export default IconEyeOpen;
