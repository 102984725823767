import styled from "@emotion/styled/macro";
import { ReactComponent as ArrowIcon } from "./assets/arrow.svg";
import { ReactComponent as GreenIcon } from "./assets/green.svg";
import { ReactComponent as RedIcon } from "./assets/red.svg";
import { useEffect, useState } from "react";
import { SkeletonContainer } from "components/SkeletonContainer";
import { useDispatch, useSelector } from "react-redux";
import { profileSelector } from "store";
import { getBankRates } from "store/slices/profile";
import { Typography } from "@mui/material";

export const Container = styled.div`
  background: rgba(247, 248, 250, 1);
  margin-top: 20px;
  border-radius: 24px;
  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: padding-bottom 0.4s ease-out;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 500;
  color: rgba(52, 64, 84, 1);
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #475467;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  &:first-child {
    margin-top: 10px;
  }
`;

export const ItemImg = styled.img`
  width: 24px;
  height: 20px;
`;

export const ItemSign = styled.p`
  margin: 0;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: rgba(71, 84, 103, 1);
  width: 30px;
`;

export const ItemReduction = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: rgba(7, 4, 23, 1);
`;

export const ItemValue = styled.p`
  margin: 0;
  margin-left: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: rgba(7, 4, 23, 1);
`;

export const Button = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const BlockIcon = styled.div`
  margin-left: 5px;
  display: flex;
`;

export const ItemsBlock = styled.div`
  transition: max-height 0.4s ease-out;
`;

export const EmptyIcon = styled.div`
  height: 4px;
  width: 7px;
`;

export const ExchangeRates = () => {
  const [hideBlock, setHideBlock] = useState(false);
  const { isLoadingBankRates, bankRates } = useSelector(profileSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBankRates());
  }, [dispatch]);

  return (
    <Container
      style={{
        padding: isLoadingBankRates ? "0px" : "24px",
        marginTop: "20px",
      }}
    >
      {isLoadingBankRates ? (
        <SkeletonContainer
          height="275px"
          isLoading={isLoadingBankRates}
          width="100%"
        />
      ) : (
        <>
          <BlockTitle
            style={{
              display: "flex",
              flexDirection: hideBlock ? "row" : "column",
              justifyContent: hideBlock ? "space-between" : "flex-start",
              alignItems: "start",
              paddingBottom: hideBlock ? "0px" : "15px",
              cursor: "pointer",
            }}
            onClick={() => setHideBlock((prev) => !prev)}
          >
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "0px",
              }}
            >
              <Title>Курсы валют</Title>
              <ArrowIcon
                style={{
                  rotate: !hideBlock ? "0deg" : "180deg",
                  transition: "rotate 0.4s ease-out",
                }}
              />
            </Container>
            {!hideBlock ? (
              <Typography
                style={{
                  textAlign: "start",
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "20px",
                  color: "#393E46",
                  width: "70%",
                  marginTop: "10px",
                }}
              >
                Для снятия иностранной валюты в банкоматах ПУ БР
              </Typography>
            ) : null}
          </BlockTitle>
          <ItemsBlock
            style={{
              maxHeight: hideBlock ? "0px" : "174px",
              overflowY: hideBlock ? "hidden" : "scroll",
            }}
          >
            {bankRates.map((item) => {
              const itemIcon = item.isIncrease ? <GreenIcon /> : <RedIcon />;

              return (
                <Item key={item.currencyCode}>
                  <ItemImg src={item.flag} />
                  <ItemSign
                    style={
                      item.currencyCode === "KGS"
                        ? { textDecoration: "underline", marginBottom: "5px" }
                        : {
                            marginBottom:
                              item.currencyCode === "TJS" ? "3px" : "0px",
                          }
                    }
                  >
                    {item.currencySymbol}
                  </ItemSign>
                  <ItemReduction>{item.currencyCode}</ItemReduction>
                  <ItemValue>{item.currentRate.replace(".", ", ")}</ItemValue>
                  <BlockIcon>
                    {item.isIncrease !== null ? itemIcon : <EmptyIcon />}
                  </BlockIcon>
                </Item>
              );
            })}
          </ItemsBlock>
        </>
      )}
    </Container>
  );
};
