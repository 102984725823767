export const IconAccountRub = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={32} height={32} rx={8} fill="#0082BA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 8.667c.368 0 .667.298.667.666v12A.667.667 0 0 1 22 22h-.667v.667a.667.667 0 1 1-1.333 0V22h-8v.667a.667.667 0 1 1-1.333 0V22H10a.667.667 0 0 1-.667-.667v-12c0-.368.299-.666.667-.666h12ZM21.333 10H10.667v10.667h10.666V10Zm-4.778 6.278a2.24 2.24 0 0 0 2.223-2.222c0-1.21-1.049-2.223-2.265-2.223h-1.641a.556.556 0 0 0-.556.557l.008 2.777h-.546a.555.555 0 1 0 0 1.11h.549l.002.56h-.635a.472.472 0 1 0 0 .945h.638l.001.444a.555.555 0 1 0 1.111-.003l-.001-.441h1.029a.472.472 0 1 0 0-.944H15.44l-.002-.56h1.117Zm-1.12-1.111-.006-2.222h1.083c.612 0 1.154.524 1.154 1.11 0 .6-.511 1.112-1.11 1.112h-1.12Z"
      fill="#fff"
    />
  </svg>
);
