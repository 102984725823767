import styled from "@emotion/styled";
import { FC } from "react";
import { ReactComponent as RemovePinIcon } from "../../assets/RemovePin.svg";
import { ReactComponent as BiometricsSmallIcon } from "../../assets/BiometricsSmall.svg";

const PinPadWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 80px);
  gap: 20px;
  width: 280px;
  margin: 0 auto;
  margin-bottom: 24px;
`;

const PinPadItem = styled.div`
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: #393e46;
  font-size: 24px;
  font-weight: 500;
  user-select: none;
  border-radius: 28px;
  &:active {
    background: #d6edf7;
  }
`;

interface PinPadProps {
  onChange?: (code?: string) => void;
  onBiometricsSelect?: () => void;
  isLoading?: boolean;
}

export const PinPad: FC<PinPadProps> = ({
  onBiometricsSelect,
  onChange,
  isLoading,
}) => (
  <PinPadWrapper>
    {Array.from(Array(12).keys()).map((index) => {
      const value = String(index === 10 ? 0 : index + 1);
      return index === 9 ? (
        onBiometricsSelect ? (
          <PinPadItem
            key={index}
            onClick={isLoading ? () => {} : onBiometricsSelect}
          >
            <BiometricsSmallIcon />
          </PinPadItem>
        ) : (
          <div key={index} />
        )
      ) : index === 11 ? (
        <PinPadItem
          key={index}
          onClick={isLoading ? () => {} : () => onChange?.()}
        >
          <RemovePinIcon />
        </PinPadItem>
      ) : (
        <PinPadItem
          key={index}
          onClick={isLoading ? () => {} : () => onChange?.(value)}
        >
          {value}
        </PinPadItem>
      );
    })}
  </PinPadWrapper>
);
