import { FC, useEffect, useRef, useState } from "react";
import theme from "theme";
import styled from "@emotion/styled/macro";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { CardDto, CardStatus } from "api/account";
import { Button } from "components";
import { SearchFiltersValues } from "pages/home/Cards/History/History";
import { AnchorStyled, DropdownAnchor } from "../..";
import IconCard from "widgets/AccountsAndCards/components/images/MiniatureCardBG.png";
import IconBlocked from "../../../../assets/Blocked.png";
import IconAccount from "../../../../assets/Account.png";
import Closed from "../../../../assets/Closed.png";
import { CARD_STATUS } from "components/CardsInfo/model";
import MiniatureCardFrozen from "widgets/AccountsAndCards/components/images/MiniatureCardFrozen.png";
import {
  HISTORY_TABS,
  getCategoryExpense,
  getCategoryIncome,
} from "store/slices/historySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

const OperationCardStyled = styled(FormControlLabel)`
  display: flex;
  margin: 0;
  padding: 0px 5px 0px 15px;
  height: 60px;
  gap: 15px;
  cursor: pointer;
  align-items: center;
  color: ${theme.palette.gray.b900};
  &:hover {
    background: ${theme.palette.blue.b700};
  }
  & > div {
    > div {
      &:nth-of-type(1) {
        white-space: nowrap;
        max-width: 155px;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${theme.palette.gray.b800};
        font-size: 16px;
      }
      &:nth-of-type(2) {
        color: ${theme.palette.gray.b400};
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
  & > img {
    width: 52px;
    height: 32px;
  }
  & > span {
    margin-left: auto;
  }
`;

type OperationCardRadioProps = {
  label: string;
  status?: CardStatus;
  description?: string;
  isMainCard?: boolean;
};

const OperationCardRadio: FC<OperationCardRadioProps> = ({
  label,
  status,
  description,
  isMainCard,
  ...rest
}) => {
  const renderIcon = () => {
    if (isMainCard) {
      return IconAccount;
    }
    if (status && (ActiveCardStatuses as CardStatus[]).includes(status)) {
      return IconCard;
    }
    if (status === CARD_STATUS.Closed) {
      return Closed;
    }
    if (status === CARD_STATUS.Temporary) {
      return MiniatureCardFrozen;
    }
    return IconBlocked;
  };

  const icon = renderIcon();

  return (
    <>
      <img src={icon} />
      <div style={{ wordBreak: "break-word" }}>
        <div>{label}</div>
        {!isMainCard && <div>{description}</div>}
      </div>
      <Radio {...rest} />
    </>
  );
};

interface OperationCardProps {
  value: string;
  cards: CardDto[];
  isMobile: boolean;
  onChange: (values: Partial<SearchFiltersValues>) => void;
  onSearch: () => void;
  filterValues: SearchFiltersValues;
}

const ActiveCardStatuses = [
  CardStatus.Active,
  CardStatus.Vip,
  CardStatus.Referral,
];

export const OperationCard: FC<OperationCardProps> = ({
  value,
  cards,
  isMobile,
  onChange,
  onSearch,
  filterValues,
}) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [isDropped, setDropped] = useState<boolean>(false);
  const [appliedValues, setAppliedValues] = useState<string>(value);
  const {
    history: { activeTab },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleSearch = () => {
    if (activeTab === HISTORY_TABS.EXPENSES) {
      dispatch(
        // @ts-ignore
        getCategoryExpense({
          ...filterValues,
          categoryIds: undefined,
        })
      );
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      dispatch(
        // @ts-ignore
        getCategoryIncome({
          ...filterValues,
          categoryIds: undefined,
        })
      );
    }
    setDropped(false);
    setAppliedValues(value);
    onSearch();
  };

  const handleDrop = () => setDropped(!isDropped);
  const handleBlur = () => setDropped(false);

  useEffect(() => {
    if (!value && !isDropped) {
      setAppliedValues(value);
    }
  }, [value, isDropped]);

  const content = (
    <>
      <RadioGroup
        value={value}
        onChange={(event) => {
          onChange({
            cardId: event.target.value,
          });
        }}
      >
        <OperationCardStyled
          value=""
          label={null}
          control={<OperationCardRadio isMainCard label="Рублевый счет" />}
        />
        {cards.map(({ cardId, cardTypeDescription, description, status }) => (
          <OperationCardStyled
            key={cardId}
            value={cardId}
            label={null}
            control={
              <OperationCardRadio
                label={cardTypeDescription!}
                description={description}
                status={status}
              />
            }
          />
        ))}
      </RadioGroup>
      <Button variant="primary" onClick={handleSearch}>
        Применить фильтр
      </Button>
    </>
  );

  const trigger = appliedValues ? (
    <AnchorStyled>
      {cards.find((card) => card.cardId === appliedValues)?.description}
    </AnchorStyled>
  ) : (
    "Все карты"
  );

  return (
    <DropdownAnchor
      trigger={trigger}
      isDropped={isDropped}
      onDrop={handleDrop}
      onBlur={handleBlur}
      refElement={isMobile ? null : cardRef}
    >
      {content}
    </DropdownAnchor>
  );
};
