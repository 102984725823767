import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, useTheme } from "@mui/material";
import { TransfersModal } from "./TransfersModal";
import { Button, InputNumber, Textarea } from "components";
import { CardInput } from "molecules/CardInput/CardInput";
import { useIsMobile } from "hooks/useIsMobile";
import {
  resetTransferErrorMessage,
  createTransferToCard,
  TRANSFER_ERROR_CODE,
  resetStateTransfer,
  resetConfirmedTransfer,
  setIsOpenModal,
} from "store/slices/transferSlice";
import { setFromTransferCard, setStatement } from "store/slices/cardsSlice";
import { RootState } from "store";
import CardSelect from "./CardSelect/CardSelect";
import {
  MAX_AMOUNT,
  MAX_TRANSFER_PAN_MIN_LENGTH,
  MAX_TRANSFER_PAN_MAX_LENGTH,
  MAX_TRANSFER_REASON_LENGTH,
} from "constants/limits";
import { formatNumber } from "utils/formatCurrency";
import { ITransferState, TabVariants } from "store/slices/system/slice";
import { TransferInfoResponseTransferParam } from "api/account";
import { systemActions } from "store/slices/system";
import { setHugeError } from "store/slices/alerts";

const Transfers = () => {
  const { isMobile } = useIsMobile();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    cards: {
      mainCard,
      account: { currency },
    },
    transfer: {
      isTransferChecked,
      isTransferConfirmed,
      createdTransfer,
      errorMessage,
      isOpenModal,
    },
    system: { activeTabState },
  } = useSelector((state: RootState) => state);

  const [payloadModal, setPayloadModal] = useState({});

  const [transferValues, setTransferValues] =
    useState<ITransferState>(activeTabState);

  const { pan, amount, reason } = transferValues;

  const ERROR_MESSAGE_AMOUNT = `Cумма должна быть меньше ${formatNumber(
    MAX_AMOUNT,
    true,
    currency?.sign
  )}`;

  // Если по выбранной карте списания превышен лимит на “Все операции по карте” выходит ошибка
  // Если на выбранной карте недостаточно средств для перевода, выходит ошибка [1.3.1 Платежи].

  const toggleModal = (
    repeatValue: string = "",
    {
      toCardPan: pan,
      sum: amount,
      reason,
    }: TransferInfoResponseTransferParam = {}
  ) => {
    if (isOpenModal) {
      setTimeout(() => {
        dispatch(resetTransferErrorMessage());
      }, 200);
    }
    dispatch(setIsOpenModal(!isOpenModal));
    if (
      isOpenModal &&
      isTransferChecked &&
      isTransferConfirmed &&
      !createdTransfer
    ) {
      const payload = repeatValue
        ? { pan, amount, reason }
        : { pan: "", amount: undefined, reason: undefined };
      dispatch(
        systemActions.setActiveTab({
          tab: TabVariants.transfers,
          state: payload,
        })
      );
      dispatch(
        setStatement({
          accId: "%",
          forceRefresh: false,
        })
      );
      dispatch(resetConfirmedTransfer());
    }
  };

  const continueHandler = () => {
    dispatch(resetStateTransfer());
    const data = {
      cardId: mainCard?.cardId || "",
      toCardPan: pan,
      sum: amount || 0,
      reason: reason || "",
    };
    dispatch(createTransferToCard(data))
      // @ts-ignore
      .unwrap()
      .then((res) => {
        if (res?.limitRestriction) {
          dispatch(
            setHugeError({
              isOpen: true,
              message:
                "Пожалуйста, измените сумму лимита по карте или уменьшите сумму перевода",
              title: "Лимит по карте превышен",
            })
          );
        } else {
          dispatch(
            setHugeError({
              isOpen: false,
              message: "",
              title: "",
            })
          );
          dispatch(setFromTransferCard(mainCard));
          setPayloadModal(data);
          toggleModal();
        }
      });
  };

  useEffect(() => {
    return () => {
      dispatch(resetTransferErrorMessage());
    };
  }, [dispatch]);

  const errorMessageAmount =
    amount && amount >= MAX_AMOUNT ? ERROR_MESSAGE_AMOUNT : "";
  const isPanValid =
    !!pan &&
    pan.length >= MAX_TRANSFER_PAN_MIN_LENGTH &&
    pan.length <= MAX_TRANSFER_PAN_MAX_LENGTH;
  const isAmountValid = !!amount && amount > 0 && !errorMessageAmount;
  const isTransferValid =
    isPanValid && isAmountValid && mainCard.status === "ACTIVE";

  return (
    <>
      <CardSelect />
      <Box
        maxWidth={isMobile ? "auto" : "580px"}
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        color="red"
        sx={{
          backgroundColor: theme.palette.gray.b50,
          padding: isMobile ? "20px 16px " : "20px 28px 28px 28px",
          borderRadius: 28,
        }}
      >
        <Box>
          <Box display="flex" mb="50px" flexDirection="column" rowGap={24}>
            <CardInput
              name="cardNumber"
              label="Введите номер карты получателя"
              value={pan}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (
                  errorMessage.title &&
                  errorMessage.code === TRANSFER_ERROR_CODE.CARD
                ) {
                  dispatch(resetTransferErrorMessage());
                }
                setTransferValues({
                  ...transferValues,
                  pan:
                    event.target.value.length <= MAX_TRANSFER_PAN_MAX_LENGTH
                      ? event.target.value
                      : pan,
                });
              }}
              mb={0}
              error={errorMessage.code === TRANSFER_ERROR_CODE.CARD}
              errorMessage={
                errorMessage.code === TRANSFER_ERROR_CODE.CARD
                  ? errorMessage.title
                  : ""
              }
            />
            <InputNumber
              label="Сумма перевода"
              placeholder={`0 ${currency?.sign}`}
              suffix={` ${currency?.sign}`}
              value={amount}
              error={!!errorMessageAmount}
              hint={errorMessageAmount}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setTransferValues({
                  ...transferValues,
                  amount:
                    Number(
                      event.target.value
                        .replace(",", ".")
                        .replace(/[^0-9.]/g, "")
                    ) || undefined,
                });
              }}
            />
          </Box>
          <Textarea
            label="Сообщение получателю"
            value={reason}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              if (
                errorMessage.title &&
                errorMessage.code === TRANSFER_ERROR_CODE.MESSAGE
              ) {
                dispatch(resetTransferErrorMessage());
              }
              setTransferValues({
                ...transferValues,
                reason:
                  event.target.value.length <= MAX_TRANSFER_REASON_LENGTH
                    ? event.target.value
                    : event.target.value.substr(0, MAX_TRANSFER_REASON_LENGTH),
              });
            }}
            inputColor={theme.palette.gray.b600}
            hint={
              errorMessage.code === TRANSFER_ERROR_CODE.MESSAGE
                ? errorMessage.title
                : ""
            }
            error={errorMessage.code === TRANSFER_ERROR_CODE.MESSAGE}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="end" mt="20px" maxWidth={580}>
        <Button
          variant="primary"
          size="lg"
          onClick={continueHandler}
          sx={{
            width: "100%",
            height: 56,
          }}
          disabled={!isTransferValid}
        >
          Продолжить
        </Button>
      </Box>
      <TransfersModal
        isOpen={isOpenModal}
        onClose={toggleModal}
        payloadModal={payloadModal}
      />
    </>
  );
};

export default Transfers;
