import { IRootState } from ".";

export const authSelector = (state: IRootState) => state.auth;
export const profileSelector = (state: IRootState) => state.profile;
export const systemSelector = (state: IRootState) => state.system;
export const cardsSelector = (state: IRootState) => state.cards;
export const chatSelector = (state: IRootState) => state.chat;
export const notificationSelector = (state: IRootState) => state.notifications;
export const alertsSelector = (state: IRootState) => state.alerts;
export const historySelector = (state: IRootState) => state.history;
export const promotionSelector = (state: IRootState) => state.promotions;
