export const fileTypeName: Record<string, string> = {
  "image/jpg": "JPG",
  "image/jpeg": "JPEG",
  "image/png": "PNG",
  "application/msword": "MS Word",
  "application/pdf": "PDF",
  "text/plain": "TXT",
};

export const getFileType = (mimeType?: string) => {
  return mimeType ? fileTypeName[mimeType] || "Файл" : "Файл";
};
