import styled from "@emotion/styled/macro";

interface Props {
  percent?: string;
  background?: string;
  opacity?: number;
  withPadding?: boolean;
  withMarginBottom?: boolean;
  cursor?: boolean;
  start?: boolean;
}

export const Container = styled.div`
  border-radius: 24px;
  margin-bottom: ${(props: Props) => (props.withMarginBottom ? "20px" : "0")};
  background: ${(props: Props) => props.background};
  padding: ${(props: Props) =>
    props.withPadding ? "16px 20px 15px 20px" : "0"};
`;

export const Sum = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #2e3238;
`;

export const LinesContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const Line = styled.div`
  height: 6px;
  border-radius: 2px;
  cursor: ${(props: Props) => (props.cursor ? "pointer" : "auto")};
  width: ${(props: Props) => props.percent};
  background: ${(props: Props) => props.background};
`;

export const CategoryItemsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`;

export const CategoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 16px;
  background: #f7f8fa;
  cursor: pointer;
  opacity: ${(props: Props) => props.opacity};
`;

export const CategoryItemColor = styled.div`
  margin-right: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${(props: Props) => props.background};
`;

export const CategoryItemText = styled.p`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  color: #393e46;
`;

export const CategoryItemSum = styled.p`
  margin: 0;
  margin-left: 5px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #393e46;
`;

export const HeaderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props: Props) =>
    props.start ? "flex-start" : "space-between"};
  margin-bottom: 10px;
  position: relative;
`;

export const HeaderLeft = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderMonth = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #0082ba;
`;

export const CalendarIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

export const MoreButton = styled.div`
  padding: 8px 12px 8px 12px;
  border-radius: 10px;
  border: 1px solid #d0d5dd;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #344054;
  &:hover {
    color: #0082ba;
    border: 1px solid #0082ba;
  }
`;

export const DateWrapper = styled.div`
  border-radius: 16px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  z-index: 1;
  background: white;
  width: 200px;
  top: 35px;
  box-shadow: 0px 2px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
`;
