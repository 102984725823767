import { Box } from "@mui/material";
import { LimitItem } from "./LimitItem";
import { useEffect, useState } from "react";
import { LimitModal } from "./LimitModal";
import { Screens } from "./models";
import { useDispatch, useSelector } from "react-redux";
import { getLimits } from "store/slices/limitsSlice";
import { CardLimitConfirmResponse } from "api/account";
import { SkeletonContainer } from "components/SkeletonContainer";
import { useIsMobile } from "hooks/useIsMobile";
import { hideErrorMessage } from "store/slices/alerts";
import CardSelect from "../Transfers/CardSelect/CardSelect";

const Limits = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [screen, setScreen] = useState<Screens>("initial");
  const [selected, setSelected] = useState<number | null>(null);
  const [limit, setLimitItem] = useState<CardLimitConfirmResponse>({});
  const handleClose = () => {
    dispatch(hideErrorMessage());
    setIsOpen(!isOpen);
  };

  const handleClick = (id: number) => {
    setLimitItem(
      limits.filter(
        (limit: CardLimitConfirmResponse) => limit?.limitId === id
      )[0]
    );
    setIsOpen(true);
    setSelected(id);
  };
  const {
    limits: { limits, isLoading },
  } = useSelector((state: any) => state);
  const {
    cards: { mainCard },
  } = useSelector((state: any) => state);

  useEffect(() => {
    const payload = {
      cardId: mainCard?.cardId,
      // limitId: "1",
    };
    dispatch(getLimits(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setScreen("initial");
      setSelected(null);
    }
  }, [isOpen]);

  const { isMobile } = useIsMobile();
  return (
    <>
      <Box width={isMobile ? "100%" : "587px"}>
        <SkeletonContainer height={500} width="100%" isLoading={isLoading}>
          <CardSelect />
          {limits.map(
            ({
              limitType,
              limitName,
              limitId,
              limitCurrentAmount,
              limitSumAmount,
              readOnly,
            }: any) => (
              <LimitItem
                selected={limitId === selected}
                onClick={handleClick}
                key={limitId}
                id={limitId}
                subtitle={limitType}
                title={limitName}
                limitCurrentAmount={limitCurrentAmount}
                limitSumAmount={limitSumAmount}
                readOnly={readOnly}
              />
            )
          )}
        </SkeletonContainer>
      </Box>
      <LimitModal
        isOpen={isOpen}
        onClose={handleClose}
        screen={screen}
        onChangeScreens={setScreen}
        limitItem={limit}
        cardId={mainCard?.cardId}
        isMobile={isMobile}
      />
    </>
  );
};

export default Limits;
