import Box from "@mui/material/Box";
import { BlockConfirm } from "./CardModalContent/BlockConfirm";
import { ChangeNameCard } from "./CardModalContent/ChangeNameCard";
import AfterConfirmInfo from "./CardModalContent/AfterConfirmInfo";
import { Button } from "../Button";
import { useSelector } from "react-redux";
import {
  blockedCard,
  CARD_ACTION,
  cardActionsToggle,
  getStatusPin,
  resetPin,
  setScreen,
  unblockedCard,
  updateCardInfo,
} from "store/slices/cardsSlice";
import { ReactNode, useState } from "react";
import { cardsSelector, useAppThunkDispatch } from "../../store";
import { ResetPinResponse } from "../../api/account";
import { setNewStatusCard, unLoading } from "../../store/slices/cardsSlice";
import { UnSuccess } from "../../pages/home/Cards/Limits/UnSuccess";
import { useIsMobile } from "hooks/useIsMobile";
import theme from "../../theme";
import FrozenCardConfirm from "./assets/FrozenCardConfirm";
import FrozenCardConfirmMobile from "./assets/FrozenCardConfirmMobile";
import BlockedCardConfirm from "./assets/BlockedCardConfirm";
import BlockedCardConfirmMobile from "./assets/BlockedCardConfirmMobile";
import ChangePinConfirm from "./assets/ChangePinConfirm.jpg";
import ConfirmImage from "./assets/ConfirmImage.jpg";
import UnfrozenIcon from "./assets/UnfrozenIcon.jpg";
import { RefillBlock } from "components/RefillBlock";

const {
  LOST,
  UNFROZEN,
  CHANGE_PIN,
  CHANGE_PIN_INFO,
  CHANGE_NAME_INFO,
  CHANGE_NAME,
  UNSUCCESS,
  TEMPORARY,
  REFILL,
} = CARD_ACTION;

interface IDataForModal {
  id?: number;
  titleModal?: string;
  title?: string;
  srcJpg?: string;
  icon?: ReactNode;
  iconMobile?: ReactNode;
  textBtn?: string;
  subtitle?: string;
  subtitleConfirm?: string;
  subtitleMob?: string;
  titleConfirm?: string;
}

const dataForModal: Record<string, IDataForModal> = {
  [TEMPORARY]: {
    id: 0,
    titleModal: "Заморозка карты",
    title: "Заморозить карту",
    icon: <FrozenCardConfirm />,
    iconMobile: <FrozenCardConfirmMobile />,
    textBtn: "Заморозить карту",
    subtitle: `Операции по карте будут приостановлены.\nВы сможете разморозить карту в любое время`,
  },
  [LOST]: {
    id: 1,
    titleModal: "Блокировка карты",
    title: "Заблокировать карту",
    icon: <BlockedCardConfirm />,
    iconMobile: <BlockedCardConfirmMobile />,
    textBtn: "Заблокировать карту",
    subtitle: `Разблокировать карту будет невозможно.\n Для получения новой карты нужно обратиться\n в обслуживающее полевое учреждение`,
  },
  [CHANGE_PIN]: {
    id: 2,
    titleModal: "Сменить пин-код карты",
    title: "Сменить пин-код карты",
    srcJpg: ChangePinConfirm,
    textBtn: "Сменить пин-код",
    subtitle:
      "Вы уверены, что хотите сменить пин-код карты? Новый пин-код будет отправлен в СМС сообщении на номер телефона, привязанный к карте.",
    subtitleConfirm:
      "Если у вас возникли вопросы или вам не пришел пин-код, обратитесь в чат поддержки или по номеру телефона",
  },
  [CHANGE_NAME]: {
    id: 3,
    titleModal: "Изменение имени карты",
    title: "Изменение имени карты",
    titleConfirm: "Имя карты изменено",
    srcJpg: ConfirmImage,
    textBtn: "Изменить имя",
    subtitle: "Для карты придумайте новое имя",
    subtitleConfirm:
      "Вы можете изменить имя карты в любое удобное для вас время",
  },
  [UNFROZEN]: {
    id: 4,
    titleModal: "Разморозка карты",
    title: "Разморозить карту",
    srcJpg: UnfrozenIcon,
    textBtn: "Разморозить карту",
    subtitle:
      "Операции по карте будут восстановлены и вы снова сможете пользоваться картой",
  },
};

export const CardActions = () => {
  const [cardName, setCardName] = useState("");
  const [blockTypeAction, setBlockTypeAction] = useState("");
  const dispatch = useAppThunkDispatch();
  const { screen } = useSelector(cardsSelector);
  const { isMobile } = useIsMobile();
  const payload = dataForModal[screen] || {};

  const {
    cards: { mainCard, isBlockLoading, chosenCardIndex, allCards },
  } = useSelector((state: any) => state);
  const {
    profile: { user },
  } = useSelector((state: any) => state);

  const onClose = () => dispatch(cardActionsToggle());

  const handleConfirm = (cardAction: string | undefined) => {
    const cardId: string = allCards[chosenCardIndex].cardId;
    if (cardAction === CARD_ACTION.LOST) {
      const payload = {
        cardId,
        type: blockTypeAction,
      };
      // @ts-ignore
      dispatch(blockedCard(payload))
        .unwrap()
        .then((res) => {
          dispatch(setNewStatusCard(res.status));
          dispatch(cardActionsToggle());
        })
        .catch(() => {
          dispatch(setScreen(CARD_ACTION.UNSUCCESS));
        });
      return;
    }

    if (
      cardAction === CARD_ACTION.LOST ||
      cardAction == CARD_ACTION.TEMPORARY
    ) {
      const payload = {
        cardId,
        type: cardAction,
      };
      // @ts-ignore
      dispatch(blockedCard(payload))
        .unwrap()
        .then((res) => {
          dispatch(setNewStatusCard(res.status));
          dispatch(cardActionsToggle());
        })
        .catch(() => {
          dispatch(setScreen(UNSUCCESS));
        });
      return;
    }

    if (cardAction === UNFROZEN) {
      dispatch(unblockedCard({ cardId: mainCard?.cardId }))
        .unwrap()
        .then((res) => {
          dispatch(setNewStatusCard(res.status));
          dispatch(cardActionsToggle());
        })
        .catch(() => {
          dispatch(setScreen(UNSUCCESS));
        });
      return;
    }

    switch (cardAction) {
      case CHANGE_PIN:
        dispatch(resetPin({ cardId: mainCard?.cardId }))
          .unwrap()
          .then((res: ResetPinResponse) => {
            if (res.id) {
              setTimeout(() => {
                if (res.id != null) {
                  dispatch(getStatusPin(res.id));
                }
              }, 3000);
            } else {
              dispatch(unLoading(false));
              dispatch(setScreen(CARD_ACTION.CHANGE_PIN_INFO));
            }
          })
          .catch((err: any) => {
            dispatch(setScreen(CARD_ACTION.UNSUCCESS));
            return err;
          });
        return;
      case CHANGE_NAME:
        dispatch(
          updateCardInfo({
            cardUID: mainCard?.cardId,
            description: cardName,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(setScreen(CHANGE_NAME_INFO));
          })
          .catch((err: any) => {
            dispatch(setScreen(UNSUCCESS));
            return err;
          });
        return;
      default:
        alert("Нет таких значений");
    }
  };

  const screenConfirm = () => {
    if (
      screen === LOST ||
      screen === TEMPORARY ||
      screen === CHANGE_PIN ||
      screen === CHANGE_NAME ||
      screen === UNFROZEN
    )
      return true;
  };

  const isDisabledBtn = (action: string | undefined) => {
    switch (action) {
      case LOST:
        if (!blockTypeAction) return true;
        break;
      case CHANGE_NAME:
        if (!cardName) return true;
        break;
      default:
        return false;
    }
  };

  const marginTopValue = (action: string | undefined) => {
    switch (action) {
      case TEMPORARY:
      case UNFROZEN:
      case LOST:
      case CHANGE_NAME:
        return isMobile ? "52px" : "40px";
    }
  };

  const screens = {
    [LOST]: (
      <BlockConfirm
        payload={payload}
        foreverBlock={true}
        setBlockTypeAction={setBlockTypeAction}
      />
    ),
    [TEMPORARY]: <BlockConfirm payload={payload} />,
    [CHANGE_PIN]: <BlockConfirm payload={payload} />,
    [CHANGE_NAME]: (
      <ChangeNameCard
        cardName={cardName}
        cardPan={mainCard?.cardPan}
        setCardName={setCardName}
      />
    ),
    [CHANGE_PIN_INFO]: (
      <AfterConfirmInfo
        payload={payload}
        isPin={true}
        cardPan={mainCard?.cardPan}
        phone={user?.phone}
      />
    ),
    [CHANGE_NAME_INFO]: <AfterConfirmInfo payload={payload} />,
    [UNSUCCESS]: <UnSuccess onClick={onClose} />,
    [UNFROZEN]: <BlockConfirm payload={payload} />,
    [REFILL]: <RefillBlock />,
  };

  const blockStyles = {
    color: "#E64545",
    backgroundColor: theme.palette.red.b200,
  };

  const isDisabled = isDisabledBtn(screen);

  return (
    <Box height="100%">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        paddingBottom={16}
      >
        {screens[screen]}

        {screenConfirm() && (
          <Box display="grid" gap="12px" mt={marginTopValue(screen)}>
            <Button
              style={{
                ...(screen === CARD_ACTION.LOST && !isDisabled && blockStyles),
              }}
              variant="primary"
              size="lg"
              onClick={() => handleConfirm(screen)}
              isLoading={isBlockLoading}
              disabled={isDisabled}
            >
              {payload?.textBtn}
            </Button>
            <Button variant="secondary" size="lg" onClick={onClose}>
              Отмена
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
