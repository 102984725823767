import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Theme, Typography, Chip } from "@mui/material";
import { statuses } from "./constants";
import { format } from "date-fns";

interface INotificationItem {
  title?: string;
  date?: string;
  status?: string;
  regNum?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  green: {
    color: theme.palette.green.b500,
    borderColor: theme.palette.green.b500,
    backgroundColor: theme.palette.green.b50,
  },
  red: {
    color: "#E64545",
    borderColor: "#E64545",
    backgroundColor: theme.palette.red.b200,
  },
  orange: {
    color: theme.palette.orange.b300,
    borderColor: theme.palette.orange.b300,
    backgroundColor: theme.palette.orange.b50,
  },
  default: {
    color: theme.palette.gray.b500,
    borderColor: theme.palette.gray.b500,
    backgroundColor: theme.palette.gray.b50,
  },
}));

const NotificationItem: FC<INotificationItem> = ({
  title,
  date,
  status,
  regNum,
}) => {
  const classes = useStyles();
  const statusInfo = statuses[status || ""];
  const time = date ? format(new Date(date), "hh:mm") : "";

  const statusColor = classes[statusInfo?.color];

  return (
    <Box borderBottom="1px solid #EBEDF3" px={20} py={16}>
      <Box
        mb={8}
        justifyContent="space-between"
        display="flex"
        alignItems="center"
      >
        {!!statusInfo && (
          <Box display="flex" gap={13} alignItems="center">
            <Chip
              className={statusColor}
              label={statusInfo?.label}
              variant="outlined"
            />
            <Typography variant="text_2" color="gray.b10">
              {regNum || ""}
            </Typography>
          </Box>
        )}
        <Typography variant="text_2" color="gray.b10">
          {time}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography variant="text_4">{title}</Typography>
      </Box>
    </Box>
  );
};

export default NotificationItem;
