import React from "react";
import { useSelector } from "react-redux";

import { ProfileIcon } from "./assets/Profile";
import { KeyIcon } from "./assets/Key";
import { LogoutIcon } from "./assets/Logout";
import { authSelector } from "store";
import { ProfileModalType } from "./models";
import { ProfileOption } from "./ProfileOption";

import { PinCodeIcon } from "./assets/PinCode";

import { useIsPWA } from "hooks/useIsPWA";
import { ProfileToggleBiometry } from "./ProfileToggleBiometry";
import { ProfileTogglePush } from "./ProfileTogglePush";
import { ProfileToggle } from "./ProfileToggle";

interface Option {
  id: ProfileModalType;
  title?: string;
  icon?: JSX.Element;
  component?: JSX.Element;
}

const options = Object.freeze([
  {
    id: ProfileModalType.BIOMETRY,
    component: <ProfileToggleBiometry />,
  },
  {
    id: ProfileModalType.PINCODE,
    title: "Создать код для входа",
    icon: <PinCodeIcon />,
  },
  {
    id: ProfileModalType.PASSWORD,
    title: "Изменить пароль",
    icon: <KeyIcon />,
  },
  {
    id: ProfileModalType.LOGIN,
    title: "Изменить логин",
    icon: <ProfileIcon />,
  },
  {
    id: ProfileModalType.SHOWCARDS,
    component: <ProfileToggle />,
  },
  {
    id: ProfileModalType.NOTIFICATION,
    component: <ProfileTogglePush />,
  },
  {
    id: ProfileModalType.LOGOUT,
    title: "Выйти из аккаунта",
    icon: <LogoutIcon />,
  },
]);

interface ProfileTogglesRenderProps {
  handleOptionClick: (id: ProfileModalType) => void;
}

export const ProfileTogglesRender: React.FC<ProfileTogglesRenderProps> = ({
  handleOptionClick,
}) => {
  const { elseDevice, isPinAllowed } = useSelector(authSelector);
  const isPWA = useIsPWA();

  const isPWAMenu = !elseDevice && isPinAllowed;

  const order = isPWA ? [1, 2, 3, 4, 5, 6, 7] : [5, 4, 3, 2, 7];

  const optionsCopy = [...options];

  const filteredOptions = order.map((index) => options[index - 1]);
  const renderOptions: Array<Option> = [];

  if (isPWAMenu) {
    optionsCopy.forEach((option) => {
      if (filteredOptions.includes(option)) {
        if (isPWA) {
          renderOptions.push(option);
        } else {
          option.id !== ProfileModalType.NOTIFICATION &&
          option.id !== ProfileModalType.BIOMETRY
            ? renderOptions.push(option)
            : null;
        }
      }
    });
  } else {
    optionsCopy.forEach((option) => {
      if (filteredOptions.includes(option)) {
        option.id !== ProfileModalType.NOTIFICATION &&
        option.id !== ProfileModalType.BIOMETRY
          ? renderOptions.push(option)
          : null;
      }
    });
  }

  return (
    <>
      {renderOptions.map((option) => (
        <React.Fragment key={option.id}>
          {option.icon ? (
            <ProfileOption
              title={
                option.id === ProfileModalType.PINCODE &&
                isPinAllowed &&
                !elseDevice
                  ? "Изменить код для входа"
                  : option.title || ""
              }
              id={option.id}
              onClick={() => handleOptionClick(option.id)}
              icon={option.icon}
            />
          ) : (
            option.component
          )}
        </React.Fragment>
      ))}
    </>
  );
};
