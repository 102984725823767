import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button, Loader } from "components";

import { useDispatch, useSelector } from "react-redux";

import { NotificationResult } from "molecules/NotificationResult";
import { IconSuccess } from "atoms/IconSuccess";
import { useIsMobile } from "hooks/useIsMobile";
import { PinPad, PinValue } from "organisms/PinCode/components";

import { PIN_LENGTH } from "organisms/PinCode";
import PinInput from "react-pin-input";
import theme from "theme";
import { authActions, updatePinCode, verifyPinCode } from "store/slices/auth";
import { authSelector } from "store";
import { useAuthCommonActions } from "hooks";

export type PinCodeUpdateType = {
  value1: string;
  value2: string;
  value3: string;
};

type UpdatePinCodeProps = {
  onClose: () => void;
};

const initialValues = {
  value1: "",
  value2: "",
  value3: "",
};

const UpdatePinCode = ({ onClose }: UpdatePinCodeProps) => {
  const { isLoading } = useSelector(authSelector);
  const { handleLogout } = useAuthCommonActions();
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const [pinCode, setPinCode] = useState<PinCodeUpdateType>(initialValues);
  const [errorAnswer, setErrorAnswer] = useState("");
  const [pinCreate, setPinIsCreate] = useState(false);
  const [verified, setVerified] = useState(false);
  const [verifyPin, setVerifyPin] = useState("");
  const [value, setValue] = useState("");

  const {
    primary: { error },
  } = theme;
  const { value1, value2, value3 } = pinCode;
  let ele = useRef();

  const isInitialPin = String(value1).length < PIN_LENGTH;
  const isPinComplete =
    value1.length === PIN_LENGTH &&
    value2.length === PIN_LENGTH &&
    value3.length === PIN_LENGTH;

  const handleChange = (code?: string) => {
    const currentStep = isInitialPin ? 1 : value2.length < PIN_LENGTH ? 2 : 3;
    setErrorAnswer("");
    setPinCode({
      ...pinCode,
      [`value${currentStep}`]: code
        ? `${pinCode[`value${currentStep}`]}${code}`
        : pinCode[`value${currentStep}`].slice(0, -1),
    });
  };

  const handleChangeWeb = (code?: string) => {
    const currentStep = isInitialPin ? 1 : value2.length < PIN_LENGTH ? 2 : 3;
    setErrorAnswer("");
    if (code?.length === PIN_LENGTH) {
      setPinCode({
        ...pinCode,
        [`value${currentStep}`]: code
          ? `${pinCode[`value${currentStep}`]}${code}`
          : pinCode[`value${currentStep}`].slice(0, -1),
      });
      //@ts-ignore
      ele?.current?.clear();
      setValue("");
    }
  };

  useEffect(() => {
    if (value1.length === PIN_LENGTH && !value2 && !value3 && !verified) {
      dispatch(verifyPinCode(value1))
        //@ts-ignore
        .unwrap()
        .then((res) => {
          setVerifyPin(res.verifyPinToken);
          setVerified(true);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            dispatch(authActions.setLoginIsBlocked(true));
            dispatch(authActions.setIsPinAllowed(false));
            handleLogout();
          } else {
            setPinCode(initialValues);
            setErrorAnswer(
              `Неверный код, осталось попыток: ${err.response.data.parameter}`
            );
          }
        });
    }
    if (isPinComplete) {
      if (value2 === value3 && verifyPin) {
        dispatch(
          updatePinCode({
            verifyPinToken: verifyPin,
            newPinCode: value2,
            repeatNewPinCode: value3,
          })
        )
          //@ts-ignore
          .unwrap()
          .then(() => {
            setPinIsCreate(true);
            dispatch(authActions.setIsPinUpdated(true));
          })
          .catch((err) => {
            setErrorAnswer(err.response.data.title);
            setPinCode({
              ...pinCode,
              value1: pinCode.value1,
              value2: "",
              value3: "",
            });
          });
      } else {
        setErrorAnswer("Коды не совпадают");
        setPinCode({
          ...pinCode,
          value1: pinCode.value1,
          value2: "",
          value3: "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCode]);

  const getTitle = () => {
    return isInitialPin
      ? errorAnswer
        ? errorAnswer
        : "Введите старый код"
      : value2.length < PIN_LENGTH
      ? errorAnswer
        ? errorAnswer
        : "Придумайте новый код для входа\nиз 4-х цифр"
      : "Повторите новый код";
  };

  if (pinCreate) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        marginTop={isMobile ? 68 : 0}
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
      >
        {!isMobile && (
          <Typography
            variant="text_8"
            color="gray.b800"
            fontWeight="500"
            marginBottom={28}
          >
            Изменить короткий код для входа
          </Typography>
        )}
        <NotificationResult
          title="Код успешно изменен"
          subtitle={
            "Используйте его для\nвхода в личный кабинет\nв онлайн-банке"
          }
          subtitleSize="text_5"
          icon={<IconSuccess />}
          height="100%"
          buttonStyle={{ margin: 24 }}
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              size: "lg",
              onClick: onClose,
            },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
        alignItems="center"
      >
        {isMobile ? (
          <>
            <Typography
              variant="text_19"
              color={errorAnswer ? error?.[500] : "gray.b500"}
              style={{ whiteSpace: "pre-line" }}
            >
              {!isLoading && getTitle()}
            </Typography>
            <PinValue
              isLoading={isLoading}
              value={
                isInitialPin
                  ? value1
                  : value2.length < PIN_LENGTH
                  ? value2
                  : value3
              }
              isError={!!errorAnswer}
            />
            <PinPad
              isLoading={isLoading}
              {...(!isPinComplete && {
                onChange: handleChange,
              })}
            />
          </>
        ) : (
          <>
            {isLoading ? (
              <Loader size={68} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  px: 32,
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="text_8"
                  color="gray.b800"
                  fontWeight="500"
                  marginBottom={28}
                >
                  Изменить короткий код для входа
                </Typography>
                <Typography
                  variant="text_19"
                  color="gray.b500"
                  marginBottom={12}
                >
                  {isInitialPin
                    ? "Введите старый код"
                    : value2.length < PIN_LENGTH
                    ? "Придумайте новый код для входа\nиз 4-х цифр"
                    : "Повторите новый код"}
                </Typography>
                <PinInput
                  length={4}
                  initialValue=""
                  secret
                  //@ts-ignore
                  ref={ele}
                  secretDelay={100}
                  onChange={(value, index) => {
                    setValue(value);
                    setErrorAnswer("");
                  }}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={{
                    borderColor: errorAnswer ? "#F97066" : "#D0D5DD",
                    borderRadius: 12,
                  }}
                  inputFocusStyle={{
                    borderColor: errorAnswer ? "#F97066" : "#D0D5DD",
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />

                {errorAnswer && (
                  <Typography variant="text_5" color="#E64545">
                    {errorAnswer}
                  </Typography>
                )}
                <Button
                  //@ts-ignore
                  disabled={value.length !== 4}
                  onClick={() => {
                    handleChangeWeb(value);
                  }}
                  variant="primary"
                  size="lg"
                  fullWidth
                  sx={{ mt: 32, mb: 8 }}
                >
                  Продолжить
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default UpdatePinCode;
