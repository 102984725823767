import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { CardModal } from "components/CardModal/CardModal";
import { CARD_STATUS } from "components/CardsInfo/model";
import IconEditNew from "components/Icons/IconEditNew";
import { Modal } from "molecules/Modal/Modal";
import { useState } from "react";

const CardName = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});
export const CardsName = ({ card }: { card: any }) => {
  const [modaliIsOpen, setModalIsOpen] = useState(false);

  return (
    <CardName
      mb={24}
      order={
        card.status === CARD_STATUS.Active ? { xs: 2, md: 1 } : { xs: 3, md: 1 }
      }
    >
      <Box flexDirection="column" display="flex">
        <Typography
          variant="text_16"
          fontWeight="500"
          color="gray.b600"
          width="85%"
          sx={{ wordBreak: "break-word" }}
        >
          {card?.description?.substring(0, 12) !== "************"
            ? card?.description
            : "**** **** **** " +
              card?.cardPan?.substring(card?.cardPan.length - 4)}
        </Typography>
        <Typography variant="text_4" fontWeight="400" color="#9AA4B5">
          Название карты
        </Typography>
      </Box>
      {card.status === CARD_STATUS.Active && (
        <Box
          onClick={() => setModalIsOpen(true)}
          pt={4}
          sx={{ cursor: "pointer" }}
        >
          <IconEditNew />
        </Box>
      )}
      <Modal
        title="Карты"
        isOpen={modaliIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
        buttonVisible={modaliIsOpen}
        callback={() => {
          setModalIsOpen(false);
        }}
      >
        <CardModal
          name={card?.description}
          cardId={card?.cardId}
          onClose={() => {
            setModalIsOpen(false);
          }}
        />
      </Modal>
    </CardName>
  );
};
