import Box from "@mui/material/Box";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconClose from "atoms/IconClose/IconClose";
import IconSearch from "atoms/IconSearch/IconSearch";
import { Label } from "molecules/Label/Label";

const useStyles = makeStyles(() => ({
  labelRoot: {
    "&.MuiInputLabel-root": {
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: 6,
      textAlign: "left",
      color: "#344054",
    },
  },
  inputRoot: {
    "& .MuiOutlinedInput-root": {
      color: "#101828",
      background: "#ffffff",
      borderRadius: "16px",
      "&.Mui-focused fieldset": {
        border: "1px solid #A4BCFD",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 38px 14px 18px",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#667085",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
}));

export const Input = ({
  value,
  onChange,
  onClick,
  label,
  name,
  placeholder = "",
  helperText = "",
  isSearch,
  type = "text",
  inputProps = {},
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Label label={label} htmlFor={name} />
      <Box position="relative" display="flex" alignItems="center">
        {isSearch && (
          <Box position="absolute" display="flex" zIndex={1} ml={20}>
            <IconSearch value={value} />
          </Box>
        )}
        <TextField
          sx={{
            div: {
              paddingLeft: isSearch && "28px",
            },
            fieldset: {
              borderColor: "#D0D5DD",
            },
          }}
          classes={{ root: classes.inputRoot }}
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          id={name}
          fullWidth
          placeholder={placeholder}
          helperText={helperText}
          inputProps={inputProps}
          autoComplete="off"
        />
        {value && (
          <Box
            onClick={onClick}
            position="absolute"
            right={22}
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
          >
            <IconClose width={9} height={9} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
