import styled from "@emotion/styled/macro";
import { ReactComponent as Arrow } from "./assets/arrow.svg";
import Card from "./Card";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useIsMobile } from "hooks/useIsMobile";
import { ButtonGroupProps } from "react-multi-carousel/lib/types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPromotions } from "store/slices/promotion/asyncThunks";
import { promotionSelector } from "store";
import { SkeletonContainer } from "components/SkeletonContainer";

const UpSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  margin-top: 40px;
`;

const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: absolute;
  top: -60px;
  right: 10px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: 1px solid #f2f4f7;
  cursor: pointer;
  background: transparent;
`;

const MainSection = styled.div`
  position: relative;
  @media (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1215, min: 1065 },
    items: 3.5,
  },
  tabletX: {
    breakpoint: { max: 1065, min: 925 },
    items: 3,
  },
  tabletL: {
    breakpoint: { max: 925, min: 775 },
    items: 2.5,
  },
  tabletM: {
    breakpoint: { max: 775, min: 620 },
    items: 2,
  },
  mobileX: {
    breakpoint: { max: 620, min: 550 },
    items: 1.5,
    partialVisibilityGutter: 52,
  },
  mobileL: {
    breakpoint: { max: 550, min: 510 },
    items: 2,
    partialVisibilityGutter: 52,
  },
  mobileM: {
    breakpoint: { max: 510, min: 440 },
    items: 1.5,
    partialVisibilityGutter: 100,
  },
  mobileS: {
    breakpoint: { max: 440, min: 390 },
    items: 1.5,
    partialVisibilityGutter: 52,
  },
  mobileXS: {
    breakpoint: { max: 390, min: 360 },
    items: 1.5,
    partialVisibilityGutter: 52,
  },
  mobile: {
    breakpoint: { max: 360, min: 0 },
    items: 1.5,
    partialVisibilityGutter: 20,
  },
};

interface CarouselButtonGroupProps extends ButtonGroupProps {
  hidden: boolean;
}

const ButtonGroup = ({ next, previous, hidden }: CarouselButtonGroupProps) => {
  if (hidden) return null;
  return (
    <ButtonsBlock>
      <Button onClick={() => previous && previous()}>
        <Arrow style={{ transform: "rotate(180deg)" }} />
      </Button>
      <Button onClick={() => next && next()}>
        <Arrow />
      </Button>
    </ButtonsBlock>
  );
};

const MainSlider = () => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();
  const { promotions, loading } = useSelector(promotionSelector);
  const [dragging, setDragging] = useState(false);
  const skeleton = [1, 2, 3, 4];

  useEffect(() => {
    dispatch(getAllPromotions());
  }, [dispatch]);

  if (!loading && !promotions.length) return null;

  return (
    <div style={{ marginTop: loading && !isMobile ? "60px" : "0" }}>
      {!isMobile && !loading && (
        <UpSection>
          <Title>Акции и предложения</Title>
        </UpSection>
      )}
      <MainSection>
        <Carousel
          responsive={responsive}
          swipeable={true}
          infinite={true}
          draggable={true}
          removeArrowOnDeviceType={[
            "tablet",
            "mobile",
            "desktop",
            "tabletX",
            "tabletL",
            "tabletM",
            "mobileX",
            "mobileL",
            "mobileM",
            "mobileS",
            "mobileXS",
          ]}
          renderButtonGroupOutside={true}
          beforeChange={() => setDragging(true)}
          afterChange={() => setDragging(false)}
          customButtonGroup={
            <ButtonGroup
              hidden={isMobile || (!isMobile && promotions.length < 5)}
            />
          }
          partialVisible={true}
          containerClass="CustomContainerClassSlider"
        >
          {!loading
            ? promotions.map((card) => (
                <Card key={card.id} card={card} processCardClick={!dragging} />
              ))
            : skeleton.map((item) => (
                <SkeletonContainer
                  height={isMobile ? 134 : 168}
                  width={isMobile ? 182 : 287}
                  isLoading={true}
                  key={item}
                />
              ))}
        </Carousel>
      </MainSection>
    </div>
  );
};

export default MainSlider;
