import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { AccountCard } from "./AccountCard";

import { AccountName } from "./AccountName";
import { ConnectedCards } from "./ConnectedCards";

export const AccountInfo = () => {
  const {
    cards: {
      account: { availableBalance, currency, description, accountNumber },
      allCards,
    },
  } = useSelector((state: any) => state);

  return (
    <Box
      p={{ sm: "0px 20px", lg: "32px" }}
      display="flex"
      flexDirection="column"
    >
      <AccountName description={description} accountNumber={accountNumber} />
      <AccountCard availableBalance={availableBalance} currency={currency} />
      {allCards.length > 0 && <ConnectedCards cards={allCards} />}
    </Box>
  );
};
