import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { AccountModal } from "components/AccountModal/AccountModal";
import IconEditNew from "components/Icons/IconEditNew";
import { useIsMobile } from "hooks/useIsMobile";
import { useState } from "react";
import { Modal } from "molecules/Modal/Modal";
const CardName = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

export const AccountName = ({
  description,
  accountNumber,
}: {
  description: string;
  accountNumber: string;
}) => {
  const { isMobile } = useIsMobile();
  const [modaliIsOpen, setModalIsOpen] = useState(false);
  return (
    <CardName mb={isMobile ? 12 : 24}>
      <Box flexDirection="column" display="flex">
        <Typography
          variant="text_16"
          fontWeight="500"
          color="gray.b600"
          width="90%"
          sx={{ wordBreak: "break-word" }}
        >
          {description}
        </Typography>
        <Typography variant="text_4" fontWeight="400" color="gray.b10">
          Название счёта
        </Typography>
      </Box>
      <Box
        onClick={() => setModalIsOpen(true)}
        pt={4}
        sx={{ cursor: "pointer" }}
      >
        <IconEditNew />
      </Box>
      <Modal
        title="Счета"
        isOpen={modaliIsOpen}
        onClose={() => {
          setModalIsOpen(false);
        }}
        buttonVisible={modaliIsOpen}
        callback={() => {
          setModalIsOpen(false);
        }}
      >
        <AccountModal
          name={description}
          accId={accountNumber}
          onClose={() => {
            setModalIsOpen(false);
          }}
        />
      </Modal>
    </CardName>
  );
};
