export const BiometryRegistration = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={151}
      height={150}
      fill="none"
    >
      <rect width={136} height={134} x={8} y={8} fill="#F2F4F9" rx={39} />
      <path
        fill="#0082BA"
        d="M58.47 105.942c18.486 0 33.472-14.986 33.472-33.471C91.942 53.985 76.956 39 58.47 39 39.985 39 25 53.985 25 72.47c0 18.486 14.985 33.472 33.47 33.472Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M75.206 83.777v-3.762a.85.85 0 0 0-1.7 0v3.762a3.733 3.733 0 0 1-3.73 3.728h-3.76a.85.85 0 1 0 0 1.701h3.76a5.436 5.436 0 0 0 5.43-5.43Zm-23.43 4.579a.85.85 0 0 0-.85-.85h-3.76a3.733 3.733 0 0 1-3.73-3.73v-3.76a.85.85 0 0 0-1.7 0v3.76a5.436 5.436 0 0 0 5.43 5.43h3.76c.47 0 .85-.38.85-.85Zm-8.34-23.43v-3.761a3.733 3.733 0 0 1 3.729-3.729h3.761a.85.85 0 0 0 0-1.7h-3.761a5.436 5.436 0 0 0-5.43 5.429v3.761a.85.85 0 1 0 1.701 0Zm31.77 0v-3.761a5.436 5.436 0 0 0-5.43-5.43h-3.76a.85.85 0 1 0 0 1.701h3.76a3.733 3.733 0 0 1 3.73 3.729v3.761a.85.85 0 1 0 1.7 0ZM64.876 80.84c.365-.362.387-.973.05-1.365a.859.859 0 0 0-1.273-.054c-1.3 1.288-2.992 1.997-4.764 1.997-1.772 0-3.463-.71-4.764-1.997a.859.859 0 0 0-1.273.054 1.015 1.015 0 0 0 .05 1.365c1.635 1.618 3.761 2.509 5.987 2.509s4.352-.891 5.986-2.51Zm-3.895-6.39v-6.137c0-.476-.429-.863-.957-.863-.529 0-.958.387-.958.863v6.136c0 .265-.238.48-.532.48h-.78c-.528 0-.957.386-.957.863 0 .476.429.863.958.863h.78c1.349 0 2.446-.99 2.446-2.206Zm5.021-3.65v-2.516a.835.835 0 0 0-.836-.834.835.835 0 0 0-.837.834V70.8c0 .46.375.833.837.833a.835.835 0 0 0 .836-.833Zm-14.225.834a.835.835 0 0 1-.837-.833v-2.517c0-.46.375-.834.837-.834.462 0 .837.373.837.834V70.8c0 .46-.375.833-.837.833Z"
        clipRule="evenodd"
      />
      <path
        fill="#0082BA"
        stroke="#F2F4F9"
        strokeWidth={2}
        d="M103.099 111.814c12.648 0 22.901-10.253 22.901-22.901s-10.253-22.901-22.901-22.901-22.901 10.253-22.901 22.9c0 12.649 10.253 22.902 22.901 22.902Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.5}
        d="M91.778 98.431c1.445-1.293 2.745-3.88 2.745-5.461 0-1.581-.578-3.018-.288-5.174.283-2.118 1.734-4.168 3.323-5.606M92.79 99.869c1.733-1.725 2.6-3.018 3.034-3.88M96.258 93.545c.144-1.438-.29-3.306-.145-4.887.145-1.58 1.012-4.312 3.18-5.605 2.167-1.294 4.768-1.437 6.213-.862 1.445.574 3.468 1.724 4.624 3.88 1.156 2.156 1.156 7.905.723 10.204M110.418 98.719c-.578 2.587-1.541 4.024-1.734 4.455"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.5}
        d="M110.852 83.772c2.024 2.874 3.469 10.06.434 17.678M114.899 90.814c0 1.15.144 4.6-.867 8.623M94.235 100.875c3.179-3.88 4.48-5.75 3.901-9.199-.578-3.449.145-5.749 1.734-6.898 1.272-.92 3.131-1.054 3.902-1.006M106.228 84.347c1.445.43 3.179 3.593 3.179 7.33 0 3.242-.867 8.767-3.179 12.072"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.5}
        d="M103.916 104.18c.578-.862 3.035-4.743 3.468-9.055M107.674 92.395c-.578-4.312-1.156-5.75-2.601-6.468s-4.191-.287-5.203 2.444"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.5}
        d="M101.748 104.037c2.601-3.737 4.768-8.48 3.468-15.379-.346-1.836-3.796-1.706-3.468.575.289 2.012.433 4.887-.145 6.324"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.5}
        d="M99.725 103.605c3.324-4.311 4.913-9.198 3.757-14.372M97.702 102.887c2.023-1.581 3.179-4.743 3.179-5.174M95.968 102.024c2.601-2.587 4.335-5.749 4.046-11.067M99.725 80.897c3.468-1.293 5.925-1.437 9.538 1.294M89.032 93.114c-.433-5.606-.433-6.756 1.156-10.492M91.633 80.466c2.313-2.875 4.624-4.513 7.515-5.318 3.612-1.006 8.236-.575 10.837.862M111.719 77.16c2.167 1.437 6.503 4.6 6.647 13.941"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.5}
        d="M89.755 95.126c.337-.287 1.011-1.15 1.011-2.3 0-1.437-.578-4.455-.144-6.323.433-1.869 2.167-6.037 6.213-8.48 3.237-1.955 6.84-1.869 8.237-1.581M107.673 77.016c1.156.431 6.936 3.306 8.237 8.624 1.301 5.317.867 9.054.722 10.348"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={0.5}
        d="M90.621 96.994c1.445-1.725 1.879-2.3 2.023-3.593.145-1.293-.144-2.3-.289-3.018-.115-.575 0-.862 0-.862M92.5 87.508c1.156-4.886 3.179-6.324 4.48-7.33 1.3-1.006 4.103-2.012 6.069-2.012 2.601 0 6.791 1.006 9.103 4.168 1.787 2.443 2.312 4.743 2.457 6.18"
      />
    </svg>
  );
};
