import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import theme from "theme";
import { Button } from "components";
import { AuthContainer } from "containers";
import { getSecondsPluralForms } from "utils";
import { SmsInput } from "../../../molecules/SmsInput/SmsInput";
import { useCounter } from "hooks/useCounter";
import { useDispatch, useSelector } from "react-redux";
import {
  authActions,
  registerCheck,
  retryOneTimePassword,
} from "store/slices/auth";
import { authSelector } from "store";
import {
  BackButtonContainer,
  SmallBackButton,
} from "components/SmallBackButton";
import { Numbers, Title } from "./components";

const SecondScreen = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState<string>("");
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const { isLoading, error, registerPhone } = useSelector(authSelector);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCode(e.target.value);
  };
  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(registerCheck(code));
  };

  const handleRetryOneTimePassword = () => {
    dispatch(retryOneTimePassword());
    restartCounter();
  };

  return (
    <>
      <BackButtonContainer>
        <SmallBackButton
          clickHandler={() =>
            dispatch(authActions.setRegisterScreen("initial"))
          }
        />
      </BackButtonContainer>
      <AuthContainer
        title="Регистрация"
        subtitle={
          <Title>
            На номер ***{" "}
            <Numbers>
              {registerPhone.slice(-4, -2)} {registerPhone.slice(-2)}
            </Numbers>
            <br />
            был отправлен код подтверждения
          </Title>
        }
      >
        <Box
          component="form"
          sx={{
            backgroundColor: theme.palette.gray.b50,
          }}
          px={{ xs: 20, xl: 30.5 }}
          py={32}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          border={`1px solid ${theme.palette.gray.b100}`}
          borderRadius={24}
          autoComplete="off"
        >
          <Box>
            <SmsInput
              placeholder="Введите код"
              label="Код"
              name="code"
              value={code}
              onChange={handleChange}
              // @ts-ignore
              error={error}
            />
          </Box>
          <Box mt={42}>
            <Box
              display="flex"
              flexDirection="column"
              rowGap={count > 0 ? 44 : 12}
            >
              {count === 0 ? (
                <Button
                  onClick={handleRetryOneTimePassword}
                  variant="secondary"
                  size="lg"
                  fullWidth
                >
                  Отправить код повторно
                </Button>
              ) : (
                <Box minHeight={48}>
                  <Typography variant="text_5" color="gray.b400">
                    Вы сможете отправить код повторно через{" "}
                    <Typography variant="text_5" color="blue.b400">
                      {count} {secondsWord}.
                    </Typography>
                  </Typography>
                </Box>
              )}
              <Button
                type="submit"
                onClick={(e) => handleConfirm(e)}
                variant="primary"
                size="lg"
                fullWidth
                disabled={code.length < 4}
                isLoading={isLoading}
              >
                Продолжить
              </Button>
            </Box>
          </Box>
        </Box>
      </AuthContainer>
    </>
  );
};

export default SecondScreen;
