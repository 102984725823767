import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { hideErrorMessage } from "store/slices/alerts";
import {
  BackButtonContainer,
  SmallBackButton,
} from "components/SmallBackButton";
import { IconBlockedCardHuge } from "atoms/IconBlockedCardHuge";
import IconPhone from "atoms/IconPhone/IconPhone";
import { useIsMobile } from "hooks/useIsMobile";
import { IconBlockedCardSmall } from "atoms/IconBlockedCardSmall";
import { authSelector } from "store";
import { authActions } from "store/slices/auth";

export type FrozenTypes = "registration" | "recovery" | "cvv";

const FrozenScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const { frozenType } = useSelector(authSelector);
  useEffect(() => {
    dispatch(hideErrorMessage());
  }, [dispatch]);

  const screens: Record<FrozenTypes, React.ReactNode> = {
    registration: "Для разморозки карты обратитесь\nв службу техподдержки",
    recovery: "Для разморозки карты обратитесь в службу техподдержки",
    cvv: isMobile
      ? `Вы ввели неверный код\nподтверждения, ваша карта\nзаморожена, для разморозки карты\nобратитесь в службу техподдержки`
      : `Вы ввели неверный код подтверждения, ваша карта заморожена,\nдля разморозки карты обратитесь в службу техподдержки`,
  };

  const getInitialScreens = (frozenType) => {
    switch (frozenType) {
      case "registration":
        return dispatch(authActions.setRegisterScreen("initial"));
      case "recovery":
        return dispatch(authActions.setRestoreScreen("initial"));
      case "cvv":
        return dispatch(authActions.setRegisterScreen("initial"));
      default:
        return null;
    }
  };

  const handlePopState = (event) => {
    const previousUrl = event.state ? event.state.url : null;
    if (previousUrl) {
      getInitialScreens(frozenType);
    }
  };

  window.addEventListener("popstate", handlePopState);

  return (
    <>
      <BackButtonContainer>
        <SmallBackButton
          clickHandler={() => {
            navigate("/sign-in", { replace: true });
            getInitialScreens(frozenType);
          }}
        />
      </BackButtonContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        mt={isMobile ? 25 : 45}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          pl={isMobile ? 35 : 0}
          textAlign={isMobile ? "start" : "center"}
          width={isMobile ? "100%" : "auto"}
        >
          <Typography
            color="#393E46"
            variant="text_20"
            mb={16}
            fontWeight="500"
          >
            Карта заморожена
          </Typography>
          <Typography
            color="#393E46"
            variant="text_19"
            mb={40}
            whiteSpace="break-spaces"
          >
            {screens[frozenType]}
          </Typography>
        </Box>
        {isMobile ? <IconBlockedCardSmall /> : <IconBlockedCardHuge />}
        <Typography
          variant="text_10"
          mt={20}
          color="#8993A4"
          sx={{ display: "flex", alignItems: "center", gap: 4 }}
        >
          <IconPhone /> Служба техподдержки
        </Typography>
        <Link
          mt={8}
          mb={{ lg: 140 }}
          variant="text_5"
          color="#0082BA"
          fontWeight="500"
          underline="none"
          href="tel:+7 (800) 200 45 67"
        >
          +7 (800) 200 45 67
        </Link>
      </Box>
    </>
  );
};

export default FrozenScreen;
