import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import IconCopy from "../Icons/IconCopy";
import IconEyeClosed from "../Icons/IconEyeClosed";
import {
  getTokenCardCvvInfo,
  getCardPanInfo,
  setScreen,
  CARD_ACTION,
} from "../../store/slices/cardsSlice";
import { useDispatch, useSelector } from "react-redux";
import { CvvModal } from "./CvvModal";
import IconEyeOpen from "../Icons/IconEyeOpen";
import IconRefill from "../Icons/IconRefill.png";
import IconRight from "../../pages/cards/modal/cardsSlider/assets/RightIcon.png";
import SkeletonContainer from "../SkeletonContainer/SkeletonContainer";
import { CardCvvTokenDto, CardPanDto } from "../../api/account";
import { RootState, useAppThunkDispatch } from "../../store";
import { useIsMobile } from "hooks/useIsMobile";
import styled from "@emotion/styled/macro";
import theme from "theme";
import copy from "copy-to-clipboard";
import { Button } from "components";
import Transfer from "../../assets/icons/TransferIcon.png";
import { CARD_STATUS } from "components/CardsInfo/model";
import {
  CreateRefillResponse,
  createRefill,
  getSavedCards,
} from "store/slices/transferSlice";

const IconButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  &:disabled {
    & > svg {
      cursor: initial;
      & > path {
        stroke: ${theme.palette.gray.b300};
      }
    }
  }
`;

const TransferIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const MobileRefillButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 12px;
  border-radius: 16px;
  background: #f2f4f9;
  outline: none;
  border: none;
`;

const MobileRefillButtonText = styled.p`
  margin: 0;
  font-weight: 400;
  color: rgba(57, 62, 70, 1);
  line-height: 24px;
  font-size: 16px;
`;

const MobileRefillButtonIcon = styled.img`
  width: 36px;
  margin-right: 15px;
`;

const MobileRefillButtonIconRight = styled.img`
  margin-left: auto;
  margin-right: 10px;
`;

const BackSideCard: React.FC = (card?: any) => {
  const theme = useTheme();
  const { gray } = theme.palette;
  const dispatchApp = useAppThunkDispatch();
  const [copyInfo, setCopyInfo] = useState("");
  const [requisitesCard, setRequisitesCard] = useState<CardPanDto>({});
  const [verifyCvvToken, setVerifyCvvToken] = useState<string>("");
  const [show, setShow] = useState(false);
  const [cvv, setCvv] = useState<string | undefined>("");
  const [cvvModalVisible, setCvvModalVisible] = useState<boolean>(false);
  const { isMobile } = useIsMobile();
  const copyCardPan = useRef<HTMLElement>(null);
  const copyLeftTime = useRef<HTMLElement>(null);
  const windowHeight = useRef(window.innerHeight);
  const dispatch = useDispatch();
  const {
    cards: { allCards, chosenCardIndex },
  } = useSelector((state: RootState) => state);
  const currentCard = chosenCardIndex ? allCards[chosenCardIndex] : allCards[0];
  const isActiveCard = currentCard.status === CARD_STATUS.Active;
  const displayCards = useMemo(
    () => allCards.filter((card) => card.status === CARD_STATUS.Active),
    [allCards]
  );
  const chosenCard =
    chosenCardIndex && allCards[chosenCardIndex].status === CARD_STATUS.Active
      ? allCards[chosenCardIndex]
      : displayCards[0];

  const loadCardPan = () => {
    dispatchApp(getCardPanInfo({ cardId: card?.card?.cardId }))
      .unwrap()
      .then((res: CardPanDto) => {
        setRequisitesCard(res);
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    if (show === true) {
      loadCardPan();
      clearCVV();
    } else {
      setShow(false);
      setRequisitesCard({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    setShow(false);
    setRequisitesCard({});
    clearCVV();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const clearCVV = () => {
    setCvv("");
  };

  const getCVV = () => {
    dispatchApp(getTokenCardCvvInfo({ cardUID: card?.card.cardId }))
      .unwrap()
      .then((res: CardCvvTokenDto) => {
        if (res) {
          setShow(false);
          setRequisitesCard({});
          setCvvModalVisible(true);
          // @ts-ignore
          setVerifyCvvToken(res.verifyToken);
        }
      })
      .catch((err) => {
        return err;
      });
  };

  const beautyCardPan = (cardPan: string | undefined, show: boolean) => {
    let string = "";
    if (cardPan && show) {
      for (let i = 0; i < cardPan.length; i++) {
        if (i % 4 === 0) {
          string += " ";
        }
        string += cardPan[i];
      }
    } else {
      string = "**** **** **** ****";
    }
    return string;
  };
  const beautyLifeTime = (lifeTime: string | undefined, show: boolean) => {
    if (lifeTime && show) {
      let arrTime = lifeTime?.split("-");
      //0 index - год, 1 index - месяц
      return String(arrTime[1] + "/" + arrTime[0][2] + arrTime[0][3]);
    } else {
      return "**/**";
    }
  };
  const copyText = async (copyId: string) => {
    // подходит для старых браузеров, но устарелый метод
    // const rangeCopyText = document.createRange();
    // rangeCopyText.selectNode(document.getElementById(copyId))(null);;
    // window.getSelection().removeAllRanges()(null); // clear current selection
    // window.getSelection().addRange(rangeCopyText)(null); // to select text
    // document.execCommand("copy");
    // window.getSelection().removeAllRanges()(null); // to deselect

    let node: string | undefined;
    if (copyId === "copyCardPan") {
      node = copyCardPan?.current?.innerText;
    }
    if (copyId === "copyLeftTime") {
      node = copyLeftTime?.current?.innerText;
    }

    if (node) {
      copy(node);
      switch (copyId) {
        case "copyCardPan":
          setCopyInfo("Номер карты скопирован");
          setTimeout(() => {
            setCopyInfo("");
          }, 3000);
          break;
        case "copyLeftTime":
          setCopyInfo("Срок действия карты скопирован");
          setTimeout(() => {
            setCopyInfo("");
          }, 3000);
          break;
      }
    }
  };
  const createRefillRequest = () => {
    dispatch(getSavedCards())
      // @ts-ignore
      .unwrap()
      .then((res) => {
        if (res.cards.length) {
          dispatch(setScreen(CARD_ACTION.REFILL));
        } else {
          dispatch(
            createRefill({
              receiverCard: chosenCard,
              savedSenderCardId: null,
            })
          )
            // @ts-ignore
            .unwrap()
            .then((res: CreateRefillResponse) => {
              setTimeout(() => {
                window.open(res.url, "_blank");
              }, 0);
            });
        }
      });
  };

  const createRefillButton = () => {
    if (!isActiveCard) return null;
    return !isMobile ? (
      <Button
        onClick={createRefillRequest}
        variant="primary"
        size="lg"
        fullWidth
        startIcon={<TransferIcon src={Transfer} />}
      >
        Пополнить
      </Button>
    ) : (
      <MobileRefillButton onClick={createRefillRequest}>
        <MobileRefillButtonIcon src={IconRefill} />
        <MobileRefillButtonText>Пополнить</MobileRefillButtonText>
        <MobileRefillButtonIconRight src={IconRight} />
      </MobileRefillButton>
    );
  };

  const refillButton = createRefillButton();

  return (
    <>
      <>
        <Box
          marginTop={isMobile ? 12 : -20}
          marginBottom={isMobile ? 0 : 30}
          order={{ xs: 5, md: 5 }}
        >
          {refillButton}
        </Box>
        <Box
          height={36}
          alignItems="center"
          display={{ xs: "none", md: "flex" }}
          justifyContent="space-between"
          pr={16}
          order={{ xs: 5, md: 5 }}
        >
          <Typography variant="text_10" color="gray.b600" fontWeight="500">
            Реквизиты карты
          </Typography>
          <Box onClick={() => setShow(!show)}>
            <Typography
              variant="text_3"
              color="blue.b400"
              fontWeight="500"
              sx={{ cursor: "pointer" }}
            >
              {show ? "Скрыть" : "Показать"}
            </Typography>
          </Box>
        </Box>
      </>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        padding={{ xs: "14px 12px 16px 12px", md: 20 }}
        bgcolor={theme.palette.gray.b100}
        borderRadius="28px"
        boxSizing="border-box"
        mb={16}
        mt={24}
        order={{ xs: 6, md: 6 }}
      >
        <Box
          alignItems="center"
          justifyContent="space-between"
          pr={16}
          mb={14}
          display={{ xs: "flex", md: "none" }}
        >
          <Typography variant="text_10" color="gray.b800" fontWeight="500">
            Реквизиты карты
          </Typography>
          <Box onClick={() => setShow(!show)}>
            <Typography
              variant="text_3"
              color="blue.b400"
              fontWeight="500"
              sx={{ cursor: "pointer" }}
            >
              {show ? "Скрыть" : "Показать"}
            </Typography>
          </Box>
        </Box>
        <SkeletonContainer width="100%">
          {copyInfo && (
            <Box
              sx={{
                left: "50%",
                transform: "translate(-50%, -160px)",
              }}
              alignItems="center"
              display="flex"
              bgcolor="gray.b600"
              padding="16px"
              borderRadius="12px"
              boxShadow="0px 4px 7px #83868B"
              position="absolute"
              boxSizing="border-box"
              width={isMobile ? 335 : 450}
              bottom={windowHeight.current * 0.01}
              zIndex={100}
            >
              <Box display="flex" mr={10}>
                <IconCopy color={gray.b100} />
              </Box>
              <Typography variant="text_3" color="gray.b100" fontWeight="400">
                {copyInfo}
              </Typography>
            </Box>
          )}

          <Box
            height={{ xs: 58, md: 74 }}
            width="100%"
            bgcolor="white"
            mb={{ xs: 12, md: 20 }}
            borderRadius="16px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="16px"
            boxSizing="border-box"
          >
            <Box flexDirection="column" display="flex">
              <Typography color="gray.b400" fontWeight="400" variant="text_1">
                Номер карты
              </Typography>
              <Typography
                variant="text_5"
                color="gray.b400"
                fontWeight="400"
                ref={copyCardPan}
              >
                {beautyCardPan(requisitesCard?.cardPan, show)}
              </Typography>
            </Box>
            <IconButton
              disabled={!show}
              onClick={() => copyText("copyCardPan")}
            >
              <IconCopy />
            </IconButton>
          </Box>
          <Box height={{ xs: 58, md: 74 }} display="flex" gap={11}>
            <Box
              height={{ xs: 58, md: 74 }}
              width="100%"
              bgcolor="white"
              borderRadius="16px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding="16px"
              boxSizing="border-box"
            >
              <Box flexDirection="column" display="flex">
                <Typography color="gray.b400" fontWeight="400" variant="text_1">
                  Срок действия
                </Typography>
                <Typography
                  variant="text_5"
                  color="gray.b400"
                  fontWeight="400"
                  ref={copyLeftTime}
                >
                  {beautyLifeTime(requisitesCard?.lifetime, show)}
                </Typography>
              </Box>
              <IconButton
                disabled={!show}
                onClick={() => copyText("copyLeftTime")}
              >
                <IconCopy />
              </IconButton>
            </Box>
            <Box
              height={{ xs: 58, md: 74 }}
              width="100%"
              bgcolor="white"
              borderRadius="16px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              padding="16px"
              boxSizing="border-box"
            >
              <Box flexDirection="column" display="flex">
                <Typography color="gray.b400" fontWeight="400" variant="text_1">
                  CVV
                </Typography>
                <Typography variant="text_5" color="gray.b400" fontWeight="400">
                  {cvv ? cvv : "***"}
                </Typography>
              </Box>
              {cvv && (
                <Box onClick={() => clearCVV()} sx={{ cursor: "pointer" }}>
                  <IconEyeOpen />
                </Box>
              )}
              {!cvv && (
                <Box onClick={() => getCVV()} sx={{ cursor: "pointer" }}>
                  <IconEyeClosed />
                </Box>
              )}
            </Box>
          </Box>
        </SkeletonContainer>
      </Box>
      <CvvModal
        isOpen={cvvModalVisible}
        verifyCvvToken={verifyCvvToken}
        setCvv={setCvv}
        setCvvModalVisible={setCvvModalVisible}
        setRequisitesCard={setRequisitesCard}
      />
    </>
  );
};

export default BackSideCard;
