import { MobileTabVariants } from "store/slices/system/slice";

interface SectionTitle {
  desktop: string | null;
  mobile: string | null;
}

export const sectionTitles: Record<MobileTabVariants, SectionTitle> = {
  [MobileTabVariants.main]: {
    desktop: null,
    mobile: null,
  },
  [MobileTabVariants.transfers]: {
    desktop: "Перевод по номеру карты",
    mobile: "Перевод по номеру карты",
  },
  [MobileTabVariants.history]: {
    desktop: "История операций по счёту",
    mobile: "История операций",
  },
  [MobileTabVariants.limits]: {
    desktop: "Лимиты по карте",
    mobile: "Лимиты по карте",
  },
  [MobileTabVariants.chat]: {
    desktop: null,
    mobile: null,
  },
};
