const IconExit = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.031 5.92a2 2 0 0 1 2 2v.425a1 1 0 1 0 2 0v-.426a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v8.709a4 4 0 0 0 4 4h5.031a4 4 0 0 0 4-4v-.47a1 1 0 1 0-2 0v.47a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7.919a2 2 0 0 1 2-2h5.031ZM11 13h8.512l-2.642 2.58a1 1 0 0 0 1.397 1.432l4.234-4.136a1 1 0 0 0 0-1.43L18.267 7.31a1 1 0 1 0-1.397 1.43L19.183 11H11a1 1 0 1 0 0 2Z"
      fill={props.fill}
    />
  </svg>
);

export default IconExit;
