export const WalletIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="17"
    fill="none"
    viewBox="0 0 22 17"
  >
    <path
      stroke="#0082BA"
      strokeWidth="2"
      d="M1 6.294h20M1 3.647C1 2.185 2.221 1 3.727 1h14.546C19.779 1 21 2.185 21 3.647v9.706C21 14.815 19.779 16 18.273 16H3.727C2.221 16 1 14.815 1 13.353V3.647z"
    ></path>
  </svg>
);
