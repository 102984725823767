import { Button, Loader, SkeletonContainer } from "components";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateRefillResponse,
  createRefill,
  deleteSavedCard,
  setIsCreateRefillLoading,
  setIsNewCard,
  setSavedCards,
} from "store/slices/transferSlice";
import { cardActionsToggle } from "store/slices/cardsSlice";
import theme from "theme";
import { RootState } from "store";
import EmptyCardIcon from "./assets/empty-card-icon.png";
import { CardIcon } from "./assets/CardIcon";
import CardInfoItem from "widgets/AccountsAndCards/components/CardInfoItem";
import { ItemCard } from "./ItemCard";
import { CARD_STATUS } from "components/CardsInfo/model";
import {
  AllCardsWrapper,
  CardsWrapper,
  ItemCardIcon,
  ItemsWrapper,
  SmallTitle,
  Title,
} from "./components";

const CARD_COLORS = [
  {
    background: "#E6FEE0",
    color: "#71DEA7",
  },
  {
    background: "#FFDEDB",
    color: "#F97066",
  },
  {
    background: "#D6EDF7",
    color: "#0082BA",
  },
  {
    background: "#E5DFFF",
    color: "#7A5AF8",
  },
  {
    background: "#F8F8D3",
    color: "#F9C81C",
  },
  {
    background: "#FFD8BC",
    color: "#F98166",
  },
];

const primaryButtonStyles = { marginTop: 15 };
const secondaryButtonStyles = {
  marginTop: 15,
  backgroundColor: theme.palette.gray.b100,
};

export const RefillBlock = () => {
  const {
    cards: { chosenCardIndex, allCards },
    transfer: {
      isSavedCardsLoading,
      savedCards,
      isNewCard,
      isCreateRefillLoading,
    },
  } = useSelector((state: RootState) => state);

  const isSelectedCard = useMemo(
    () => savedCards.filter((card) => card.active).length,
    [savedCards]
  );
  const isDisabledButton =
    (!isNewCard && !isSelectedCard) || isSavedCardsLoading;
  const displayCards = useMemo(
    () => allCards.filter((card) => card.status === CARD_STATUS.Active),
    [allCards]
  );

  const chosenCard =
    chosenCardIndex && allCards[chosenCardIndex].status === CARD_STATUS.Active
      ? allCards[chosenCardIndex]
      : displayCards[0];

  const [activeCard, setActiveCard] = useState(chosenCard);
  const [showCards, setShowCards] = useState(false);

  const dispatch = useDispatch();

  const createRefillRequest = () => {
    dispatch(setIsCreateRefillLoading(true));
    dispatch(
      createRefill({
        receiverCard: activeCard,
        savedSenderCardId: isNewCard
          ? null
          : savedCards.filter((card) => card.active)[0].id,
      })
    )
      // @ts-ignore
      .unwrap()
      .then((res: CreateRefillResponse) => {
        setTimeout(() => {
          window.open(res.url, "_blank");
        }, 0);
      })
      .finally(() => dispatch(setIsCreateRefillLoading(false)));
  };

  const showCardsHandler = () => {
    setShowCards((prevState) => !prevState);
  };

  return (
    <>
      <Title>Выберите карты для пополнения</Title>
      <SmallTitle>c карты</SmallTitle>
      <SkeletonContainer
        height="150px"
        isLoading={isSavedCardsLoading}
        width="100%"
      >
        <ItemsWrapper>
          {savedCards.map((card, index) => (
            <ItemCard
              key={card.id}
              checked={card.active}
              title={card.brandName}
              cardNumber={card.pan?.slice(-6)}
              onChange={() => dispatch(setSavedCards(card.id))}
              onDelete={() => dispatch(deleteSavedCard(card.id))}
              background={CARD_COLORS[index].background}
              icon={<CardIcon color={CARD_COLORS[index].color} />}
            />
          ))}
          <ItemCard
            checked={isNewCard}
            title="Новая карта"
            onChange={() => dispatch(setIsNewCard())}
            background="#F0F0F0"
            icon={<ItemCardIcon src={EmptyCardIcon} />}
            disabledMenu
          />
        </ItemsWrapper>
      </SkeletonContainer>
      <SmallTitle>на карту</SmallTitle>
      <AllCardsWrapper>
        <CardInfoItem
          cardInfo={activeCard}
          key={activeCard.cardId}
          index={0}
          customSelect
          showCards={showCards}
          setShowCards={showCardsHandler}
        />
        {showCards && (
          <CardsWrapper>
            {displayCards.map((card, index) => {
              return (
                <CardInfoItem
                  cardInfo={card}
                  key={card.cardId}
                  index={index}
                  customSelect
                  showCards={showCards}
                  setShowCards={showCardsHandler}
                  setActiveCard={() => setActiveCard(card)}
                  hideRightArrow
                  activeCard={card.cardId === activeCard.cardId}
                />
              );
            })}
          </CardsWrapper>
        )}
      </AllCardsWrapper>
      <>
        <Button
          onClick={createRefillRequest}
          variant="primary"
          size="lg"
          fullWidth
          startIcon={isCreateRefillLoading && <Loader />}
          sx={primaryButtonStyles}
          disabled={isDisabledButton}
        >
          Применить
        </Button>
        <Button
          size="lg"
          variant="secondary"
          onClick={() => dispatch(cardActionsToggle())}
          fullWidth
          sx={secondaryButtonStyles}
        >
          Отмена
        </Button>
      </>
    </>
  );
};
