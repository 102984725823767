import React from "react";
import { ButtonProps } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import theme from "theme";

export interface CustomButtonProps extends ButtonProps {
  variant?: "primary" | "secondary" | "text";
  size?: "sm" | "lg";
  isLoading?: boolean;
  component?: any;
  to?: any;
}

const Button: React.FC<CustomButtonProps> = (props) => {
  const { children, isLoading, ...restProps } = props;

  return (
    <LoadingButton
      sx={{
        "&": {
          borderRadius: "12px",
        },
        "& .MuiCircularProgress-root": {
          width: 24,
          height: 24,
        },
        "& .MuiCircularProgress-svg": {
          color: theme.primary.brand.blue["400"],
          "& circle": {
            strokeWidth: 5,
          },
        },
        "&.MuiLoadingButton-loading:disabled": {
          background: theme.primary.brand.blue["gradient"],
          color: theme.primary.brand.blue["400"],
        },
      }}
      loading={isLoading}
      loadingPosition="start"
      {...restProps}
    >
      {children}
    </LoadingButton>
  );
};

export default Button;
