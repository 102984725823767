import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/system";

import AccountsList from "./components/AccountsList";
import CardsList from "./components/CardsList";
import { setStatement } from "store/slices/cardsSlice";
import { cardsSelector } from "store";
import { SkeletonContainer } from "components";

const AccountsAndCardsContainer = styled(Box)({
  backgroundColor: "#F2F4F9",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
  height: "fit-content",
  width: "100%",
});

export const AccountsAndCards: React.FC = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(cardsSelector);

  useEffect(() => {
    dispatch(
      setStatement({
        accId: "%",
        forceRefresh: false,
      })
    );
  }, [dispatch]);

  return (
    <Box>
      {isLoading ? (
        <SkeletonContainer height="272px" isLoading={isLoading} width="100%" />
      ) : (
        <AccountsAndCardsContainer>
          <AccountsList />
          <CardsList />
        </AccountsAndCardsContainer>
      )}
    </Box>
  );
};
