import { Dialog, Typography, Box } from "@mui/material";
import { styled } from "@mui/styles";
import { useIsMobile } from "hooks/useIsMobile";
import { Footer } from "components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { hideErrorMessage } from "store/slices/alerts";
import IconClose from "atoms/IconClose/IconClose";
import ArrowLeft from "./assets/ArrowLeft";
import { Header } from "organisms/Header/Header";
import UnregisteredHeader from "organisms/UnregisteredHeader";
import { authSelector, profileSelector } from "store";
import { authActions } from "store/slices/auth";
import { useNavigate } from "react-router-dom";
import { useResetStore } from "hooks/useResetStore";
const DialogContainer = styled(Dialog)(({ theme, top, isMobile }) => ({
  "& .MuiPaper-root": {
    margin: 0,
    padding: 0,
    paddingTop: 0,
    boxSizing: "border-box",
    maxHeight: "100%",
    maxWidth: "490px",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: top && isMobile ? top : 0,
    boxShadow: top && isMobile && "none",
    right: 0,
    borderRadius: 0,
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      boxSizing: "border-box",
      maxHeight: "100%",
      width: "100%",
      overflowX: "hidden",
      padding: theme.spacing(16),
      paddingTop: 0,
    },
  },
}));

export const Modal = ({
  title,
  isOpen,
  onClose,
  buttonVisible,
  callback,
  children,
  centerText = false,
  ...props
}) => {
  const { isMobile } = useIsMobile();
  const top = props.top;
  const dispatch = useDispatch();
  const { isAuthenticated, isConfirmed } = useSelector(authSelector);
  const { isLoginUpdated, isPasswordUpdated } = useSelector(profileSelector);
  const navigate = useNavigate();
  const resetStore = useResetStore();

  const handleClose = () => {
    if (isLoginUpdated || isPasswordUpdated) {
      dispatch(authActions.setLogout());
      const deviceIdItem = localStorage.getItem("deviceId");
      const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
      localStorage.clear();
      if (deviceIdItem !== null) localStorage.setItem("deviceId", deviceIdItem);
      if (PWAdeviceIdItem !== null)
        localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
      sessionStorage.clear();
      navigate("/sign-in", { replace: true });
      resetStore();
    } else {
      onClose();
      dispatch(hideErrorMessage());
    }
  };

  const onBack = () => {
    if (buttonVisible) {
      callback();
    } else {
      onClose();
    }
    dispatch(hideErrorMessage());
  };

  const paddingX = isMobile ? 20 : 32;
  const ptTitle = title ? 24 : 12;
  const pbTitle = title ? 20 : 12;

  const header =
    isAuthenticated && isConfirmed ? <Header /> : <UnregisteredHeader />;

  return (
    <DialogContainer
      hideBackdrop={top && isMobile}
      open={isOpen}
      onClose={handleClose}
      {...props}
      isMobile={isMobile}
    >
      {isMobile ? (
        header
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          boxShadow="0 4px 12px rgba(235, 237, 243, 0.62)"
          py={27}
          px={paddingX}
        >
          <Typography variant="text_8" color="gray.b900">
            {title}
          </Typography>
          {!isLoginUpdated && !isPasswordUpdated && (
            <Box
              sx={{ cursor: "pointer" }}
              display="flex"
              onClick={handleClose}
            >
              <IconClose width={13.2} height={13.2} color="#393E46" />
            </Box>
          )}
        </Box>
      )}
      {(isMobile || buttonVisible) && !isLoginUpdated && !isPasswordUpdated && (
        <Box
          sx={{ cursor: "pointer" }}
          px={paddingX}
          pt={isMobile ? ptTitle : 36}
          pb={isMobile ? pbTitle : 28}
        >
          <Box
            bgcolor="gray.b100"
            gap={10}
            display="flex"
            alignItems="center"
            borderRadius={8}
            width="fit-content"
            padding="4px 8px"
            onClick={onBack}
          >
            <ArrowLeft />
            <Typography variant="text_2" color="gray.b700">
              Назад
            </Typography>
          </Box>
        </Box>
      )}
      {isMobile && title && (
        <Box pt={20} pb={36} px={paddingX} textAlign={centerText && "center"}>
          <Typography variant="text_8" fontWeight={500}>
            {title}
          </Typography>
        </Box>
      )}
      {children}
      {isMobile && title && (
        <Box pt={140} pb={top && 67}>
          <Footer />
        </Box>
      )}
    </DialogContainer>
  );
};
