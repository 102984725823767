export const LogoutIcon = () => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.031 5.667a2.25 2.25 0 0 1 2.25 2.25v.425a.75.75 0 1 0 1.5 0v-.425a3.75 3.75 0 0 0-3.75-3.75H6a3.75 3.75 0 0 0-3.75 3.75v8.708A3.75 3.75 0 0 0 6 20.375h5.031a3.75 3.75 0 0 0 3.75-3.75v-.469a.75.75 0 1 0-1.5 0v.469a2.25 2.25 0 0 1-2.25 2.25H6a2.25 2.25 0 0 1-2.25-2.25V7.917A2.25 2.25 0 0 1 6 5.667h5.031Zm-.281 6.83h9.632l-3.338 3.26a.75.75 0 0 0 1.048 1.073l4.235-4.135a.75.75 0 0 0 0-1.073l-4.235-4.136a.75.75 0 0 0-1.048 1.073l2.497 2.439H10.75a.75.75 0 0 0 0 1.5Z"
      fill="#0082BA"
    />
  </svg>
);
