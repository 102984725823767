import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { formatAmount } from "utils/formatCurrency";
import CardBG from "components/public/images/CardBG.png";
import { useDispatch } from "react-redux";
import {
  setIsAccountInfoOpen,
  setIsCardInfoOpen,
} from "store/slices/cardsSlice";
import IconArrowRight from "widgets/AccountsAndCards/components/IconArrowRight";
import { CARD_STATUS } from "components/CardsInfo/model";
import MiniatureCardBlocked from "widgets/AccountsAndCards/components/images/MiniatureCardBlocked";
import MiniatureCardFrozen from "widgets/AccountsAndCards/components/images/MiniatureCardFrozen";
import { useIsPWA } from "hooks/useIsPWA";

const CardInfoItemContainer = styled(Box)({
  display: "flex",
  columnGap: "9px",
  alignItems: "center",
  cursor: "pointer",
  backgroundColor: "#F3F5F8",
  position: "relative",
});

const CardMiniature = styled("img")({
  width: "78px",
  height: "48px",
  borderRadius: "6px",
});

const ArrowContainer = styled("div")({
  position: "absolute",
  right: "15px",
  top: "28px",
});

export const ConnectedCards = ({ cards }: { cards: any }) => {
  const dispatch = useDispatch();
  const openCard = (index: any) => {
    dispatch(setIsAccountInfoOpen(index));
    dispatch(setIsCardInfoOpen(index));
  };
  const isPWA = useIsPWA();
  return (
    <Box mt={{ sm: 28, md: 44 }}>
      <Typography
        display="flex"
        mb={{ sm: 20, md: 24 }}
        color="#475467"
        variant="text_10"
        fontWeight="500"
      >
        Привязанные карты
      </Typography>
      <Box>
        {cards.map((card, index) => {
          const shortCardPan = card.cardPan.slice(-6);
          const cardAmount = formatAmount(card.amount, card.currency?.sign);

          return (
            <>
              <CardInfoItemContainer
                p={{ sm: "13px 12px", md: 20 }}
                mb={{ sm: 8, md: 16 }}
                borderRadius={{ sm: "12px", md: "20px" }}
                onClick={() => openCard(index)}
              >
                <Box sx={{ display: "flex", position: "relative" }}>
                  {card.status === CARD_STATUS.Active && (
                    <>
                      <CardMiniature src={CardBG} />
                      <Typography
                        variant="text_3"
                        sx={{
                          color: "#FFFFFF",
                          position: "absolute",
                          bottom: 0,
                          width: "100%",
                          backdropFilter: "blur(1px)",
                          borderBottomLeftRadius: "6px",
                          borderBottomRightRadius: "6px",
                        }}
                      >
                        {shortCardPan}
                      </Typography>
                    </>
                  )}
                  {card.status === CARD_STATUS.Temporary && (
                    <MiniatureCardFrozen />
                  )}
                  {CARD_STATUS.Blocked.includes(card.status as string) && (
                    <MiniatureCardBlocked />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Typography
                    noWrap
                    variant="text_13"
                    sx={{ color: "#2E3238", fontWeight: 500 }}
                  >
                    {cardAmount}
                  </Typography>
                  <Typography
                    noWrap
                    variant="text_3"
                    sx={{
                      color: "#8993A4",
                      width: "70%",
                      wordBreak: "break-word",
                    }}
                  >
                    {card.description}
                  </Typography>
                  {isPWA && (
                    <ArrowContainer>
                      <IconArrowRight />
                    </ArrowContainer>
                  )}
                </Box>
              </CardInfoItemContainer>
            </>
          );
        })}
      </Box>
    </Box>
  );
};
