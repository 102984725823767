import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Button } from "components";
import IconOutlineCard from "./Icons/IconOutlineCard";
import { useSelector } from "react-redux";
import { getPaymentReport } from "../store/slices/transferSlice";
import IconRefreshReverse from "./Icons/IconRefreshReverse";
import IconDownload from "./Icons/IconDownload";
import { useAppThunkDispatch } from "../store";
import { formatCurrency } from "utils/formatCurrency";
import { FileType, downloadFile } from "utils/downloadFile";

export const OperationCheck = ({
  confirmedTransfer,
  onClose,
  payloadModal,
}: any) => {
  const theme = useTheme();
  const { gray } = theme.palette;
  const dispatch = useAppThunkDispatch();
  const {
    transfer: { isCheckLoading },
    cards: {
      account: { currency },
    },
  } = useSelector((state: any) => state);

  const [paymentId, setPaymentId] = useState();
  const [integerPart, setIntegerPart] = useState<number | null>(null);
  const [fractionalPart, setFractionalPart] = useState("");
  const [confirmedTransferValue, setConfirmedTransferValue] = useState([
    { title: "", value: "" },
  ]);

  const arrConfirmedTransfer = useMemo(
    () => [
      {
        title: "Карта списания",
        key: "cardDescription",
      },
      {
        title: "Номер карты получателя",
        key: "toCardPan",
      },
      {
        title: "Банк получателя",
        key: "toClientBank",
      },
      {
        title: "Сумма перевода",
        key: "amount",
      },
      {
        title: "Комиссия",
        key: "fee",
      },
      {
        title: "Дата и время",
        key: "dateTime",
      },
      {
        title: "Сообщение",
        key: "reason",
      },
    ],
    []
  );

  useEffect(() => {
    let arr: any = [];
    if (confirmedTransfer) {
      arr = arrConfirmedTransfer.map((el) => {
        return { title: el.title, value: confirmedTransfer[el.key] };
      });
    }
    setConfirmedTransferValue(arr);
    setPaymentId(confirmedTransfer?.paymentId);
  }, [confirmedTransfer, arrConfirmedTransfer]);

  useEffect(() => {
    const parts = confirmedTransferValue[3]?.value.toString().split(".");
    if (parts) {
      setIntegerPart(parseInt(parts[0]));
      setFractionalPart((parts[1] || "0").padEnd(2, "0"));
    }
  }, [confirmedTransferValue]);

  const downloadCheck = () => {
    if (paymentId) {
      dispatch(getPaymentReport(paymentId))
        .unwrap()
        .then((res) => {
          downloadFile(res, "check.pdf", FileType.PDF);
        });
    }
  };

  const beautyValue = (element: any) => {
    if (element.title === "Дата и время") {
      // "2023-03-05T13:36:10.097114+03:00" т.к. дата всегда статична:
      let datePlusTime = element.value.substring(0, 16).split("T");
      let date = datePlusTime[0].split("-").reverse().join(".");
      let time = datePlusTime[1];
      return date + " " + time;
    } else if (
      element.title === "Сумма перевода" ||
      element.title === "Комиссия"
    ) {
      return formatCurrency(element.value, true);
    } else {
      return element.value;
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        height={180}
        bgcolor={gray.b50}
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingTop="16px"
        mt={16}
        mb={32}
        borderRadius="16px"
      >
        <IconOutlineCard />
        <Typography variant="text_7" color="gray.b900" fontWeight={500} mt={10}>
          Перевод
        </Typography>
        <Typography variant="text_8" color="gray.b900" fontWeight={600} mt={4}>
          <Typography
            variant="text_8"
            color="gray.b900"
            fontWeight={600}
            mt={4}
          >
            -{integerPart}
          </Typography>
          <Typography
            variant="text_8"
            color={theme.primary.main.gray?.[300]}
            fontWeight={600}
            mt={4}
          >
            ,{`${fractionalPart} `}
          </Typography>
          {currency.sign}
        </Typography>
      </Box>

      {confirmedTransferValue.map((el, i) => {
        if (el.value) {
          return (
            <Box
              key={i}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              borderBottom={`0.5px solid ${theme.palette.gray.b100}`}
              style={{ wordBreak: "break-word" }}
              pb={16}
              gap={4}
              mb={16}
            >
              <Typography variant="text_3" color="gray.b300" fontWeight={400}>
                {el?.title}
              </Typography>
              <Typography variant="text_5" color="gray.b800" fontWeight={500}>
                {beautyValue(el)}
              </Typography>
            </Box>
          );
        }
      })}

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        mt={44}
        mb={16}
        gap={12}
      >
        <Button
          variant="primary"
          size="lg"
          startIcon={<IconRefreshReverse />}
          onClick={() => onClose("repeatVal", payloadModal)}
        >
          Повторить
        </Button>
        <Button
          variant="secondary"
          size="lg"
          startIcon={<IconDownload />}
          onClick={downloadCheck}
          isLoading={isCheckLoading}
        >
          Скачать чек
        </Button>
      </Box>
    </Box>
  );
};
