import { Modal } from "molecules/Modal/Modal";
import Box from "@mui/material/Box";

import { useSelector } from "react-redux";
import { TransferCheck } from "./TransferCheck";
import { TransferConfirmed } from "./TransferConfirmed";
import { TransferOTP } from "./TransferOTP";
import { useEffect, useState } from "react";
import { SkeletonContainer } from "../../../../components";
import { RootState } from "store";
import { TransferError } from "./TransferError";
import { TRANSFER_ERROR_CODE } from "store/slices/transferSlice";
import { useIsMobile } from "hooks/useIsMobile";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  payloadModal: object;
};

export const TransfersModal: React.FC<Props> = ({
  isOpen,
  onClose,
  payloadModal,
}) => {
  const {
    transfer: {
      createdTransfer,
      isLoading,
      isTransferChecked,
      confirmedTransfer,
      isTransferConfirmed,
      errorMessage,
    },
  } = useSelector((state: RootState) => state);
  const [titleName, setTitleName] = useState("");
  const [chooseCard, setChooseCard] = useState(false);
  const { isMobile } = useIsMobile();

  const showErrorScreen =
    errorMessage.code === TRANSFER_ERROR_CODE.PAYMENT_ERROR ||
    errorMessage.code === TRANSFER_ERROR_CODE.PAYMENT_RESPONSE_ERROR;

  useEffect(() => {
    if (createdTransfer && !isTransferChecked && !chooseCard) {
      setTitleName("Перевод");
    }
    if (createdTransfer && !isTransferChecked && chooseCard) {
      setTitleName("Карты");
    }
    if (isTransferChecked && !confirmedTransfer) {
      setTitleName("Подтверждение операции");
    }
    if (isTransferConfirmed) {
      setTitleName("Перевод");
    }
    if (showErrorScreen) {
      if (isMobile) {
        setTitleName("");
      } else {
        setTitleName("Ошибка");
      }
    }
  }, [
    createdTransfer,
    chooseCard,
    isTransferChecked,
    isTransferConfirmed,
    confirmedTransfer,
    showErrorScreen,
    isMobile,
  ]);

  const stepBack = () => {
    if (chooseCard) {
      setChooseCard(false);
    } else {
      onClose();
    }
  };

  return (
    // @ts-ignore
    <Modal title={titleName} isOpen={isOpen} onClose={stepBack}>
      <Box mx={{ lg: 48, sm: 16 }} height="100%">
        <SkeletonContainer isLoading={isLoading} width="400px" height="500px">
          {!isLoading && createdTransfer && !isTransferChecked && (
            <TransferCheck
              payloadModal={payloadModal}
              onClose={onClose}
              setChooseCard={setChooseCard}
              chooseCard={chooseCard}
            />
          )}
          {isTransferChecked && !confirmedTransfer && !showErrorScreen && (
            <TransferOTP />
          )}
          {showErrorScreen && <TransferError />}
          {isTransferConfirmed && (
            <TransferConfirmed onClose={onClose} payloadModal={payloadModal} />
          )}
        </SkeletonContainer>
      </Box>
    </Modal>
  );
};
