/* eslint-disable no-console */
import { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { MainContainer } from "layouts";
import { Modal } from "molecules/Modal/Modal";
import { Cards } from "./Cards";
import { getUserInfo } from "store/slices/profile/asyncThunks";
import { Profile } from "../profile";
import {
  RootState,
  cardsSelector,
  notificationSelector,
  profileSelector,
  systemSelector,
} from "store";
import { profileActions } from "store/slices/profile";
import {
  checkNotifications,
  notificationActions,
} from "store/slices/notifications";
import { useIsMobile } from "hooks/useIsMobile";
import Notifications from "organisms/Notifications/Notifications";
import News from "organisms/Notifications/News";
import {
  MobileTabVariants,
  systemActions,
  TabVariants,
} from "store/slices/system";
import { AccountsAndCards, ChatWidget, ProfileLink } from "widgets";
import { sectionTitles } from "constants/sectionTitles";
import { CardInfo } from "pages/cards/modal/cardsSlider/CardInfo";
import {
  cardActionsToggle,
  setIsAccountInfoOpen,
  setIsCardInfoOpen,
} from "store/slices/cardsSlice";
import { CardActions } from "components/CardsInfo/CardActions";
import { AccountInfo } from "pages/cards/modal/AccountModal/AccountInfo";
import { IconAccessBiometry } from "atoms/IconAccessBiometry";
import IconClose from "atoms/IconClose/IconClose";
import { MainSlider } from "components/MainSlider";
import { AnotherPages } from "store/slices/system/slice";
import { ExchangeRates } from "components/ExchangeRates";
import { IconErrorBiometry } from "atoms/IconErrorBiometry";
import { ErrorFullScreen } from "components/ErrorFullScreen";
import { TRANSFER_ERROR_CODE } from "store/slices/transferSlice";
import { FinancialAnalysisWidget } from "./Cards/History/components/FinancialAnalysisWidget/FinancialAnalysisWidget";
import { useIsPWA } from "hooks/useIsPWA";
import { FinancialAnalysisMobilePage } from "./Cards/History/components/FinancialAnalysisWidget/FinancialAnalysisMobilePage";
import {
  HISTORY_TABS,
  appendExpenseList,
  appendIncomeList,
  getCategoryExpense,
  getCategoryIncome,
  getExpenseList,
  getIncomeList,
  setShowMobileFinancialAnalysisPage,
} from "store/slices/historySlice";
import { NewsInfo } from "organisms/Notifications/NewsInfo";

const Home = () => {
  const dispatch = useDispatch();
  const { isModalOpened, user, isBiometryConnected } =
    useSelector(profileSelector);
  const { selected } = useSelector(notificationSelector);
  const { isCardInfoOpen, isAccountInfoOpen, isCardActionsOpen } =
    useSelector(cardsSelector);

  const pushType = localStorage.getItem("pushType");

  const isPWA = useIsPWA();
  const {
    transfer: { errorMessage },
    history,
    cards,
  } = useSelector((state: RootState) => state);

  const {
    date,
    showMobileFinancialAnalysisPage,
    hideWidget,
    activeCategoryId,
    widgetData,
    historyList,
    expenseList,
    incomeList,
  } = history;

  const data = {
    [HISTORY_TABS.ALL_OPERATIONS]: historyList,
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  const payload = {
    from: date.from,
    to: date.to,
    operationFilterType: undefined,
    page: 0,
    acctId: cards.account.accountNumber,
    search: "",
    fromAmount: undefined,
    toAmount: undefined,
    cardId: "",
    size: 10,
    categoryIds: undefined,
  };

  const [initialState, setInitialState] = useState(payload);

  const { activeTab, chatIsOpen, newsIsOpen, notificationsIsOpen } =
    useSelector(systemSelector);

  const { isMobile } = useIsMobile();

  const handleProfileClose = useCallback(() => {
    dispatch(profileActions.setIsModalOpened(false));
  }, [dispatch]);

  const handleCardModalClose = () => {
    dispatch(setIsCardInfoOpen(0));
  };

  const handleAccountModalClose = () => {
    dispatch(setIsAccountInfoOpen(0));
  };

  useEffect(() => {
    setInitialState(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards.account.accountNumber, date.from, date.to]);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(checkNotifications());
  }, [dispatch]);

  useEffect(() => {
    dispatch(systemActions.setInitialTab({ isMobile }));
  }, [dispatch, isMobile]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(profileActions.setBiometryConnected(""));
    }, 5000);
  }, [dispatch, isBiometryConnected]);

  const onBackNews = () => dispatch(notificationActions.clearSelected());

  const onCloseNotifications = () => {
    if (isMobile) {
      dispatch(systemActions.setBack());
    } else {
      dispatch(systemActions.setNotificationsOpen(false));
    }
  };
  const onCloseNews = () => {
    if (isMobile) {
      dispatch(systemActions.setBack());
    } else {
      dispatch(systemActions.setNewsOpen(false));
    }
  };
  const onCloseChat = () => {
    if (isMobile) {
      dispatch(systemActions.setBack());
    } else {
      dispatch(systemActions.setChatOpen(false));
    }
  };
  const showTransferError =
    activeTab === MobileTabVariants.transfers &&
    errorMessage.title.length &&
    errorMessage.code !== TRANSFER_ERROR_CODE.CARD &&
    errorMessage.code !== TRANSFER_ERROR_CODE.PAYMENT_ERROR &&
    errorMessage.code !== TRANSFER_ERROR_CODE.PAYMENT_RESPONSE_ERROR &&
    errorMessage.code !== TRANSFER_ERROR_CODE.MESSAGE;

  const displayCardsAndAccountsBlock =
    (isMobile && activeTab === MobileTabVariants.main) ||
    (!isMobile && activeTab !== AnotherPages.promotion);

  const onToggleCardActions = () => dispatch(cardActionsToggle());

  const handleLoadMore = () => {
    const len = data[history.activeTab].reduce(
      // @ts-ignore
      (acc, it) => it.statements.length + acc,
      0
    );
    const isEndOfSlice = len % 10 === 0;
    const isSamePage = Math.floor(len / 10) === initialState.page;

    const body = {
      ...initialState,
      page: Math.floor(len / 10),
    };

    if (!isSamePage && isEndOfSlice) {
      if (history.activeTab === HISTORY_TABS.EXPENSES) {
        dispatch(appendExpenseList(body));
      }
      if (history.activeTab === HISTORY_TABS.INCOMES) {
        dispatch(appendIncomeList(body));
      }
      setInitialState(body);
    }
  };

  useEffect(() => {
    const dateNow = new Date();
    const firstDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth(),
      1
    );
    const format1 = new Date(firstDayMonth.toISOString()).toLocaleDateString(
      "ru"
    );
    const format2 = new Date(initialState.from).toLocaleDateString("ru");
    if (
      initialState.acctId &&
      history.activeTab === HISTORY_TABS.EXPENSES &&
      showMobileFinancialAnalysisPage &&
      format1 !== format2
    ) {
      dispatch(getExpenseList(initialState));
      dispatch(getCategoryExpense(initialState));
    }
    if (
      initialState.acctId &&
      history.activeTab === HISTORY_TABS.INCOMES &&
      showMobileFinancialAnalysisPage &&
      format1 !== format2
    ) {
      dispatch(getIncomeList(initialState));
      dispatch(getCategoryIncome(initialState));
    }
    if (
      !showMobileFinancialAnalysisPage &&
      initialState.acctId &&
      history.activeTab === HISTORY_TABS.EXPENSES
    ) {
      dispatch(
        getCategoryExpense({
          ...initialState,
          page: 0,
          from: firstDayMonth.toISOString(),
          to: dateNow.toISOString(),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState.from, initialState.to, showMobileFinancialAnalysisPage]);

  useEffect(() => {
    if (
      !activeCategoryId &&
      initialState.categoryIds &&
      showMobileFinancialAnalysisPage
    ) {
      setInitialState((prev) => ({
        ...prev,
        categoryIds: undefined,
        page: 0,
      }));
      if (history.activeTab === HISTORY_TABS.EXPENSES) {
        dispatch(
          getExpenseList({
            ...payload,
            categoryIds: undefined,
            page: 0,
          })
        );
      }
      if (history.activeTab === HISTORY_TABS.INCOMES) {
        dispatch(
          getIncomeList({
            ...payload,
            categoryIds: undefined,
            page: 0,
          })
        );
      }
    }
    if (
      activeCategoryId &&
      activeCategoryId !== initialState.categoryIds &&
      showMobileFinancialAnalysisPage
    ) {
      const activeCategory = widgetData?.filter(
        (category) => category.id === activeCategoryId
      )[0].filterCategories;
      // @ts-ignore
      setInitialState((prev) => ({
        ...prev,
        categoryIds: [...(activeCategory || [])],
        page: 0,
      }));
      if (history.activeTab === HISTORY_TABS.EXPENSES) {
        dispatch(
          getExpenseList({
            ...payload,
            categoryIds: [...(activeCategory || [])],
            page: 0,
          })
        );
      }
      if (history.activeTab === HISTORY_TABS.INCOMES) {
        dispatch(
          getIncomeList({
            ...payload,
            categoryIds: [...(activeCategory || [])],
            page: 0,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategoryId, showMobileFinancialAnalysisPage]);

  useEffect(() => {
    if (pushType === "MESSAGE" && activeTab !== MobileTabVariants.chat) {
      setTimeout(() => {
        dispatch(systemActions.setActiveTab({ tab: MobileTabVariants.chat }));
        localStorage.removeItem("pushType");
      }, 500);
    }
    if (pushType === "NEWS" && activeTab !== TabVariants.news) {
      setTimeout(() => {
        dispatch(systemActions.setActiveTab({ tab: TabVariants.news }));
        localStorage.removeItem("pushType");
      }, 500);
    }
    if (
      pushType === "NOTIFICATION" &&
      activeTab !== TabVariants.notifications
    ) {
      setTimeout(() => {
        dispatch(
          systemActions.setActiveTab({ tab: TabVariants.notifications })
        );
        localStorage.removeItem("pushType");
      }, 500);
    }
  }, [activeTab, dispatch, pushType]);

  return (
    <MainContainer>
      {activeTab === TabVariants.main && !isMobile && (
        <Grid item xs={12} xl={12}>
          <MainSlider />
        </Grid>
      )}
      {sectionTitles?.[activeTab]?.desktop &&
        sectionTitles?.[activeTab]?.mobile && (
          <Grid item xs={12} xl={12}>
            <Box p={{ xs: "24px 0 28px 0", xl: "40px 0 40px 0" }}>
              {showTransferError ? (
                <ErrorFullScreen
                  title={errorMessage.title}
                  description={errorMessage.subtitle}
                />
              ) : (
                <Typography
                  variant="text_14"
                  sx={{ fontWeight: 500, fontSize: "24px !important" }}
                >
                  {isMobile
                    ? sectionTitles?.[activeTab]?.mobile
                    : sectionTitles?.[activeTab]?.desktop}
                </Typography>
              )}
            </Box>
          </Grid>
        )}
      {(isBiometryConnected === "connected" ||
        isBiometryConnected === "error") && (
        <Box
          sx={{
            position: "fixed",
            top: 88,
            left: 20,
            right: 20,
            padding: 16,
            backgroundColor:
              isBiometryConnected === "connected" ? "#ECFDF3" : "#FFFAEB",
            borderRadius: 16,
            display: "flex",
            alignItems: "flex-start",
            gap: 4,
            zIndex: 10,
            "-webkit-box-shadow": "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
            "-moz-box-shadow": "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
            boxShadow: "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
          }}
        >
          {isBiometryConnected === "connected" ? (
            <IconAccessBiometry />
          ) : (
            <IconErrorBiometry />
          )}
          <Typography
            variant="text_3"
            color="#475467"
            width="90%"
            fontWeight="400"
          >
            {isBiometryConnected === "connected" ? (
              <>
                Вход по Face и Touch ID успешно
                <br />
                подключён
              </>
            ) : (
              <>
                При подключении входа по Face
                <br />и Touch ID возникла ошибка
              </>
            )}
          </Typography>

          <IconClose
            color={isBiometryConnected === "connected" ? "#12B76A" : "#F79009"}
            onClick={() => {
              dispatch(profileActions.setBiometryConnected(""));
            }}
          />
        </Box>
      )}

      {displayCardsAndAccountsBlock && (
        <>
          {isMobile && (
            <Grid item xs={12} xl={4}>
              <Box pt={24} pb={28}>
                <ProfileLink />
                {activeTab === MobileTabVariants.main && <MainSlider />}
              </Box>
            </Grid>
          )}
          <Grid item xs={12} xl={4}>
            <div
              style={{
                marginTop:
                  activeTab === MobileTabVariants.main
                    ? isMobile
                      ? "20px"
                      : "40px"
                    : "0px",
              }}
            >
              <AccountsAndCards />
            </div>
            {activeTab === MobileTabVariants.main && (
              <>
                {!hideWidget && (
                  <Box mt={20}>
                    <FinancialAnalysisWidget isPreview={true} />
                  </Box>
                )}
                <ExchangeRates />
              </>
            )}
          </Grid>
        </>
      )}
      <Grid item xs={12} xl={8}>
        <Cards />
      </Grid>
      <FinancialAnalysisMobilePage
        isOpen={showMobileFinancialAnalysisPage}
        onClose={() => dispatch(setShowMobileFinancialAnalysisPage(false))}
        loadMore={handleLoadMore}
      />
      <Modal
        title={isPWA ? "" : "Карты"}
        isOpen={isCardInfoOpen}
        onClose={handleCardModalClose}
        buttonVisible={isCardActionsOpen}
        callback={onToggleCardActions}
      >
        {isCardActionsOpen ? (
          <Box px={isMobile ? 20 : 32}>
            <CardActions />
          </Box>
        ) : (
          <CardInfo />
        )}
      </Modal>
      <Modal
        title="Счета"
        isOpen={isAccountInfoOpen}
        onClose={handleAccountModalClose}
        buttonVisible={isMobile}
        callback={handleAccountModalClose}
      >
        <AccountInfo />
      </Modal>
      <Modal
        title="Профиль"
        isOpen={isModalOpened}
        onClose={handleProfileClose}
        buttonVisible={isMobile}
        callback={handleProfileClose}
      >
        <Profile phoneNumber={user?.phone as string} />
      </Modal>
      <Modal
        title={isMobile ? "" : "Чат с оператором"}
        isOpen={isMobile ? activeTab === MobileTabVariants.chat : chatIsOpen}
        onClose={onCloseChat}
        buttonVisible={isMobile}
        callback={onCloseChat}
      >
        <ChatWidget />
      </Modal>
      <Modal
        title="Уведомления"
        isOpen={
          isMobile
            ? activeTab === TabVariants.notifications
            : notificationsIsOpen
        }
        onClose={onCloseNotifications}
        buttonVisible={isMobile}
        callback={onCloseNotifications}
      >
        <Notifications />
      </Modal>
      <Modal
        title={selected?.title || "Новости"}
        isOpen={isMobile ? activeTab === TabVariants.news : newsIsOpen}
        onClose={onCloseNews}
        buttonVisible={!isEmpty(selected)}
        callback={onBackNews}
      >
        <News />
      </Modal>
      <Modal
        title={selected?.title}
        isOpen={!isEmpty(selected)}
        onClose={onBackNews}
        buttonVisible={!isEmpty(selected)}
        callback={onBackNews}
      >
        <NewsInfo {...selected} />
      </Modal>
    </MainContainer>
  );
};

export default Home;
