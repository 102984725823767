import { Modal } from "molecules/Modal/Modal";
import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { Button } from "../Button";
import { SmsInput } from "molecules/SmsInput/SmsInput";
import { useCounter } from "hooks";
import { getSecondsPluralForms } from "utils";
import React, { useState } from "react";
import { retryOneTimePassword } from "store/slices/auth";
import { getCardCvvInfo } from "store/slices/cardsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useAppThunkDispatch } from "store";
import { CardCvvDto } from "api/account";
import { useIsMobile } from "hooks/useIsMobile";
import ModalPopUp from "molecules/Modal/ModalPopUp";

type Props = {
  isOpen: boolean;
  verifyCvvToken: string;
  setCvv: (val: string | undefined) => void;
  setCvvModalVisible: (val: boolean) => void;
  setRequisitesCard: (val: object) => void;
};

export const CvvModal = ({
  isOpen,
  verifyCvvToken,
  setCvv,
  setCvvModalVisible,
}: Props) => {
  const [code, setCode] = useState<string>("");
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const theme = useTheme();
  const dispatch = useDispatch();
  const dispatchApp = useAppThunkDispatch();
  const {
    cards: { isLoading, error, mainCard },
    profile: { user },
  } = useSelector((state: any) => state);
  const phoneLastNum = `${(user?.phone || "").slice(-4, -2)} ${(
    user?.phone || ""
  ).slice(-2)}`;
  const { isMobile } = useIsMobile();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCode(e.target.value);
  };

  const handleConfirm = () => {
    dispatchApp(
      getCardCvvInfo({
        cardUID: mainCard?.cardId,
        verifyToken: verifyCvvToken,
        verifyCode: code,
      })
    )
      .unwrap()
      .then((res: CardCvvDto) => {
        setCvvModalVisible(false);
        setCvv(res.cvv);
        setCode("");
      })
      .catch((err) => {
        return err;
      });
  };

  const handleResendCode = () => {
    dispatch(retryOneTimePassword(verifyCvvToken));
    restartCounter();
  };

  const SMSActions = (
    <>
      <Box>
        <SmsInput
          placeholder="Введите код"
          label="Код"
          name="code"
          value={code}
          onChange={handleChange}
          error={error}
        />
      </Box>
      <Box>
        <Box
          display="flex"
          flexDirection="column"
          rowGap={count > 0 ? 44 : 12}
          mt={42}
        >
          {count === 0 ? (
            <Button
              onClick={handleResendCode}
              variant="secondary"
              size="lg"
              fullWidth
            >
              Отправить код повторно
            </Button>
          ) : (
            <Box minHeight={48} textAlign="center">
              <Typography variant="text_5" color="gray.b400">
                Вы сможете отправить код повторно через{" "}
                <Typography variant="text_5" color="blue.b400">
                  {count} {secondsWord}.
                </Typography>
              </Typography>
            </Box>
          )}
          <Button
            onClick={handleConfirm}
            variant="primary"
            size="lg"
            fullWidth
            disabled={code.length < 4}
            isLoading={isLoading}
          >
            Продолжить
          </Button>
        </Box>
      </Box>
    </>
  );

  const onClose = () => setCvvModalVisible(false);

  if (isMobile) {
    return (
      <ModalPopUp isOpen={isOpen} onClose={onClose}>
        <Typography
          variant="text_18"
          color="#393E46"
          fontWeight={500}
          whiteSpace="pre-line"
          mb={8}
        >
          Введите код из сообщения, чтобы посмотреть CVV-код карты
        </Typography>
        <Typography
          variant="text_4"
          color="#475467"
          fontWeight={400}
          whiteSpace="pre-line"
          mb={20}
        >
          На номер{" "}
          <span style={{ color: theme.palette.blue.b400 }}>
            *** {phoneLastNum}
          </span>{" "}
          был отправлен код подтверждения
        </Typography>
        {SMSActions}
      </ModalPopUp>
    );
  }

  return (
    <Modal
      title="Карты"
      isOpen={isOpen}
      buttonVisible
      callback={onClose}
      onClose={onClose}
    >
      <Box mx={32}>
        <Box display="flex" flexDirection="column" gap={24}>
          <Typography
            variant="text_18"
            color="#393E46"
            fontWeight={500}
            whiteSpace="pre-line"
          >
            Введите код из сообщения, чтобы посмотреть CVV-код карты
          </Typography>
          <Typography
            variant="text_5"
            color="#475467"
            fontWeight={400}
            whiteSpace="pre-line"
          >
            На номер{" "}
            <span style={{ color: theme.palette.blue.b400 }}>
              *** {phoneLastNum}
            </span>{" "}
            был отправлен код подтверждения
          </Typography>
        </Box>

        <Box
          component="form"
          sx={{
            backgroundColor: theme.palette.gray.b50,
          }}
          px={{ xs: 20, lg: 25 }}
          py={32}
          display="flex"
          flexDirection="column"
          border={`1px solid ${theme.palette.gray.b100}`}
          borderRadius={24}
          mt={12}
          autoComplete="off"
        >
          {SMSActions}
        </Box>
      </Box>
    </Modal>
  );
};
