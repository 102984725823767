import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import { IconAccountRub } from "atoms/IconAccountRub";
import { formatAmount } from "utils/formatCurrency";
import { setIsAccountInfoOpen } from "store/slices/cardsSlice";

const AccountsListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "12px",
  padding: "20px",
  backgroundColor: "#F7F8FA",
  borderRadius: "20px",
  boxShadow: "0px 2px 10px rgba(194, 201, 214, 0.25)",
});

const AccountsContainer = styled(Box)({
  display: "flex",
  columnGap: "12px",
  cursor: "pointer",
});

const AccountsList = () => {
  const dispatch = useDispatch();
  const {
    cards: {
      account: { accountNumber, availableBalance, currency, description },
    },
  } = useSelector((state: any) => state);

  const accountAmount = formatAmount(availableBalance, currency?.sign);

  return (
    <AccountsListContainer>
      <Typography variant="text_10" sx={{ color: "#475467", fontWeight: 500 }}>
        Счета
      </Typography>
      <Box>
        <AccountsContainer
          onClick={() => dispatch(setIsAccountInfoOpen(accountNumber))}
        >
          <Box sx={{ padding: "4px 0" }}>
            <IconAccountRub />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography
              variant="text_13"
              noWrap
              sx={{ color: "#2E3238", fontWeight: 500 }}
            >
              {accountAmount}
            </Typography>
            <Typography
              variant="text_4"
              noWrap
              sx={{ color: "#475467", width: "85%" }}
            >
              {description}
            </Typography>
          </Box>
        </AccountsContainer>
      </Box>
    </AccountsListContainer>
  );
};

export default AccountsList;
