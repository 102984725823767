import React from "react";

// <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <rect x="1.83337" y="6.5" width="12.3333" height="8.33333" rx="2.5" stroke="#F97066"/>
//   <path d="M4.66663 6.66699V4.00033C4.66663 3.11627 5.01782 2.26842 5.64294 1.6433C6.26806 1.01818 7.1159 0.666992 7.99996 0.666992C8.88401 0.666992 9.73186 1.01818 10.357 1.6433C10.9821 2.26842 11.3333 3.11627 11.3333 4.00033V6.66699" stroke="#F97066"/>
// </svg>

const IconClosed = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill="#EBEDF3"></circle>
      <g clipPath="url(#clip0_606_37079)">
        <path
          fill="#393E46"
          d="M12 20a8 8 0 110-16 8 8 0 010 16zm0-9.131l-1.697-1.697a.8.8 0 10-1.132 1.132L10.87 12l-1.697 1.697a.8.8 0 101.132 1.132L12 13.13l1.697 1.697a.8.8 0 101.132-1.132L13.13 12l1.697-1.697a.8.8 0 10-1.132-1.132L12 10.87z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_606_37079">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconClosed;
