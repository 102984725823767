import { ReactNode } from "react";
import Box from "@mui/material/Box";
import { RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";

type Props = {
  payload: {
    id?: number;
    title?: string;
    actionType?: string;
    srcJpg?: string;
    icon?: ReactNode;
    iconMobile?: ReactNode;
    subtitle?: string;
    subtitleMob?: string;
    textBtn?: string;
  };
  foreverBlock?: boolean;
  setBlockTypeAction?: (val: string) => void;
};
export const BlockConfirm = ({
  payload,
  foreverBlock,
  setBlockTypeAction,
}: Props) => {
  const { isMobile } = useIsMobile();
  const changeCause = (event: any) => {
    if (setBlockTypeAction) {
      setBlockTypeAction(event.target.value);
    }
  };

  const icon = isMobile ? payload.iconMobile : payload?.icon;

  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="text_8" color="#393E46" fontWeight={500} mb={8}>
        {payload?.title}?
      </Typography>
      {!isMobile && (
        <Typography
          variant="text_5"
          color="gray.b600"
          fontWeight={400}
          whiteSpace="pre-line"
          style={{ hyphens: "auto" }}
        >
          {payload?.subtitle}
        </Typography>
      )}
      <Box display="flex" justifyContent="center" mt={24}>
        {payload?.icon ? (
          icon
        ) : (
          <img
            src={payload?.srcJpg}
            alt="картинка"
            style={{
              width: 426,
              height: 150,
              maxWidth: "100%",
            }}
          />
        )}
      </Box>
      {isMobile && (
        <Typography
          variant="text_4"
          color="gray.b600"
          fontWeight={400}
          whiteSpace="pre-line"
          mt={24}
          style={{ hyphens: "auto" }}
        >
          {payload?.subtitleMob || payload?.subtitle}
        </Typography>
      )}
      {foreverBlock && (
        <RadioGroup style={{ marginTop: 24 }} onChange={changeCause}>
          <FormControlLabel
            value="STOLEN"
            control={<Radio />}
            label="Карта была украдена"
            sx={{ color: "#070417" }}
          />
          <FormControlLabel
            value="LOST"
            control={<Radio />}
            label="Карта была утеряна"
            sx={{ color: "#070417" }}
          />
        </RadioGroup>
      )}
    </Box>
  );
};
