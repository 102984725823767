import React from "react";
import { styled } from "@mui/styles";
import { Dialog } from "@mui/material";

const DialogStyled = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 12,
    marginRight: 20,
    marginLeft: 20,
    padding: "20px 28px 28px 28px",
  },
}));

const ModalPopUp = ({ isOpen, onClose, children }) => {
  return (
    <DialogStyled open={isOpen} onClose={onClose}>
      {children}
    </DialogStyled>
  );
};

export default ModalPopUp;
