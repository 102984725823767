import React, { FC } from "react";

const FrozenCardConfirm: FC = () => {
  return (
    <svg
      width="426"
      height="150"
      viewBox="0 0 426 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="426" height="150" rx="15" fill="#F2F4F9" />
      <path
        d="M297 110.5C297 114.918 293.418 118.5 289 118.5H137C132.582 118.5 129 114.918 129 110.5V42.5038C129 38.087 132.582 34.5058 136.999 34.5043C193.929 34.4848 247.278 34.5396 288.997 34.5137C293.418 34.5109 297 38.0938 297 42.5149V110.5Z"
        fill="white"
      />
      <path
        d="M278.936 90.0254H258.114C256.788 90.0254 255.712 91.9377 255.712 94.2966C255.712 96.6555 256.788 98.5678 258.114 98.5678H278.936C280.263 98.5678 281.339 96.6555 281.339 94.2966C281.339 91.9377 280.263 90.0254 278.936 90.0254Z"
        fill="#EBEDF3"
      />
      <path
        d="M210.153 90.0254H147.508C145.15 90.0254 143.237 91.9377 143.237 94.2966C143.237 96.6555 145.15 98.5678 147.508 98.5678H210.153C212.511 98.5678 214.424 96.6555 214.424 94.2966C214.424 91.9377 212.511 90.0254 210.153 90.0254Z"
        fill="#EBEDF3"
      />
      <path
        d="M213 71C226.255 71 237 60.2548 237 47C237 33.7452 226.255 23 213 23C199.745 23 189 33.7452 189 47C189 60.2548 199.745 71 213 71Z"
        fill="#0082BA"
      />
      <path
        d="M212.261 31.25V61.875"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M216.207 34.527C215.016 35.2447 213.652 35.6229 212.261 35.6207C210.871 35.6229 209.506 35.2447 208.315 34.527"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M208.315 58.5945C209.507 57.8788 210.871 57.5007 212.261 57.5007C213.651 57.5007 215.015 57.8788 216.207 58.5945"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.522 38.907L199 54.2194"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M224.652 43.9641C223.436 43.2898 222.427 42.2974 221.732 41.0932C221.037 39.889 220.683 38.5183 220.707 37.1282"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.87 49.1587C201.086 49.833 202.095 50.8255 202.79 52.0297C203.485 53.2339 203.839 54.6045 203.815 55.9947"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199 38.907L225.522 54.2194"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M199.87 43.9641C201.086 43.2898 202.095 42.2974 202.79 41.0932C203.485 39.889 203.839 38.5183 203.815 37.1282"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M224.652 49.1587C223.436 49.833 222.427 50.8255 221.732 52.0297C221.037 53.2339 220.683 54.6045 220.707 55.9947"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FrozenCardConfirm;
