import { ReactElement, useMemo, useState } from "react";

import RemoveIcon from "./assets/remove-icon.png";
import CheckedIcon from "./assets/check-contained-icon.png";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  ActionContainer,
  ActionTitle,
  CheckboxIcon,
  EmptyCheckbox,
  ItemCardActionsBlock,
  ItemCardContainer,
  ItemCardDot,
  ItemCardIconWrapper,
  ItemCardMenu,
  ItemCardNumber,
  ItemCardRemoveIcon,
  ItemCardTitle,
} from "./components";

interface ItemCardProps {
  title?: string;
  cardNumber?: string;
  checked: boolean;
  onChange: () => void;
  onDelete?: () => void;
  background?: string;
  icon: ReactElement;
  disabledMenu?: boolean;
}

export const ItemCard = ({
  title,
  checked,
  cardNumber,
  onChange,
  onDelete,
  background,
  icon,
  disabledMenu,
}: ItemCardProps) => {
  const [showActions, setShowActions] = useState(false);
  const actions = [
    {
      icon: <ItemCardRemoveIcon src={RemoveIcon} />,
      title: "Удалить карту",
      color: "#E64545",
      action: () => {
        if (onDelete) onDelete();
        setShowActions(false);
      },
    },
  ];

  const handleClickOutside = () => {
    setShowActions(false);
  };

  const rootElement = useOutsideClick(handleClickOutside);

  const itemCardMenuStyles = useMemo(
    () => ({
      background: showActions ? "#ebedf3" : "transparent",
      cursor: disabledMenu ? "auto" : "pointer",
    }),
    [showActions, disabledMenu]
  );

  return (
    <ItemCardContainer
      style={{
        background: showActions ? "#F7F8FA" : "transparent",
      }}
    >
      <ItemCardMenu
        disabled={disabledMenu}
        onClick={() => setShowActions((prevState) => !prevState)}
        style={itemCardMenuStyles}
      >
        <ItemCardDot />
        <ItemCardDot
          style={{
            marginTop: 2,
            marginBottom: 2,
          }}
        />
        <ItemCardDot />
      </ItemCardMenu>
      <ItemCardIconWrapper background={background || ""}>
        {icon}
      </ItemCardIconWrapper>
      <ItemCardTitle>
        {title}
        <ItemCardNumber>{cardNumber}</ItemCardNumber>
      </ItemCardTitle>
      {checked ? (
        <CheckboxIcon src={CheckedIcon} onClick={onChange} />
      ) : (
        <EmptyCheckbox onClick={onChange} />
      )}
      {showActions && (
        <ItemCardActionsBlock>
          {actions.map((action) => {
            return (
              <ActionContainer
                onClick={action.action}
                key={action.title}
                ref={rootElement}
              >
                {action.icon}
                <ActionTitle style={{ color: action.color }}>
                  {action.title}
                </ActionTitle>
              </ActionContainer>
            );
          })}
        </ItemCardActionsBlock>
      )}
    </ItemCardContainer>
  );
};
