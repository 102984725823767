import React from "react";

// <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <rect x="1.83337" y="6.5" width="12.3333" height="8.33333" rx="2.5" stroke="#F97066"/>
//   <path d="M4.66663 6.66699V4.00033C4.66663 3.11627 5.01782 2.26842 5.64294 1.6433C6.26806 1.01818 7.1159 0.666992 7.99996 0.666992C8.88401 0.666992 9.73186 1.01818 10.357 1.6433C10.9821 2.26842 11.3333 3.11627 11.3333 4.00033V6.66699" stroke="#F97066"/>
// </svg>

const IconBigKey = ({ ...props }) => {
  return (
    <svg
      width={props.width || 56}
      height={props.height || 56}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/*<circle cx="28" cy="28" r="28" fill={props.bgColor || "#E64545"} />*/}
      <rect
        x="16.375"
        y="25.5625"
        width="23.25"
        height="15.375"
        rx="2.5"
        stroke={props.color || "white"}
        strokeWidth="3"
      />
      <path
        d="M21.4375 25.375V20.125C21.4375 18.3845 22.1289 16.7153 23.3596 15.4846C24.5903 14.2539 26.2595 13.5625 28 13.5625C29.7405 13.5625 31.4097 14.2539 32.6404 15.4846C33.8711 16.7153 34.5625 18.3845 34.5625 20.125V25.375"
        stroke={props.color || "white"}
        strokeWidth="3"
      />
    </svg>
  );
};

export default IconBigKey;
