import * as React from "react";

const MiniatureCardFrozen = (props) => (
  <svg
    width={78}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <g filter="url(#b)">
        <path
          d="m6.023 36.545.08-2.187-1.85 1.173-.477-.835 1.948-1.014-1.948-1.014.477-.836 1.85 1.174-.08-2.188h.954l-.08 2.188 1.85-1.174.477.836-1.948 1.014 1.948 1.014-.477.835-1.85-1.173.08 2.188h-.954Zm7 0 .08-2.187-1.85 1.173-.477-.835 1.948-1.014-1.948-1.014.477-.836 1.85 1.174-.08-2.188h.954l-.08 2.188 1.85-1.174.477.836-1.948 1.014 1.948 1.014-.477.835-1.85-1.173.08 2.188h-.954Zm4.15 2.367v-1.014l4.474-7.08h.736v1.571h-.497l-3.38 5.35v.08h6.025v1.093h-7.358ZM21.965 41V30.818h1.174V41h-1.174Zm4.429 0v-.895l3.36-3.679c.395-.43.72-.805.975-1.123.255-.322.444-.623.567-.905a2.19 2.19 0 0 0 .189-.895c0-.358-.086-.668-.259-.93a1.676 1.676 0 0 0-.696-.606 2.257 2.257 0 0 0-.994-.214c-.391 0-.733.081-1.024.243-.289.16-.512.383-.671.672a2.102 2.102 0 0 0-.234 1.014h-1.173c0-.597.137-1.12.412-1.571.275-.451.65-.802 1.124-1.054a3.387 3.387 0 0 1 1.606-.378c.596 0 1.125.126 1.586.378.46.252.822.591 1.083 1.019.262.428.393.903.393 1.427 0 .374-.068.74-.204 1.099-.132.354-.364.75-.696 1.188-.328.434-.784.964-1.367 1.59l-2.287 2.447v.08h4.733V41h-6.423Zm8.795 0 4.553-9.009v-.08h-5.25v-1.093h6.523v1.154L36.481 41H35.19Zm8.271 0 4.554-9.009v-.08h-5.25v-1.093h6.523v1.154L44.753 41H43.46Z"
          fill="#fff"
        />
      </g>
      <g filter="url(#c)">
        <rect width={78} height={48} rx={5} fill="#C6E8F7" />
      </g>
      <circle cx={39} cy={24} r={12} fill="#F7F8FA" />
      <path
        d="M39.315 17v14.583M41.193 18.56a3.63 3.63 0 0 1-1.878.521 3.63 3.63 0 0 1-1.88-.52M37.435 30.021a3.65 3.65 0 0 1 3.758 0M45.63 20.646 33 27.938M45.215 23.054a3.649 3.649 0 0 1-1.879-3.255M33.414 25.528a3.649 3.649 0 0 1 1.879 3.255M33 20.646l12.63 7.292M33.414 23.054a3.648 3.648 0 0 0 1.879-3.255M45.215 25.528a3.65 3.65 0 0 0-1.879 3.255"
        stroke="#0082BA"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="b"
        x={-2}
        y={22}
        width={82}
        height={28}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_363_40267"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_363_40267"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        x={-7}
        y={-7}
        width={92}
        height={62}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={3.5} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_363_40267"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_363_40267"
          result="shape"
        />
      </filter>
      <clipPath id="a">
        <rect width={78} height={48} rx={6} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default MiniatureCardFrozen;
