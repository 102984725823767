import { AuthContainer } from "containers";
import { FC, useState } from "react";
import { Box } from "@mui/material";
import { PushIcon } from "../../atoms/PushIcons/PushIcon";
import { Button, Loader } from "components";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { deleteFCMToken, requestPermission } from "../../firebase";
import { handleTogglePush } from "../../store/slices/notifications";
import { showErrorMessage } from "../../store/slices/alerts";
import { PushError } from "pages/profile/ProfileTogglePush";

export const Push: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deviceIdItem = localStorage.getItem("PWADeviceId");
  const [isLoading, seiIsLoading] = useState(false);
  const [error, setShowError] = useState(false);

  const displayError = (message: string) => {
    dispatch(
      showErrorMessage({
        errorTitle: "Ошибка",
        errorMessage: message,
      })
    );
  };

  const handlePushToggle = async (status: boolean) => {
    seiIsLoading(true);
    requestPermission()
      .then((res) => {
        dispatch(
          handleTogglePush({
            deviceId: deviceIdItem || "",
            fcm: res.token || "",
            enabled: true,
            device_type: "W",
          })
        )
          // @ts-ignore
          .unwrap()
          .then(() => {
            localStorage.setItem("isPushOn", "true");
            navigate("/", { replace: true });
          })
          .catch(() => {
            displayError("Проверьте соединение");
          });
      })
      .catch((err) => {
        if (err.alert) {
          setShowError(true);
        } else {
          displayError(err.text);
        }
      })
      .finally(() => {
        seiIsLoading(false);
      });
  };

  return (
    <AuthContainer title="Разрешить приложению отправлять вам push-уведомления?">
      {isLoading && (
        <Box
          width="100%"
          height="calc(100% - 150px)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: "azure",
            opacity: 0.4,
            zIndex: 10,
          }}
        >
          <Loader />
        </Box>
      )}

      <Box mt={48} mb={232}>
        <PushIcon />
      </Box>

      <Box>
        <Button
          type="submit"
          onClick={() => {
            handlePushToggle(true);
          }}
          variant="primary"
          size="lg"
          fullWidth
        >
          Разрешить
        </Button>
        <Button
          type="submit"
          onClick={() => {
            navigate("/", { replace: true });
          }}
          variant="secondary"
          size="lg"
          fullWidth
          style={{
            marginTop: 12,
            backgroundColor: "#F7F8FA",
            color: "#0082BA",
          }}
        >
          Не разрешать
        </Button>
      </Box>
      {error && <PushError setShowError={setShowError} />}
    </AuthContainer>
  );
};
