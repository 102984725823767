import React from "react";

const FrozenCardConfirmMobile = () => {
  return (
    <svg
      width="151"
      height="150"
      viewBox="0 0 151 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.5 150C116.921 150 150.5 116.421 150.5 75C150.5 33.5786 116.921 0 75.5 0C34.0786 0 0.5 33.5786 0.5 75C0.5 116.421 34.0786 150 75.5 150Z"
        fill="url(#paint0_linear_119_16182)"
      />
      <path
        d="M134 142C134 146.418 130.418 150 126 150H24C19.5817 150 16 146.418 16 142V59.0026C16 54.5868 19.5786 51.006 23.9944 51.0038C62.0136 50.9851 97.6462 51.0427 125.994 51.0184C130.417 51.0146 134 54.5982 134 59.0213V142Z"
        fill="white"
      />
      <path
        d="M82.3125 106H67.6875C66.7555 106 66 107.343 66 109C66 110.657 66.7555 112 67.6875 112H82.3125C83.2445 112 84 110.657 84 109C84 107.343 83.2445 106 82.3125 106Z"
        fill="#EBEDF3"
      />
      <path
        d="M97 116H53C51.3431 116 50 117.343 50 119C50 120.657 51.3431 122 53 122H97C98.6569 122 100 120.657 100 119C100 117.343 98.6569 116 97 116Z"
        fill="#EBEDF3"
      />
      <path
        d="M75 94C88.2548 94 99 83.2548 99 70C99 56.7452 88.2548 46 75 46C61.7452 46 51 56.7452 51 70C51 83.2548 61.7452 94 75 94Z"
        fill="#0082BA"
      />
      <path
        d="M74.261 54.25V84.875"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78.2067 57.5273C77.0159 58.2451 75.6514 58.6233 74.261 58.6211C72.8707 58.6233 71.5062 58.2451 70.3153 57.5273"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.3153 81.5948C71.507 80.8791 72.8709 80.501 74.261 80.501C75.6511 80.501 77.015 80.8791 78.2067 81.5948"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.5221 61.9072L61 77.2197"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.6525 66.9639C85.4366 66.2896 84.4271 65.2971 83.7321 64.0929C83.0372 62.8887 82.683 61.5181 82.7075 60.1279"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.8695 72.1592C63.0854 72.8335 64.0949 73.8259 64.7899 75.0301C65.4849 76.2344 65.8391 77.605 65.8146 78.9951"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61 61.9072L87.5221 77.2197"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.8695 66.9639C63.0854 66.2896 64.0949 65.2971 64.7899 64.0929C65.4849 62.8887 65.8391 61.5181 65.8146 60.1279"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.6525 72.1592C85.4366 72.8335 84.4271 73.8259 83.7321 75.0301C83.0372 76.2344 82.683 77.605 82.7075 78.9951"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_119_16182"
          x1="75.5"
          y1="-2.75704e-06"
          x2="148.768"
          y2="129.337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEF0F5" />
          <stop offset="1" stopColor="#E5EEF2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FrozenCardConfirmMobile;
