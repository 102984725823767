import Box from "@mui/material/Box";
import { Typography, useTheme } from "@mui/material";
import { Button, InputText } from "components";
import { Controller, useForm } from "react-hook-form";
import { Screens } from "./models";
import { useDispatch, useSelector } from "react-redux";
import { updateLimits } from "store/slices/limitsSlice";
import {
  CardLimitConfirmResponse,
  CardLimitUpdateConfirmRequest,
  LimitType,
} from "api/account";
import { formatNumber } from "utils/formatCurrency";
import { useCallback, useMemo } from "react";
import { limitDescriptions } from "./limitConstants";
import { useIsMobile } from "hooks/useIsMobile";
import { rusSubtitle } from "./LimitItem";
import { RootState } from "store";

type Props = {
  onClick: (nextPage: Screens) => void;
  nextScreen: Screens;
  limitItem: CardLimitConfirmResponse;
  cardId: string;
};

export const AddLimit = ({ onClick, nextScreen, limitItem, cardId }: Props) => {
  const {
    cards: {
      account: { currency },
    },
  } = useSelector((state: RootState) => state);
  const formatInput = useCallback((value: string): string => {
    return value.replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }, []);

  const formatValue = useCallback(
    (value: string | number, sign: string = "") => {
      if (value && !String(value).includes(sign)) {
        return `${value} ${sign}`;
      }
      return value;
    },
    []
  );

  const defaultValue = useMemo(() => {
    const limitSum = String(limitItem.limitSumAmount);
    const formatted = formatInput(limitSum);
    return formatValue(formatted, currency?.sign);
  }, [formatInput, formatValue, limitItem.limitSumAmount, currency]);
  const { isMobile } = useIsMobile();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<{ limit: string | number }>({
    mode: "onChange",
    defaultValues: {
      limit: defaultValue,
    },
  });
  const limit = watch("limit");
  const theme = useTheme();

  const dispatch = useDispatch();

  const makeNum = (value: string | number) =>
    +String(value).replace(/[^0-9]/g, "");

  const handleClick = () => {
    const limitNum = makeNum(limit);
    const payload = {
      cardId: cardId,
      limitId: limitItem.limitId,
      limitType: LimitType.Daily,
      limitSumAmount: +limitNum,
      // limitOperationAmount: 0,
    };
    dispatch(updateLimits(payload))
      // @ts-ignore
      .unwrap()
      .then((res: CardLimitUpdateConfirmRequest) => {
        if (res.updateLimitToken) {
          onClick(nextScreen);
        }
      })
      .catch((err: any) => {
        onClick("unsuccess");
        return err;
      });
  };

  const maxLimit = limitItem.readOnly
    ? limitItem.limitSumAmount
    : limitItem.maxAvailableLimit;

  const sameLimits = makeNum(limit) === Number(limitItem?.limitSumAmount);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box
        width={`calc(100% - ${isMobile ? 40 : 64}px)`}
        height="100%"
        margin={`${isMobile ? 0 : 32}px auto`}
      >
        {isMobile && (
          <Box pb={36}>
            <Typography variant="text_8" fontWeight={500} textAlign="left">
              Изменение лимита на
            </Typography>
          </Box>
        )}
        <Box textAlign="left">
          {!isMobile && (
            <Typography
              variant="text_4"
              color={theme.primary.main.gray["300"]}
              display="block"
            >
              {limitItem.limitType && rusSubtitle(limitItem.limitType)}
            </Typography>
          )}
          <Typography
            variant="text_16"
            color={theme.primary.main.gray["800"]}
            fontWeight={{ sm: 400, md: 500 }}
            display="block"
          >
            {limitItem.limitName}
          </Typography>
        </Box>
        <Box mt={24}>
          <Box display="flex" flexDirection="column" gap={4}>
            <Typography variant="text_5" color={theme.primary.main.gray["400"]}>
              Максимальный лимит:&nbsp;
              {formatNumber(Number(maxLimit) || 0, false, currency?.sign)}
            </Typography>
            <Box margin="6px 0 20px 0">
              <Controller
                control={control}
                name="limit"
                rules={{
                  validate: (value) => {
                    const valueNum = makeNum(value);
                    return (
                      +valueNum <= (maxLimit || 0) ||
                      `Максимальный лимит ${formatNumber(
                        Number(maxLimit) || 0,
                        false,
                        currency?.sign
                      )}`
                    );
                  },
                }}
                render={({ field }) => (
                  <InputText
                    onChange={(e) => {
                      const value = e.target.value.length
                        ? makeNum(e.target.value).toString()
                        : e.target.value;

                      const formatted = formatInput(value);
                      field.onChange(formatted);
                    }}
                    onBlur={() => {
                      const formatted = formatValue(limit, currency?.sign);
                      field.onChange(formatted);
                    }}
                    value={limit}
                    disabled={limitItem.readOnly}
                    placeholder={`0 ${currency?.sign}`}
                    hint={errors.limit?.message}
                    error={!!errors.limit}
                  />
                )}
              />
            </Box>
            <Typography
              variant="text_3"
              color={theme.primary.main.gray["800"]}
              pr={10}
            >
              {limitDescriptions[limitItem.limitId || ""]}
            </Typography>
          </Box>
          <Box mt={isMobile ? 108 : 144}>
            <Button
              disabled={!limit || !!errors.limit || sameLimits}
              variant="primary"
              size="lg"
              fullWidth
              onClick={handleClick}
            >
              Установить лимит
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
