import { Box, Typography } from "@mui/material";
import { Button, InputText } from "components";
import theme from "theme";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationResult } from "molecules/NotificationResult";
import { IconSuccess } from "atoms/IconSuccess";
import { cardsSelector, profileSelector } from "store";
import { useIsMobile } from "hooks/useIsMobile";
import { updateAccount } from "store/slices/cardsSlice";

type LoginModalProps = {
  name?: string;
  onClose: () => void;
  accId: string;
};

export const AccountModal = ({ name, onClose, accId }: LoginModalProps) => {
  const dispatch = useDispatch();
  const { isLoginUpdated } = useSelector(profileSelector);
  const { account } = useSelector(cardsSelector);
  const accountLastNum = (account?.accountNumber || "").slice(-4);
  const [newAccountName, setNewAccountName] = useState("");
  const [operationSuccess, setOperationSuccess] = useState(false);
  const { isMobile } = useIsMobile();

  const disabled = newAccountName.length === 0 || newAccountName === name;
  const handleClick = () => {
    dispatch(
      updateAccount({
        acctId: accId,
        description: newAccountName,
      })
    )
      // @ts-ignore
      .unwrap()
      .then(() => {
        setOperationSuccess(true);
      })
      .catch((err: any) => {
        return err;
      });
  };

  if (operationSuccess) {
    return (
      <Box
        height="100%"
        pt={{ xs: 46, md: 32 }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <NotificationResult
          title="Имя счета изменено"
          subtitle="Вы можете изменить имя счета в любое удобное для вас время"
          icon={<IconSuccess />}
          height="100%"
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              size: "lg",
              onClick: onClose,
            },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box px={isMobile ? 20 : 32} height="100%">
      <Box boxSizing="border-box">
        <Box mb={24}>
          <Typography variant="text_7" fontWeight={500}>
            Изменение названия для {isMobile && <br />}счёта **{accountLastNum}
          </Typography>
        </Box>
        <InputText
          value={newAccountName}
          name="newLogin"
          color="#475467"
          label="Название счёта"
          placeholder="Название счёта"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNewAccountName(e.target.value);
          }}
          maxLength={26}
        />

        <Box
          width="100%"
          mt={40}
          display="flex"
          flexDirection="column"
          gap={20}
        >
          <Button
            isLoading={isLoginUpdated}
            disabled={disabled}
            size="lg"
            variant="primary"
            onClick={handleClick}
          >
            Применить
          </Button>
          <Button
            size="lg"
            variant="secondary"
            onClick={onClose}
            sx={{ backgroundColor: theme.palette.gray.b100 }}
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
