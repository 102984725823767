import { TextField, InputAdornment, TextFieldProps } from "@mui/material";
import Box from "@mui/material/Box";
import { IMaskInput } from "react-imask";

import { Label } from "molecules/Label/Label";
import React, { ChangeEventHandler } from "react";
import theme from "theme";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .MuiOutlinedInput-root": {
      color: theme.palette.gray.b900,
      background: theme.primary.white,
      borderRadius: "16px",
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.palette.indigo.b300}`,
      },
      "& .MuiInputBase-input": {
        marginLeft: "-8px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 18px",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: theme.palette.gray.b500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline ": {
      border: "1px solid #FDA29B !Important",
    },
  },
}));

type PhoneInputProps = TextFieldProps & {
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  endAdornment?: React.ReactNode;
  mask?: string;
  codeValue?: string;
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        unmask={true}
        definitions={{
          "#": /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export const PhoneInput = ({
  label,
  name,
  endAdornment,
  codeValue,
  mask,
  placeholder,
  onChange,
  ...rest
}: PhoneInputProps) => {
  const classes = useStyles();
  return (
    <Box width="100%" mb={20}>
      <Label label={label} htmlFor={name} />
      <TextField
        {...rest}
        name={name}
        autoComplete="off"
        placeholder={placeholder}
        onChange={onChange}
        classes={{ root: classes.inputRoot }}
        sx={{
          "& > .MuiOutlinedInput-root > input": {
            padding: "14px 0",
            "&::placeholder": {
              color: theme.palette.gray.b400,
              opacity: 1,
              fontSize: 16,
              fontWeight: 500,
              lineHeight: 24,
            },
          },
          "& > .MuiOutlinedInput-root > .MuiInputAdornment-root > p": {
            color: theme.palette.gray.b400,
            fontWeight: 500,
          },
        }}
        InputProps={{
          inputComponent: TextMaskCustom as any,
          inputProps: { mask: mask?.replaceAll("x", "0") },
          startAdornment: (
            <InputAdornment
              position="start"
              style={{
                fontSize: 16,
                fontWeight: 500,
                lineHeight: 24,
                color: "#101828",
              }}
            >
              +{codeValue || ""}
            </InputAdornment>
          ),
          endAdornment,
        }}
        id={name}
        fullWidth
      />
    </Box>
  );
};
