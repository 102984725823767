export const IconBlocked = () => {
  return (
    <svg
      width="150"
      height="153"
      viewBox="0 0 150 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#FFEEED"
      />
      <g filter="url(#filter0_d_6558_111344)">
        <mask
          id="mask0_6558_111344"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="150"
          height="150"
        >
          <path
            d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
            fill="url(#paint0_linear_6558_111344)"
          />
        </mask>
        <g mask="url(#mask0_6558_111344)">
          <rect x="31.5" y="36" width="87" height="121" rx="7" fill="white" />
          <rect x="35.5" y="40" width="79" height="114" rx="5" fill="#FFEEED" />
        </g>
      </g>
      <path
        d="M75.5 119C88.7548 119 99.5 108.255 99.5 95C99.5 81.7452 88.7548 71 75.5 71C62.2452 71 51.5 81.7452 51.5 95C51.5 108.255 62.2452 119 75.5 119Z"
        fill="#FDA29B"
      />
      <path
        d="M85.0269 104.527C84.2459 105.308 82.9796 105.308 82.1985 104.527L75.1274 97.4555L68.0564 104.527C67.2753 105.308 66.009 105.308 65.2279 104.527C64.4469 103.746 64.4469 102.479 65.2279 101.698L72.299 94.6271L65.2279 87.556C64.4469 86.775 64.4469 85.5087 65.2279 84.7276C66.009 83.9466 67.2753 83.9466 68.0564 84.7276L75.1274 91.7987L82.1985 84.7276C82.9796 83.9466 84.2459 83.9466 85.0269 84.7276C85.808 85.5087 85.808 86.775 85.0269 87.556L77.9559 94.6271L85.0269 101.698C85.808 102.479 85.808 103.746 85.0269 104.527Z"
        fill="white"
      />
      <rect x="68.5" y="44" width="11" height="2" rx="1" fill="white" />
      <rect x="80.5" y="44" width="2" height="2" rx="1" fill="white" />
      <defs>
        <filter
          id="filter0_d_6558_111344"
          x="25.5"
          y="27"
          width="99"
          height="126"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6558_111344"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6558_111344"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6558_111344"
          x1="75"
          y1="0"
          x2="75"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3ECFA" />
          <stop offset="1" stopColor="#DAE7FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
