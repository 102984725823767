import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Logo from "../Header/finative-logo.png";
import { authActions } from "store/slices/auth";
import { hideErrorMessage } from "store/slices/alerts";

export const UnregisteredHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const disabledLogo =
    location.pathname === "/404" || location.pathname.includes("/refill-info");
  const isRedirectAccess =
    location.pathname === "/pin-code" || location.pathname === "/pin-enter";

  const logoClickHandler = () => {
    if (disabledLogo) {
      return null;
    }
    if (location.pathname === "/biometry-registration") {
      navigate("/", { replace: true });
    }
    if (!isRedirectAccess) {
      dispatch(hideErrorMessage());
      dispatch(authActions.resetError());
      dispatch(authActions.setRegisterScreen("initial"));
      dispatch(authActions.setRestoreScreen("initial"));
      navigate("/sign-in");
    }
  };

  return (
    <Grid
      container
      px={{ xs: 16, sm: 16, md: 34, lg: 34, xl: 86 }}
      height={{ sm: "66px", md: "98px" }}
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      boxSizing="border-box"
      maxWidth={1440}
      margin="0 auto"
    >
      <Box
        position="absolute"
        top="100%"
        width="100vw"
        height="1px"
        bgcolor="#F2F4F7"
        left="calc((100% - 100vw) / 2)"
      />
      <Grid item xl={3}>
        <Box height="100%" display="flex" alignItems="center">
          <Box
            sx={{
              cursor: disabledLogo ? "auto" : "pointer",
            }}
            onClick={logoClickHandler}
          >
            <img src={Logo} width={158} height={28} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
