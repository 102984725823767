import styled from "@emotion/styled/macro";

export const Title = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #667085;
`;

export const Numbers = styled.span`
  color: #0082ba;
`;
