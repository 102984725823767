import { useCallback } from "react";
import { Typography, Box } from "@mui/material";

import { Button } from "components";
import { ReactComponent as LogoutCircleIcon } from "../../assets/LogoutCircle.svg";
import { ReactComponent as LogoutDesktopIcon } from "../../assets/LogoutDesktop.svg";
import { useIsMobile } from "hooks/useIsMobile";

type LogoutModalProps = {
  onClick: () => void;
  onCancel: () => void;
};

export const LogoutModal = ({ onClick, onCancel }: LogoutModalProps) => {
  const { isMobile, size } = useIsMobile();

  const getTitle = useCallback(() => {
    switch (size) {
      case "xl":
        return `Вы уверены, что хотите выйти\nиз аккаунта?`;
      case "sm":
        return `Вы уверены, что хотите\nвыйти из аккаунта?`;
      case "xs":
        return `Вы уверены, что хотите\nвыйти из аккаунта?`;
      default:
        return `Вы уверены, что хотите выйти из аккаунта?`;
    }
  }, [size]);

  const getSubtitle = useCallback(() => {
    switch (size) {
      case "xl":
        return `Для входа вам потребуется повторный ввод логина\nи пароля`;
      case "sm":
        return `Для входа вам потребуется повторный\nввод логина и пароля`;
      case "xs":
        return `Для входа вам потребуется повторный\nввод логина и пароля`;
      default:
        return `Для входа вам потребуется повторный ввод логина и пароля`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection="column" px={{ xs: "20px", lg: "32px" }}>
      <Typography
        variant="text_8"
        fontWeight={500}
        color="#393E46"
        whiteSpace="pre-wrap"
      >
        {getTitle()}
      </Typography>
      {isMobile && (
        <Box display="flex" justifyContent="center" mt={40}>
          <LogoutCircleIcon />
        </Box>
      )}
      <Typography
        variant="text_4"
        color="#475467"
        whiteSpace="pre-wrap"
        mt={{ xs: 24, xl: 16 }}
      >
        {getSubtitle()}
      </Typography>
      {!isMobile && (
        <Box mt={24}>
          <LogoutDesktopIcon />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        rowGap="20px"
        mt={{ xs: 52, xl: 40 }}
      >
        <Button onClick={onClick} size="lg" variant="primary">
          Выйти из аккаунта
        </Button>
        <Button onClick={onCancel} size="lg" variant="secondary">
          Отмена
        </Button>
      </Box>
    </Box>
  );
};
