import React, { FC } from "react";
import { Theme, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

interface IListItem {
  title?: string;
  subTitle?: string;
  date?: string;
  onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: "flex",
    justifyContent: "space-between",
    padding: "16px 24px",
    borderBottom: "1px solid #EBEDF3",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.primary.main.gray[75],
      "& $rightIcon": {
        display: "inline",
      },
      "& $date": {
        display: "none",
      },
    },
  },
  rightIcon: {
    display: "none",
  },
  date: {
    display: "inline",
  },
}));

const ListItem: FC<IListItem> = ({ title, subTitle, date, onClick }) => {
  const classes = useStyles();
  const day = date ? format(new Date(date), "d MMMM", { locale: ru }) : "";

  return (
    <Box onClick={onClick} className={classes.box}>
      <Box maxWidth="75%" display="flex" flexDirection="column">
        <Typography variant="text_4">{title}</Typography>
        <Typography variant="text_2" color="gray.b10">
          {subTitle}
        </Typography>
      </Box>
      <Box>
        <ChevronRightIcon className={classes.rightIcon} fontSize="small" />
        <Typography className={classes.date} variant="text_2" color="gray.b400">
          {day}
        </Typography>
      </Box>
    </Box>
  );
};

export default ListItem;
