const IconAccessBiometry = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
      <g clipPath="url(#a)">
        <path
          fill="#12B76A"
          d="M10 18.333a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 0 1 0 16.666Zm-.83-5 5.89-5.892-1.178-1.179-4.713 4.715-2.357-2.358-1.179 1.178 3.536 3.536Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconAccessBiometry;
