import { useEffect, useState } from "react";
import {
  CalendarIcon,
  CategoryItem,
  CategoryItemColor,
  CategoryItemSum,
  CategoryItemText,
  CategoryItemsContainer,
  Container,
  HeaderBlock,
  HeaderLeft,
  HeaderMonth,
  HeaderRight,
  Line,
  LinesContainer,
  MoreButton,
  Sum,
  DateWrapper,
} from "./components";
import Calendar from "../../assets/calendar.png";
import { useIsMobile } from "hooks/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { TabVariants, systemActions } from "store/slices/system";
import {
  ERROR_MESSAGE_DATEFROM_INTERVAL,
  ERROR_MESSAGE_DATEFROM_TODAY,
  ERROR_MESSAGE_DATETO_INTERVAL,
  ERROR_MESSAGE_DATETO_TODAY,
} from "../SearchFilters/components";
import { Button, DatePicker, SkeletonContainer } from "components";
import { parseDate } from "utils";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  HISTORY_TABS,
  getCategoryExpense,
  getCategoryIncome,
  getExpenseList,
  getIncomeList,
  setActiveCategory,
  setActiveTab,
  setDate,
  setShowMobileFinancialAnalysisPage,
} from "store/slices/historySlice";
import { RootState } from "store";

interface FinancialAnalysisWidgetProps {
  isPreview: boolean;
}

const COLORS = ["#12B76A", "#62C1F7", "#FCC914", "#FF4F42", "#7A5AF8"];
const EMPTY_COLOR = "#EAEAEA";

export const FinancialAnalysisWidget = ({
  isPreview,
}: FinancialAnalysisWidgetProps) => {
  const {
    cards,
    history: {
      widgetData,
      isWidgetDataLoading,
      activeTab,
      showMobileFinancialAnalysisPage,
      date,
      activeCategoryId,
    },
  } = useSelector((state: RootState) => state);

  const allSum = widgetData?.length
    ? widgetData
        .map((item) => item.sum)
        .reduce((accumulator, currentValue) => {
          if (accumulator && currentValue) {
            return accumulator + currentValue;
          }
        })
    : 0;

  const dateNow = new Date();
  const currentMonth = dateNow.toLocaleString("ru", { month: "long" });
  const currentYear = dateNow.toLocaleString("ru", { year: "numeric" });
  const firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

  const initialState = {
    from: firstDayMonth.toISOString(),
    to: dateNow.toISOString(),
    operationFilterType: undefined,
    page: 0,
    acctId: cards.account.accountNumber,
    search: "",
    fromAmount: undefined,
    toAmount: undefined,
    cardId: "",
    size: 10,
  };

  const [sum, setSum] = useState<number>(0);
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [intermediateDate, setIntermediateDate] = useState(date);
  const { isMobile } = useIsMobile();
  const isMainPageDesktop = isPreview && !isMobile;
  const dispatch = useDispatch();

  const handleClickOutside = () => {
    setIsOpenDate(false);
  };

  const rootElement = useOutsideClick(handleClickOutside);

  const errorMessageFrom =
    intermediateDate.from > intermediateDate.to
      ? ERROR_MESSAGE_DATEFROM_INTERVAL
      : intermediateDate.from > new Date().toISOString()
      ? ERROR_MESSAGE_DATEFROM_TODAY
      : "";

  const errorMessageTo =
    intermediateDate.to < intermediateDate.from
      ? ERROR_MESSAGE_DATETO_INTERVAL
      : intermediateDate.to > new Date().toISOString()
      ? ERROR_MESSAGE_DATETO_TODAY
      : "";

  let fromText = parseDate(date.from);
  const toText = parseDate(date.to);

  const fromTextYear = fromText.split(".").at(-1);
  const toTextYear = toText.split(".").at(-1);

  if (fromTextYear === toTextYear) {
    const fromTextArr = fromText.split(".");
    fromTextArr.pop();
    fromText = fromTextArr.join(".");
  }

  const customDate = date.from
    ? date.to
      ? `${fromText} - ${toText}`
      : `с ${fromText}`
    : date.to && toText !== parseDate(new Date().toISOString())
    ? `по ${toText}`
    : "";

  const defaultDate = `${
    currentMonth[0].toUpperCase() + currentMonth.slice(1)
  } ${currentYear}`;

  const categoryClick = (categoryName: number, categorySum: number) => {
    if (isPreview) return;
    if (activeCategoryId === categoryName) {
      dispatch(setActiveCategory(null));
      setSum(allSum || 0);
    } else {
      dispatch(setActiveCategory(categoryName));
      setSum(categorySum);
    }
  };

  const widgetClick = () => {
    if (!isPreview) return;
    if (!isMainPageDesktop) {
      dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
      dispatch(setShowMobileFinancialAnalysisPage(true));
    }
  };

  const submitPeriod = () => {
    dispatch(setDate(intermediateDate));
    setIsOpenDate(false);
  };

  useEffect(() => {
    if (initialState.acctId && activeTab === HISTORY_TABS.EXPENSES) {
      dispatch(getCategoryExpense(initialState));
    }
    if (initialState.acctId && activeTab === HISTORY_TABS.INCOMES) {
      dispatch(getCategoryIncome(initialState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState.acctId, activeTab]);

  useEffect(() => {
    if (
      initialState.acctId &&
      activeTab === HISTORY_TABS.EXPENSES &&
      showMobileFinancialAnalysisPage
    ) {
      dispatch(getExpenseList(initialState));
    }
    if (
      initialState.acctId &&
      activeTab === HISTORY_TABS.INCOMES &&
      showMobileFinancialAnalysisPage
    ) {
      dispatch(getIncomeList(initialState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, initialState.acctId, showMobileFinancialAnalysisPage]);

  useEffect(() => {
    if (allSum) {
      setSum(allSum);
    } else {
      setSum(0);
    }
    if (activeCategoryId) {
      const activeCategory = widgetData?.filter(
        (category) => category.id === activeCategoryId
      )[0];
      if (activeCategory && activeCategory.sum) {
        setSum(activeCategory.sum);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSum]);

  useEffect(() => {
    setIntermediateDate(date);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.from, date.to]);

  return (
    <SkeletonContainer
      width="100%"
      height={150}
      isLoading={isWidgetDataLoading}
    >
      <Container
        withPadding={isPreview}
        withMarginBottom={isPreview}
        background={isPreview ? "#F7F8FA" : "transparent"}
        onClick={widgetClick}
      >
        <HeaderBlock start={isPreview}>
          {isPreview && <HeaderLeft>Расходы за {currentMonth}</HeaderLeft>}
          {!isPreview && isMobile && (
            <>
              <HeaderRight onClick={() => setIsOpenDate(!isOpenDate)}>
                <CalendarIcon src={Calendar} />
                <HeaderMonth>{customDate || defaultDate}</HeaderMonth>
              </HeaderRight>
              {isOpenDate && (
                <DateWrapper ref={rootElement}>
                  <DatePicker
                    label="Начало периода"
                    placeholder="00.00.0000"
                    value={
                      intermediateDate.from
                        ? new Date(intermediateDate.from)
                        : null
                    }
                    error={!!errorMessageFrom}
                    hint={errorMessageFrom}
                    onChange={(from: Date) => {
                      setIntermediateDate((prev) => ({
                        ...prev,
                        from: from?.toISOString(),
                      }));
                    }}
                  />
                  <DatePicker
                    label="Конец периода"
                    placeholder="00.00.0000"
                    value={
                      intermediateDate.to ? new Date(intermediateDate.to) : null
                    }
                    error={!!errorMessageTo}
                    hint={errorMessageTo}
                    onChange={(to: Date) => {
                      setIntermediateDate((prev) => ({
                        ...prev,
                        to: to?.toISOString(),
                      }));
                    }}
                  />
                  <Button
                    variant="primary"
                    onClick={submitPeriod}
                    disabled={!!errorMessageFrom || !!errorMessageTo}
                  >
                    Применить фильтр
                  </Button>
                </DateWrapper>
              )}
            </>
          )}
        </HeaderBlock>
        {!isWidgetDataLoading && (
          <>
            <Sum>{sum.toLocaleString()} ₽</Sum>
            <LinesContainer>
              {widgetData?.length ? (
                widgetData?.map((item, index) => {
                  return (
                    <Line
                      cursor={!isMainPageDesktop}
                      onClick={() => categoryClick(item.id || 0, item.sum || 0)}
                      key={item.name}
                      background={
                        activeCategoryId === null ||
                        activeCategoryId === item.id
                          ? COLORS[index]
                          : EMPTY_COLOR
                      }
                      percent={`${item.percent}%`}
                    />
                  );
                })
              ) : (
                <Line
                  cursor={false}
                  onClick={() => {}}
                  background={EMPTY_COLOR}
                  percent="100%"
                />
              )}
            </LinesContainer>
            {!isPreview && widgetData?.length ? (
              <CategoryItemsContainer>
                {widgetData?.map((item, index) => {
                  return (
                    <CategoryItem
                      key={item.name}
                      onClick={() => categoryClick(item.id || 0, item.sum || 0)}
                      opacity={
                        activeCategoryId === null ||
                        activeCategoryId === item.id
                          ? 1
                          : 0.5
                      }
                    >
                      <CategoryItemColor background={COLORS[index]} />
                      <CategoryItemText>{item.name}</CategoryItemText>
                      {activeCategoryId === item.id && (
                        <CategoryItemSum>
                          {item.sum && item.sum.toLocaleString()} ₽
                        </CategoryItemSum>
                      )}
                    </CategoryItem>
                  );
                })}
              </CategoryItemsContainer>
            ) : null}
            {isMainPageDesktop && (
              <MoreButton
                onClick={() => {
                  dispatch(
                    systemActions.setActiveTab({ tab: TabVariants.history })
                  );
                  dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
                }}
              >
                Подробнее
              </MoreButton>
            )}
          </>
        )}
      </Container>
    </SkeletonContainer>
  );
};
