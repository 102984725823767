type EnvType = {
  REACT_APP_AUTH_SWAGGER_URL: string;
  REACT_APP_ACCOUNT_SWAGGER_URL: string;
  REACT_APP_NOTIFICATION_URL: string;
  REACT_APP_BASE_PATH: string;
  REACT_APP_VERSION: string;
  REACT_APP_BUGSNAP_API_KEY: string;
  REACT_APP_BUGSNAP_STAGE: string;

  FIREBASE_NOTIFICATION_URL: string;
  FIREBASE_API_KEY: string;
  FIREBASE_AUTH_DOMAIN: string;
  FIREBASE_PROJECT_ID: string;
  FIREBASE_STORAGE_BUCKET: string;
  FIREBASE_MESSAGING_SENDER_ID: string;
  FIREBASE_APP_ID: string;
  FIREBASE_MEASUREMENT_ID: string;
  FIREBASE_VAPID_KEY: string;
};

const env: EnvType = { ...process.env, ...window["_env_"] };

export default env;
