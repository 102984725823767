import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  checkNotifications,
  getNewsList,
  getNotificationList,
} from "./asyncThunks";
import {
  CheckNewNotificationsResponse,
  NotificationDto,
} from "api/notification";
import { groupByDate } from "./utils";

export enum PUSH_TYPES {
  MESSAGE = "MESSAGE",
  NEWS = "NEWS",
}

export interface NotificationState {
  isLoading: boolean;
  newNotifications: boolean;
  newNews: boolean;
  notifications: Record<string, NotificationDto[]>;
  news: Record<string, NotificationDto[]>;
  selected: NotificationDto;
  push_type: PUSH_TYPES | null;
}

const initialState: NotificationState = {
  isLoading: false,
  notifications: {},
  news: {},
  selected: {},
  newNotifications: false,
  newNews: false,
  push_type: null,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    setSelected: (state, { payload }: PayloadAction<NotificationDto>) => {
      state.selected = payload;
    },
    clearSelected: (state) => {
      state.selected = {};
    },
    setReadNews: (state) => {
      state.newNews = false;
    },
    setReadNotifications: (state) => {
      state.newNotifications = false;
    },
    clearNotifications: (state) => {
      state.notifications = {};
      state.news = {};
    },
    setPushType: (state, { payload }) => {
      state.push_type = payload;
    },
    resetStore: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNotificationList.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.notifications = groupByDate(
          payload.notifications,
          state.notifications,
          "d MMMM, yyyy"
        );
      }
    );
    builder.addCase(getNotificationList.rejected, (state) => {
      state.isLoading = false;
      state.notifications = {};
    });
    builder.addCase(
      getNewsList.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        state.isLoading = false;
        state.news = groupByDate(
          payload.notifications,
          state.news,
          "LLLL yyyy"
        );
      }
    );
    builder.addCase(getNewsList.rejected, (state) => {
      state.isLoading = false;
      state.news = {};
    });
    builder.addCase(
      checkNotifications.fulfilled,
      (state, { payload }: PayloadAction<CheckNewNotificationsResponse>) => {
        state.newNotifications = !!payload.newNotifications;
        state.newNews = !!payload.newNews;
      }
    );
  },
});
