import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import theme from "theme";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import { AuthContainer } from "containers";
import { Button } from "components";
import { useDispatch, useSelector } from "react-redux";
import { authActions, registerCVV } from "store/slices/auth";
import { authSelector } from "../../../store";
import {
  BackButtonContainer,
  SmallBackButton,
} from "components/SmallBackButton";
const ThirdScreen = () => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector(authSelector);
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<{ code: string }>({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });

  const code = watch("code");

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(registerCVV(code));
  };

  const counter = (count: string) => {
    if (count === "4" || count === "3" || count === "2")
      return `${count} попытки`;
    if (count === "1") {
      return `${count} попытка`;
    }
  };
  return (
    <>
      <BackButtonContainer>
        <SmallBackButton
          clickHandler={() =>
            dispatch(authActions.setRegisterScreen("initial"))
          }
        />
      </BackButtonContainer>
      <AuthContainer
        title="Регистрация"
        subtitle="Укажите ваши данные для регистрации"
      >
        <Box
          component="form"
          sx={{
            backgroundColor: theme.palette.gray.b50,
          }}
          px={{ xs: 16, md: 30.5 }}
          py={32}
          display="flex"
          flexDirection="column"
          border={`1px solid ${theme.palette.gray.b100}`}
          borderRadius={24}
          autoComplete="off"
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="text_8" color="#393E46" fontWeight="600">
              CVV-код
            </Typography>
            <Typography
              variant="text_3"
              color="#393E46"
              fontWeight="500"
              mt={12}
            >
              Для продолжения регистрации введите трехзначный CVV-код с обратной
              стороны вашей карты
            </Typography>
            <Controller
              control={control}
              name="code"
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  // @ts-ignore
                  centered
                  value={code}
                  placeholder="Введите CVV"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    if (
                      value.length <= 3 &&
                      (/^\d+$/.test(value) || value === "")
                    ) {
                      field.onChange(value);
                    }
                  }}
                  error={
                    error
                      ? ""
                      : errors.code?.message
                      ? errors.code?.message
                      : null
                  }
                  style={{ marginTop: 20 }}
                />
              )}
            />
            <Typography variant="text_5" color="#98A2B3" mt={24}>
              После 5-ой попытки ваша карта будет заблокирована
            </Typography>
            {error?.parameter && !isLoading && (
              <Typography variant="text_5" color="#98A2B3" mt={16}>
                Осталось{" "}
                <Typography variant="text_5" color="#0082BA">
                  {counter(error?.parameter)}
                </Typography>
              </Typography>
            )}
          </Box>
          <Button
            type="submit"
            variant="primary"
            size="lg"
            fullWidth
            disabled={code.length < 3}
            onClick={(e) => handleClick(e)}
            isLoading={isLoading}
            style={{ marginTop: 58 }}
          >
            Продолжить
          </Button>
        </Box>
      </AuthContainer>
    </>
  );
};

export default ThirdScreen;
