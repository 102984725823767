import { Typography, useTheme, Box } from "@mui/material";
import { Button } from "components";
import { Controller, useForm } from "react-hook-form";
import { useCounter } from "../../../../hooks";
import { SmsInput } from "molecules/SmsInput/SmsInput";
import { getSecondsPluralForms } from "utils";
import { useDispatch, useSelector } from "react-redux";
import { confirmTransferToCard } from "store/slices/transferSlice";
import { retryOneTimePassword } from "store/slices/auth";
import { useIsMobile } from "hooks/useIsMobile";

export const TransferOTP = () => {
  const dispatch = useDispatch();
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const { control, watch } = useForm<{ code: string }>({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });
  const code = watch("code");
  const theme = useTheme();

  const {
    transfer: { createdTransfer, isConfirmLoading },
    profile: { user },
  } = useSelector((state: any) => state);

  const lastFourNumberPhone = user?.phone.substr(7);
  const { isMobile } = useIsMobile();

  const handleResendCode = () => {
    dispatch(retryOneTimePassword());
    restartCounter();
  };

  const handleClick = () => {
    dispatch(
      confirmTransferToCard({
        paymentToken: createdTransfer?.paymentToken,
        code,
      })
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box height="100%">
        <Box>
          <Box mt={isMobile ? "0" : "24px"} mb="4px">
            <p
              style={{
                fontSize: "23px",
                fontWeight: 500,
                lineHeight: "32px",
                color: "#393E46",
                marginTop: isMobile ? "0" : "inherit",
              }}
            >
              Введите код из сообщения, чтобы подтвердить операцию
            </p>
            <Typography
              variant="text_5"
              color={theme.primary.main.gray["800"]}
              whiteSpace="pre-line"
            >
              На номер{" "}
              <span style={{ color: "#0082BA" }}>
                *** {lastFourNumberPhone[0]}
                {lastFourNumberPhone[1]} {lastFourNumberPhone[2]}
                {lastFourNumberPhone[3]}
              </span>{" "}
              был отправлен код подтверждения
            </Typography>
          </Box>
        </Box>
        <Box
          bgcolor={theme.primary.main.gray["50"]}
          p="32px 25.5px"
          borderRadius="24px"
          mt={12}
        >
          <Box display="flex" flexDirection="column" gap={4}>
            <Box mt={18} mb={42}>
              <Controller
                control={control}
                name="code"
                defaultValue={code}
                render={({ field }) => (
                  <SmsInput
                    placeholder="Введите код"
                    label="Код"
                    name="code"
                    value={code}
                    onChange={(e: any) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Box>
            {count !== 0 && (
              <Box textAlign="center" minHeight={48} mb={12}>
                <Typography variant="text_5" color="gray.b400">
                  Вы сможете отправить код повторно через{" "}
                  <Typography variant="text_5" color="blue.b400">
                    {count} {secondsWord}.
                  </Typography>
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="column" gap={12}>
            {count === 0 && (
              <Button
                onClick={handleResendCode}
                variant="secondary"
                size="lg"
                fullWidth
              >
                Отправить код повторно
              </Button>
            )}
            <Button
              disabled={code.length < 4}
              variant="primary"
              size="lg"
              fullWidth
              onClick={handleClick}
              isLoading={isConfirmLoading}
            >
              Продолжить
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
