import React, { TextareaHTMLAttributes } from "react";
import styled from "@emotion/styled/macro";
import theme from "theme";
import classNames from "classnames";

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  disabled?: boolean;
  className?: string;
  label?: string;
  hint?: string;
  inputColor?: string;
}

const TextareaContainer = styled.div<{ inputColor?: string }>`
  font-family: Inter;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  position: relative;
  label {
    font-size: 14px;
    color: ${({ inputColor }) =>
      inputColor ? inputColor : theme.palette.gray.b700};
  }

  .hint {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${theme.palette.gray.b500};
  }

  &.disabled {
    textarea {
      color: ${theme.palette.gray.b400};
      background-color: ${theme.palette.gray.b50};
    }
    .hint {
      color: ${theme.palette.gray.b400};
    }
  }

  &.error {
    textarea {
      border-color: ${theme.palette.red.b300};
    }
    .hint {
      color: ${theme.palette.red.b500};
    }
  }
`;

const TextareaWrapper = styled.div<Partial<TextareaProps>>`
  position: relative;
  display: flex;
  textarea {
    font-family: Inter;
    outline: none;
    resize: none;
    height: 86px;
    border: 1px solid ${theme.palette.gray.b300};
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 16px;
    border-radius: 16px;
    text-overflow: ellipsis;
    width: calc(100% - 28px);
    &::placeholder {
      color: ${theme.palette.gray.b400};
    }
    &:focus:not(.with-error) {
      border-color: ${theme.palette.indigo.b300};
    }
  }
`;

export const Textarea: React.FC<TextareaProps> = ({
  label,
  className,
  error,
  disabled,
  hint,
  inputColor,
  ...rest
}) => (
  <TextareaContainer
    inputColor={inputColor}
    className={classNames(className, { error, disabled })}
  >
    {label && <label>{label}</label>}
    <TextareaWrapper>
      <textarea
        disabled={disabled}
        className={classNames({ "with-error": error })}
        placeholder="Введите текст"
        {...rest}
      />
    </TextareaWrapper>
    {hint && <div className="hint">{hint}</div>}
  </TextareaContainer>
);
