export const CardCvv = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="186"
      height="147"
      fill="none"
      viewBox="0 0 186 147"
    >
      <path
        stroke="#C2C9D6"
        strokeWidth="4"
        d="M2 52.633h182M2 27.556C2 13.707 13.111 2.48 26.818 2.48h132.364C172.889 2.48 184 13.707 184 27.556v91.948c0 13.849-11.111 25.076-24.818 25.076H26.818C13.111 144.58 2 133.353 2 119.504V27.556z"
      ></path>
      <rect
        width="63"
        height="35"
        x="108"
        y="76"
        fill="#D9D9D9"
        fillOpacity="0.5"
        rx="8"
      ></rect>
      <rect width="8" height="8" x="123" y="90" fill="#0082BA" rx="4"></rect>
      <rect width="8" height="8" x="136" y="90" fill="#0082BA" rx="4"></rect>
      <rect width="8" height="8" x="149" y="90" fill="#0082BA" rx="4"></rect>
    </svg>
  );
};
