export const StatusIconSent = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1304 1.01909C10.304 0.845524 10.5854 0.845524 10.7589 1.01909C10.9191 1.17931 10.9315 1.43141 10.7959 1.60576L10.7589 1.64763L5.11731 7.28925C4.95709 7.44947 4.70499 7.46179 4.53064 7.32622L4.48877 7.28925L1.2415 4.04199C1.06794 3.86842 1.06794 3.58701 1.2415 3.41345C1.40172 3.25323 1.65382 3.24091 1.82817 3.37647L1.87004 3.41345L4.80288 6.34625L10.1304 1.01909Z"
      fill="#9AA4B5"
      stroke="#9AA4B5"
      strokeWidth="0.5"
    />
  </svg>
);
