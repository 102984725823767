import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button } from "components";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import theme from "theme";
import { passwordPattern } from "constants/regexp";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "store/slices/profile";
import { profileSelector } from "store";
import { NotificationResult } from "molecules/NotificationResult";
import { IconSuccess } from "atoms/IconSuccess";
import { useIsMobile } from "hooks/useIsMobile";
import { authActions } from "store/slices/auth";
import { useNavigate } from "react-router-dom";
import { useResetStore } from "hooks/useResetStore";

type UpdatePasswordProps = {
  onClose: () => void;
};

enum UpdatePasswordErrors {
  match = "PASSWORD_NOT_MATCH",
  old = "PASSWORD_AS_OLD",
}

const UpdatePassword = ({ onClose }: UpdatePasswordProps) => {
  const { error } = useSelector(profileSelector);
  const dispatch = useDispatch();
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<{
    oldPassword: string;
    password: string;
    confirmPassword: string;
  }>({
    mode: "onChange",
    defaultValues: {
      password: "",
      oldPassword: "",
      confirmPassword: "",
    },
  });
  const [password, confirmPassword, oldPassword] = watch([
    "password",
    "confirmPassword",
    "oldPassword",
  ]);
  const [checkError, setCheckError] = useState();
  const { isLoading, isPasswordUpdated } = useSelector(profileSelector);
  const handlePasswordUpdate = () => {
    dispatch(updatePassword({ password: oldPassword, newPassword: password }));
  };
  const { isMobile, size } = useIsMobile();
  const navigate = useNavigate();
  const resetStore = useResetStore();

  useEffect(() => {
    if (confirmPassword && password && password !== confirmPassword) {
      //@ts-ignore
      setCheckError({ message: "Пароли не совпадают" });
    } else {
      setCheckError(undefined);
    }
  }, [password, confirmPassword]);

  const disabled =
    !!errors.password ||
    !!errors.confirmPassword ||
    !password ||
    !confirmPassword ||
    !oldPassword ||
    confirmPassword !== password;

  const matchError = error?.code === UpdatePasswordErrors.match ? error : null;
  const asOldError = error?.code === UpdatePasswordErrors.old ? error : null;

  const getTitle = useCallback(() => {
    switch (size) {
      case "xl":
        return `Пароль должен содержать не менее\n8 символов, в том числе латинские заглавные и\nстрочные буквы, цифры  и специальные символы`;
      case "sm":
        return `Пароль должен содержать\nне менее 8 символов, в том числе\nлатинские заглавные и строчные буквы, цифры \nи специальные символы`;
      case "xs":
        return `Пароль должен содержать\nне менее 8 символов, в том числе\nлатинские заглавные и строчные буквы, цифры \nи специальные символы`;
      default:
        return `Пароль должен содержать не менее 8 символов, в том числе латинские заглавные и строчные буквы, цифры  и специальные символы`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickSuccess = () => {
    dispatch(authActions.setLogout());
    const deviceIdItem = localStorage.getItem("deviceId");
    const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
    localStorage.clear();
    if (deviceIdItem !== null) localStorage.setItem("deviceId", deviceIdItem);
    if (PWAdeviceIdItem !== null)
      localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
    sessionStorage.clear();
    navigate("/sign-in", { replace: true });
    resetStore();
    onClose();
  };

  if (isPasswordUpdated) {
    return (
      <Box
        width="100%"
        height="100%"
        pt={32}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <NotificationResult
          title="Ваш пароль был успешно изменён"
          subtitle={"Пройдите авторизацию\nс новым паролем"}
          subtitleSize="text_5"
          icon={<IconSuccess />}
          height="100%"
          buttons={[
            {
              name: "Перейти к авторизации",
              variant: "primary",
              size: "lg",
              onClick: clickSuccess,
            },
          ]}
          titleStyle={{
            whiteSpace: !isMobile ? "nowrap" : "",
            fontWeight: 500,
            lineHeight: "32px",
            textAlign: "center",
            color: "#393E46",
          }}
          subtitleStyle={{
            fontWeight: 400,
            lineHeight: "30px",
            textAlign: "center",
            fontSize: "20px",
            color: "#393E46",
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box px={{ xs: "20px", lg: "32px" }}>
          <Typography variant="text_8" fontWeight={500} color="#393E46">
            Изменить пароль
          </Typography>
          <Typography
            variant="text_5"
            fontWeight={400}
            display="flex"
            color={theme.primary.main.gray[800]}
            whiteSpace="pre-wrap"
            mt={{ xs: 28, xl: 16 }}
          >
            {getTitle()}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap={{ xs: 20, xl: 24 }}
            mt={{ xs: 20, xl: 24 }}
          >
            <Controller
              control={control}
              name="oldPassword"
              rules={{
                pattern: {
                  value: passwordPattern,
                  message: "Пароль не соответствует требованиям",
                },
              }}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  // @ts-ignore
                  value={oldPassword}
                  onChange={field.onChange}
                  label="Текущий пароль"
                  placeholder="Введите пароль"
                  autoComplete="off"
                  // @ts-ignore
                  error={matchError?.comment || matchError?.message}
                />
              )}
            />
            <Box>
              <Controller
                control={control}
                name="password"
                rules={{
                  pattern: {
                    value: passwordPattern,
                    message: "Пароль не соответствует требованиям",
                  },
                }}
                render={({ field }) => (
                  <PasswordInput
                    {...field}
                    // @ts-ignore
                    value={password}
                    onChange={field.onChange}
                    label="Новый пароль"
                    placeholder="Введите пароль"
                    error={
                      asOldError?.comment ||
                      // @ts-ignore
                      asOldError?.message ||
                      // @ts-ignore
                      asOldError?.password?.message
                    }
                  />
                )}
              />
            </Box>
            <Box>
              <Controller
                control={control}
                name="confirmPassword"
                rules={{
                  validate: (value) => {
                    if (value !== watch("password")) {
                      return "Пароли не совпадают";
                    }
                  },
                }}
                render={({ field }) => (
                  <PasswordInput
                    {...field}
                    // @ts-ignore
                    value={confirmPassword}
                    onChange={field.onChange}
                    label="Повторите пароль"
                    placeholder="Введите пароль"
                    error={
                      errors.confirmPassword?.message ||
                      // @ts-ignore
                      checkError?.message
                    }
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            rowGap={20}
            mt={{ xs: "52px", lg: "40px" }}
          >
            <Button
              isLoading={isLoading}
              disabled={disabled || !passwordPattern.test(oldPassword)}
              onClick={handlePasswordUpdate}
              variant="primary"
              size="lg"
              fullWidth
            >
              {isMobile ? "Применить" : "Изменить пароль"}
            </Button>
            <Button size="lg" variant="secondary" onClick={onClose}>
              Отмена
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdatePassword;
