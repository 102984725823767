import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button, Loader } from "components";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "store";
import { NotificationResult } from "molecules/NotificationResult";
import { IconSuccess } from "atoms/IconSuccess";
import { useIsMobile } from "hooks/useIsMobile";
import { PinPad, PinValue } from "organisms/PinCode/components";
import { PIN_LENGTH } from "organisms/PinCode";
import PinInput from "react-pin-input";
import { authActions, createPin } from "store/slices/auth";

export type CreatePinCodeType = {
  value1: string;
  value2: string;
};

type CreatePinCodeProps = {
  onClose: () => void;
};

const initialValues = {
  value1: "",
  value2: "",
};

const CreatePinCode = ({ onClose }: CreatePinCodeProps) => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();
  let ele = useRef();

  const [pinCode, setPinCode] = useState<CreatePinCodeType>(initialValues);
  const [errorStatus, setErrorStatus] = useState(false);
  const [pinCreate, setPinIsCreate] = useState(false);
  const [value, setValue] = useState("");

  const { isLoading, elseDevice } = useSelector(authSelector);
  const elseLogin = localStorage.getItem("elseLogin");
  const tmpLogin = localStorage.getItem("tmpLogin");

  const { value1, value2 } = pinCode;
  const isInitialPin = String(value1).length < PIN_LENGTH;
  const isPinComplete =
    value1.length === PIN_LENGTH && value2.length === PIN_LENGTH;

  const handleChange = (code?: string) => {
    const currentStep = isInitialPin ? 1 : 2;
    setErrorStatus(false);
    setPinCode({
      ...pinCode,
      [`value${currentStep}`]: code
        ? `${pinCode[`value${currentStep}`]}${code}`
        : pinCode[`value${currentStep}`].slice(0, -1),
    });
  };

  const handleChangeWeb = (code?: string) => {
    const currentStep = isInitialPin ? 1 : 2;
    setErrorStatus(false);
    if (code?.length === PIN_LENGTH) {
      setPinCode({
        ...pinCode,
        [`value${currentStep}`]: code
          ? `${pinCode[`value${currentStep}`]}${code}`
          : pinCode[`value${currentStep}`].slice(0, -1),
      });
      //@ts-ignore
      ele?.current?.clear();
      setValue("");
    }
  };

  useEffect(() => {
    if (isPinComplete) {
      if (value1 === value2) {
        dispatch(createPin({ pinCode: value1, repeatPin: value2 }))
          //@ts-ignore
          .unwrap()
          .then(() => {
            setPinIsCreate(true);
            if (tmpLogin) {
              localStorage.setItem("login", tmpLogin);
              localStorage.removeItem("tmpLogin");
              localStorage.removeItem("useBiometry");
            }
            if (elseDevice && elseLogin) {
              localStorage.setItem("login", elseLogin);
              localStorage.removeItem("elseLogin");
              localStorage.removeItem("useBiometry");
            }
          })
          .catch(() => {
            setErrorStatus(true);
            setPinCode(initialValues);
          });
      } else {
        setErrorStatus(true);
        setPinCode(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCode]);

  if (pinCreate) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        marginTop={isMobile ? 68 : 0}
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
      >
        {!isMobile && (
          <Typography
            variant="text_8"
            color="gray.b800"
            fontWeight="500"
            marginBottom={28}
          >
            Создать короткий код для входа
          </Typography>
        )}
        <NotificationResult
          title={"Короткий код\nдля входа создан"}
          subtitleSize="text_5"
          icon={<IconSuccess />}
          height="100%"
          buttonStyle={{ margin: 24 }}
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              size: "lg",
              onClick: () => {
                dispatch(authActions.setElseDevice(false));
                onClose();
                dispatch(authActions.setIsPinAllowed(true));
              },
            },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%" }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
        alignItems="center"
      >
        {isMobile ? (
          <>
            {!errorStatus && (
              <Typography
                variant="text_19"
                color="gray.b500"
                style={{ whiteSpace: "pre-line" }}
              >
                {isInitialPin
                  ? "Придумайте 4-х значный код"
                  : "Повторите 4-х значный код"}
              </Typography>
            )}
            {errorStatus && (
              <Typography variant="text_5" color="#E64545">
                Коды не совпадают
              </Typography>
            )}
            <PinValue
              isLoading={isLoading}
              value={isInitialPin ? value1 : value2}
              isError={errorStatus}
            />
            <PinPad
              isLoading={isLoading}
              {...(!isPinComplete && {
                onChange: handleChange,
              })}
            />
          </>
        ) : (
          <>
            {isLoading ? (
              <Loader size={68} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  px: 32,
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="text_8"
                  color="gray.b800"
                  fontWeight="500"
                  marginBottom={28}
                >
                  Изменить короткий код для входа
                </Typography>
                <Typography
                  variant="text_19"
                  color="gray.b500"
                  marginBottom={12}
                >
                  {isInitialPin
                    ? "Придумайте 4-х значный код"
                    : "Повторите 4-х значный код"}
                </Typography>
                <PinInput
                  length={4}
                  initialValue=""
                  secret
                  //@ts-ignore
                  ref={ele}
                  secretDelay={100}
                  onChange={(value, index) => {
                    setValue(value);
                    setErrorStatus(false);
                  }}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={{
                    borderColor: errorStatus ? "#F97066" : "#D0D5DD",
                    borderRadius: 12,
                  }}
                  inputFocusStyle={{
                    borderColor: errorStatus ? "#F97066" : "#D0D5DD",
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />

                {errorStatus && (
                  <Typography variant="text_5" color="#E64545">
                    Коды не совпадают
                  </Typography>
                )}
                <Button
                  //@ts-ignore
                  disabled={value.length !== 4}
                  onClick={() => {
                    handleChangeWeb(value);
                  }}
                  variant="primary"
                  size="lg"
                  fullWidth
                  sx={{ mt: 32, mb: 8 }}
                >
                  Продолжить
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CreatePinCode;
