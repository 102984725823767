export const Biometry = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none">
    <rect width={36} height={36} fill="#fff" rx={18} />
    <path
      stroke="#0082BA"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M28 12.58v-.21c0-.343 0-.515-.01-.66a4 4 0 0 0-3.7-3.7C24.146 8 23.975 8 23.63 8v0M12.37 28v0c-.343 0-.515 0-.66-.01a4 4 0 0 1-3.7-3.7C8 24.146 8 23.975 8 23.63v-.21M23.63 28v0c.344 0 .515 0 .66-.01a4 4 0 0 0 3.7-3.7c.01-.145.01-.316.01-.66v-.21M8 12.58v-.21c0-.343 0-.515.01-.66a4 4 0 0 1 3.7-3.7c.144-.01.316-.01.66-.01v0M11.75 18.25h12.5"
    />
  </svg>
);
