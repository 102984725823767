import styled from "@emotion/styled/macro";
import { useDispatch } from "react-redux";
import { getPromotionById } from "store/slices/promotion";
import { systemActions } from "store/slices/system";
import { AnotherPages, ICardState } from "store/slices/system/slice";

const Container = styled.div`
  border-radius: 24px;
  border: 1px solid #e6e7e8;
  background: #f7f8fa;
  width: 285px;
  height: 166px;
  cursor: pointer;
  display: flex;

  @media (max-width: 550px) {
    width: 180px;
    height: 132px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-shadow: 0px 2px 9px 0px rgba(224, 227, 235, 0.77);
  }
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  line-clamp: 1;
  word-wrap: break-word;
  color: rgba(59, 79, 78, 1);
  word-break: break-word;

  @media (max-width: 550px) {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color: rgba(71, 84, 103, 1);
  }
`;

const Gradient = styled.div`
  height: 50%;
  border-radius: 0px 0px 22px 22px;
  margin-top: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: flex-end;

  @media (max-width: 550px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
`;

interface ICardProps {
  card: ICardState;
  processCardClick: boolean;
}

const Card = ({ card, processCardClick }: ICardProps) => {
  const dispatch = useDispatch();

  const setTab = () => {
    if (processCardClick) {
      dispatch(systemActions.setActiveTab({ tab: AnotherPages.promotion }));
      if (card.id) dispatch(getPromotionById(card.id));
    }
  };

  return (
    <Container
      onClick={setTab}
      style={{
        backgroundImage: `url(${card.srcImgLenta})`,
        backgroundSize: "cover",
      }}
    >
      <Gradient>
        <Title>{card.titleShort}</Title>
      </Gradient>
    </Container>
  );
};

export default Card;
