import styled from "@emotion/styled/macro";

interface Props {
  isMobile?: boolean;
  marginTop?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d0d5dd;
  margin-bottom: 20px;
  margin-top: ${(props: Props) => (props.marginTop ? "-30px" : "0px")};
  scroll-behavior: smooth;
  overflow: ${(props: Props) => (props.isMobile ? "visible" : "hidden")};
  overflow-x: ${(props: Props) => (props.isMobile ? "clip" : "scroll")};
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  width: ${(props: { width: string; active: boolean }) => props.width};
  border-bottom: ${(props: { active: boolean }) =>
    props.active ? "3px solid #0082ba" : "none"};
`;

export const TabText = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0em;
  font-weight: ${(props: { active: boolean }) =>
    props.active ? "600" : "500"};
  color: ${(props: { active: boolean }) =>
    props.active ? "#0082ba" : "#9aa4b5"};
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 12px;
  background: #f7f8fa;
  cursor: pointer;
  opacity: ${(props: { disabled: boolean }) => (props.disabled ? "0.6" : "1")};

  @media (max-width: 550px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const DownloadIcon = styled.img`
  width: 20px;
  height: 20px;
`;
