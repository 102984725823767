import { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { setAnotherCards, setMainCard } from "store/slices/cardsSlice";
import { RootState } from "store";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// @ts-ignore
import CardBG from "../../../../../components/public/images/CardBG.png";
import { formatAmount } from "utils/formatCurrency";
import { CardDto } from "api/account";
import { RenderStatus } from "pages/cards/modal/cardsSlider/RenderStatus";
import { useOutsideClick } from "hooks/useOutsideClick";

const CardSelect = () => {
  const { isMobile } = useIsMobile();
  const theme = useTheme();

  const {
    cards: { anotherCards, account, mainCard },
  } = useSelector((state: RootState) => state);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const cardLastNumber = (cardPan: string) => {
    return Number(
      cardPan
        ?.split("")
        .filter((symbol) => symbol !== "*")
        .join("")
    );
  };

  const switchCard = (card: CardDto) => {
    dispatch(setMainCard(card));
    const anotherCards = account?.cards
      ?.filter((cardItem: any) => cardItem.cardId !== card.cardId)
      .map((el) => (el.cardId === mainCard.cardId ? mainCard : el));
    dispatch(setAnotherCards(anotherCards));
  };

  const modalHeight =
    anotherCards.length > 1
      ? anotherCards.length * 48 + (anotherCards.length - 1) * 12 + 32
      : 0;

  const handleClickOutside = () => {
    setIsModalOpen(false);
  };

  const divFocus = useOutsideClick(handleClickOutside);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.gray.b50,
        padding: isMobile ? "12px 16px 16px 16px" : "20px 28px 28px 28px",
        borderRadius: 28,
        boxSizing: "border-box",
        width: isMobile ? "auto" : "580px",
        display: "flex",
        flexDirection: "column",
        color: "red",
      }}
      mb={20}
    >
      <Typography
        variant="text_10"
        fontWeight={500}
        color={theme.palette.gray.b600}
        mb={15}
      >
        Выберите карту
      </Typography>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "14px 18px",
          borderRadius: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          width: isMobile ? "303" : "524px",
          boxSizing: "border-box",
        }}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              position: "relative",
              justifyContent: "center",
              height: 32,
              cursor: "pointer",
            }}
          >
            <RenderStatus
              status={mainCard.status}
              width="calc(100% - 9px)"
              iconHeight={15}
              iconWidth={15}
              radius={4}
            />

            <img
              src={CardBG}
              alt="ваша карта"
              style={{
                width: "50px",
                height: "32px",
                borderRadius: 4,
                marginRight: 9,
              }}
            />
            <Typography
              variant="text_1"
              fontWeight={400}
              sx={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "50px",
                textAlign: "center",
                backdropFilter: "blur(1px)",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
                color: "#fff",
              }}
            >
              {mainCard.cardPan && `**${cardLastNumber(mainCard.cardPan)}`}
            </Typography>
          </Box>
          <Typography variant="text_12" fontWeight={500}>
            {mainCard.amount &&
              formatAmount(mainCard.amount, mainCard.currency?.sign)}
          </Typography>
        </Box>
        {anotherCards.length > 0 && (
          <Box
            onBlur={() => {}}
            ref={divFocus}
            width="100%"
            maxWidth={isMobile ? "auto" : "522px"}
            display={isModalOpen ? "flex" : "none"}
            flexDirection="column"
            sx={{
              backgroundColor: "#fff",
              padding: "12px 20px 20px 20px",
              borderRadius: 16,
              boxShadow: "0px 2px 10px rgba(194, 201, 214, 0.25)",
              animation: "fadeIn 0.2s ease-in-out",
              opacity: isModalOpen ? 1 : 0,
              transition: "0.5s",
              height: isModalOpen ? modalHeight : 0,
              rowGap: "12px",
              position: "absolute",
              top: "69px",
              left: 0,
              zIndex: 1,
              boxSizing: "border-box",
            }}
          >
            {anotherCards?.map((cardItem: any, index: number) => {
              return (
                <Box
                  key={index}
                  pt={8}
                  pb={8}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    ":hover": {
                      backgroundColor: "#0082BA0D",
                    },
                  }}
                  onClick={() => switchCard(cardItem)}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        position: "relative",
                        justifyContent: "center",
                        height: 32,
                      }}
                    >
                      <RenderStatus
                        status={cardItem.status}
                        width="calc(100% - 9px)"
                        iconHeight={15}
                        iconWidth={15}
                        radius={4}
                        size="small"
                      />
                      <img
                        src={CardBG}
                        alt="ваша карта"
                        style={{
                          width: "50px",
                          height: "32px",
                          borderRadius: 4,
                          marginRight: 9,
                        }}
                      />
                      <Typography
                        variant="text_1"
                        fontWeight={400}
                        sx={{
                          position: "absolute",
                          left: 0,
                          bottom: 0,
                          width: "50px",
                          textAlign: "center",
                          backdropFilter: "blur(1px)",
                          borderBottomLeftRadius: "4px",
                          borderBottomRightRadius: "4px",
                          color: "#fff",
                        }}
                      >
                        {`**${cardLastNumber(cardItem.cardPan)}`}
                      </Typography>
                    </Box>
                    <Typography variant="text_12" fontWeight={500}>
                      {formatAmount(cardItem.amount, cardItem.currency.sign)}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        )}
        {anotherCards.length > 0 && <ArrowDropDownIcon />}
      </Box>
    </Box>
  );
};

export default CardSelect;
