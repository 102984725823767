import { store as reduxStore } from "store";
import { authActions } from "../store/slices/auth";
import { authApi, axiosInstance } from "../api";
import { chatActions } from "../store/slices/chat";
import { useResetStore } from "hooks/useResetStore";
import { clearErrorMessage } from "store/slices/alerts";
let store: typeof reduxStore;

export const injectStore = (_store: typeof reduxStore) => {
  store = _store;
};

function clear() {
  sessionStorage.clear();
  store.dispatch(authActions.setLogout());
}

axiosInstance.interceptors.request.use((config) => {
  const tokenFromStorage = sessionStorage.getItem("accessToken");
  const { accessToken } = store.getState().auth;
  if (accessToken || tokenFromStorage) {
    config.headers!.Authorization = `Bearer ${accessToken || tokenFromStorage}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { accessToken, isAuthenticated } = store.getState().auth;
    const { chatMessageCounter } = store.getState().chat;
    const tokenFromStorage = sessionStorage.getItem("accessToken");
    const isAuthFromStorage = sessionStorage.getItem("isAuthenticated");
    const prevRequest = error?.config;
    const rURL = error.request.responseURL;
    const logoutPath = "/auth/v1/user/logout";
    const logoutRequest = rURL.indexOf(logoutPath) !== -1;
    const resetStore = useResetStore();
    if (
      error?.response?.status === 401 &&
      !prevRequest._retry &&
      (isAuthenticated || isAuthFromStorage) &&
      (accessToken || tokenFromStorage) &&
      !logoutRequest
    ) {
      prevRequest._retry = true;
      const isPWA = window.matchMedia("(display-mode: standalone)").matches;

      try {
        store.dispatch(clearErrorMessage());
        const response = await authApi.refreshToken({
          deviceId:
            (isPWA
              ? localStorage.getItem("PWADeviceId")
              : localStorage.getItem("deviceId")) || "",
        });

        const { accessToken } = response.data;
        if (accessToken) {
          sessionStorage.setItem("accessToken", accessToken);
          prevRequest.headers.Authorization = `Bearer ${accessToken}`;
          store.dispatch(authActions.setNewAccessToken(accessToken));
        }

        const isMessagesPath =
          error.response.data.path === "/api/notification/v1/chat/messages";

        if (isMessagesPath) {
          if (chatMessageCounter >= 2) {
            clear();
            resetStore();

            return Promise.reject(error);
          }

          store.dispatch(chatActions.setChatMessageCounter());
        }

        return axiosInstance(prevRequest);
      } catch (err: any) {
        if (err.response.status === 403 || err.response.status === 500) {
          clear();
          resetStore();
        }
      }
    }

    return Promise.reject(error);
  }
);
