import { OperationCheck } from "components/OperationCheck";
import { useSelector } from "react-redux";

export const TransferConfirmed = ({ onClose, payloadModal }: any) => {
  const { transfer } = useSelector((state: any) => state);

  if (!transfer?.confirmedTransfer) return null;

  return (
    <OperationCheck
      confirmedTransfer={transfer.confirmedTransfer}
      onClose={onClose}
      payloadModal={payloadModal}
    />
  );
};
