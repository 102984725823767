import React from "react";

export const IconDownload = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.478 13.5424C13.0303 13.5424 13.478 13.0947 13.478 12.5424V12.2974L16.8475 8.84717H13.478V3.01492C13.478 2.46264 13.0303 2.01492 12.478 2.01492C11.9257 2.01492 11.478 2.46264 11.478 3.01492V8.84717H7.85069L11.4782 12.5615C11.4883 13.1049 11.9321 13.5424 12.478 13.5424ZM5 13C5 12.4477 4.55228 12 4 12C3.44772 12 3 12.4477 3 13V17C3 20.3137 5.68629 23 9 23H16C19.3137 23 22 20.3137 22 17V13C22 12.4477 21.5523 12 21 12C20.4477 12 20 12.4477 20 13V17C20 19.2091 18.2091 21 16 21H9C6.79086 21 5 19.2091 5 17V13Z"
        fill="#0082BA"
      />
    </svg>
  );
};

export default IconDownload;
