import * as React from "react";

export const IconLimitsTab = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 14h6m0 0v6m0-6-7 7m17-11h-6m0 0V4m0 6 7-7"
      stroke={props?.color || "#C2C9D6"}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
