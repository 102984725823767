import * as React from "react";

export const IconTransfersTab = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 10.666a2 2 0 0 1 2-2h9.333a2 2 0 0 1 2 2v2.667a2 2 0 0 1-2 2H4.5a2 2 0 0 1-2-2v-2.667Z"
      fill={props?.color || "#C2C9D6"}
    />
    <path
      d="M21.919 11.306a1 1 0 0 1-.006 1.53l-9.322 7.792a1 1 0 0 1-1.641-.767V4.16a1 1 0 0 1 1.646-.763l9.323 7.91Z"
      fill={props?.color || "#C2C9D6"}
    />
  </svg>
);
