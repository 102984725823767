import { createTheme } from "@mui/material";
import { CSSProperties } from "react";
import type {} from "@mui/lab/themeAugmentation";

declare module "@mui/material/styles" {
  interface Components {
    [key: string]: any;
  }

  interface TypographyVariants {
    text_404: CSSProperties;
    text_20: CSSProperties;
    text_19: CSSProperties;
    text_18: CSSProperties;
    text_17: CSSProperties;
    text_16: CSSProperties;
    text_15: CSSProperties;
    text_14: CSSProperties;
    text_13: CSSProperties;
    text_12: CSSProperties;
    text_11: CSSProperties;
    text_10: CSSProperties;
    text_9: CSSProperties;
    text_8: CSSProperties;
    text_7: CSSProperties;
    text_6: CSSProperties;
    text_5: CSSProperties;
    text_4: CSSProperties;
    text_3: CSSProperties;
    text_2: CSSProperties;
    text_1: CSSProperties;
    text_0: CSSProperties;
  }

  interface TypographyVariantsOptions {
    text_404?: CSSProperties;
  }

  interface Palette {
    white: string;
    gray: {
      b10: string;
      b25: string;
      b50: string;
      b75: string;
      b100: string;
      b200: string;
      b300: string;
      b400: string;
      b500: string;
      b600: string;
      b700: string;
      b800: string;
      b900: string;
      disabled: string;
    };
    indigo: {
      b25: string;
      b50: string;
      b200: string;
      b300: string;
      b500: string;
      b600: string;
    };
    blue: {
      b50: string;
      b100: string;
      b200: string;
      b400: string;
      b500: string;
      b600: string;
      b700: string;
    };
    blueLight: {
      b50: string;
      b100: string;
      b200: string;
      b300: string;
    };
    green: {
      b50: string;
      b500: string;
    };
    blueGray: {};
    red: {
      b200: string;
      b300: string;
      b400: string;
      b500: string;
    };
    pink: {};
    orange: {
      b50: string;
      b300: string;
      b500: string;
    };
    black: {};
    violet: {};
  }

  interface PaletteOptions {
    gray: {};
    indigo: {};
    blue: {};
    blueLight: {
      b50: string;
      b100: string;
      b200: string;
      b300: string;
    };
    green: {};
    blueGray: {};
    red: {};
    pink: {};
    orange: {};
    black: {};
    violet: {};
  }

  interface Theme {
    primary: {
      main: {
        gray: {
          0: string;
          50: string;
          75: string;
          100: string;
          200: string;
          300: string;
          400: string;
          800: string;
          900: string;
        };
      };
      brand: {
        blue: {
          100: string;
          400: string;
          gradient: string;
        };
      };
      white?: string;
      error?: {
        50?: string;
        300?: string;
        500?: string;
      };
      gray?: {
        50?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        700?: string;
        900?: string;
      };
    };
    backgrounds?: {
      bankCard?: {
        c1?: string;
        c2?: string;
      };
    };
  }

  interface ThemeOptions {
    primary: {
      main: {
        gray: {
          0: string;
          50: string;
          75: string;
          100: string;
          200: string;
          300: string;
          400: string;
          800: string;
          900: string;
        };
      };
      brand: {
        blue: {
          100: string;
          400: string;
          gradient: string;
        };
      };
      white?: string;
      error?: {
        50?: string;
        300?: string;
        500?: string;
      };
      gray?: {
        50?: string;
        100?: string;
        200?: string;
        300?: string;
        400?: string;
        500?: string;
        700?: string;
        900?: string;
        disabled: string;
      };
    };
    backgrounds?: {
      bankCard?: {
        c1?: string;
        c2?: string;
      };
    };
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    "no-border": true;
    disabled: true;
    "no-fill": true;
  }

  interface ButtonPropsSizeOverrides {
    sm: true;
    lg: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    text_404: true;
    text_20: true;
    text_19: true;
    text_18: true;
    text_17: true;
    text_16: true;
    text_15: true;
    text_14: true;
    text_13: true;
    text_12: true;
    text_11: true;
    text_10: true;
    text_9: true;
    text_8: true;
    text_7: true;
    text_6: true;
    text_5: true;
    text_4: true;
    text_3: true;
    text_2: true;
    text_1: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ["Inter", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  spacing: 1,
  shape: {
    borderRadius: 1,
  },
  primary: {
    main: {
      gray: {
        0: "rgba(255, 255, 255, 1)",
        50: "rgba(247, 248, 250, 1)",
        75: "#F2F4F9",
        100: "rgba(235, 237, 243, 1)",
        200: "#C2C9D6",
        300: "rgba(154, 164, 181, 1)",
        400: "rgba(137, 147, 164, 1)",
        800: "rgba(57, 62, 70, 1)",
        900: "rgba(7, 4, 23, 1)",
      },
    },
    brand: {
      blue: {
        100: "rgba(100, 166, 194, 1)",
        400: "rgba(0, 130, 186, 1)",
        gradient: "linear-gradient(146.49deg, #F1F7FF 2.88%, #DCEFF8 93.47%)",
      },
    },
    white: "rgba(255, 255, 255, 1)",
    error: {
      50: "#FEF3F2",
      300: "rgba(253, 162, 155, 1)",
      500: "#F04438",
    },
    gray: {
      50: "rgba(249, 250, 251, 1)",
      100: "rgba(242, 244, 247, 1)",
      200: "rgba(228, 231, 236, 1)",
      300: "rgba(208, 213, 221, 1)",
      400: "rgba(152, 162, 179, 1)",
      500: "rgba(102, 112, 133, 1)",
      700: "rgba(52, 64, 84, 1)",
      900: "rgba(16, 24, 40, 1)",
      disabled: "#EBEDF3",
    },
  },
  palette: {
    gray: {
      b10: "#9AA4B5",
      b25: "#FCFCFD",
      b50: "#F7F8FA",
      b75: "#F2F4F9",
      b100: "#F2F4F7",
      b200: "#E4E7EC",
      b300: "#D0D5DD",
      b400: "#98A2B3",
      b500: "#667085",
      b600: "#475467",
      b700: "#344054",
      b800: "#1D2939",
      b900: "#101828",
      disabled: "#EBEDF3",
    },
    indigo: {
      b25: "#F5F8FF",
      b50: "#EEF4FF",
      b200: "#C7D7FE",
      b300: "#A4BCFD",
      b500: "#6172F3",
      b600: "#444CE7",
    },
    blue: {
      b50: "#EEF4FF",
      b100: "#64A6C2",
      b200: "#C7D7FE",
      b400: "#0082BA",
      b500: "#6172F3",
      b600: "#444CE7",
      b700: "#f5f8fd",
    },
    blueLight: {
      b50: "#F0F9FF",
      b300: "#7CD4FD",
      b200: "#B9E6FE",
      b100: "#E0F2FE",
    },
    green: {
      b50: "#ECFDF3",
      b100: "#D1FADF",
      b300: "#6CE9A6",
      b500: "#12B76A",
    },
    blueGray: {
      b100: "#EAECF5",
      b200: "#C8CCE5",
      b500: "#4E5BA6",
    },
    red: {
      b200: "#FFEEED",
      b300: "#FDA29B",
      b400: "#F97066",
      b500: "#F04438",
    },
    pink: {
      b500: "#EE46BC",
    },
    orange: {
      b50: "#FFFAEB",
      b300: "#FEC84B",
      b500: "#F79009",
    },
    black: "#000",
    violet: {
      b500: "#9E77ED",
    },
  },
  backgrounds: {
    bankCard: {
      c1: "linear-gradient(90deg, #7F56D9 0%, #9E77ED 100%)",
      c2: "#6172F3",
    },
  },
  // shadows: {
  //   ...Array(25).fill("none"),
  //   bankCard: {
  //     s1: "0px 2px 20px rgba(97, 114, 243, 0.25)",
  //   },
  //   payments: "0px 10px 20px 4px rgba(16, 24, 40, 0.06)",
  //   officeFilter:
  //     "0px 12px 24px -4px rgba(16, 24, 40, 0.04), 0px 8px 8px -4px rgba(16, 24, 40, 0.02)",
  // },
  components: {
    IconWrapper: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 44,
          height: 44,
          borderRadius: 13,
        },
      },
      variants: [
        {
          props: { variant: "wrapper" },
          style: ({ theme }: any) => ({
            backgroundColor: theme.primary.main.gray[0],
          }),
        },
        {
          props: { variant: "no-wrapper" },
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    AccountWrapper: {
      styleOverrides: {
        root: {
          boxSizing: "border-box",
          display: "flex",
          alignItems: "center",
          columnGap: 12,
          padding: "18px 20px 18px 20px",
          maxWidth: 597,
          borderRadius: 24,
        },
      },
      variants: [
        {
          props: { variant: "dashed" },
          style: ({ theme }: any) => ({
            border: `3px dashed ${theme.primary.main.gray[100]}`,
          }),
        },
        {
          props: { variant: "filled" },
          style: ({ theme }: any) => ({
            backgroundColor: theme.primary.main.gray[50],
          }),
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxSizing: "border-box",
        },
      },

      variants: [
        {
          props: { variant: "primary" },
          style: ({ theme }) => ({
            color: theme.primary.main.gray[0],
            boxShadow: "0px 1px 2px rgba(185, 53, 71, 0.05)",
            backgroundColor: theme.primary.brand.blue[400],
            transition: ".2s",
            "& svg:not(.loader) path": {
              stroke: theme.primary.main.gray[0],
              fill: theme.primary.main.gray[0],
              transition: ".2s",
            },
            "&:hover": {
              backgroundColor: theme.primary.brand.blue["100"],
            },
            "&:active": {
              background: theme.primary.brand.blue["gradient"],
              color: theme.primary.brand.blue["400"],
              "& span svg path": {
                stroke: theme.primary.brand.blue["400"],
                fill: theme.primary.brand.blue["400"],
              },
            },
            "&:disabled": {
              color: theme.primary.main.gray["400"],
              background: theme.palette.gray["disabled"],
              "& span svg:not(.loader) path": {
                stroke: theme.primary.main.gray["400"],
                fill: theme.primary.main.gray["400"],
              },
            },
          }),
        },
        {
          props: { variant: "secondary" },
          style: ({ theme }) => ({
            color: theme.primary.brand.blue[400],
            backgroundColor: theme.primary.main.gray[50],
            transition: ".2s",
            "& span svg:not(.loader) path": {
              stroke: theme.primary.brand.blue[400],
              fill: theme.primary.brand.blue[400],
              transition: ".2s",
            },
            "&:hover": {
              color: theme.primary.main.gray[0],
              backgroundColor: theme.primary.brand.blue["100"],
              "& span svg path": {
                stroke: theme.primary.main.gray[0],
                fill: theme.primary.main.gray[0],
              },
            },
            "&:active": {
              color: theme.primary.brand.blue["100"],
              backgroundColor: theme.primary.main.gray[50],
              "& span svg path": {
                stroke: theme.primary.brand.blue["100"],
                fill: theme.primary.brand.blue["100"],
              },
            },
            "&:disabled": {
              color: theme.primary.brand.blue["100"],
              "& span svg path": {
                stroke: theme.primary.brand.blue["100"],
                fill: theme.primary.brand.blue["100"],
              },
            },
          }),
        },
        {
          props: { variant: "disabled" },
          style: ({ theme }) => ({
            color: theme.primary.main.gray[400],
            backgroundColor: theme.primary.main.gray[100],
            "&:hover": {
              backgroundColor: "transparent",
            },
          }),
        },
        {
          props: { variant: "no-fill" },
          style: ({ theme }) => ({
            color: theme.primary.brand.blue[400],
            // border: `2px solid ${theme.primary.brand.blue[100]}`,
            "&:hover": {
              backgroundColor: "transparent",
            },
          }),
        },
        {
          props: { size: "sm" },
          style: {
            padding: "8px 16px",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "500",
            "& span": {
              width: "20px",
              height: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        },
        {
          props: { size: "lg" },
          style: {
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
            padding: "14px 16px",
            "& span": {
              width: "24px",
              height: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
        },
        {
          props: { variant: "text" },
          style: () => ({
            color: "#98A2B3",
            height: "20px",
            fontSize: "14px",
            lineHeight: "20px",
            transition: ".2s",
            padding: "0",
            "&:hover": {
              backgroundColor: "transparent",
              color: "#070417",
            },
            "&:active": {
              color: "#0082BA",
            },
          }),
        },
      ],
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            [theme.breakpoints.up("xs")]: {
              padding: "0",
            },
            [theme.breakpoints.up("md")]: {
              padding: "14px 20px",
            },
            [theme.breakpoints.up("xl")]: {
              padding: "0 56px",
            },
          };
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          return {
            [theme.breakpoints.up("xs")]: {
              padding: ownerState.global === "true" ? "0 16px" : 0,
            },
            [theme.breakpoints.up("sm")]: {
              padding: ownerState.global === "true" ? "0 16px" : 0,
            },
            [theme.breakpoints.up("md")]: {
              padding: ownerState.global === "true" ? "0 34px" : 0,
            },
            [theme.breakpoints.up("lg")]: {
              padding: ownerState.global === "true" ? "0 86px" : 0,
            },
            [theme.breakpoints.up("xl")]: {
              padding: ownerState.global === "true" ? "0 86px" : 0,
            },
          };
        },
      },
    },
  },
});

// [ШРИФТЫ]

theme.typography.text_404 = {
  fontSize: "100px",
  lineHeight: "100px",
};

theme.typography.text_20 = {
  fontSize: "24px",
  lineHeight: "32px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "30px",
    lineHeight: "38px",
  },
};

theme.typography.text_19 = {
  fontSize: "18px",
  lineHeight: "28px",
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    lineHeight: "30px",
  },
};

theme.typography.text_18 = {
  fontSize: "16px",
  lineHeight: "24px",
  [theme.breakpoints.up("lg")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};

theme.typography.text_17 = {
  fontSize: "18px",
  lineHeight: "28px",
};

theme.typography.text_16 = {
  fontSize: "16px",
  lineHeight: "24px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
    lineHeight: "30px",
  },
};

theme.typography.text_15 = {
  fontSize: "12px",
  lineHeight: "16px",
};

theme.typography.text_14 = {
  fontSize: "20px",
  lineHeight: "30px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "30px",
    lineHeight: "38px",
  },
};

theme.typography.text_13 = {
  fontSize: "18px",
  lineHeight: "28px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "20px",
    lineHeight: "30px",
  },
};

theme.typography.text_12 = {
  fontSize: "20px",
  lineHeight: "30px",
};

// 600
theme.typography.text_11 = {
  fontSize: "24px",
  lineHeight: "32px",
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
};
// 600
theme.typography.text_10 = {
  fontSize: "16px",
  lineHeight: "24px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "18px",
    lineHeight: "28px",
  },
};
theme.typography.text_9 = {
  fontSize: "24px",
  lineHeight: "32px",
  letterSpacing: "-0.32px",
  [theme.breakpoints.up("md")]: {
    fontSize: "36px",
    lineHeight: "44px",
  },
};
theme.typography.text_8 = {
  fontSize: "24px",
  lineHeight: "32px",
};
// 600
theme.typography.text_7 = {
  fontSize: "20px",
  lineHeight: "30px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};
// 600
theme.typography.text_6 = {
  fontSize: "18px",
  lineHeight: "28px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "24px",
    lineHeight: "32px",
  },
};
// 500, 600wwd
theme.typography.text_5 = {
  fontSize: "16px",
  lineHeight: "24px",
};
// 400
theme.typography.text_4 = {
  fontSize: "14px",
  lineHeight: "20px",
  [theme.breakpoints.up("lg")]: {
    fontSize: "16px",
    lineHeight: "24px",
  },
};
// 400, 500
theme.typography.text_3 = {
  fontSize: "14px",
  lineHeight: "20px",
};
// 500
theme.typography.text_2 = {
  fontSize: "12px",
  lineHeight: "18px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "14px",
    lineHeight: "20px",
  },
};
// 500
theme.typography.text_1 = {
  fontSize: "12px",
  lineHeight: "18px",
  [theme.breakpoints.up("xl")]: {
    fontSize: "12px",
    lineHeight: "18px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "12px",
    lineHeight: "18px",
  },
};

theme.typography.text_0 = {
  fontSize: "10px",
  lineHeight: "12px",
};

export default theme;
