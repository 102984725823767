/* eslint-disable no-console */
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { Home, SignUp, SignIn, Recovery } from "pages";
import { NotFound } from "pages/not-found";
import { PrivateRoute } from "navigation/PrivateRoute";
import { PrivateRoutePinEnter } from "navigation/PrivateRoutePinEnter";
import { Confirmation } from "./organisms/Confirmation";
import { injectStore } from "./common";
import { authActions } from "./store/slices/auth";
import { alertsSelector, authSelector, systemSelector, store } from "store";
import { Header } from "./organisms/Header/Header";
import Footer from "./components/Footer/Footer";
import UnregisteredHeader from "./organisms/UnregisteredHeader";
import "./App.module.sass";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Hidden, Typography } from "@mui/material";
import Alert from "./components/Alert/Alert";
import HugeAlert from "./components/HugeAlert/HugeAlert";
import { Modal } from "./molecules/Modal/Modal";
import { LogoutModal } from "./organisms/LogoutModal";
import { useAuthCommonActions } from "./hooks";
import { setHugeError, hideErrorMessage } from "store/slices/alerts";
import { MobileTabs } from "widgets";
import { useIsMobile } from "hooks/useIsMobile";
import { useResetStore } from "hooks/useResetStore";
import {
  NotificationType,
  getNotificationTypeFromUrl,
} from "utils/pwaPushDeepLink";
import { PinCode } from "organisms/PinCode";
import { Biometry } from "organisms/Biometry";
import ArrowLeft from "molecules/Modal/assets/ArrowLeft";
import { RefillInfo } from "pages/refill-info";
import { Push } from "./organisms/Push";
import { systemActions } from "store/slices/system";
import { useIsPWA } from "hooks/useIsPWA";
injectStore(store);
const CommonContainer = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isPWA = useIsPWA();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isConfirmed,
    isBurgerActive,
    isLogoutVisible,
    isInitialPin,
  } = useSelector(authSelector);
  const resetStore = useResetStore();

  const {
    errorMessage,
    errorTitle,
    isOpen,
    isHugeAlertOpen,
    errorHugeMessage,
    errorHugeTitle,
  } = useSelector(alertsSelector);
  const { handleLogout } = useAuthCommonActions();
  const { bodyMaxHeight, activeTab } = useSelector(systemSelector);
  const isAuth = sessionStorage.getItem("isAuthenticated") === "true";
  const { isMobile } = useIsMobile();
  const handleToggle = useCallback(() => {
    dispatch(authActions.setIsLogoutVisible());
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      dispatch(authActions.setIsAuthenticated());
    }
  }, [dispatch, isAuth]);

  useEffect(() => {
    dispatch(
      setHugeError({
        isOpen: false,
        message: "",
        title: "",
      })
    );
  }, [activeTab, dispatch]);

  const isHistoryActive = activeTab === "history" && isMobile;
  const hideElement =
    location.pathname === "/biometry-registration" ||
    location.pathname === "/pin-code" ||
    location.pathname.includes("/refill-info") ||
    location.pathname.includes("/404") ||
    location.pathname === "/pin-enter" ||
    location.pathname === "/push-registration";

  const isPinPage = location.pathname === "/pin-code";

  const isWayBack =
    location.pathname === "/pin-enter" || location.pathname === "/pin-code";

  const logout = () => {
    if (isPinPage && isInitialPin) {
      dispatch(authActions.setElseDevice(true));
      navigate("/", { replace: true });
      return;
    }
    if (isPinPage && !isInitialPin) {
      dispatch(authActions.setIsInitialPin(true));
      return;
    }
    const deviceIdItem = localStorage.getItem("deviceId");
    const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
    localStorage.clear();
    if (deviceIdItem !== null) {
      localStorage.setItem("deviceId", deviceIdItem);
    }
    if (PWAdeviceIdItem !== null) {
      localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
    }
    sessionStorage.clear();
    dispatch(authActions.setIsPinForgotten(true));
    dispatch(hideErrorMessage());
    navigate("/sign-in", { replace: true });
    resetStore();
  };
  const currentUrl = window.location.href;
  useEffect(() => {
    const notificationType = getNotificationTypeFromUrl(currentUrl);
    switch (notificationType) {
      case NotificationType.MESSAGE:
        localStorage.setItem("pushType", "MESSAGE");
        break;
      case NotificationType.NEWS:
        localStorage.setItem("pushType", "NEWS");
        break;
      case NotificationType.NOTIFICATION:
        localStorage.setItem("pushType", "NOTIFICATION");
        break;
      default:
        break;
    }
  }, [currentUrl, dispatch]);

  return (
    <Box
      sx={{
        overflow: isBurgerActive ? "hidden" : "unset",
        maxHeight: bodyMaxHeight,
      }}
    >
      {!hideElement && isAuthenticated && isConfirmed ? (
        <Header />
      ) : (
        <UnregisteredHeader />
      )}
      <Box
        maxWidth={1440}
        margin="0 auto"
        component="main"
        height={{
          xs: isHistoryActive && "calc(100svh - 162px)",
        }}
      >
        {isPinPage && (
          <Box
            onClick={logout}
            sx={{
              cursor: "pointer",
              position: "relative",
              left: isMobile ? 20 : 86,
              top: isMobile ? 20 : 40,
              p: "4px 8px",
              backgroundColor: "#F7F8FA",
              borderRadius: 8,
              width: "fit-content",
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            <ArrowLeft />
            <Typography color="#475467" variant="text_2" fontWeight="500">
              Назад
            </Typography>
          </Box>
        )}
        {!isWayBack ? (
          <Alert isOpen={isOpen} title={errorTitle} message={errorMessage} />
        ) : errorTitle === "Срок действия сессии истек" ? (
          <Alert isOpen={isOpen} title={errorTitle} message={errorMessage} />
        ) : null}
        <HugeAlert
          isOpen={isHugeAlertOpen}
          title={errorHugeTitle}
          message={errorHugeMessage}
        />
        {children}
        <Modal
          title="Выход из аккаунта"
          isOpen={isLogoutVisible}
          onClose={handleToggle}
          callback={handleToggle}
          buttonVisible={false}
        >
          <LogoutModal
            onClick={() => {
              handleToggle();
              handleLogout();
            }}
            onCancel={handleToggle}
          />
        </Modal>
      </Box>
      {!isHistoryActive && <Footer />}
      {!hideElement && isAuthenticated && isConfirmed && (
        <Hidden lgUp>
          <MobileTabs />
        </Hidden>
      )}
    </Box>
  );
};

const App = () => (
  <CommonContainer>
    <Routes>
      <Route path="/" element={<PrivateRoute element={Home} />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="sign-in" element={<SignIn />} />
      <Route path="confirm" element={<Confirmation />} />
      <Route path="pin-code" element={<PinCode />} />
      <Route path="biometry-registration" element={<Biometry />} />
      <Route path="push-registration" element={<Push />} />
      <Route path="pin-enter" element={<PrivateRoutePinEnter />} />
      <Route path="recovery" element={<Recovery />} />
      <Route path="refill-info/:id" element={<RefillInfo />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </CommonContainer>
);

export default App;
