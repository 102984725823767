import { Box } from "@mui/material";
import { NotificationResult } from "molecules/NotificationResult";
import { IconUnSuccess } from "atoms/IconUnSuccess";

type Props = {
  onClick: () => void;
};

export const UnSuccess = ({ onClick }: Props) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
    >
      <Box marginTop="80px" height="100%">
        <NotificationResult
          title="Упс, что-то пошло не так!"
          titleSize="text_12"
          subtitleSize="text_5"
          subtitle="Пожалуйста, попробуйте снова"
          icon={<IconUnSuccess />}
          height="100%"
          buttons={[
            {
              name: "Попробовать снова",
              variant: "primary",
              size: "lg",
              onClick,
            },
          ]}
        />
      </Box>
    </Box>
  );
};
