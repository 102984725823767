import { TextField, FormHelperText } from "@mui/material";
import { Label } from "molecules/Label/Label";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .Mui-error .MuiOutlinedInput-notchedOutline ": {
      border: "1px solid #FDA29B !Important",
    },
    "& .MuiOutlinedInput-root": {
      color: "#101828",
      background: "#ffffff",
      borderRadius: "16px",
      "&.Mui-focused fieldset": {
        border: "1px solid #A4BCFD",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FDA29B !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 18px",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#667085",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  errorRoot: {
    "&.Mui-error": {
      color: "#F04438",
      fontSize: 14,
      lineHeight: "20px",
    },
  },
}));

export const LoginInput = ({
  name,
  label,
  value,
  onChange,
  placeholder = "",
  error = undefined,
  disabled = false,
  noMessage = false,
  ...props
}) => {
  const classes = useStyles();
  return (
    <>
      <Label label={label} htmlFor={name} />
      <TextField
        classes={{ root: classes.inputRoot }}
        type="text"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete="off"
        id={name}
        fullWidth
        disabled={disabled}
        error={error}
        inputProps={{
          maxLength: 16,
        }}
        {...props}
      />
      {!!error && !noMessage && (
        <FormHelperText classes={{ root: classes.errorRoot }} error>
          {error.message || error.comment}
        </FormHelperText>
      )}
    </>
  );
};
