import * as React from "react";

const IconArrowRight = (props) => (
  <svg
    width={8}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 14"
    {...props}
  >
    <path
      d="M1.02 1.375 6.647 7 1.02 12.625"
      stroke="#0082BA"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconArrowRight;
