import { AuthContainer } from "containers";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { PinPad, PinValue } from "./components";
import {
  checkIsBiometryKeyExist,
  createPin,
} from "store/slices/auth/asyncThunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authSelector } from "store";
import { Button, Typography } from "@mui/material";
import { Loader } from "components";
import PinInput from "react-pin-input";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsPWA } from "hooks/useIsPWA";
import { authActions } from "store/slices/auth";

export const PIN_LENGTH = 4;

export type PinCodeType = {
  value1: string;
  value2: string;
};

export const PinCode: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = useMemo(() => {
    return {
      value1: "",
      value2: "",
    };
  }, []);
  const [pinCode, setPinCode] = useState<PinCodeType>(initialValues);
  const { isLoading, isInitialPin } = useSelector(authSelector);
  const [errorStatus, setErrorStatus] = useState(false);
  const { value1, value2 } = pinCode;
  let ele = useRef();
  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();
  const [value, setValue] = useState("");
  const isPinComplete =
    value1.length === PIN_LENGTH && value2.length === PIN_LENGTH;
  const isAuth = () => {
    localStorage.setItem("isAuthed", "true");
  };
  const tmpLogin = localStorage.getItem("tmpLogin") || "";
  const handleChange = (code?: string) => {
    const currentStep = isInitialPin ? 1 : 2;
    setErrorStatus(false);
    setPinCode({
      ...pinCode,
      [`value${currentStep}`]: code
        ? `${pinCode[`value${currentStep}`]}${code}`
        : pinCode[`value${currentStep}`].slice(0, -1),
    });
  };

  const handleChangeWeb = (code?: string) => {
    const currentStep = isInitialPin ? 1 : 2;
    setErrorStatus(false);
    if (code?.length === PIN_LENGTH) {
      setPinCode({
        ...pinCode,
        [`value${currentStep}`]: code
          ? `${pinCode[`value${currentStep}`]}${code}`
          : pinCode[`value${currentStep}`].slice(0, -1),
      });
      //@ts-ignore
      ele?.current?.clear();
      setValue("");
    }
  };

  useEffect(() => {
    if ((isMobile || isPWA) && value1.length === PIN_LENGTH) {
      dispatch(authActions.setIsInitialPin(false));
    }
    if (isPinComplete) {
      if (value1 === value2) {
        dispatch(createPin({ pinCode: value1, repeatPin: value2 }));
        dispatch(authActions.setIsPinAllowed(true));
        localStorage.setItem("login", tmpLogin);
        localStorage.removeItem("useBiometry");
        if (isPWA) {
          dispatch(checkIsBiometryKeyExist())
            //@ts-ignore
            .unwrap()
            .then((res) => {
              isAuth();
              navigate("/biometry-registration", { replace: true });
              // else {
              //   localStorage.setItem("useBiometry", "true");
              //   dispatch(profileActions.setBiometryConnected("connected"));
              //   navigate("/", { replace: true });
              // }
            })
            .catch(() => {
              isAuth();
              navigate("/", { replace: true });
            });
        } else {
          isAuth();
          navigate("/", { replace: true });
        }
      } else {
        setErrorStatus(true);
        setPinCode(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCode]);

  useEffect(() => {
    return () => {
      dispatch(authActions.setIsInitialPin(true));
    };
  }, [dispatch]);

  useEffect(() => {
    if (isInitialPin) {
      //@ts-ignore
      ele?.current?.clear();
      setPinCode(initialValues);
    }
  }, [isInitialPin, initialValues]);

  return (
    <AuthContainer
      isLeft={false}
      title="Создайте короткий код для быстрого входа"
      {...(errorStatus && isMobile && { error: "Коды не совпадают" })}
      subtitle={
        isInitialPin
          ? "Придумайте 4-х значный код"
          : "Повторите 4-х значный код"
      }
    >
      {isMobile ? (
        <>
          <PinValue
            isLoading={isLoading}
            value={isInitialPin ? value1 : value2}
            isError={errorStatus}
          />
          <PinPad
            isLoading={isLoading}
            {...(!isPinComplete && {
              onChange: handleChange,
            })}
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <Loader size={68} />
          ) : (
            <>
              <PinInput
                length={4}
                initialValue=""
                secret
                //@ts-ignore
                ref={ele}
                secretDelay={100}
                onChange={(value, index) => {
                  setValue(value);
                  setErrorStatus(false);
                }}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{
                  borderColor: errorStatus ? "#F97066" : "#D0D5DD",
                  borderRadius: 12,
                }}
                inputFocusStyle={{
                  borderColor: errorStatus ? "#F97066" : "#D0D5DD",
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              {errorStatus && (
                <Typography variant="text_5" color="#E64545">
                  Коды не совпадают
                </Typography>
              )}
            </>
          )}

          <Button
            //@ts-ignore
            disabled={value.length !== 4}
            onClick={() => {
              if (isInitialPin) {
                dispatch(authActions.setIsInitialPin(false));
              }
              handleChangeWeb(value);
            }}
            variant="primary"
            size="lg"
            fullWidth
            sx={{ mt: 38, mb: 8 }}
          >
            Продолжить
          </Button>
        </>
      )}
    </AuthContainer>
  );
};
