import { useEffect, useRef, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { HistoryModal } from "./HistoryModal";
import { useDispatch, useSelector } from "react-redux";
import {
  HISTORY_TABS,
  appendExpenseList,
  appendHistoryList,
  appendIncomeList,
  getCategoryExpense,
  getCategoryIncome,
  getExpenseList,
  getHistoryList,
  getIncomeList,
  getListReport,
  setActiveTab,
} from "store/slices/historySlice";
import { SkeletonContainer } from "components";
import { InfiniteScroll } from "widgets/ChatWidget/components";
import {
  GetListRequestOperationFilterTypeEnum,
  StatementType,
  StatementView,
} from "api/account";
import { ReactComponent as IconIncoming } from "./assets/Incoming.svg";
import { ReactComponent as IconOutcoming } from "./assets/Outcoming.svg";
import { SearchFilters } from "./components";
import styled from "@emotion/styled/macro";
import theme from "theme";
import { formatNumber } from "utils/formatCurrency";
import { parseDate } from "utils";
import { useIsMobile } from "hooks/useIsMobile";
import { HistoryTabs } from "./components/HistoryTabs";
import { FinancialAnalysisWidget } from "./components/FinancialAnalysisWidget/FinancialAnalysisWidget";
import { FileType, downloadFile } from "utils/downloadFile";
import { EmptyScreenDefault } from "./components/EmptyScreenDefault/EmptyScreenDefault";
import { EmptyScreenCustom } from "./components/EmptyScreenCustom/EmptyScreenCustom";

export const InStatementTypes = [
  StatementType.CashIn,
  StatementType.OtherIn,
  StatementType.CashInExternal,
  StatementType.Salary,
  StatementType.TransferIn,
];

export type SearchFiltersValues = {
  search: string;
  fromAmount: number | null;
  toAmount: number | null;
  from: string;
  to: string;
  operationFilterType: GetListRequestOperationFilterTypeEnum | "";
  page: number;
  cardId: string;
  accId?: string;
  categoryIds?: Array<number>;
};

export const EmptyStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  h2 {
    font-size: 24px;
    line-height: 32px;
    color: rgba(57, 62, 70, 1);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px;
    margin-top: 20px;
  }
  h4 {
    color: rgba(57, 62, 70, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 0px;
    margin-top: 10px;
  }
`;

export const DateStyled = styled.div`
  background: ${theme.palette.gray.b50};
  width: calc(100% - 20px);
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray.b400};
`;

export const AmountStyled = styled.span<{ isIncoming: boolean }>`
  color: ${(props) =>
    props.isIncoming ? theme.palette.blue.b400 : theme.palette.gray.b800};
  margin-left: auto;
  white-space: nowrap;
  &:before {
    content: "${(props) => (props.isIncoming ? "+" : "")}";
  }
`;

const HistoryWrapper = styled.div<{
  isMobile: boolean;
  isEmptyScreen: boolean;
}>`
  position: relative;
  overflow-x: ${(props) => (props.isMobile ? "hidden" : "inherit")};
  overflow-y: scroll;
  height: ${(props) => (props.isEmptyScreen ? "calc(100svh - 250px)" : "100%")};
`;

const History = () => {
  const dispatch = useDispatch();
  const {
    history: {
      historyList,
      isLoading: isApiLoading,
      activeTab,
      expenseList,
      incomeList,
      isReportLoading,
      activeCategoryId,
      widgetData,
      showMobileFinancialAnalysisPage,
    },
    cards,
  } = useSelector((state: any) => state);

  const data = {
    [HISTORY_TABS.ALL_OPERATIONS]: historyList,
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  const [isSearchVisible, setSearchVisible] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });
  const theme = useTheme();
  const { isMobile } = useIsMobile();
  const lastBlockRef = useRef<HTMLDivElement>(null);
  const rootSentinelRef = useRef<HTMLDivElement>(null);

  const dateNow = new Date();
  const firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

  const initialPayload = {
    size: 10,
  };

  const initialState = {
    search: "",
    fromAmount: null,
    toAmount: null,
    from: firstDayMonth.toISOString(),
    to: new Date().toISOString(),
    operationFilterType: "" as GetListRequestOperationFilterTypeEnum | "",
    page: 0,
    cardId: "",
    acctId: cards.account.accountNumber,
    categoryIds: undefined,
  };

  const [searchFilters, setSearchFilters] =
    useState<SearchFiltersValues>(initialState);

  const payload = {
    ...initialPayload,
    ...searchFilters,
    ...{
      operationFilterType: searchFilters.operationFilterType || null,
    },
  };

  const fromTextFilter = parseDate(payload.from);
  const toTextFilter = parseDate(payload.to);
  const fromTextDefault = parseDate(firstDayMonth.toISOString());
  const toTextDefault = parseDate(dateNow.toISOString());

  const isDefaultFilters =
    !payload.search.length &&
    !payload.cardId.length &&
    !payload.fromAmount &&
    !payload.toAmount &&
    fromTextFilter === fromTextDefault &&
    toTextFilter === toTextDefault;

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isChanged, setChanged] = useState<boolean>(false);

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const closeModal = () =>
    setModalStatus(() => ({ historyId: "", isOpen: false }));

  const handleLoadMore = () => {
    const len = data[activeTab].reduce(
      (acc, it) => it.statements.length + acc,
      0
    );
    const isEndOfSlice = len % 10 === 0;
    const isSamePage = Math.floor(len / 10) === searchFilters.page;

    if (!isSamePage && isEndOfSlice) {
      setSearchFilters({
        ...searchFilters,
        page: Math.floor(len / 10),
      });
    }
  };

  const handleSearch = () => {
    const isValuesChanged =
      JSON.stringify({
        ...initialState,
        to: initialState.to.substring(0, 10),
      }) !==
      JSON.stringify({
        ...searchFilters,
        to: searchFilters.to.substring(0, 10),
      });
    setLoading(true);
    setSearchFilters({ ...searchFilters, page: 0 });
    setChanged(isValuesChanged);
  };

  const handleReset = (fullReset: boolean) => {
    setSearchFilters(
      fullReset ? initialState : { ...searchFilters, search: "", page: 0 }
    );
    if (activeTab === HISTORY_TABS.EXPENSES) {
      dispatch(
        // @ts-ignore
        getCategoryExpense({
          ...initialState,
          from: firstDayMonth.toISOString(),
          to: dateNow.toISOString(),
        })
      );
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      dispatch(
        // @ts-ignore
        getCategoryIncome({
          ...initialState,
          from: firstDayMonth.toISOString(),
          to: dateNow.toISOString(),
        })
      );
    }
    setLoading(true);
    setChanged(false);
  };

  const handleDownload = () => {
    if (isReportLoading) return;
    const operationViewType = {
      [HISTORY_TABS.ALL_OPERATIONS]: StatementView.All,
      [HISTORY_TABS.EXPENSES]: StatementView.Expense,
      [HISTORY_TABS.INCOMES]: StatementView.Income,
    };
    dispatch(
      // @ts-ignore
      getListReport({
        ...payload,
        operationViewType: operationViewType[activeTab],
      })
    )
      // @ts-ignore
      .unwrap()
      .then((res) => downloadFile(res, "report.xls", FileType.EXCEL));
  };

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters.search.length, searchFilters.operationFilterType]);

  useEffect(() => {
    if (isLoading) {
      if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
        // @ts-ignore
        dispatch(getHistoryList(payload));
      }
      if (activeTab === HISTORY_TABS.EXPENSES) {
        // @ts-ignore
        dispatch(getExpenseList(payload));
      }
      if (activeTab === HISTORY_TABS.INCOMES) {
        // @ts-ignore
        dispatch(getIncomeList(payload));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, searchFilters.search.length]);

  useEffect(() => {
    if (searchFilters.page > 0) {
      if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
        // @ts-ignore
        dispatch(appendHistoryList(payload));
      }
      if (activeTab === HISTORY_TABS.EXPENSES) {
        // @ts-ignore
        dispatch(appendExpenseList(payload));
      }
      if (activeTab === HISTORY_TABS.INCOMES) {
        // @ts-ignore
        dispatch(appendIncomeList(payload));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilters.page, activeTab]);

  useEffect(() => {
    if (!showMobileFinancialAnalysisPage) {
      if (!activeCategoryId && searchFilters.categoryIds) {
        setSearchFilters((prev) => ({
          ...prev,
          categoryIds: undefined,
          page: 0,
        }));
        if (activeTab === HISTORY_TABS.EXPENSES) {
          dispatch(
            // @ts-ignore
            getExpenseList({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
          dispatch(
            // @ts-ignore
            getCategoryExpense({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
        }
        if (activeTab === HISTORY_TABS.INCOMES) {
          dispatch(
            // @ts-ignore
            getIncomeList({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
          dispatch(
            // @ts-ignore
            getCategoryIncome({
              ...payload,
              categoryIds: undefined,
              page: 0,
            })
          );
        }
      }
      if (activeCategoryId && activeCategoryId !== searchFilters.categoryIds) {
        const activeCategory = widgetData.filter(
          (category) => category.id === activeCategoryId
        )[0].filterCategories;
        setSearchFilters((prev) => ({
          ...prev,
          categoryIds: [...activeCategory],
          page: 0,
        }));
        if (activeTab === HISTORY_TABS.EXPENSES) {
          dispatch(
            // @ts-ignore
            getExpenseList({
              ...payload,
              categoryIds: [...activeCategory],
              page: 0,
            })
          );
        }
        if (activeTab === HISTORY_TABS.INCOMES) {
          dispatch(
            // @ts-ignore
            getIncomeList({
              ...payload,
              categoryIds: [...activeCategory],
              page: 0,
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategoryId, showMobileFinancialAnalysisPage]);

  useEffect(() => {
    handleReset(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    if (!isApiLoading) setLoading(isApiLoading);
  }, [isApiLoading]);

  useEffect(() => {
    if (isMobile) dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    return () => {
      dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HistoryWrapper
      isMobile={isMobile}
      isEmptyScreen={!isLoading && isMobile && !data[activeTab].length}
    >
      {isMobile && <FinancialAnalysisWidget isPreview={true} />}
      {!isMobile && <HistoryTabs handleDownload={handleDownload} />}
      <>
        <SearchFilters
          filterValues={searchFilters}
          cards={[cards.mainCard, ...cards.anotherCards]}
          onFilterChange={setSearchFilters}
          onSearch={handleSearch}
          onReset={handleReset}
          isMobile={isMobile}
          isChanged={isChanged}
          isLoading={isApiLoading}
          isSearchVisible={isSearchVisible}
          setSearchVisible={setSearchVisible}
        />
        {!isMobile && activeTab !== HISTORY_TABS.ALL_OPERATIONS && (
          <FinancialAnalysisWidget isPreview={false} />
        )}
        <SkeletonContainer height="570px" isLoading={isApiLoading} width="100%">
          {!isLoading &&
            (data[activeTab].length ? (
              <InfiniteScroll
                lastBlockRef={lastBlockRef}
                rootSentinelRef={rootSentinelRef}
                isLoading={false}
                onLoadMore={handleLoadMore}
                reverse
                withScrollTrack
                cssVieportHeight={
                  isMobile
                    ? `calc(100svh - ${isSearchVisible ? "565" : "510"}px)`
                    : "500px"
                }
              >
                {/*@ts-ignore*/}
                {data[activeTab].map((item) => {
                  if (!item.statements.length) return null;
                  return (
                    <Box key={item.date}>
                      <DateStyled>{parseDate(item.date || "")}</DateStyled>
                      <Box display="flex" flexDirection="column" gap="16px">
                        {/*@ts-ignore*/}
                        {item.statements.map((el) => (
                          <Box
                            key={el.statementId}
                            p="12px 19px 12px 16px"
                            display="flex"
                            gap="20px"
                            onClick={() => openModal(el.statementId || "")}
                            sx={{
                              "&:hover": {
                                cursor: "pointer",
                              },
                            }}
                          >
                            {InStatementTypes.includes(el.type) ? (
                              <IconIncoming />
                            ) : (
                              <IconOutcoming />
                            )}
                            <Box display="flex" flexDirection="column">
                              <Typography
                                variant="text_5"
                                color={theme.primary.main.gray["800"]}
                                fontWeight="500"
                              >
                                {el.acquirer}
                              </Typography>
                              <Typography
                                variant="text_3"
                                color={theme.primary.main.gray["300"]}
                              >
                                {el.acquirerGroup}
                              </Typography>
                            </Box>
                            <AmountStyled isIncoming={el.amount > 0}>
                              {formatNumber(
                                el.amount,
                                true,
                                el.currency ? el.currency.sign : ""
                              )}
                            </AmountStyled>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  );
                })}
              </InfiniteScroll>
            ) : isDefaultFilters ? (
              <EmptyScreenDefault />
            ) : (
              <EmptyScreenCustom />
            ))}
          {modalStatus.isOpen && (
            <HistoryModal
              isOpen={modalStatus.isOpen}
              onClose={closeModal}
              historyId={modalStatus.historyId}
            />
          )}
        </SkeletonContainer>
      </>
    </HistoryWrapper>
  );
};

export default History;
