import { Box, Typography } from "@mui/material";
import { Button } from "components";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { loginPattern } from "constants/regexp";
import { updateLogin } from "store/slices/profile";
import { NotificationResult } from "molecules/NotificationResult";
import { IconSuccess } from "atoms/IconSuccess";
import { profileSelector } from "store";
import { useIsMobile } from "hooks/useIsMobile";
import { LoginInput } from "molecules/LoginInput/LoginInput";
import { useNavigate } from "react-router-dom";
import { useResetStore } from "hooks/useResetStore";
import { authActions } from "store/slices/auth";

type LoginModalProps = {
  login?: string;
  onClose: () => void;
};

export const LoginModal = ({ login, onClose }: LoginModalProps) => {
  const dispatch = useDispatch();
  const { error, isLoading, isLoginUpdated } = useSelector(profileSelector);
  const { isMobile, size } = useIsMobile();
  const navigate = useNavigate();
  const resetStore = useResetStore();

  const {
    control,
    watch,
    formState: { errors },
  } = useForm<{ newLogin: string }>({
    mode: "onChange",
    defaultValues: {
      newLogin: "",
    },
  });
  const newLogin = watch("newLogin");

  const disabled =
    !!errors.newLogin || newLogin.length === 0 || newLogin.length > 16;

  const handleClick = () => {
    dispatch(updateLogin(newLogin));
  };

  const getTitle = useCallback(() => {
    switch (size) {
      case "xl":
        return `Придумайте логин, состоящий из 6-16\nсимволов латинского алфавита, цифр\nи/или специальных символов`;
      case "sm":
        return `Придумайте логин,\nсостоящий из 6-16 символов\nлатинского алфавита, цифр\nи/или специальных символов`;
      case "xs":
        return `Придумайте логин,\nсостоящий из 6-16 символов\nлатинского алфавита, цифр\nи/или специальных символов`;
      default:
        return `Придумайте логин, состоящий из 6-16 символов латинского алфавита, цифр и/или специальных символов`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickSuccess = () => {
    dispatch(authActions.setLogout());
    const deviceIdItem = localStorage.getItem("deviceId");
    const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
    localStorage.clear();
    if (deviceIdItem !== null) localStorage.setItem("deviceId", deviceIdItem);
    if (PWAdeviceIdItem !== null)
      localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
    sessionStorage.clear();
    navigate("/sign-in", { replace: true });
    resetStore();
    onClose();
  };

  if (isLoginUpdated) {
    return (
      <Box
        pt={{ xs: 46, md: 32 }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <NotificationResult
          title="Ваш логин был успешно изменён"
          subtitle={"Пройдите авторизацию\nс новым логином"}
          icon={<IconSuccess />}
          bottomSpace={0}
          buttons={[
            {
              name: "Перейти к авторизации",
              variant: "primary",
              size: "lg",
              onClick: clickSuccess,
            },
          ]}
          titleStyle={{
            whiteSpace: !isMobile ? "nowrap" : "",
            fontWeight: 500,
            lineHeight: "32px",
            textAlign: "center",
            color: "#393E46",
          }}
          subtitleStyle={{
            fontWeight: 400,
            lineHeight: "30px",
            textAlign: "center",
            fontSize: "20px",
            color: "#393E46",
          }}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" px={{ xs: 20, xl: 32 }}>
      <Typography variant="text_8" fontWeight={500} color="#393E46">
        Изменить логин
      </Typography>
      <Typography
        variant="text_5"
        fontWeight={400}
        color="#393E46"
        display="flex"
        whiteSpace="pre-wrap"
        mt={{ xs: 28, xl: 16 }}
      >
        {getTitle()}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={{ xs: 20, xl: 24 }}
        width="100%"
        mt={{ xs: 20, xl: 24 }}
      >
        <Box>
          <LoginInput
            value={login}
            name="newLogin"
            label="Текущий логин"
            placeholder="Введите логин"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
            disabled={true}
          />
        </Box>
        <Box>
          <Controller
            control={control}
            name="newLogin"
            rules={{
              required: true,
              pattern: {
                value: loginPattern,
                message: "Логин не соответствует требованиям",
              },
              validate: (newLogin) => {
                if (newLogin === login) {
                  return "Логины совпадают'";
                }
              },
            }}
            render={({ field }) => (
              <LoginInput
                {...field}
                value={field.value.trim()}
                name="newLogin"
                label="Новый логин"
                placeholder="Введите логин"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.value.trim());
                }}
                // @ts-ignore
                error={error}
                noMessage={true}
              />
            )}
          />
        </Box>
        <Box
          width="100%"
          mt={{ xs: "52px", lg: "40px" }}
          display="flex"
          flexDirection="column"
          rowGap={20}
        >
          <Button
            isLoading={isLoading}
            disabled={disabled}
            size="lg"
            variant="primary"
            onClick={handleClick}
          >
            {isMobile ? "Применить" : "Изменить логин"}
          </Button>
          <Button size="lg" variant="secondary" onClick={onClose}>
            Отмена
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
