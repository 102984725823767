import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, SvgIcon } from "@mui/material";
import { Button } from "components";
import { AuthContainer } from "containers";
import theme from "theme";
import { LoginInput } from "molecules/LoginInput/LoginInput";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "store";
import { loginPasswordPattern } from "constants/regexp";
import { authActions, login } from "../../store/slices/auth";
import BlockedScreen from "./BlockedScreen";
import BlockedScreenPinChange from "./BlockedScreenPinChange";
import Checkbox from "@mui/material/Checkbox";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import { showErrorMessage } from "../../store/slices/alerts";
import { useIsPWA } from "hooks/useIsPWA";
import { deleteFCMToken } from "../../firebase";
export const Authorization = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPWA = useIsPWA();
  const [values, setValues] = useState({
    login: "",
    password: "",
  });

  const { isLoading, error, elseDevice, isAccountBlocked, isLoginBlocked } =
    useSelector(authSelector);
  const handleClickToMain = (e) => {
    e.preventDefault();
    if (localStorage.getItem("isPushOn")) {
      deleteFCMToken();
      localStorage.removeItem("isPushOn");
    }

    dispatch(
      login({
        login: values.login,
        password: values.password,
        isPWA,
      })
    )
      .unwrap()
      .then(() => {
        if (elseDevice) {
          localStorage.setItem("elseLogin", values.login);
        } else {
          localStorage.setItem("tmpLogin", values.login);
        }
        navigate("/confirm", { replace: true });
      })
      .catch((err) => {
        dispatch(
          showErrorMessage({
            errorTitle: err.response.data.title,
            errorMessage: err.response.data.subtitle,
          })
        );
      });
  };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value.replace(loginPasswordPattern, ""),
    });
  };

  const canGoNext = () => {
    const loginLength = values.login.length > 2;
    const passwordLength =
      values.password.length >= 1 && values.password.length <= 20;
    if (loginLength && passwordLength) {
      return true;
    }
  };

  if (isAccountBlocked) {
    return <BlockedScreen />;
  }
  if (isLoginBlocked) {
    return <BlockedScreenPinChange />;
  }

  const elseDeviceToggle = (value) => {
    dispatch(authActions.setElseDevice(value));
  };

  return (
    <AuthContainer
      title="Добро пожаловать!"
      subtitle="Для авторизации введите ваши данные"
    >
      <Box
        p={{ md: "32px 30.5px" }}
        mb={26}
        backgroundColor={theme.palette.gray.b50}
        border="1px solid #F2F4F7"
        borderRadius={24}
      >
        <Box padding={{ xs: "32px 16px", md: 0 }} as="form" autoComplete="off">
          <LoginInput
            name="login"
            label="Логин"
            value={values.login}
            placeholder="Введите логин"
            onChange={handleChange}
            error={error ? "" : null}
            sx={{ mb: 20 }}
          />
          <PasswordInput
            value={values.password}
            placeholder="Введите пароль"
            onChange={handleChange}
            name="password"
            autoComplete="off"
            label={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={8}
              >
                Пароль
                <Button
                  variant="text"
                  size="lg"
                  component={Link}
                  to="/recovery"
                  style={{ color: "#0082BA", fontWeight: "600" }}
                >
                  Забыли пароль?
                </Button>
              </Box>
            }
            error={error ? "" : null}
          />
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            mt={8}
            ml={-8}
          >
            <Checkbox
              icon={
                <SvgIcon
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="12" fill="#EBEDF3" />
                </SvgIcon>
              }
              value={elseDevice}
              checkedIcon={<CircleCheckedFilled htmlColor="#0082BA" />}
              title="Чужое устройство"
              onChange={() => elseDeviceToggle(!elseDevice)}
            />
            Чужое устройство
          </Box>
          <Box display="flex" flexDirection="column" mt={70} rowGap={12}>
            <Button
              type="submit"
              disabled={!canGoNext()}
              variant="primary"
              size="lg"
              fullWidth
              onClick={(e) => handleClickToMain(e)}
              isLoading={isLoading}
            >
              Продолжить
            </Button>
            <Link to="/sign-up">
              <Button variant="secondary" size="lg" fullWidth>
                Зарегистрироваться
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </AuthContainer>
  );
};
