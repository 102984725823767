import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { cardLimitsApi } from "../../api";
import {
  CardLimitConfirmResponse,
  CardLimitRequest,
  CardLimitUpdateConfirmRequest,
  CardLimitUpdateRequest,
} from "../../api/account";

export type LimitState = {
  isLoading: boolean;
  limits: Array<CardLimitConfirmResponse>;
  limitToken: string | undefined;
};

const initialState: LimitState = {
  isLoading: false,
  limits: [],
  limitToken: "",
};

export const getLimits = createAsyncThunk(
  "getLimits",
  async (payload: CardLimitRequest, { rejectWithValue }) => {
    try {
      const response = await cardLimitsApi.getLimitsInformation(
        "string",
        payload
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateLimits = createAsyncThunk(
  "updateLimits",
  async (payload: CardLimitUpdateRequest, { rejectWithValue }) => {
    try {
      const response = await cardLimitsApi.updateCardLimit("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCardLimitConfirm = createAsyncThunk(
  "updateCardLimitConfirm",
  async (payload: CardLimitUpdateConfirmRequest, { rejectWithValue }) => {
    try {
      const response = await cardLimitsApi.updateCardLimitConfirm(
        "string",
        payload
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const limitsSlice = createSlice({
  name: "limits",
  initialState,
  reducers: {
    setNewLimit: (state, { payload }) => {
      let limit = state.limits.filter((l) => l.limitId === payload.limitId);
      limit[0].limitSumAmount = payload.limitSumAmount;
    },
    resetLimitsStore: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLimits.fulfilled, (state, action) => {
      state.limits = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getLimits.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(updateLimits.fulfilled, (state, action) => {
      state.limitToken = action?.payload?.updateLimitToken;
    });
  },
});

export const { setNewLimit, resetLimitsStore } = limitsSlice.actions;
export default limitsSlice.reducer;
