import styled from "@emotion/styled/macro";
import IconCard from "./assets/IconCard.png";
import { TabVariants, systemActions } from "store/slices/system";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  HISTORY_TABS,
  getHistoryList,
  setActiveTab,
} from "store/slices/historySlice";
import { SkeletonContainer } from "components";
import { useIsMobile } from "hooks/useIsMobile";
import { ReactComponent as IconIncoming } from "../History/assets/Incoming.svg";
import { ReactComponent as IconOutcoming } from "../History/assets/Outcoming.svg";
import { ReactComponent as IconEmpty } from "../History/assets/Empty.svg";
import { EmptyStyled, InStatementTypes } from "../History/History";

const Container = styled.div`
  margin-top: 40px;

  @media (max-width: 550px) {
    margin-top: 20px;
  }
`;

const Title = styled.p`
  margin: 0px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: rgba(57, 62, 70, 1);

  @media (max-width: 550px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const TransferBtn = styled.div`
  width: 376px;
  padding: 24px 0px 24px 0px;
  border-radius: 20px;
  background: rgba(247, 248, 250, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 40px;
  flex-direction: column;

  @media (max-width: 550px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const TransferBtnText = styled.p`
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  color: rgba(71, 84, 103, 1);
`;

const IconCardBtn = styled.img`
  width: 56px;
  height: 56px;
  margin-bottom: 20px;
`;

const TransferHistoryBlock = styled.div`
  width: 685px;
  padding: 9px 24px 24px 24px;
  border-radius: 24px;
  background: rgba(247, 248, 250, 1);

  @media (max-width: 550px) {
    width: inherit;
  }
`;

const TransferHistoryBtn = styled.div`
  border-radius: 10px;
  background: rgba(247, 248, 250, 1);
  margin-top: 20px;
  padding: 16px;
  border: 1px solid rgba(208, 213, 221, 1);
  color: rgba(52, 64, 84, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #0082ba;
    border: 1px solid #0082ba;
  }
`;

const HistoryItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const HistoryItemLeftPart = styled.div`
  display: flex;
  align-items: center;
`;

const HistoryItemIcon = styled.div`
  margin-right: 15px;
  margin-top: 5px;
`;

const HistoryItemTitle = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(29, 41, 57, 1);
`;

const HistoryItemDescription = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: rgba(152, 162, 179, 1);
`;

const HistoryItemSum = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  color: rgba(29, 41, 57, 1);
  white-space: nowrap;
`;

const Main = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const {
    history: { historyList, isLoading },
    cards,
  } = useSelector((state: any) => state);
  const notFoundOperations = !historyList.length && !isLoading;

  const goToHandler = (tab: TabVariants) => {
    if (tab === TabVariants.history) {
      dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    }
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  useEffect(() => {
    if (cards.account.accountNumber) {
      dispatch(
        getHistoryList({
          size: 4,
          to: new Date().toISOString(),
          page: 0,
          acctId: cards.account.accountNumber,
        })
      );
    }
  }, [cards.account.accountNumber, dispatch]);

  if (isMobile) return null;

  return (
    <Container>
      <Title>Переводы</Title>
      <TransferBtn onClick={() => goToHandler(TabVariants.transfers)}>
        <IconCardBtn src={IconCard} />
        <TransferBtnText>По номеру карты</TransferBtnText>
      </TransferBtn>
      <Title>История операций</Title>
      <SkeletonContainer isLoading={isLoading} height={345} width={685}>
        <TransferHistoryBlock>
          {historyList.map((item) => {
            return item.statements.map((el) => {
              const isTransferIn = InStatementTypes.includes(el.type);
              return (
                <HistoryItem key={el.statementId}>
                  <HistoryItemLeftPart>
                    <HistoryItemIcon>
                      {isTransferIn ? <IconIncoming /> : <IconOutcoming />}
                    </HistoryItemIcon>
                    <div>
                      <HistoryItemTitle>{el.acquirer}</HistoryItemTitle>
                      <HistoryItemDescription>
                        {el.acquirerGroup}
                      </HistoryItemDescription>
                    </div>
                  </HistoryItemLeftPart>
                  <HistoryItemSum
                    style={{
                      color: el.amount > 0 ? "rgba(0, 130, 186, 1)" : "inherit",
                    }}
                  >
                    {el.amount > 0 && "+"}
                    {el.amount} ₽
                  </HistoryItemSum>
                </HistoryItem>
              );
            });
          })}
          {notFoundOperations && (
            <EmptyStyled>
              <IconEmpty />
              <h2>История операций пуста</h2>
            </EmptyStyled>
          )}
          {!notFoundOperations && (
            <TransferHistoryBtn
              onClick={() => goToHandler(TabVariants.history)}
            >
              Все операции
            </TransferHistoryBtn>
          )}
        </TransferHistoryBlock>
      </SkeletonContainer>
    </Container>
  );
};

export default Main;
