// @ts-ignore
import { Typography, BoxTypeMap, Box, SxProps, Theme } from "@mui/material";
import { Button, CustomButtonProps } from "components";

type NotificationResultProps = {
  pageTitle?: string;
  title: string;
  titleSize?: any;
  subtitle?: string | React.ReactNode;
  subtitleSize?: any;
  icon?: React.ReactNode;
  height?: BoxTypeMap["props"]["height"];
  buttons?: (Pick<CustomButtonProps, "variant" | "size"> & {
    onClick: () => void;
    name: string;
  })[];
  type?: "success" | "error";
  bottomSpace?: number;
  buttonStyle?: SxProps<Theme>;
  titleStyle?: object;
  subtitleStyle?: object;
};

const NotificationResult = (props: NotificationResultProps) => {
  const {
    pageTitle,
    title,
    subtitle,
    icon,
    buttons,
    height,
    titleSize = "text_8",
    subtitleSize = "text_4",
    bottomSpace = 0,
    buttonStyle,
    titleStyle,
    subtitleStyle,
    type = "success",
  } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={height}
      minHeight={height}
      width={{ xl: 394 }}
      textAlign="center"
      padding={{ sm: "0px 16px", lg: "0px 48px" }}
      marginBottom={bottomSpace}
    >
      {pageTitle && (
        <Typography
          sx={{ fontWeight: 600 }}
          mt={{ xs: 32, xl: 60 }}
          mb={{ xs: 24, xl: 48 }}
          variant="text_9"
        >
          {pageTitle}
        </Typography>
      )}
      <Box display="flex" flexDirection="column">
        {icon && (
          <Box display="flex" justifyContent="center">
            {icon}
          </Box>
        )}

        <Box mt={24}>
          <Typography
            variant={titleSize}
            color="gray.b900"
            fontWeight={600}
            whiteSpace="pre-wrap"
            style={{ ...titleStyle }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          margin="10 auto 0"
          style={{ whiteSpace: "pre-wrap" }}
          mt={10}
          maxWidth={375}
          justifyContent="center"
        >
          {typeof subtitle === "string" ? (
            <Typography
              variant={subtitleSize}
              color="gray.b500"
              fontWeight={500}
              style={{ ...subtitleStyle }}
            >
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </Box>
      </Box>
      {buttons && (
        <Box
          width="100%"
          margin={{ xs: "100px auto 0", xl: "100px auto 0" }}
          sx={buttonStyle}
        >
          {buttons?.map(({ name, ...rest }) => (
            <Button key={name} fullWidth {...rest}>
              {name}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default NotificationResult;
