import { Box, Hidden } from "@mui/material";
import { AvatarAndName } from "molecules/AvatarAndName/AvatarAndName";
import { Exit } from "molecules/Exit/Exit";
import Navigation from "../Navigation/Navigation";
import { useDispatch } from "react-redux";
import { MobileTabVariants, systemActions } from "store/slices/system";
import { useIsMobile } from "hooks/useIsMobile";
import Logo from "./finative-logo.png";

export const Header = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const logoHandler = () =>
    dispatch(
      systemActions.setActiveTab({
        tab: MobileTabVariants.main,
      })
    );

  const shadows = isMobile
    ? "0px 2px 8px rgba(235, 237, 243, 0.54)"
    : "0 4px 12px rgba(235, 237, 243, 0.62)";

  return (
    <>
      <Box
        display="flex"
        py={{ sm: 16, md: 16, lg: 0 }}
        px={{ sm: 16, md: 16, lg: 0 }}
        justifyContent="center"
        boxShadow={shadows}
      >
        <Box
          maxWidth={1200}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <Box sx={{ cursor: "pointer" }} onClick={logoHandler}>
            <img src={Logo} width={158} height={28} />
          </Box>
          <Hidden lgDown>
            <Navigation />
          </Hidden>
          <Hidden lgDown>
            <Box display="flex" alignItems="center">
              <AvatarAndName />
              <Exit />
            </Box>
          </Hidden>
        </Box>
      </Box>
    </>
  );
};
