import styled from "@emotion/styled/macro";
import { Box } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { Button } from "components";
import { SmallBackButton } from "components/SmallBackButton";
import { ReactComponent as ErrorIcon } from "./assets/ErrorImage.svg";
import { ReactComponent as ErrorIconMobile } from "./assets/ErrorImageMobile.svg";
import { TabVariants, systemActions } from "store/slices/system";
import {
  TRANSFER_ERROR_CODE,
  resetTransferErrorMessage,
  setIsOpenModal,
} from "store/slices/transferSlice";

const Title = styled.p`
  margin: 0px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: #393e46;
  margin-top: 40px;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

const Subtitle = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #393e46;
  margin-top: 20px;
  @media (max-width: 500px) {
    text-align: center;
    margin-top: 10px;
  }
`;

export const TransferError = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const {
    transfer: {
      errorMessage: { title, subtitle, code },
      isOpenModal,
    },
  } = useSelector((state: RootState) => state);

  const isPaymentError = code === TRANSFER_ERROR_CODE.PAYMENT_ERROR;

  const clearError = () => {
    if (isOpenModal) {
      setTimeout(() => {
        dispatch(resetTransferErrorMessage());
      }, 200);
    }
  };

  const submitButtonHandler = () => {
    clearError();
    dispatch(setIsOpenModal(!isOpenModal));
    if (!isPaymentError) {
      dispatch(systemActions.setActiveTab({ tab: TabVariants.history }));
    }
  };

  const backButtonHandler = () => {
    clearError();
    dispatch(setIsOpenModal(!isOpenModal));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box
        mt={isMobile ? "0" : "24px"}
        mb="4px"
        height="100%"
        position="relative"
      >
        <Box>
          {!isMobile && (
            <Box mt={20}>
              <SmallBackButton clickHandler={backButtonHandler} />
            </Box>
          )}
          {isMobile && (
            <ErrorIconMobile
              style={{
                display: "block",
                margin: "auto",
                marginTop: "80px",
              }}
            />
          )}
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
          {!isMobile && (
            <ErrorIcon
              style={{
                marginTop: "20px",
                marginBottom: "30px",
                width: "100%",
              }}
            />
          )}
        </Box>
        <Button
          type="submit"
          variant="primary"
          size="lg"
          fullWidth
          onClick={submitButtonHandler}
          style={{
            position: isMobile ? "absolute" : "static",
            bottom: isMobile ? "30px" : "auto",
          }}
        >
          {isPaymentError ? "Понятно" : "Перейти к истории"}
        </Button>
      </Box>
    </Box>
  );
};
