import { Typography, useTheme, Box } from "@mui/material";
import { Divider } from "atoms/Divider/Divider";
import { Button, SkeletonContainer } from "components";
import { useDispatch, useSelector } from "react-redux";
import { transferCheck } from "store/slices/transferSlice";
import IconForward from "../../../../components/Icons/IconForward";
import Card from "../../../../components/public/images/bankCardsToTransfer.jpg";
import { useState, useEffect } from "react";
import ChosenCard from "./ChosenCard";
import IconInfo from "../../../../atoms/IconInfo/IconInfo";
import { formatCurrency } from "../../../../utils/formatCurrency";

export const TransferCheck = ({
  payloadModal,
  onClose,
  chooseCard,
  setChooseCard,
}: any) => {
  const [isAlert, setIsAlert] = useState(false);
  const theme = useTheme();
  const {
    transfer: { createdTransfer, isLoading },
  } = useSelector((state: any) => state);
  const {
    cards: { mainCard, fromTransferCard },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (fromTransferCard?.amount) {
      fromTransferCard?.amount < createdTransfer.amount
        ? setIsAlert(true)
        : setIsAlert(false);
    }
  }, [fromTransferCard?.amount, mainCard, createdTransfer.amount]);

  const transferHandler = () => {
    dispatch(transferCheck({ paymentToken: createdTransfer?.paymentToken }));
  };

  return (
    <>
      <SkeletonContainer width="400px" height="500px" isLoading={isLoading}>
        {chooseCard && (
          <ChosenCard
            setChooseCard={setChooseCard}
            payloadModal={payloadModal}
            onClose={onClose}
          />
        )}
        {!chooseCard && (
          <>
            <Box flexGrow="1">
              {isAlert && (
                <Box
                  display="flex"
                  flexDirection="column"
                  borderRadius="16px"
                  padding="18px"
                  gap={8}
                  bgcolor={theme.palette.orange.b50}
                  mb={32}
                >
                  <Box display="flex" gap={6}>
                    <IconInfo fill={theme.palette.orange.b500} />
                    <Typography
                      variant="text_5"
                      fontWeight={500}
                      color="orange.b500"
                    >
                      Недостаточно средств на карте
                    </Typography>
                  </Box>
                  <Typography
                    variant="text_4"
                    fontWeight={400}
                    color="gray.b500"
                  >
                    Пожалуйста, пополните счет или выберите другую карту{" "}
                  </Typography>
                </Box>
              )}

              <Box
                display="flex"
                flexDirection="column"
                gap={9}
                py={4}
                mt={32}
                onClick={() => setChooseCard(!chooseCard)}
              >
                <Typography variant="text_5" color="gray.b400" fontWeight={400}>
                  Карта списания
                </Typography>

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  mb={18}
                >
                  <Box display="flex" flexDirection="row" gap={16}>
                    <img
                      src={Card}
                      style={{
                        width: "64px",
                        height: "42px",
                        alignSelf: "center",
                      }}
                      alt="картинка"
                    />
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="text_7"
                        color="gray.b900"
                        fontWeight={700}
                      >
                        {fromTransferCard?.amount
                          ? formatCurrency(fromTransferCard?.amount, true)
                          : null}
                      </Typography>
                      <Typography
                        variant="text_3"
                        color="gray.b400"
                        fontWeight={400}
                        style={{ wordBreak: "break-word" }}
                      >
                        {fromTransferCard?.description}
                      </Typography>
                    </Box>
                  </Box>

                  <Box alignSelf="center">
                    <IconForward />
                  </Box>
                </Box>
              </Box>
              <Box boxSizing="border-box">
                <Divider />
              </Box>

              <Box display="flex" flexDirection="column" mt={12}>
                <Box display="flex" flexDirection="column" gap="4px" py={16}>
                  <Typography
                    variant="text_3"
                    color={theme.primary.main.gray["300"]}
                  >
                    Получатель
                  </Typography>
                  <Typography
                    variant="text_5"
                    fontWeight="500"
                    color={theme.primary.main.gray["800"]}
                  >
                    {"************" +
                      createdTransfer?.toCardPan.substring(
                        createdTransfer?.toCardPan?.length - 4
                      )}
                  </Typography>
                </Box>
                <Box
                  boxSizing="border-box"
                  paddingRight={{ sm: 0, md: "24px" }}
                >
                  <Divider />
                </Box>
                <Box display="flex" flexDirection="column" gap="4px" py={16}>
                  <Typography
                    variant="text_3"
                    color={theme.primary.main.gray["300"]}
                  >
                    Банк получателя
                  </Typography>
                  <Typography
                    variant="text_5"
                    fontWeight="500"
                    color={theme.primary.main.gray["800"]}
                  >
                    {createdTransfer.toClientBank}
                  </Typography>
                </Box>
                <Box
                  boxSizing="border-box"
                  paddingRight={{ sm: 0, md: "24px" }}
                >
                  <Divider />
                </Box>
                <Box display="flex" flexDirection="column" gap="4px" py={16}>
                  <Typography
                    variant="text_3"
                    color={theme.primary.main.gray["300"]}
                  >
                    Сумма перевода
                  </Typography>
                  <Typography
                    variant="text_5"
                    fontWeight="500"
                    color={theme.primary.main.gray["800"]}
                  >
                    {formatCurrency(createdTransfer.amount, true)}
                  </Typography>
                </Box>
                <Box
                  boxSizing="border-box"
                  paddingRight={{ sm: 0, md: "24px" }}
                >
                  <Divider />
                </Box>
                <Box display="flex" flexDirection="column" gap="4px" py={16}>
                  <Typography
                    variant="text_3"
                    color={theme.primary.main.gray["300"]}
                  >
                    Комиссия
                  </Typography>
                  <Typography
                    variant="text_5"
                    fontWeight="500"
                    color={theme.primary.main.gray["800"]}
                  >
                    {formatCurrency(createdTransfer.fee, true)}
                  </Typography>
                </Box>
                <Box
                  boxSizing="border-box"
                  paddingRight={{ sm: 0, md: "24px" }}
                >
                  <Divider />
                </Box>
              </Box>
              {createdTransfer.reason && (
                <Box
                  style={{ wordBreak: "break-word" }}
                  display="flex"
                  flexDirection="column"
                  gap="4px"
                  py={16}
                >
                  <Typography
                    variant="text_3"
                    color={theme.primary.main.gray["300"]}
                  >
                    Сообщение получателю
                  </Typography>
                  <Typography
                    variant="text_5"
                    fontWeight="500"
                    color={theme.primary.main.gray["800"]}
                  >
                    {createdTransfer.reason}
                  </Typography>
                </Box>
              )}
            </Box>
            <Button
              variant="primary"
              size="lg"
              onClick={transferHandler}
              isLoading={isLoading}
              sx={{ width: "100%", marginTop: "48px" }}
            >
              Перевести
            </Button>
          </>
        )}
      </SkeletonContainer>
    </>
  );
};
