import React from "react";
import { useTheme } from "@mui/material";

const ArrowLeft = () => {
  const theme = useTheme();
  const { gray } = theme.palette;

  return (
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.1665 1.5L0.666504 6L5.1665 10.5"
        stroke={gray.b700}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
