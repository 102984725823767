import { Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { IconAccountRubHuge } from "atoms/IconAccountRubHuge";
import { IconAccountRubMiddle } from "atoms/IconAccountRubMiddle";
import { useIsMobile } from "hooks/useIsMobile";
import { formatAmount } from "utils/formatCurrency";

const AccountsContainer = styled(Box)({
  display: "flex",
  columnGap: "12px",
  cursor: "pointer",
  padding: "20px",
  backgroundColor: " #F3F5F8",
  borderRadius: "20px",
});

export const AccountCard = ({
  availableBalance,
  currency,
}: {
  availableBalance: any;
  currency: any;
}) => {
  const { isMobile } = useIsMobile();
  const accountAmount = formatAmount(availableBalance, currency?.sign);
  return (
    <AccountsContainer p={{ sm: 12, md: 20 }}>
      <Box sx={{ padding: "4px 0" }}>
        {isMobile ? <IconAccountRubMiddle /> : <IconAccountRubHuge />}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Typography variant="text_2" noWrap sx={{ color: "#8993A4" }}>
          Баланс счёта
        </Typography>
        <Typography
          variant="text_16"
          noWrap
          sx={{ color: "#2E3238", fontWeight: 500 }}
        >
          {accountAmount}
        </Typography>
      </Box>
    </AccountsContainer>
  );
};
