import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { Limits } from "./Limits";
import { Transfers } from "./Transfers";
import { History } from "./History";
import { Promotion } from "./Promotion";
import { Main } from "./Main";
import { systemActions } from "store/slices/system";
import { systemSelector, cardsSelector, chatSelector } from "store";
import IconChat from "components/Icons/IconChat";
import { useIsMobile } from "hooks/useIsMobile";
import { MobileTabVariants, TabVariants } from "store/slices/system/slice";
import { SkeletonContainer } from "components";
import { EventSourcePolyfill } from "event-source-polyfill";
import { chatActions } from "../../../store/slices/chat";
import env from "../../../env";

const content: Record<string, React.ReactNode> = {
  transfers: <Transfers />,
  limits: <Limits />,
  history: <History />,
  promotion: <Promotion />,
  main: <Main />,
};

const Cards = () => {
  const dispatch = useDispatch();
  const tabsRef = useRef<null | HTMLElement>(null);
  const { activeTab, needToScrollToTabs } = useSelector(systemSelector);
  const { isLoading } = useSelector(cardsSelector);
  const accessToken = sessionStorage.getItem("accessToken");
  const { newMessageCounter } = useSelector(chatSelector);
  const [chatListening, setChatListening] = useState(false);

  const { isMobile } = useIsMobile();
  const handleChatClick = () => {
    if (isMobile) {
      dispatch(systemActions.setActiveTab({ tab: MobileTabVariants.chat }));
    } else {
      dispatch(systemActions.setChatOpen(true));
    }
  };

  useEffect(() => {
    if (needToScrollToTabs && activeTab !== "notifications") {
      const tabComponent = tabsRef;
      tabComponent?.current?.scrollIntoView({
        block: "start",
        inline: "end",
        behavior: "smooth",
      });
      dispatch(systemActions.setNeedScrollState(false));
    }
  }, [dispatch, needToScrollToTabs, activeTab]);

  useEffect(() => {
    if (!chatListening && accessToken) {
      const url = `${env.REACT_APP_BASE_PATH}notification/v1/chat/subscription/new-messages`;
      const options = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        heartbeatTimeout: 120000,
      };
      const es = new EventSourcePolyfill(url, options);
      es.addEventListener("open", () => {
        setChatListening(true);
      });

      es.addEventListener("message", (event) => {
        localStorage.setItem("lastKnownNewMessageCount", event.data);
        dispatch(chatActions.setNewMessageCounter(event.data));
      });
      es.addEventListener("error", () => {
        es.close();
        setChatListening(false);
      });
    }
  }, [dispatch, chatListening, accessToken]);

  return (
    <Grid display="flex" flexDirection="column" item sm={12} md={12} lg={12}>
      <Grid
        container
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Grid xs={12} lg={12} item>
          {isLoading
            ? activeTab !== TabVariants.main && (
                <SkeletonContainer
                  height="272px"
                  isLoading={isLoading}
                  width="100%"
                />
              )
            : content[activeTab]}
        </Grid>
      </Grid>
      <Box
        sx={
          !isMobile
            ? {
                width: "fit-content",
                position: "fixed",
                bottom: "140px",
                right: "120px",
                cursor: "pointer",
              }
            : { display: "none" }
        }
        onClick={handleChatClick}
      >
        <IconChat count={newMessageCounter} />
      </Box>
    </Grid>
  );
};

export default Cards;
