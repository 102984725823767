import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import CardInfoItem from "./CardInfoItem";

const CardsListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
  padding: "0 20px 32px",
});

const CardsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  rowGap: "10px",
});

const CardsList = () => {
  const {
    cards: { allCards },
  } = useSelector((state: any) => state);

  return (
    <CardsListContainer>
      <Typography variant="text_10" sx={{ color: "#475467", fontWeight: 500 }}>
        Карты
      </Typography>
      <CardsContainer>
        {allCards.map((cardInfo, index) => (
          <CardInfoItem
            cardInfo={cardInfo}
            key={cardInfo.cardId}
            index={index}
          />
        ))}
      </CardsContainer>
    </CardsListContainer>
  );
};

export default CardsList;
