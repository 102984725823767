import { FC, useEffect, useRef, useState } from "react";
import { Button, InputNumber } from "components";
import { SearchFiltersValues } from "pages/home/Cards/History/History";
import { formatNumber, stringToNumber } from "utils/formatCurrency";
import { AnchorStyled, DropdownAnchor } from "../..";
import { useDispatch, useSelector } from "react-redux";
import {
  HISTORY_TABS,
  getCategoryExpense,
  getCategoryIncome,
} from "store/slices/historySlice";
import { RootState } from "store";

const ERROR_MESSAGE_FROMAMOUNT =
  "Начальная сумма не может быть больше конечной";
const ERROR_MESSAGE_TOAMOUNT = "Конечная сумма не может быть меньше начальной";

type ValueAmountType = {
  fromAmount: number | null;
  toAmount: number | null;
};

interface OperationAmountProps {
  value: ValueAmountType;
  isMobile: boolean;
  onChange: (values: Partial<SearchFiltersValues>) => void;
  onSearch: () => void;
  filterValues: SearchFiltersValues;
}

export const OperationAmount: FC<OperationAmountProps> = ({
  value,
  isMobile,
  onChange,
  onSearch,
  filterValues,
}) => {
  const amountRef = useRef<HTMLDivElement | null>(null);
  const [isDropped, setDropped] = useState<boolean>(false);
  const [appliedValues, setAppliedValues] = useState<ValueAmountType>(value);
  const [lastModified, setLastModified] = useState<"from" | "to" | null>(null);
  const {
    cards: {
      account: { currency },
    },
    history: { activeTab },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleSearch = () => {
    if (activeTab === HISTORY_TABS.EXPENSES) {
      dispatch(
        // @ts-ignore
        getCategoryExpense({
          ...filterValues,
          categoryIds: undefined,
        })
      );
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      dispatch(
        // @ts-ignore
        getCategoryIncome({
          ...filterValues,
          categoryIds: undefined,
        })
      );
    }
    setDropped(false);
    setAppliedValues(value);
    onSearch();
  };

  const handleDrop = () => setDropped(!isDropped);
  const handleBlur = () => setDropped(false);

  useEffect(() => {
    if (value.fromAmount === null && value.toAmount === null) {
      setAppliedValues(value);
    }
  }, [value]);

  const errorMessageFrom =
    lastModified === "from" &&
    value.fromAmount &&
    value.toAmount &&
    value.fromAmount > value.toAmount
      ? ERROR_MESSAGE_FROMAMOUNT
      : "";

  const errorMessageTo =
    lastModified === "to" &&
    value.fromAmount &&
    value.toAmount &&
    value.toAmount < value.fromAmount
      ? ERROR_MESSAGE_TOAMOUNT
      : "";

  const isAmountFromError = errorMessageFrom;
  const isAmountToError = errorMessageTo;

  const content = (
    <>
      <InputNumber
        label="Введите сумму от"
        placeholder={`0 ${currency?.sign}`}
        suffix={` ${currency?.sign}`}
        value={value.fromAmount}
        error={!!isAmountFromError}
        hint={isAmountFromError}
        onChange={(event) => {
          setLastModified("from");
          onChange({
            fromAmount: stringToNumber(event.target.value),
          });
        }}
      />
      <InputNumber
        label="Введите сумму до"
        placeholder={`0 ${currency?.sign}`}
        suffix={` ${currency?.sign}`}
        value={value.toAmount}
        error={!!isAmountToError}
        hint={isAmountToError}
        onChange={(event) => {
          setLastModified("to");
          onChange({
            toAmount: stringToNumber(event.target.value),
          });
        }}
      />
      <Button
        variant="primary"
        onClick={handleSearch}
        disabled={!!isAmountFromError || !!isAmountToError}
      >
        Применить фильтр
      </Button>
    </>
  );
  const amountAnchor = appliedValues?.fromAmount
    ? appliedValues?.toAmount
      ? `от ${formatNumber(
          appliedValues?.fromAmount,
          true,
          currency?.sign
        )} до ${formatNumber(appliedValues?.toAmount, true, currency?.sign)}`
      : `от ${formatNumber(appliedValues?.fromAmount, true, currency?.sign)}`
    : appliedValues?.toAmount
    ? `до ${formatNumber(appliedValues?.toAmount, true, currency?.sign)}`
    : "";

  const trigger = amountAnchor ? (
    <AnchorStyled>{amountAnchor}</AnchorStyled>
  ) : (
    "Сумма"
  );

  return (
    <DropdownAnchor
      trigger={trigger}
      isDropped={isDropped}
      onDrop={handleDrop}
      onBlur={handleBlur}
      refElement={isMobile ? null : amountRef}
    >
      {content}
    </DropdownAnchor>
  );
};
