import { useSelector } from "react-redux";
import { Box, Typography, useTheme } from "@mui/material";

import { LimitType } from "api/account/api";
import { useIsMobile } from "hooks/useIsMobile";
import { formatAmount } from "utils/formatCurrency";

type Props = {
  id: number;
  subtitle: string;
  title: string;
  selected: boolean;
  onClick: (id: number) => void;
  limitCurrentAmount: string;
  limitSumAmount: string;
  readOnly: boolean;
};

export const rusSubtitle = (subtitle: string) => {
  switch (subtitle) {
    case LimitType.Daily:
      return "Суточный лимит на";
    case LimitType.Weekly:
      return "Еженедельный лимит на";
    case LimitType.Monthly:
      return "Месячный лимит на";
    case LimitType.Quarterly:
      return "Квартальный лимит на";
    case LimitType.Yearly:
      return "Годовой лимит на";
  }
};

export const LimitItem = ({
  id,
  subtitle,
  title,
  selected,
  onClick,
  limitCurrentAmount,
  limitSumAmount,
  readOnly,
}: Props) => {
  const theme = useTheme();
  const { isMobile } = useIsMobile();
  const {
    cards: {
      mainCard,
      account: { currency },
    },
  } = useSelector((state: any) => state);

  const handleClick = () => {
    if (mainCard.status === "ACTIVE") {
      onClick(id);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "&:not(:last-child)": {
          borderBottom: `0.5px solid ${theme.primary.main.gray[200]}`,
        },
        cursor: "pointer",
        padding: isMobile ? "12px 0" : "16px 0",
        background: selected ? theme.primary.main.gray[50] : "none",
      }}
      {...(!readOnly && { onClick: handleClick })}
    >
      <Box display="flex" flexDirection="column" rowGap={4}>
        <Typography variant="text_3" color={theme.primary.main.gray["300"]}>
          {rusSubtitle(subtitle)}
        </Typography>
        <Typography
          variant="text_5"
          color={
            readOnly
              ? theme.primary.main.gray["300"]
              : theme.primary.main.gray["800"]
          }
        >
          {title}
        </Typography>
        <Typography
          variant="text_3"
          color={theme.primary.main.gray["400"]}
          mt={4}
        >
          {formatAmount(limitCurrentAmount, currency.sign, true)} /{" "}
          {formatAmount(limitSumAmount, currency.sign, true)}
        </Typography>
      </Box>
      {!readOnly && (
        <Box>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.75 3.5L10.25 8L5.75 12.5"
              stroke="#070417"
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Box>
      )}
    </Box>
  );
};
