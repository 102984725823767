export const IconArrowhead = ({ color, direction = "top", ...props }) => {
  const directions = {
    top: "0deg",
    right: "90deg",
    bottom: "180deg",
    left: "270deg",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.73"
      height="6.78"
      viewBox="0 0 10.73 6.78"
      fill="none"
      style={{ transform: `rotate(${directions[direction]})` }}
      {...props}
    >
      <path
        d="M250,249.52l3.95,3.95,1.41-1.41L250,246.69l-5.36,5.37,1.41,1.41Z"
        transform="translate(-244.6 -246.69)"
        fill={color || "#98a2b3"}
      />
    </svg>
  );
};
