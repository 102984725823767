import styled from "@emotion/styled/macro";

export const TitleBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.p`
  margin: 0px;
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  color: #393e46;
  margin-top: 50px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 20px;
    text-align: left;
  }
`;

export const BackButtonWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 20px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;
