import { Modal } from "molecules/Modal/Modal";
import { AddLimit } from "./AddLimit";
import { Confirmation } from "./Confiramtion";
import { Screens } from "./models";
import { Success } from "./Success";
import { UnSuccess } from "./UnSuccess";
import { CardLimitConfirmResponse } from "../../../../api/account";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "store/slices/auth";

type Props = {
  isOpen: boolean;
  isMobile: boolean;
  onClose: () => void;
  screen: Screens;
  onChangeScreens: (screen: Screens) => void;
  limitItem: CardLimitConfirmResponse;
  cardId: string;
};

export const LimitModal = ({
  isOpen,
  isMobile,
  onClose,
  screen,
  onChangeScreens,
  limitItem,
  cardId,
}: Props) => {
  const dispatch = useDispatch();
  const screens: Record<Screens, React.ReactNode> = {
    initial: (
      <AddLimit
        onClick={onChangeScreens}
        nextScreen="confirmation"
        limitItem={limitItem}
        cardId={cardId}
      />
    ),
    confirmation: (
      <Confirmation onClick={onChangeScreens} nextScreen="success" />
    ),
    success: <Success onClick={onClose} />,
    unsuccess: <UnSuccess onClick={onClose} />,
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(authActions.setIsBurgerActive(true));
    } else {
      dispatch(authActions.setIsBurgerActive(false));
    }
  }, [isOpen, dispatch]);
  return (
    <Modal
      title={isMobile ? null : "Изменение лимита"}
      isOpen={isOpen}
      onClose={onClose}
      buttonVisible={false}
      callback={() => {}}
    >
      {screens[screen]}
    </Modal>
  );
};
