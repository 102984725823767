import { Box, Typography } from "@mui/material";
import { Button, InputText } from "components";
import theme from "theme";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationResult } from "molecules/NotificationResult";
import { IconSuccess } from "atoms/IconSuccess";
import { profileSelector } from "store";
import { useIsMobile } from "hooks/useIsMobile";
import { updateCardInfo } from "store/slices/cardsSlice";

type LoginModalProps = {
  name?: string;
  onClose: () => void;
  cardId: string;
};

export const CardModal = ({ name, onClose, cardId }: LoginModalProps) => {
  const dispatch = useDispatch();
  const { isLoginUpdated } = useSelector(profileSelector);
  const accountLastNum = name;
  const [newCardName, setNewCardName] = useState("");
  const [operationSuccess, setOperationSuccess] = useState(false);
  const { isMobile } = useIsMobile();

  const disabled = newCardName.length === 0 || newCardName === name;
  const handleClick = () => {
    dispatch(
      updateCardInfo({
        cardUID: cardId,
        description: newCardName,
      })
    )
      // @ts-ignore
      .unwrap()
      .then((res) => {
        setOperationSuccess(true);
      })
      .catch((err: any) => {
        return err;
      });
  };

  if (operationSuccess) {
    return (
      <Box
        height="100%"
        pt={{ xs: 46, md: 32 }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <NotificationResult
          title="Имя карты изменено"
          subtitle="Вы можете изменить имя карты в любое удобное для вас время"
          icon={<IconSuccess />}
          height="100%"
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              size: "lg",
              onClick: onClose,
            },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box px={isMobile ? 20 : 32} height="100%">
      <Box boxSizing="border-box">
        <Box mb={24}>
          <Typography
            variant="text_7"
            fontWeight={500}
            style={{ wordBreak: "break-word" }}
          >
            Изменение названия для {isMobile && <br />}карты{" "}
            {!isMobile && <br />}
            {accountLastNum}
          </Typography>
        </Box>
        <InputText
          value={newCardName}
          color="#475467"
          name="newLogin"
          label="Название карты"
          placeholder="Название карты"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNewCardName(e.target.value);
          }}
          maxLength={26}
        />

        <Box
          width="100%"
          mt={40}
          display="flex"
          flexDirection="column"
          gap={20}
        >
          <Button
            isLoading={isLoginUpdated}
            disabled={disabled}
            size="lg"
            variant="primary"
            onClick={handleClick}
          >
            Применить
          </Button>
          <Button
            size="lg"
            variant="secondary"
            onClick={onClose}
            sx={{ backgroundColor: theme.palette.gray.b100 }}
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
