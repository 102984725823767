import React from "react";
import { useTheme } from "@mui/material";

const IconCopy = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  const theme = useTheme();
  const { blue } = theme.palette;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.9765 6L18 4.875C17.998 4.17941 17.7208 3.51288 17.2289 3.02103C16.7371 2.52918 16.0705 2.25198 15.375 2.25H5.24997C4.45504 2.25235 3.69334 2.56917 3.13124 3.13127C2.56914 3.69338 2.25232 4.45507 2.24997 5.25V15.375C2.25195 16.0706 2.52915 16.7371 3.021 17.229C3.51285 17.7208 4.17938 17.998 4.87497 18H5.99997M8.67169 6.00028H19.0779C20.5536 6.00028 21.7498 7.19651 21.7498 8.67215V19.0784C21.7498 20.554 20.5536 21.7503 19.0779 21.7503H8.67169C7.19606 21.7503 5.99982 20.554 5.99982 19.0784V8.67215C5.99982 7.19651 7.19606 6.00028 8.67169 6.00028Z"
        stroke={props.color || blue.b400}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconCopy;
