import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import Styled from "@emotion/styled/macro";

import IconArrowRight from "./IconArrowRight";
import CardBG from "./images/MiniatureCardBG.png";
import { formatAmount } from "utils/formatCurrency";
import { useDispatch } from "react-redux";
import { setIsCardInfoOpen } from "store/slices/cardsSlice";
import { CARD_STATUS } from "components/CardsInfo/model";
import MiniatureCardBlocked from "./images/MiniatureCardBlocked.png";
import MiniatureCardClosed from "./images/MiniatureCardClosed.png";
import MiniatureCardFrozen from "./images/MiniatureCardFrozen.png";
import { CardDto } from "api/account";
import CheckedImg from "components/RefillBlock/assets/check-outlined-icon.png";

const CardInfoItemContainer = styled(Box)({
  display: "flex",
  columnGap: "9px",
  alignItems: "center",
  padding: "4px 0",
  cursor: "pointer",
});

const CardMiniature = styled("img")({
  width: "78px",
  height: "48px",
  borderRadius: "6px",
});

const CheckedIcon = Styled.img`
  width: 13px;
  height: 9px;
`;

interface CardInfoItemProps {
  cardInfo: CardDto;
  index: number;
  customSelect?: boolean;
  showCards?: boolean;
  setShowCards?: () => void;
  setActiveCard?: () => void;
  hideRightArrow?: boolean;
  activeCard?: boolean;
}

const CardInfoItem = ({
  cardInfo,
  index,
  customSelect,
  showCards,
  setShowCards,
  setActiveCard,
  hideRightArrow,
  activeCard,
}: CardInfoItemProps) => {
  const { cardPan, amount, currency, description, status } = cardInfo;
  const dispatch = useDispatch();

  const shortCardPan = cardPan?.slice(-6);
  const cardAmount = formatAmount(amount, currency?.sign);

  const deg = showCards ? "270deg" : "90deg";
  const position = activeCard ? "0deg" : deg;

  const cardClick = () => {
    if (setActiveCard) setActiveCard();
    if (customSelect && setShowCards) {
      setShowCards();
    } else {
      dispatch(setIsCardInfoOpen(index));
    }
  };

  return (
    <CardInfoItemContainer onClick={cardClick}>
      <Box sx={{ display: "flex", position: "relative" }}>
        {status === CARD_STATUS.Active && (
          <>
            <CardMiniature src={CardBG} />
            <Typography
              variant="text_3"
              sx={{
                color: "#FFFFFF",
                position: "absolute",
                bottom: 2,
                left: 3,
              }}
            >
              {shortCardPan}
            </Typography>
          </>
        )}
        {status === CARD_STATUS.Temporary && (
          <Box
            component="img"
            width={78}
            height={48}
            src={MiniatureCardFrozen}
          />
        )}
        {CARD_STATUS.Blocked.includes(status as string) && (
          <Box
            component="img"
            width={78}
            height={48}
            src={MiniatureCardBlocked}
          />
        )}
        {status === CARD_STATUS.Closed && (
          <Box
            component="img"
            width={78}
            height={48}
            src={MiniatureCardClosed}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "fit-content",
          overflow: "hidden",
        }}
      >
        <Typography
          noWrap
          variant="text_13"
          sx={{ color: "#2E3238", fontWeight: 500 }}
        >
          {cardAmount}
        </Typography>
        <Typography noWrap variant="text_3" sx={{ color: "#8993A4" }}>
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          marginLeft: customSelect ? "auto" : "inherit",
          paddingRight: customSelect ? "15px" : "inherit",
        }}
      >
        <Box
          sx={{
            transition: "rotate 0.2s ease-out",
            rotate: customSelect ? position : "inherit",
          }}
        >
          {activeCard ? (
            <CheckedIcon src={CheckedImg} />
          ) : (
            !hideRightArrow && <IconArrowRight />
          )}
        </Box>
      </Box>
    </CardInfoItemContainer>
  );
};

export default CardInfoItem;
