import { forwardRef } from "react";
import Box from "@mui/material/Box";
import { InputAdornment, TextField } from "@mui/material";
import NumberFormat from "react-number-format";
import { Label } from "molecules/Label/Label";
import DisabledCard from "./DisabledCard.png";
import ActiveCard from "./ActiveCard.png";
import { makeStyles } from "@mui/styles";
import theme from "theme";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .MuiOutlinedInput-root": {
      color: "#101828",
      background: theme.primary.white,
      borderRadius: "16px",
      "&::placeholder": {
        color: "red",
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.palette.indigo.b300}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 18px",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#F04438",
      fontSize: "14px",
      lineHeight: "20px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline ": {
      border: "1px solid #FDA29B !Important",
    },
  },
}));

const CardFormatInput = forwardRef(function CardFormatInput(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format="#### #### #### #### ###"
      getInputRef={ref}
      allowEmptyFormatting={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

export const CardInput = ({
  name,
  label,
  value,
  onChange,
  placeholder = "0000 0000 0000 0000",
  icon = false,
  error = false,
  errorMessage = "",
  mb = 20,
  transfer = false,
}) => {
  const classes = useStyles();
  return (
    <Box mb={mb}>
      <Label
        label={label}
        htmlFor={name}
        style={{
          marginBottom: transfer ? 20 : 6,
          color: theme.palette.gray.b600,
          fontWeight: transfer ? 400 : 500,
        }}
      />
      <TextField
        classes={{ root: classes.inputRoot }}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        error={!!error}
        autoComplete="off"
        sx={{
          "& > .MuiOutlinedInput-root > input": {
            "&::placeholder": {
              color: theme.palette.gray.b400,
              opacity: 1,
            },
          },
          "& > .MuiOutlinedInput-root > .MuiInputAdornment-root > p": {
            color: theme.palette.gray.b400,
          },
        }}
        InputProps={{
          inputComponent: CardFormatInput,
          startAdornment: icon && (
            <InputAdornment position="start">
              {value ? (
                <Box component="img" src={ActiveCard} />
              ) : (
                <Box component="img" src={DisabledCard} />
              )}
            </InputAdornment>
          ),
        }}
        id={name}
        fullWidth
        helperText={errorMessage}
      />
    </Box>
  );
};
