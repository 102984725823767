import React from "react";
import { Box, Typography } from "@mui/material";

import { ArrowRightIcon } from "./assets/ArrowRight";

import { ProfileModalType } from "./models";

type ProfileOptionProps = {
  title: string;
  id: ProfileModalType;
  onClick: (id: ProfileModalType) => void;
  icon: React.ReactNode;
};

export const ProfileOption = (props: ProfileOptionProps) => {
  const { title, id, onClick, icon } = props;

  const handleClick = () => {
    onClick(id);
  };

  return (
    <Box
      sx={{
        cursor: "pointer",
        borderRadius: 16,
        backgroundColor: "#F2F4F9",
        "&:hover": {
          background: "#D6EDF7",
        },
        "&:active": {
          background: "#D6EDF7",
        },
      }}
      p={{ xs: 12, xl: 20 }}
      display="flex"
      gap={12}
      alignItems="center"
      onClick={handleClick}
    >
      <Box
        sx={{
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 36,
          height: 36,
          backgroundColor: "#FFFFFF",
        }}
      >
        {icon}
      </Box>
      <Typography variant="text_5" color="gray.b800" flex={1}>
        {title}
      </Typography>
      <ArrowRightIcon />
    </Box>
  );
};
