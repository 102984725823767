import { Box, Theme } from "@mui/system";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// @ts-ignore
import CardBG from "../../../../../src/components/public/images/CardBG.png";
import SwiperCore, { Pagination, Navigation, Scrollbar, A11y } from "swiper";
import { makeStyles } from "@mui/styles";
import { useEffect, useRef } from "react";
import { useIsMobile } from "hooks/useIsMobile";
import { CARD_STATUS } from "components/CardsInfo/model";
import { RenderStatus } from "./RenderStatus";
import { Typography } from "@mui/material";
import theme from "theme";
import { formatCurrency } from "utils/formatCurrency";
import { createStyles } from "@material-ui/core/styles";
import { setChosenCardIndex } from "store/slices/cardsSlice";
import { useDispatch } from "react-redux";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

type Props = {
  width?: string;
};
const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    slider: {
      width: ({ width }) => width || "0px",
      overflow: "visible !important",

      "& .swiper-pagination": {
        position: "relative",
        marginTop: "10px",
        marginBottom: "6px",

        "& .swiper-pagination-bullet": {
          backgroundColor: "#0082BA",
          width: 12,
          height: 4,
          borderRadius: 8,
          transition: "0.5s",
          cursor: "pointer",
        },
        "& .swiper-pagination-bullet-active": {
          backgroundColor: "#0082BA",
          width: 40,
        },
      },
    },
  })
);

export const CardsSlider = ({
  setIsIndex,
  allCards,
  chosenCardIndex,
}: {
  setIsIndex: React.Dispatch<React.SetStateAction<number>>;
  allCards: any;
  chosenCardIndex?: number;
}) => {
  const swiperRef = useRef(null);
  const { isMobile } = useIsMobile();
  const classes = useStyles({ width: isMobile ? "280px" : "372px" });
  const dispatch = useDispatch();
  const imageSrc = CardBG;

  const cardLastNumber = (cardPan: string) => {
    return Number(
      cardPan
        ?.split("")
        .filter((symbol) => symbol !== "*")
        .join("")
    );
  };

  useEffect(() => {
    if (swiperRef !== null && chosenCardIndex) {
      //@ts-ignore
      swiperRef?.current?.swiper.slideTo(chosenCardIndex);
    }
  }, [chosenCardIndex]);

  return (
    <Box
      overflow="hidden"
      sx={{ borderRadius: 20, background: "#F3F5F8" }}
      order={{ xs: 1, md: 2 }}
      pt={{ xs: 12, md: 20 }}
      pb={allCards.length === 1 ? "20px" : "0px"}
      mb={{ xs: 32, md: 0 }}
    >
      <Swiper
        ref={swiperRef}
        slidesPerView="auto"
        width={isMobile ? 280 : 372}
        // slidesPerView={1}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        spaceBetween={allCards.length > 0 ? 20 : 0}
        modules={[Pagination]}
        navigation
        scrollbar={{ draggable: true }}
        onSlideChange={(e) => {
          setIsIndex(e.realIndex);
          dispatch(setChosenCardIndex(e.realIndex));
        }}
        className={classes.slider}
      >
        {allCards.map((card: any, index: any) => {
          return (
            <SwiperSlide key={index}>
              {card.status !== CARD_STATUS.Active && (
                <RenderStatus
                  status={card.status}
                  iconHeight={56}
                  iconWidth={56}
                  size="huge"
                />
              )}

              <Box
                height={isMobile ? 180 : 240}
                width={isMobile ? 280 : 372}
                position="relative"
              >
                <img
                  src={imageSrc}
                  alt="ваша карта"
                  style={{
                    zIndex: 0,
                    position: "absolute",
                    width: "inherit",
                    borderRadius: "12px",
                  }}
                />
                <Typography
                  variant="text_10"
                  color="gray.b50"
                  fontWeight="600"
                  style={{
                    position: "relative",
                    top: isMobile ? "20px" : "24px",
                    left: isMobile ? "14px" : "20px",
                  }}
                >
                  ** {cardLastNumber(card.cardPan)}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  position="absolute"
                  bottom={0}
                  left={0}
                  height={67}
                  right={0}
                  borderRadius="0px 0px 12px 12px"
                >
                  <Box
                    flexDirection="column"
                    display="flex"
                    position="relative"
                    left={12}
                    top={10}
                  >
                    <Typography variant="text_7" color="#fff" fontWeight="600">
                      {formatCurrency(card.amount, true)}
                    </Typography>
                    <Typography
                      variant="text_2"
                      color={theme.palette.gray.disabled}
                      fontWeight="400"
                    >
                      {card.cardTypeDescription}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Box>
  );
};
