import { useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import ArrowRightIcon from "./assets/ArrowRightIcon";
import ProfileAccountIcon from "./assets/ProfileAccountIcon";
import { profileActions } from "store/slices/profile";

export const ProfileLink = () => {
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(profileActions.setIsModalOpened(true));
  }, [dispatch]);

  return (
    <Box
      display="flex"
      columnGap={7}
      alignItems="center"
      sx={{ cursor: "pointer" }}
      onClick={handleClick}
    >
      <ProfileAccountIcon />
      <Typography variant="text_12" color="#475467" fontWeight={500}>
        Профиль
      </Typography>
      <ArrowRightIcon />
    </Box>
  );
};
