import { EmptyStyled } from "../../History";
import NoResults from "../../assets/NoResults.png";

export const EmptyScreenCustom = () => {
  return (
    <EmptyStyled>
      <img
        src={NoResults}
        style={{
          width: "150px",
          height: "150px",
        }}
      />
      <h2>Ничего не найдено</h2>
      <h4>
        По вашему запросу ничего не нашлось, проверьте, правильно ли введен
        запрос
      </h4>
    </EmptyStyled>
  );
};
