import * as React from "react";

const ArrowRightIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m7 6 5 4.5L7 15"
      stroke="#0082BA"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRightIcon;
