import * as React from "react";

const ProfileAccountIcon = () => (
  <svg
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width={32} height={32} rx={16} fill="#0082BA" />
    <path
      d="M20.096 19.324c-1.053-.484-2.529-.991-4.096-.991-1.567 0-3.043.507-4.096.99-1.038.478-1.648 1.517-1.782 2.651L10 23h12l-.122-1.026c-.134-1.134-.744-2.173-1.782-2.65ZM16 15.333a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      stroke="#D6EDF7"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ProfileAccountIcon;
