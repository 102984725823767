export const ProfileIcon = () => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.525 16.346C16.2 15.691 14.17 14.914 12 14.914c-2.17 0-4.2.777-5.525 1.432-1.025.506-1.635 1.539-1.77 2.674L4.5 20.747h15l-.205-1.727c-.135-1.135-.745-2.168-1.77-2.674ZM12 11.164a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z"
      stroke="#0082BA"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
