import { Box } from "@mui/material";
import Icon from "organisms/Header/Profile.png";
import theme from "theme";

const OperatorIcon = () => {
  return (
    <Box
      display="flex"
      alignSelf="flex-end"
      sx={{
        width: 20,
        height: 20,
        border: `6px solid ${theme.primary.main.gray["100"]}`,
        borderRadius: "50%",
      }}
    >
      <img
        src={Icon}
        style={{
          width: 14,
          height: 14,
          marginLeft: 3,
          marginTop: 2,
        }}
      />
    </Box>
  );
};

export default OperatorIcon;
