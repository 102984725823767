export const IconEditNew = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <rect width="36" height="36" fill="#F3F5F8" rx="12"></rect>
      <path
        stroke="#0082BA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.111 11L25 14.889 14.889 25H11v-3.889L21.111 11z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default IconEditNew;
