import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatApi } from "api";

type ChatMessagePayload = { page?: number; size?: number };

export const getChatMessages = createAsyncThunk(
  "getChatMessages",
  async (payload: ChatMessagePayload | undefined, { rejectWithValue }) => {
    try {
      const response = await chatApi.getMessages(
        "",
        payload?.page,
        payload?.size || 20
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type SendChatMessage = {
  text?: string;
  attachments?: File[];
};
export const sendChatMessage = createAsyncThunk(
  "sendChatMessage",
  async ({ text, attachments }: SendChatMessage, { rejectWithValue }) => {
    try {
      const response = await chatApi.createMessage(
        "",
        text?.length ? text : undefined,
        // @ts-ignore
        attachments
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadChatAttachment = createAsyncThunk(
  "loadChatAttachment",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await chatApi.loadChatAttachment("", id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const markRead = createAsyncThunk(
  "markRead",
  async (ids: [string], { rejectWithValue }) => {
    try {
      //@ts-ignore
      const response = await chatApi.markRead("", { ids: ids });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
