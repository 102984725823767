const IconPhone = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      stroke="#C2C9D6"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M19.535 16.12c-.57-.595-1.947-1.463-2.616-1.813-.87-.455-.942-.492-1.626.035-.457.353-.76.667-1.294.549s-1.695-.785-2.711-1.836c-1.017-1.052-1.696-2.291-1.81-2.844-.115-.552.193-.864.53-1.338.473-.67.437-.781.032-1.685-.316-.702-1.176-2.119-1.752-2.706-.615-.631-.615-.52-1.012-.349-.952.416-2.674 1.239-3.136 2.262-.222.493-.322 1.65.827 3.816s1.955 3.274 3.623 5c1.669 1.727 2.952 2.655 4.827 3.747 2.32 1.348 3.21 1.086 3.687.855.703-.34 2.466-1.903 2.767-2.644.165-.41.273-.41-.336-1.049Z"
    />
  </svg>
);

export default IconPhone;
