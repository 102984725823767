export const PushAlert = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={92} height={92} fill="none">
      <rect width={90} height={90} x={1} y={1} fill="#F2F4F9" rx={45} />
      <path
        fill="#fff"
        stroke="#F2F4F9"
        strokeWidth={2}
        d="M37.5 66C51.031 66 62 55.031 62 41.5S51.031 17 37.5 17 13 27.969 13 41.5 23.969 66 37.5 66Z"
      />
      <path
        fill="#0082BA"
        d="M37 52.375a4.067 4.067 0 0 0 3.515-2.029c.109-.187-.035-.408-.251-.408h-6.528c-.216 0-.36.221-.251.408A4.067 4.067 0 0 0 37 52.376ZM45.21 43.821a4.49 4.49 0 0 1-.897-2.694V39.55c0-3.578-1.39-6.696-4.875-7.488l-.073-.434a2.398 2.398 0 0 0-4.73 0l-.072.434c-3.498.792-4.875 3.898-4.875 7.488v.366c0 1.758-.57 3.468-1.625 4.875l-.447.596a1.828 1.828 0 0 0 1.462 2.925h15.844a1.828 1.828 0 0 0 1.462-2.925l-1.173-1.566Z"
      />
      <path
        fill="#0082BA"
        stroke="#F2F4F9"
        strokeWidth={2}
        d="M65 73c9.389 0 17-7.611 17-17s-7.611-17-17-17-17 7.611-17 17 7.611 17 17 17Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M65 58a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M69.933 58a1.099 1.099 0 0 0 .22 1.214l.04.04a1.333 1.333 0 1 1-1.886 1.886l-.04-.04a1.1 1.1 0 0 0-1.214-.22 1.1 1.1 0 0 0-.666 1.007V62a1.333 1.333 0 1 1-2.667 0v-.06a1.1 1.1 0 0 0-.72-1.006 1.099 1.099 0 0 0-1.213.22l-.04.04a1.334 1.334 0 1 1-1.887-1.887l.04-.04a1.1 1.1 0 0 0 .22-1.213 1.1 1.1 0 0 0-1.007-.667H59a1.334 1.334 0 0 1 0-2.667h.06a1.1 1.1 0 0 0 1.007-.72 1.1 1.1 0 0 0-.22-1.213l-.04-.04a1.332 1.332 0 0 1 .432-2.176 1.334 1.334 0 0 1 1.454.29l.04.04a1.1 1.1 0 0 0 1.214.22H63a1.1 1.1 0 0 0 .667-1.007V50a1.334 1.334 0 0 1 2.666 0v.06A1.1 1.1 0 0 0 67 51.067a1.1 1.1 0 0 0 1.213-.22l.04-.04a1.332 1.332 0 0 1 2.176.433 1.334 1.334 0 0 1-.289 1.454l-.04.04a1.1 1.1 0 0 0-.22 1.213V54a1.1 1.1 0 0 0 1.007.667H71a1.333 1.333 0 0 1 0 2.667h-.06a1.1 1.1 0 0 0-1.007.666Z"
      />
    </svg>
  );
};
