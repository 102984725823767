import { Switch, SwitchProps, Typography, Box } from "@mui/material";
import styled from "@emotion/styled";
import { WalletIcon } from "./assets/WalletIcon";
import { useDispatch, useSelector } from "react-redux";
import { setStatement } from "store/slices/cardsSlice";
import { profileSelector } from "store";
import { getCardsShow } from "store/slices/profile/asyncThunks";
import { profileActions } from "store/slices/profile";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#0082BA",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#0082BA",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "#fff",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#EBEDF3",
    opacity: 1,
  },
}));

export const ProfileToggle = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(profileSelector);
  const showBlockedCards = () => {
    dispatch(getCardsShow({ all: !user?.showAllCards }))
      //@ts-ignore
      .unwrap()
      .then(() => {
        dispatch(profileActions.setIsllCardsShow());
        dispatch(
          setStatement({
            accId: "%",
            forceRefresh: false,
          })
        );
      });
  };

  return (
    <Box
      sx={{
        borderRadius: 16,
        backgroundColor: "#F2F4F9",
      }}
      p={{ xs: 12, xl: 20 }}
      display="flex"
      gap={12}
      alignItems="center"
    >
      <Box
        sx={{
          borderRadius: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 36,
          height: 36,
          backgroundColor: "#FFFFFF",
        }}
      >
        <WalletIcon />
      </Box>
      <Typography variant="text_5" color="gray.b800" flex={1}>
        Показать закрытые (заблокированные) карты
      </Typography>
      <IOSSwitch
        sx={{ m: 1 }}
        checked={user?.showAllCards}
        onChange={() => showBlockedCards()}
      />
    </Box>
  );
};
