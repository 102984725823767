import React from "react";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import Box from "@mui/material/Box";
import { useForm, Controller } from "react-hook-form";
import { AuthContainer } from "containers";
import { Button } from "components";
import theme from "theme";
import { useIsMobile } from "hooks/useIsMobile";
import { useDispatch, useSelector } from "react-redux";
import { confirmResetPassword, authActions } from "store/slices/auth";
import { authSelector } from "store";
import { passwordPattern } from "constants/regexp";
import { hideErrorMessage } from "store/slices/alerts";

const ThirdScreen = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(authSelector);

  const {
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<{ password: string; confirmPassword: string }>({
    mode: "onChange",
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [password, confirmedPassword] = watch(["password", "confirmPassword"]);

  const disabled =
    !!errors.password ||
    !!errors.confirmPassword ||
    !password.length ||
    !confirmedPassword ||
    password !== confirmedPassword;

  const { isMobile } = useIsMobile();

  const handleConfirmReset = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(confirmResetPassword(password));
  };

  const handleGoBack = () => {
    dispatch(hideErrorMessage());
    dispatch(authActions.resetError());
    dispatch(authActions.setRestoreScreen("initial"));
  };

  const subtitleResize = isMobile
    ? `Пароль должен содержать от 8 до 18 символов, в том числе латинские заглавные и строчные буквы,\nцифры и специальные символы`
    : `Пароль должен содержать от 8 до 18 символов, в том числе латинские\nзаглавные и строчные буквы, цифры  и специальные символы`;

  return (
    <AuthContainer title="Восстановить пароль" subtitle={subtitleResize}>
      <Box
        px={{ xs: "20px", md: "30.5px" }}
        py={32}
        sx={{
          backgroundColor: theme.palette.gray.b50,
        }}
        border={`1px solid ${theme.palette.gray.b100}`}
        borderRadius={24}
        component="form"
        autoComplete="off"
      >
        <Box component="form" autoComplete="off">
          <Controller
            control={control}
            name="password"
            rules={{
              pattern: {
                value: passwordPattern,
                message: "Пароль не соответствует требованиям",
              },
            }}
            render={({ field }) => (
              <PasswordInput
                {...field}
                // @ts-ignore
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (
                    e.target.value !== confirmedPassword &&
                    confirmedPassword
                  ) {
                    setError("confirmPassword", {
                      type: "custom",
                      message: "Введенные пароли не совпадают",
                    });
                  } else {
                    clearErrors("confirmPassword");
                  }
                  field.onChange(e.target.value);
                }}
                label="Пароль"
                error={errors.password?.message || ""}
              />
            )}
          />
          <Box mt={20}>
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                validate: (value) =>
                  value === watch("password") ||
                  "Введенные пароли не совпадают",
              }}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  // @ts-ignore
                  value={confirmedPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e.target.value);
                  }}
                  label="Повторите пароль"
                  error={errors.confirmPassword?.message || ""}
                />
              )}
            />
          </Box>

          <Box display="flex" flexDirection="column" rowGap={12} mt={98}>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={disabled}
              isLoading={isLoading}
              fullWidth
              onClick={(e) => handleConfirmReset(e)}
            >
              Продолжить
            </Button>
            <Button
              variant="secondary"
              size="lg"
              fullWidth
              onClick={handleGoBack}
            >
              Назад
            </Button>
          </Box>
        </Box>
      </Box>
    </AuthContainer>
  );
};

export default ThirdScreen;
