import React from "react";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import NumberFormat from "react-number-format";
import Box from "@mui/material/Box";
import IconClose from "atoms/IconClose/IconClose";
import { Label } from "molecules/Label/Label";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .MuiOutlinedInput-root": {
      color: "#101828",
      background: "#ffffff",
      borderRadius: "16px",
      "&.Mui-focused fieldset": {
        border: "1px solid #A4BCFD",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 38px 14px 18px",
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#667085",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
}));

const NumericInputFormat = React.forwardRef(function NumericInputFormat(
  props,
  ref
) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      isNumericString
      thousandSeparator=" "
      allowNegative={false}
      allowLeadingZeros={false}
      decimalScale={0}
    />
  );
});

export const NumericInput = ({
  value,
  onChange,
  onClick,
  label,
  name,
  placeholder = "",
  helperText = "",
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Label label={label} htmlFor={name} />
      <Box position="relative" display="flex" alignItems="center">
        <TextField
          classes={{ root: classes.inputRoot }}
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          id={name}
          fullWidth
          placeholder={placeholder}
          helperText={helperText}
          autoComplete="off"
          InputProps={{
            inputComponent: NumericInputFormat,
          }}
        />
        {value && (
          <Box
            onClick={onClick}
            position="absolute"
            right={22}
            display="flex"
            alignItems="center"
            className="cursorPointer"
          >
            <IconClose width={9} height={9} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
