import { FC, useEffect, useRef, useState } from "react";
import { Button, DatePicker } from "components";
import { SearchFiltersValues } from "pages/home/Cards/History/History";
import { parseDate } from "utils";
import { AnchorStyled, DropdownAnchor } from "../..";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  HISTORY_TABS,
  getCategoryExpense,
  getCategoryIncome,
} from "store/slices/historySlice";

export const ERROR_MESSAGE_DATEFROM_INTERVAL =
  "Начальная дата не может быть позже конечной";
export const ERROR_MESSAGE_DATEFROM_TODAY =
  "Начальная дата не может быть позже сегодняшней";
export const ERROR_MESSAGE_DATETO_INTERVAL =
  "Конечная дата не может быть раньше начальной";
export const ERROR_MESSAGE_DATETO_TODAY =
  "Конечная дата не может быть позже сегодняшней";

export type ValueDateType = {
  from: string;
  to: string;
};

interface OperationDateProps {
  value: ValueDateType;
  appliedValues: ValueDateType;
  setAppliedValues: (value: ValueDateType) => void;
  isMobile: boolean;
  onChange: (values: Partial<SearchFiltersValues>) => void;
  onSearch: () => void;
  filterValues: SearchFiltersValues;
}

export const OperationDate: FC<OperationDateProps> = ({
  value,
  isMobile,
  onChange,
  onSearch,
  filterValues,
  appliedValues,
  setAppliedValues,
}) => {
  const dateRef = useRef<HTMLDivElement | null>(null);
  const [isDropped, setDropped] = useState<boolean>(false);
  const [lastModified, setLastModified] = useState<"from" | "to" | null>(null);
  const {
    history: { activeTab },
  } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const handleSearch = () => {
    if (activeTab === HISTORY_TABS.EXPENSES) {
      dispatch(
        // @ts-ignore
        getCategoryExpense({
          ...filterValues,
          categoryIds: undefined,
        })
      );
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      dispatch(
        // @ts-ignore
        getCategoryIncome({
          ...filterValues,
          categoryIds: undefined,
        })
      );
    }
    setDropped(false);
    setAppliedValues(value);
    onSearch();
  };

  const handleDrop = () => setDropped(!isDropped);
  const handleBlur = () => setDropped(false);

  const errorMessageFrom =
    lastModified === "from" && value.from > value.to
      ? ERROR_MESSAGE_DATEFROM_INTERVAL
      : value.from > new Date().toISOString()
      ? ERROR_MESSAGE_DATEFROM_TODAY
      : "";

  const errorMessageTo =
    lastModified === "to" && value.to < value.from
      ? ERROR_MESSAGE_DATETO_INTERVAL
      : value.to > new Date().toISOString()
      ? ERROR_MESSAGE_DATETO_TODAY
      : "";

  const content = (
    <>
      <DatePicker
        label="Начало периода"
        placeholder="00.00.0000"
        value={value.from ? new Date(value.from) : null}
        error={!!errorMessageFrom}
        hint={errorMessageFrom}
        onChange={(from: Date) => {
          setLastModified("from");
          onChange({ from: from?.toISOString() });
        }}
      />
      <DatePicker
        label="Конец периода"
        value={value.to ? new Date(value.to) : null}
        error={!!errorMessageTo}
        hint={errorMessageTo}
        onChange={(to: Date) => {
          setLastModified("to");
          onChange({ to: to?.toISOString() });
        }}
      />
      <Button
        variant="primary"
        onClick={handleSearch}
        disabled={!!errorMessageFrom || !!errorMessageTo}
      >
        Применить фильтр
      </Button>
    </>
  );

  let fromText = parseDate(appliedValues?.from);
  const toText = parseDate(appliedValues?.to);

  const fromTextYear = fromText.split(".").at(-1);
  const toTextYear = toText.split(".").at(-1);

  if (fromTextYear === toTextYear) {
    const fromTextArr = fromText.split(".");
    fromTextArr.pop();
    fromText = fromTextArr.join(".");
  }

  const dateAnchor = appliedValues?.from
    ? appliedValues?.to
      ? `${fromText} - ${toText}`
      : `с ${fromText}`
    : appliedValues?.to && toText !== parseDate(new Date().toISOString())
    ? `по ${toText}`
    : "";

  const trigger = dateAnchor ? (
    <AnchorStyled>{dateAnchor}</AnchorStyled>
  ) : (
    "Период"
  );

  return (
    <DropdownAnchor
      trigger={trigger}
      isDropped={isDropped}
      onDrop={handleDrop}
      onBlur={handleBlur}
      refElement={isMobile ? null : dateRef}
    >
      {content}
    </DropdownAnchor>
  );
};
