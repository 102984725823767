export const IconUnSuccess = () => {
  return (
    <svg
      width="150"
      height="152"
      viewBox="0 0 150 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75 150.5C116.421 150.5 150 116.921 150 75.5C150 34.0786 116.421 0.5 75 0.5C33.5786 0.5 0 34.0786 0 75.5C0 116.921 33.5786 150.5 75 150.5Z"
        fill="url(#paint0_linear_7672_32456)"
      />
      <path
        d="M68.9378 23C71.6321 18.3333 78.3679 18.3333 81.0622 23L129.127 106.25C131.821 110.917 128.453 116.75 123.064 116.75H26.9356C21.547 116.75 18.1791 110.917 20.8734 106.25L68.9378 23Z"
        fill="white"
      />
      <rect
        x="70.7998"
        y="45.5"
        width="9.09999"
        height="48.5333"
        rx="4.55"
        fill="#0082BA"
      />
      <circle cx="75.3498" cy="101.616" r="4.55" fill="#0082BA" />
      <defs>
        <linearGradient
          id="paint0_linear_7672_32456"
          x1="75"
          y1="0.499997"
          x2="148.268"
          y2="129.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEF0F5" />
          <stop offset="1" stopColor="#E5EEF2" />
        </linearGradient>
      </defs>
    </svg>
  );
};
