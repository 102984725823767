import React from "react";

// <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <rect x="1.83337" y="6.5" width="12.3333" height="8.33333" rx="2.5" stroke="#F97066"/>
//   <path d="M4.66663 6.66699V4.00033C4.66663 3.11627 5.01782 2.26842 5.64294 1.6433C6.26806 1.01818 7.1159 0.666992 7.99996 0.666992C8.88401 0.666992 9.73186 1.01818 10.357 1.6433C10.9821 2.26842 11.3333 3.11627 11.3333 4.00033V6.66699" stroke="#F97066"/>
// </svg>

const IconClosedHuge = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <circle cx="28" cy="28" r="28" fill="#EBEDF3"></circle>
      <path
        fill="#475467"
        d="M28 39.183c6.275 0 11.47-5.207 11.47-11.471 0-6.275-5.206-11.47-11.481-11.47-6.264 0-11.46 5.195-11.46 11.47 0 6.264 5.207 11.47 11.471 11.47zm-3.959-6.568a.939.939 0 01-.652-1.597l3.295-3.295-3.295-3.295a.948.948 0 01-.27-.652.92.92 0 01.922-.922c.27 0 .484.09.653.27L28 26.419l3.34-3.307c.191-.19.394-.28.641-.28.506 0 .922.415.922.921a.9.9 0 01-.27.664l-3.306 3.306 3.295 3.284c.18.191.27.405.27.663a.94.94 0 01-.934.945.965.965 0 01-.663-.281L28 29.039l-3.273 3.295a.95.95 0 01-.686.281z"
      ></path>
    </svg>
  );
};

export default IconClosedHuge;
