import React from "react";

const IconDownloadOutline = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0661 8.14918L10.978 11.1307V3.01562C10.978 2.46334 11.4257 2.01562 11.978 2.01562C12.5303 2.01562 12.978 2.46334 12.978 3.01562V10.8668L15.6321 8.14918C16.018 7.75406 16.6511 7.74658 17.0462 8.13246C17.4413 8.51834 17.4488 9.15146 17.0629 9.54657L12.5645 14.1526C12.3764 14.3453 12.1184 14.4539 11.8491 14.4539C11.5798 14.4539 11.3219 14.3453 11.1337 14.1526L6.63527 9.54657C6.24939 9.15146 6.25687 8.51834 6.65199 8.13246C7.0471 7.74658 7.68022 7.75406 8.0661 8.14918ZM4.5 13.0007C4.5 12.4484 4.05228 12.0007 3.5 12.0007C2.94772 12.0007 2.5 12.4484 2.5 13.0007V17.0007C2.5 20.3144 5.18629 23.0007 8.5 23.0007H15.5C18.8137 23.0007 21.5 20.3144 21.5 17.0007V13.0007C21.5 12.4484 21.0523 12.0007 20.5 12.0007C19.9477 12.0007 19.5 12.4484 19.5 13.0007V17.0007C19.5 19.2098 17.7091 21.0007 15.5 21.0007H8.5C6.29086 21.0007 4.5 19.2098 4.5 17.0007V13.0007Z"
        fill="white"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default IconDownloadOutline;
