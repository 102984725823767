const IconError = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20ZM10.5 8.586L7.672 5.757L6.257 7.172L9.086 10L6.257 12.828L7.672 14.243L10.5 11.414L13.328 14.243L14.743 12.828L11.914 10L14.743 7.172L13.328 5.757L10.5 8.586Z"
        fill="#F04438"
      />
    </svg>
  );
};

export default IconError;
