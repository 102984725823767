import { FC, useEffect } from "react";
import { Tab, Tabs, Theme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { systemSelector } from "store";
import { systemActions, TabVariants } from "store/slices/system";
import { tabLabels } from "store/slices/system/slice";
import { makeStyles } from "@mui/styles";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";

const useStyles = makeStyles<Theme>((theme) => ({
  tabs: {
    "& .MuiTabs-flexContainer": {
      gap: 56,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.primary.brand.blue[400],
    },
  },
  tab: {
    fontSize: "16px",
    padding: "34px 0",
    [theme.breakpoints.down("md")]: {
      padding: "17px 0",
    },
    minWidth: 50,
    color: theme.primary.main.gray[400],
    "&.Mui-selected": {
      color: theme.primary.brand.blue[400],
    },
  },
}));

const Navigation: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activeTab, newsIsOpen, notificationsIsOpen } =
    useSelector(systemSelector);
  const tabsList = Object.keys(TabVariants) as Array<TabVariants>;
  const setTab = (tab: TabVariants) => {
    switch (tab) {
      case TabVariants.news:
        dispatch(systemActions.setNewsOpen(true));
        break;
      case TabVariants.notifications:
        dispatch(systemActions.setNotificationsOpen(true));
        break;
      case TabVariants.history:
        dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
        dispatch(systemActions.setActiveTab({ tab }));
        break;
      default:
        dispatch(systemActions.setActiveTab({ tab }));
    }
  };

  let ACTIVE_TAB = activeTab;

  if (newsIsOpen) ACTIVE_TAB = TabVariants.news;
  if (notificationsIsOpen) ACTIVE_TAB = TabVariants.notifications;

  useEffect(() => {
    return () => {
      dispatch(systemActions.setNewsOpen(false));
      dispatch(systemActions.setNotificationsOpen(false));
    };
  }, [dispatch]);

  return (
    <Tabs
      className={classes.tabs}
      value={ACTIVE_TAB}
      TabIndicatorProps={{
        sx: { height: 3, backgroundColor: "#0082BA" },
      }}
    >
      {tabsList.map((key) => (
        <Tab
          className={classes.tab}
          disableRipple
          key={key}
          onClick={() => setTab(key)}
          label={tabLabels[key]}
          value={key}
        />
      ))}
    </Tabs>
  );
};

export default Navigation;
