import React, { FC } from "react";
import { Box, Typography } from "@mui/material";

interface ISectionHeader {
  date: string;
}

const SectionHeader: FC<ISectionHeader> = ({ date }) => {
  return (
    <Box px={24} py={12} bgcolor="#F9FAFB">
      <Typography fontWeight={500} variant="text_3">
        {date}
      </Typography>
    </Box>
  );
};

export default SectionHeader;
