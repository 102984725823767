import React from "react";

export const Notifications = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9998 17.9992V18.7492C14.9998 19.5449 14.6837 20.3079 14.1211 20.8705C13.5585 21.4331 12.7954 21.7492 11.9998 21.7492C11.2041 21.7492 10.4411 21.4331 9.87847 20.8705C9.31586 20.3079 8.99979 19.5449 8.99979 18.7492V17.9992M20.0472 16.4733C18.8435 15 17.9936 14.25 17.9936 10.1883C17.9936 6.46875 16.0943 5.14359 14.531 4.5C14.3233 4.41469 14.1279 4.21875 14.0646 4.00547C13.7904 3.07219 13.0216 2.25 11.9997 2.25C10.9779 2.25 10.2086 3.07266 9.93724 4.00641C9.87396 4.22203 9.67849 4.41469 9.47083 4.5C7.90568 5.14453 6.00818 6.465 6.00818 10.1883C6.00584 14.25 5.15599 15 3.95224 16.4733C3.45349 17.0836 3.89037 18 4.76271 18H19.2415C20.1091 18 20.5432 17.0808 20.0472 16.4733Z"
        stroke="#0082BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
