export const PinCodeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={36} height={36} fill="none">
    <rect width={36} height={36} fill="#fff" rx={18} />
    <g clipPath="url(#a)">
      <path
        fill="#0082BA"
        fillRule="evenodd"
        d="M15.414 14.853c0-.942.259-1.62.662-2.056.395-.427 1.012-.711 1.923-.711s1.528.284 1.922.71c.402.434.66 1.11.66 2.054v1.57h-5.167v-1.567Zm-1.5 1.577v-1.577c0-1.208.334-2.289 1.06-3.074.735-.794 1.785-1.193 3.025-1.193 1.239 0 2.289.398 3.023 1.191.726.785 1.059 1.865 1.059 3.073v1.58a2.75 2.75 0 0 1 2.5 2.74v3.5a2.75 2.75 0 0 1-2.75 2.75h-7.667a2.75 2.75 0 0 1-2.75-2.75v-3.5a2.75 2.75 0 0 1 2.5-2.74Zm-1 2.74c0-.691.56-1.25 1.25-1.25h7.667c.69 0 1.25.559 1.25 1.25v3.5c0 .69-.56 1.25-1.25 1.25h-7.667c-.69 0-1.25-.56-1.25-1.25v-3.5Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8 8h20v20H8z" />
      </clipPath>
    </defs>
  </svg>
);
