import { useNavigate } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { IconSuccess } from "atoms/IconSuccess";
import { NotificationResult } from "molecules/NotificationResult";
import { useDispatch } from "react-redux";
import { authActions } from "store/slices/auth";

const SuccessedScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    navigate("/sign-in");
    dispatch(authActions.setRegisterScreen("initial"));
  };
  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      component="main"
      container
      justifyContent="center"
      fontFamily="Inter"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <Box
        p={{ xs: "80px 0 140px", md: "32px 30.5px" }}
        component="form"
        autoComplete="off"
      >
        <NotificationResult
          title="Аккаунт создан"
          subtitle={`Теперь вы можете продолжить работу\n в личном кабинете`}
          icon={<IconSuccess />}
          buttons={[
            {
              name: "Войти в аккаунт",
              variant: "primary",
              size: "lg",
              onClick: handleClick,
            },
          ]}
        />
      </Box>
    </Grid>
  );
};

export default SuccessedScreen;
