import React, { PropsWithChildren } from "react";
import { Skeleton, SkeletonTypeMap } from "@mui/material";
import theme from "../../theme";

type SkeletonContainerProps = Omit<SkeletonTypeMap["props"], "animation"> & {
  isLoading?: boolean;
  height?: number | string;
  width?: number | string;
};

const AsDiv = (props: any) => <div {...props} />;

export const SkeletonContainer = ({
  isLoading,
  children,
  ...props
}: PropsWithChildren<SkeletonContainerProps>) => {
  if (isLoading) {
    return (
      <Skeleton
        component={AsDiv}
        sx={{
          borderRadius: 10,
          bgcolor: theme.primary.main.gray["50"],
          transformOrigin: "0",
          transform: "scale(1, 1)",
        }}
        animation="wave"
        {...props}
      >
        {children}
      </Skeleton>
    );
  }

  return <>{children}</>;
};

export default SkeletonContainer;
