import styled from "@emotion/styled/macro";

interface DropdownArrowProps {
  open: boolean;
}

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  margin-top: 60px;
  background: #f7f8fa;
  border-radius: 16px;
  z-index: 10;

  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 45px;
  }
`;

export const DropdownTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background: #f7f8fa;
  border-radius: 12px;
  color: #475467;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 16px;
`;

export const DropdownItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #393e46;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    background: #d6edf778;
  }
  &:first-child {
    border-radius: 16px 16px 0px 0px;
  }
  &:last-child {
    border-radius: 0px 0px 16px 16px;
  }
`;

export const DropdownArrow = styled.img`
  width: 12px;
  height: 7px;
  margin-left: 10px;
  transition: rotate 0.2s ease-out;
  rotate: ${(props: DropdownArrowProps) => (props.open ? "180deg" : "0deg")};
`;

export const DropdownCheck = styled.img`
  width: 13px;
  height: 9px;
  margin-left: 50px;
`;

export const Container = styled.div`
  display: flex;
`;
