import { Modal } from "molecules/Modal/Modal";
import { HistoryTabs } from "../HistoryTabs";
import { Box, Typography } from "@mui/material";
import { FinancialAnalysisWidget } from "./FinancialAnalysisWidget";
import { SkeletonContainer } from "components";
import { InfiniteScroll } from "widgets/ChatWidget/components";
import { useRef, useState } from "react";
import {
  AmountStyled,
  DateStyled,
  EmptyStyled,
  InStatementTypes,
} from "../../History";
import { parseDate } from "utils";
import { ReactComponent as IconIncoming } from "../../assets/Incoming.svg";
import { ReactComponent as IconOutcoming } from "../../assets/Outcoming.svg";
import NoResults from "../../assets/NoResults.png";
import { formatNumber } from "utils/formatCurrency";
import theme from "theme";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { HISTORY_TABS, setActiveTab, setDate } from "store/slices/historySlice";
import { HistoryModal } from "../../HistoryModal";
import { EmptyScreenDefault } from "../EmptyScreenDefault/EmptyScreenDefault";
import { EmptyScreenCustom } from "../EmptyScreenCustom/EmptyScreenCustom";

interface FinancialAnalysisMobilePageProps {
  isOpen: boolean;
  onClose: () => void;
  loadMore: () => void;
}

export const FinancialAnalysisMobilePage = ({
  isOpen,
  onClose,
  loadMore,
}: FinancialAnalysisMobilePageProps) => {
  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });
  const dispatch = useDispatch();

  const closeModal = () =>
    setModalStatus(() => ({ historyId: "", isOpen: false }));

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const closePage = () => {
    dispatch(
      setDate({
        from: firstDayMonth.toISOString(),
        to: dateNow.toISOString(),
      })
    );
    dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
    onClose();
  };

  const lastBlockRef = useRef<HTMLDivElement>(null);
  const rootSentinelRef = useRef<HTMLDivElement>(null);

  const {
    history: { activeTab, expenseList, incomeList, isLoading, date },
  } = useSelector((state: RootState) => state);

  const dateNow = new Date();
  const firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

  const fromTextFilter = parseDate(date.from);
  const toTextFilter = parseDate(date.to);
  const fromTextDefault = parseDate(firstDayMonth.toISOString());
  const toTextDefault = parseDate(dateNow.toISOString());

  const isDefaultFilters =
    fromTextFilter === fromTextDefault && toTextFilter === toTextDefault;

  const DATA = {
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  if (activeTab === HISTORY_TABS.ALL_OPERATIONS) return null;

  return (
    // @ts-ignore
    <Modal title="Анализ финансов" isOpen={isOpen} onClose={closePage}>
      <Box mt={10} pl={20} pr={20}>
        <HistoryTabs marginTop />
        <FinancialAnalysisWidget isPreview={false} />
        <SkeletonContainer height="570px" isLoading={isLoading} width="100%">
          {!isLoading && DATA[activeTab].length ? (
            <InfiniteScroll
              lastBlockRef={lastBlockRef}
              rootSentinelRef={rootSentinelRef}
              isLoading={false}
              onLoadMore={loadMore}
              reverse
              withScrollTrack
              cssVieportHeight="calc(100svh - 300px)"
            >
              {DATA[activeTab].map((item) => (
                <Box key={item.date}>
                  <DateStyled>{parseDate(item.date || "")}</DateStyled>
                  <Box display="flex" flexDirection="column" gap="16px">
                    {/*@ts-ignore*/}
                    {item.statements.map((el) => (
                      <Box
                        key={el.statementId}
                        p="12px 19px 12px 16px"
                        display="flex"
                        gap="20px"
                        onClick={() => openModal(el.statementId || "")}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                      >
                        {/*@ts-ignore*/}
                        {InStatementTypes.includes(el.type) ? (
                          <IconIncoming />
                        ) : (
                          <IconOutcoming />
                        )}
                        <Box display="flex" flexDirection="column">
                          <Typography
                            variant="text_5"
                            color={theme.primary.main.gray["800"]}
                            fontWeight="500"
                          >
                            {el.acquirer}
                          </Typography>
                          <Typography
                            variant="text_3"
                            color={theme.primary.main.gray["300"]}
                          >
                            {el.acquirerGroup}
                          </Typography>
                        </Box>
                        {/*@ts-ignore*/}
                        <AmountStyled isIncoming={el.amount > 0}>
                          {formatNumber(
                            // @ts-ignore
                            el.amount,
                            true,
                            el.currency ? el.currency.sign : ""
                          )}
                        </AmountStyled>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </InfiniteScroll>
          ) : isDefaultFilters ? (
            <EmptyScreenDefault />
          ) : (
            <EmptyScreenCustom />
          )}
          {modalStatus.isOpen && (
            <HistoryModal
              isOpen={modalStatus.isOpen}
              onClose={closeModal}
              historyId={modalStatus.historyId}
            />
          )}
        </SkeletonContainer>
      </Box>
    </Modal>
  );
};
