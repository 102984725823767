export const KeyIcon = () => (
  <svg
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m17.44 1.664-1.666 1.667m0 0 1.51 1.51a1.4 1.4 0 0 1 0 1.98l-.937.936a1.4 1.4 0 0 1-1.98 0l-1.51-1.51m2.917-2.916-2.917 2.916M9.432 9.672a4.583 4.583 0 1 1-6.481 6.482 4.584 4.584 0 0 1 6.48-6.48l.001-.002Zm0 0 3.425-3.425"
      stroke="#0082BA"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
