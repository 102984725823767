import { createAsyncThunk } from "@reduxjs/toolkit";
import { currentUserApi, bankRateApi } from "api";
import { format } from "date-fns";
import { IRootState } from "../../index";
import { UserUpdatePasswordRequest } from "../../../api/auth";

const timeZone = `${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

export const getUserInfo = createAsyncThunk(
  "getUserInfo",
  async (payload, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await currentUserApi.getPmpInfo(accessToken || "");
      return {
        ...response.data,
        birthdate: response?.data?.birthdate
          ? format(new Date(response.data.birthdate || ""), "dd.MM.yyyy")
          : null,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCardsShow = createAsyncThunk(
  "getCardsshow",
  async (payload: { all: boolean }, { rejectWithValue }) => {
    try {
      const response = await currentUserApi.updateShowAllCards("", {
        all: payload.all,
      });
      return {
        ...response.data,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateLogin = createAsyncThunk(
  "updateLogin",
  async (login: string, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await currentUserApi.updateLogin(accessToken || "", {
        login,
      });
      localStorage.setItem("login", login);
      localStorage.removeItem("useBiometry");
      return {
        ...response.data,
        birthdate: response?.data?.birthdate
          ? format(new Date(response.data.birthdate || ""), "dd.MM.yyyy")
          : null,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (payload: UserUpdatePasswordRequest, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await currentUserApi.updatePassword(
        accessToken || "",
        payload
      );
      return {
        ...response.data,
        birthdate: response?.data?.birthdate
          ? format(new Date(response.data.birthdate || ""), "dd.MM.yyyy")
          : null,
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBankRates = createAsyncThunk(
  "getBankRates",
  async (payload, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const response = await bankRateApi.getBankRates(accessToken || "");
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
