import { Typography, useTheme, Box } from "@mui/material";
import { Button } from "components";
import { Controller, useForm } from "react-hook-form";
import { Screens } from "./models";
import { useCounter } from "../../../../hooks";
import { SmsInput } from "molecules/SmsInput/SmsInput";
import { getSecondsPluralForms } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCardLimitConfirm,
  setNewLimit,
} from "../../../../store/slices/limitsSlice";
import { CardLimitConfirmResponse } from "../../../../api/account";
import { useIsMobile } from "hooks/useIsMobile";
import { useState } from "react";
import { retryOneTimePassword } from "store/slices/auth";

type Props = {
  onClick: (nextPage: Screens) => void;
  nextScreen: Screens;
};

export const Confirmation = ({ onClick, nextScreen }: Props) => {
  const dispatch = useDispatch();
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const [error, setError] = useState();
  const { isMobile } = useIsMobile();
  const { control, watch } = useForm<{ code: string }>({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });
  const code = watch("code");
  const theme = useTheme();

  const {
    limits: { limitToken },
    profile: { user },
  } = useSelector((state: any) => state);

  const lastFourNumberPhone = user?.phone.substr(7);

  const handleResendCode = () => {
    dispatch(retryOneTimePassword(limitToken));
    restartCounter();
  };

  const handleClick = () => {
    dispatch(
      updateCardLimitConfirm({
        updateLimitToken: limitToken,
        verifyCode: code,
      })
    )
      // @ts-ignore
      .unwrap()
      .then((res: Array<CardLimitConfirmResponse>) => {
        dispatch(setNewLimit(res[0]));
        onClick(nextScreen);
      })
      .catch(() => {
        // onClick("unsuccess");
        //@ts-ignore
        setError({ message: "Введен неверный код" });
        // return err;
      });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box width={isMobile ? 343 : 394} height="100%" margin="0 auto">
        <Box mt={32}>
          <Typography
            variant="text_12"
            color={theme.primary.main.gray["800"]}
            fontWeight={500}
            textAlign="center"
          >
            Введите код из сообщения, чтобы подтвердить смену лимита карты
          </Typography>
        </Box>
        <Box>
          <Box display="flex" flexDirection="column" gap={4}>
            <Box mt={24}>
              <Typography
                variant="text_5"
                color={theme.primary.main.gray["800"]}
              >
                На номер{" "}
                <span style={{ color: "#0082BA" }}>
                  *** {lastFourNumberPhone[0]}
                  {lastFourNumberPhone[1]} {lastFourNumberPhone[2]}
                  {lastFourNumberPhone[3]}
                </span>{" "}
                был отправлен код подтверждения
              </Typography>
            </Box>
            <Box
              mt={18}
              padding="32px 20px"
              sx={{
                backgroundColor: theme.palette.gray.b100,
                borderRadius: 24,
              }}
            >
              <Controller
                control={control}
                name="code"
                defaultValue={code}
                render={({ field }) => (
                  <SmsInput
                    placeholder="Введите код"
                    label="Код"
                    name="code"
                    value={code}
                    error={error}
                    onChange={(e: any) => {
                      field.onChange(e.target.value);
                      setError(undefined);
                    }}
                  />
                )}
              />
              <Box display="flex" flexDirection="column" gap={12} mt={42}>
                {count === 0 ? (
                  <Button
                    onClick={handleResendCode}
                    variant="secondary"
                    size="lg"
                    fullWidth
                  >
                    Отправить код повторно
                  </Button>
                ) : (
                  <Box textAlign="center" minHeight={52}>
                    <Typography variant="text_5" color="gray.b400">
                      Вы сможете отправить код повторно через{" "}
                      <Typography variant="text_5" color="blue.b400">
                        {count} {secondsWord}.
                      </Typography>
                    </Typography>
                  </Box>
                )}
                <Button
                  disabled={code.length < 4}
                  variant="primary"
                  size="lg"
                  fullWidth
                  onClick={handleClick}
                >
                  Продолжить
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
