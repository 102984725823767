export const IconAccountRubMiddle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    fill="none"
    viewBox="0 0 36 36"
  >
    <rect width="36" height="36" fill="#0082BA" rx="13"></rect>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M25.2 9.2a.8.8 0 01.8.8v14.4a.8.8 0 01-.8.8h-.8v.8a.8.8 0 11-1.6 0v-.8h-9.6v.8a.8.8 0 11-1.6 0v-.8h-.8a.8.8 0 01-.8-.8V10a.8.8 0 01.8-.8h14.4zm-.8 1.6H11.6v12.8h12.8V10.8zm-5.733 7.534a2.689 2.689 0 002.666-2.667c0-1.451-1.258-2.666-2.718-2.666h-1.968c-.37 0-.668.3-.667.668L15.989 17h-.656a.667.667 0 000 1.334h.66l.002.671h-.762a.567.567 0 000 1.134h.765l.002.532a.667.667 0 101.333-.003l-.002-.53h1.236a.567.567 0 000-1.133h-1.24l-.001-.671h1.34zM17.322 17l-.007-2.666h1.3c.734 0 1.385.629 1.385 1.333 0 .72-.614 1.333-1.334 1.333h-1.344z"
      clipRule="evenodd"
    ></path>
  </svg>
);
