import {
  Container,
  Tab,
  TabText,
  TabWrapper,
  DownloadIconWrapper,
  DownloadIcon,
} from "./components";
import Download from "./assets/download.png";
import {
  HISTORY_TABS,
  setActiveCategory,
  setActiveTab,
  setDate,
} from "store/slices/historySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useIsMobile } from "hooks/useIsMobile";

const TABS = Object.values(HISTORY_TABS);
const TABS_MOBILE = [TABS[0], TABS[1]];

interface HistoryTabsProps {
  marginTop?: boolean;
  handleDownload?: () => void;
}

export const HistoryTabs = ({
  marginTop,
  handleDownload,
}: HistoryTabsProps) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const { activeTab, isReportLoading } = useSelector(
    (state: RootState) => state.history
  );
  const tabs = isMobile ? TABS_MOBILE : TABS;

  const dateNow = new Date();
  const firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

  const download = () => {
    if (handleDownload) handleDownload();
  };

  return (
    <Container marginTop={marginTop}>
      <TabWrapper>
        {tabs.map((tab) => (
          <Tab
            key={tab}
            active={activeTab === tab}
            onClick={() => {
              if (activeTab === tab) return;
              dispatch(
                setDate({
                  from: firstDayMonth.toISOString(),
                  to: dateNow.toISOString(),
                })
              );
              dispatch(setActiveCategory(null));
              dispatch(setActiveTab(tab));
            }}
            width={isMobile ? "50%" : "fit-content"}
          >
            <TabText active={activeTab === tab}>{tab}</TabText>
          </Tab>
        ))}
      </TabWrapper>
      {!isMobile && (
        <DownloadIconWrapper disabled={isReportLoading} onClick={download}>
          <DownloadIcon src={Download} />
        </DownloadIconWrapper>
      )}
    </Container>
  );
};
