export type payloadCardModal = {
  id?: number;
  title?: string;
  actionType?: string;
  srcJpg?: string;
  subtitle?: string;
  textBtn?: string;
};

export const CARD_STATUS = {
  Active: "ACTIVE",
  Lost: "LOST",
  Stolen: "STOLEN",
  Closed: "CLOSED",
  Temporary: "TEMPORARY",
  Blocked: ["LOST", "STOLEN"],
};

export const CARD_FRONT_ACTION = {
  Temporary: "TEMPORARY",
  //относится блокировке
  Lost: "LOST",
  Change_Pin: "CHANGE_PIN",
  Change_Name: "CHANGE_NAME",
  Unfrozen: "UNFROZEN",
};
