const IconErrorBiometry = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
      <path
        fill="#F79009"
        d="M9 17.334A8.333 8.333 0 1 1 9 .667a8.333 8.333 0 0 1 0 16.667ZM8.167 11.5v1.667h1.666V11.5H8.167Zm0-6.666v5h1.666v-5H8.167Z"
      />
    </svg>
  );
};

export default IconErrorBiometry;
