import { FC, MutableRefObject } from "react";
import { createPortal } from "react-dom";
import styled from "@emotion/styled/macro";
import { ReactComponent as DownIcon } from "./assets/Down.svg";
import { ReactComponent as UpIcon } from "./assets/Up.svg";
import theme from "theme";
export interface DropdownProps {
  trigger: string | JSX.Element;
  children: string | JSX.Element;
  className?: string;
  isDropped?: boolean;
  disabled?: boolean;
  withArrow?: boolean;
  refElement?: MutableRefObject<HTMLDivElement | null> | null;
  onDrop?(): void;
  onBlur?(): void;
}

export const DropdownStyled = styled.div`
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
  position: absolute;
  background: ${theme.primary.white};
  border-radius: 16px;
  padding: 15px;
  box-shadow: 0px 2px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: calc(100% - 60px);
  top: 200px;
  left: 15px;
`;

export const TriggerStyled = styled.div`
  font-family: Inter;
  font-weight: 500;
  cursor: pointer;
  max-width: 400px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

export const Dropdown: FC<DropdownProps> = ({
  trigger,
  children,
  className,
  isDropped,
  withArrow = true,
  disabled,
  refElement,
  onDrop,
  onBlur,
}) => {
  const handleMouseDown = () => {
    if (!disabled) {
      onDrop?.();
    }
  };
  const handleClickOutside = () => {
    onBlur?.();
    document.removeEventListener("mousedown", handleClickOutside);
  };
  const handleMouseLeave = () => {
    document.addEventListener("mousedown", handleClickOutside);
  };
  const handleMouseEnter = () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
  const DropdownContent =
    isDropped &&
    createPortal(
      <DropdownStyled>{children}</DropdownStyled>,
      refElement?.current || document.body
    );

  return (
    <DropdownWrapper
      className={className}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      ref={refElement}
    >
      <TriggerStyled onMouseDown={handleMouseDown}>
        {trigger}
        {withArrow && (isDropped ? <UpIcon /> : <DownIcon />)}
      </TriggerStyled>
      {DropdownContent}
    </DropdownWrapper>
  );
};
