import { ReactNode } from "react";
import styled from "@emotion/styled/macro";
import theme from "theme";

enum TableColumnAlign {
  Left = "left",
  Right = "right",
  Center = "center",
}

type ColumnPadding = 5 | 10 | 15;

export type TableProps = {
  columns: TableColumnProps[];
  data: TableRowProps[];
  className?: string;
};

export type TableRowProps = {
  key?: React.Key;
};

type TableColumnProps = {
  title: string;
  field: keyof TableRowProps;
  align: TableColumnAlign;
  width: number;
  padding: ColumnPadding;
  render?(
    data: any,
    row: any,
    index: { rowIndex: number; columnIndex: number }
  ): ReactNode;
};

const TableContainer = styled.div`
  font-family: Inter;
  font-weight: 500;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.palette.gray.b400};
`;

const TableHeader = styled.div`
  font-family: Inter;
  font-weight: 500;
  display: flex;
  flex-direction: columns;
  border-bottom: 1px solid ${theme.palette.gray.b100};
  gap: 20px;
  white-space: pre-line;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 16px 0 0 0;
`;

const TABLE_CELL_PADDING = 10;

export const Table: React.FC<TableProps> = ({ columns, data }) => (
  <TableContainer>
    <TableHeader>
      {columns.map(({ field, title, width, padding = TABLE_CELL_PADDING }) => (
        <div
          key={field}
          style={{
            width: `calc(${width}% - ${padding * 2}px)`,
            padding: `2px ${padding}px`,
          }}
        >
          {title}
        </div>
      ))}
    </TableHeader>
    <div>
      {data.map((row, rowIndex) => (
        <TableRow key={`row-${rowIndex}`}>
          {columns.map(
            (
              { field, width, padding = TABLE_CELL_PADDING, render },
              columnIndex
            ) => (
              <div
                key={`cell-${rowIndex}-${field}`}
                style={{
                  width: `calc(${width}% - ${padding * 2}px)`,
                  padding: `2px ${padding}px`,
                }}
              >
                {render?.(row[field], row, { rowIndex, columnIndex }) ||
                  row[field]}
              </div>
            )
          )}
        </TableRow>
      ))}
    </div>
  </TableContainer>
);
