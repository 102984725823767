import theme from "theme";

type Props = {
  disabled?: boolean;
  click?: () => void;
};

export const AttachFileButton = ({ disabled, click }: Props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={click}
  >
    <g id="icons/outline/24/socialattach">
      <path
        id="Vector"
        d="M8.44067 7.50056V13.1197C8.44716 13.5306 8.61495 13.9225 8.90782 14.2107C9.20069 14.499 9.59516 14.6606 10.0061 14.6606C10.417 14.6606 10.8115 14.499 11.1044 14.2107C11.3973 13.9225 11.565 13.5306 11.5715 13.1197L11.5766 5.75446C11.5809 5.40326 11.5154 5.05471 11.3839 4.72901C11.2525 4.40331 11.0577 4.10694 10.8108 3.85709C10.564 3.60723 10.27 3.40886 9.94594 3.27347C9.62186 3.13808 9.27413 3.06836 8.9229 3.06836C8.57167 3.06836 8.22394 3.13808 7.89986 3.27347C7.57578 3.40886 7.2818 3.60723 7.03496 3.85709C6.78811 4.10694 6.59332 4.40331 6.46187 4.72901C6.33042 5.05471 6.26493 5.40326 6.26919 5.75446V13.1693C6.26203 13.6637 6.35324 14.1547 6.5375 14.6135C6.72176 15.0724 6.99541 15.4901 7.34252 15.8423C7.68964 16.1944 8.1033 16.4741 8.55946 16.665C9.01562 16.8559 9.50517 16.9542 9.99966 16.9542C10.4941 16.9542 10.9837 16.8559 11.4399 16.665C11.896 16.4741 12.3097 16.1944 12.6568 15.8423C13.0039 15.4901 13.2776 15.0724 13.4618 14.6135C13.6461 14.1547 13.7373 13.6637 13.7301 13.1693V6.2404"
        stroke={
          disabled ? theme.palette.gray.b200 : theme.primary.brand.blue["400"]
        }
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
  </svg>
);
