import { useEffect, useRef, FC, PropsWithChildren, RefObject } from "react";
import { Box } from "@mui/material";
import { Loader } from "components";
import { useSelector } from "react-redux";
import { chatSelector } from "store";
import { makeStyles } from "@mui/styles";

function useRefVariable<T extends any>(value: T) {
  const ref = useRef<any>();
  ref.current = value;
  return ref;
}

type InfiniteScrollProps = PropsWithChildren<{
  onLoadMore?: () => void;
  isLoading?: boolean;
  reverse?: boolean;
  withScrollTrack?: boolean;
  cssVieportHeight?: string;
  lastBlockRef: RefObject<HTMLDivElement>;
  rootSentinelRef: RefObject<HTMLDivElement>;
  onScroll?: () => void;
}>;

function getMargin(element: HTMLDivElement | null) {
  if (element) {
    const computedStyles = window.getComputedStyle(element);
    return ["margin-top", "margin-right", "margin-bottom", "margin-left"]
      .reduce(
        (accum, prop) =>
          `${accum} ${computedStyles?.getPropertyValue(prop) || "0px"}`,
        ""
      )
      .trim();
  }
}

const useStyles = makeStyles((theme) => ({
  scrollWithTrack: {
    "&::-webkit-scrollbar": {
      display: "block",
      width: 4,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 20,
      background: "#9aa4b5",
      border: "1px solid #9aa4b5",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f7f8fa",
    },
  },
}));

const InfiniteScroll: FC<InfiniteScrollProps> = ({
  children,
  onLoadMore,
  isLoading,
  lastBlockRef,
  rootSentinelRef,
  reverse,
  withScrollTrack,
  onScroll,
  cssVieportHeight = "100%",
}) => {
  const { paginationPage, chatMessagesState } = useSelector(chatSelector);
  const targetSentinelRef = useRef<HTMLElement>(null);
  const onLoadMoreRef = useRefVariable(onLoadMore);
  const classes = useStyles();

  useEffect(() => {
    !reverse && lastBlockRef.current?.scrollIntoView(true);
  }, [lastBlockRef, reverse]);

  useEffect(() => {
    const rootSentinel = rootSentinelRef.current;
    const targetSentinel: HTMLElement | null = targetSentinelRef.current;
    const lastBlock: HTMLElement | null = lastBlockRef.current;
    const anchor = reverse ? lastBlock : targetSentinel;
    const observer = new IntersectionObserver(
      async ([entry], observer) => {
        if (entry.isIntersecting) {
          onLoadMoreRef.current(entry, observer);
        }
      },
      {
        root: rootSentinel,
        rootMargin: "1px",
        threshold: 1,
      }
    );
    if (anchor) observer.observe(anchor);
    return () => {
      if (anchor) observer.unobserve(anchor);
    };
  }, [
    chatMessagesState,
    lastBlockRef,
    onLoadMoreRef,
    paginationPage,
    rootSentinelRef,
    reverse,
  ]);

  return (
    <Box
      ref={rootSentinelRef}
      sx={{
        overflow: "auto",
        height: cssVieportHeight,
      }}
      display="flex"
      flexDirection="column"
      className={withScrollTrack ? classes.scrollWithTrack : undefined}
      onScroll={() => (onScroll ? onScroll() : {})}
    >
      {isLoading ? (
        <Box
          ref={targetSentinelRef}
          width="100%"
          height={15}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Loader size={15} />
        </Box>
      ) : (
        <Box height={reverse ? 0 : 15} ref={targetSentinelRef} />
      )}
      {children}
      <Box ref={lastBlockRef} />
    </Box>
  );
};

export default InfiniteScroll;
