import React from "react";

export const News = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4 8.5V5.8C17.4 5.8 17.4 4 19.2 4C21 4 21 5.8 21 5.8V8C21 8.27614 20.7761 8.5 20.5 8.5H17.4ZM17.4 8.5V20.2C17.4 20.2 17.4 22 15.6 22M15.6 22C13.8 22 13.8 20.2 13.8 20.2V18C13.8 17.7239 13.5761 17.5 13.3 17.5H3.5C3.22386 17.5 3 17.7239 3 18V20.2C3 20.2 3 22 4.8 22H15.6ZM19 4H6.86667C5 4 5 5.73333 5 5.73333V17M8 9H14M8 13H14"
        stroke="#0082BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
