import { Box, Typography, useTheme } from "@mui/material";
import { Modal } from "molecules/Modal/Modal";
import { Divider } from "atoms/Divider/Divider";
import { Button, SkeletonContainer } from "components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHistoryItem } from "store/slices/historySlice";
import { formatAmount } from "utils/formatCurrency";
import { OtherIn } from "components/Icons/historyOperationIcons/OtherIn";
import { Cage } from "components/Icons/historyOperationIcons/Cage";
import { OtherOut } from "components/Icons/historyOperationIcons/OtherOut";
import { TransferIn } from "components/Icons/historyOperationIcons/TransferIn";
import { Wallet } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import IconRefreshReverse from "components/Icons/IconRefreshReverse";
import IconDownload from "components/Icons/IconDownload";
import { historySelector, useAppThunkDispatch } from "store";
import { systemActions, TabVariants } from "store/slices/system";
import { TransferOut } from "../../../../components/Icons/historyOperationIcons/TransferOut";
import { getReport, repeatTransfer } from "store/slices/transferSlice";
import { FileType, downloadFile } from "utils/downloadFile";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  historyId: string;
};

const useStyles = makeStyles(() => ({
  wrap: {
    height: "56px",
    width: "56px",
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
}));

export const HistoryModal: React.FC<Props> = ({
  isOpen,
  onClose,
  historyId,
}) => {
  const {
    history: { historyItem },
  } = useSelector((state: any) => state);
  const isCanceled = historyItem?.status === "error";
  const canBeRepeated = historyItem?.canBeRepeated;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const thunkDispatch = useAppThunkDispatch();
  const { isItemLoading } = useSelector(historySelector);
  const { gray, blue, red } = theme.palette;
  const [repeatLoading, setRepeatLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      dispatch(getHistoryItem(historyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!historyItem) return null;

  const operationStatus = (status: string) => {
    if (status !== "error") {
      return "Операция выполнена";
    } else {
      return "Операция отклонена";
    }
  };
  //@ts-ignore
  const numberSeparator = (num) => {
    const parts = formatAmount(num, "").toString().split(",");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      <Box flexDirection="row" justifyContent="center" mt={4}>
        <Typography
          variant="text_8"
          color={isCanceled ? red.b500 : gray.b800}
          fontWeight={600}
        >
          {num >= 0 ? "+" : ""}
        </Typography>
        <Typography
          variant="text_8"
          color={isCanceled ? red.b500 : gray.b800}
          fontWeight={600}
        >
          {numberPart.replace(thousands, " ")}
        </Typography>
        <Typography
          variant="text_8"
          color={isCanceled ? red.b400 : "#9AA4B5"}
          fontWeight={600}
        >
          {decimalPart ? "," + `${decimalPart}` : ",00"}
        </Typography>
        <Typography
          variant="text_8"
          color={isCanceled ? red.b500 : gray.b800}
          fontWeight={600}
        >
          {historyItem?.currency?.sign || ""}
        </Typography>
      </Box>
    );
  };

  const renderPreviewIcon = (icon: string) => {
    switch (icon) {
      case "transfer-out":
        return <TransferOut fill={isCanceled ? red.b400 : gray.b900} />;
      case "payment":
        return <Cage fill={isCanceled ? red.b400 : blue.b400} />;
      case "cash-in":
        return <OtherOut fill={isCanceled ? red.b400 : blue.b400} />;
      case "cash-out":
        return <TransferIn fill={isCanceled ? red.b400 : blue.b400} />;
      case "cash-in-external":
        return <Wallet fill={isCanceled ? red.b400 : blue.b400} />;
      case "transfer-in":
        return <OtherIn fill={isCanceled ? red.b400 : blue.b400} />;
      case "reverse":
        return <Wallet fill={isCanceled ? red.b400 : blue.b400} />;
      case "other-out":
        return <Wallet fill={isCanceled ? red.b400 : blue.b400} />;
      case "other-in":
        return <Wallet fill={isCanceled ? red.b400 : blue.b400} />;
      case "other":
        return <Wallet fill={isCanceled ? red.b400 : blue.b400} />;
      case "salary":
      default:
        return <Wallet fill={isCanceled ? red.b400 : blue.b400} />;
    }
  };

  const repeatPayment = () => {
    setRepeatLoading(true);
    thunkDispatch(repeatTransfer(historyItem.statementId))
      .unwrap()
      .then((res) =>
        dispatch(
          systemActions.setActiveTab({ tab: TabVariants.transfers, state: res })
        )
      )
      .catch(() => setRepeatLoading(false));
  };

  const downloadCheck = () => {
    if (historyItem) {
      dispatch(getReport(historyItem.statementId))
        //@ts-ignore
        .unwrap()
        .then((res) => {
          downloadFile(res, "check.pdf", FileType.PDF);
        });
    }
  };

  return (
    // @ts-ignore
    <Modal
      title={operationStatus(historyItem?.status)}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box flexGrow="1" paddingX={{ sm: "16px", lg: "48px" }} mt={16}>
        <Box margin={{ sm: "0px 0px 32px 0px", lg: "0px 25px 32px 25px" }}>
          <SkeletonContainer
            height="180px"
            width="344px"
            isLoading={isItemLoading}
          >
            <Box
              bgcolor={isCanceled ? red.b200 : gray.b50}
              display="flex"
              flexDirection="column"
              alignItems="center"
              pt={16}
              pb={32}
              borderRadius="16px"
            >
              <Box className={classes.wrap}>
                {renderPreviewIcon(historyItem?.type)}
              </Box>

              <Typography
                variant="text_12"
                color="gray.b900"
                fontWeight={500}
                mt={10}
              >
                {historyItem.acquirerGroup}
              </Typography>
              {numberSeparator(historyItem.amount)}
            </Box>
          </SkeletonContainer>
        </Box>
        {!isItemLoading && (
          <>
            <Box display="flex" flexDirection="column" gap="16px">
              {["other-out", "cash-out", "transfer-out", "payment"].includes(
                historyItem?.type
              ) && (
                <>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      variant="text_3"
                      color={theme.primary.main.gray["300"]}
                    >
                      Карта списания
                    </Typography>
                    <Typography
                      variant="text_5"
                      fontWeight="500"
                      color={theme.primary.main.gray["800"]}
                    >
                      {historyItem?.pan}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {historyItem?.type === "cash-in" && (
                <>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      variant="text_3"
                      color={theme.primary.main.gray["300"]}
                    >
                      Карта пополнения
                    </Typography>
                    <Typography
                      variant="text_5"
                      fontWeight="500"
                      color={theme.primary.main.gray["800"]}
                    >
                      {historyItem?.pan}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {[
                "salary",
                "other-in",
                "reverse",
                "transfer-in",
                "other",
              ].includes(historyItem?.type) && (
                <>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      variant="text_3"
                      color={theme.primary.main.gray["300"]}
                    >
                      Карта зачисления
                    </Typography>
                    <Typography
                      variant="text_5"
                      fontWeight="500"
                      color={theme.primary.main.gray["800"]}
                    >
                      {historyItem?.pan}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {["payment", "transfer-out", "cash-out"].includes(
                historyItem?.type
              ) &&
                historyItem?.originalCurrency.name !==
                  historyItem?.currency?.name && (
                  <>
                    <Box display="flex" flexDirection="column" gap="4px">
                      <Typography
                        variant="text_3"
                        color={theme.primary.main.gray["300"]}
                      >
                        Валюта списания
                      </Typography>
                      <Typography
                        variant="text_5"
                        fontWeight="500"
                        color={theme.primary.main.gray["800"]}
                      >
                        {`${historyItem?.originalCurrency.name}, ${historyItem?.originalCurrency.sign}`}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                )}
              {["payment", "transfer-out", "cash-out"].includes(
                historyItem?.type
              ) &&
                historyItem?.originalCurrency.name !==
                  historyItem?.currency?.name && (
                  <>
                    <Box display="flex" flexDirection="column" gap="4px">
                      <Typography
                        variant="text_3"
                        color={theme.primary.main.gray["300"]}
                      >
                        Сумма в валюте списания (
                        {`${historyItem?.originalCurrency.name}, ${historyItem?.originalCurrency.sign}`}
                        )
                      </Typography>
                      <Typography
                        variant="text_5"
                        fontWeight="500"
                        color={theme.primary.main.gray["800"]}
                      >
                        {`${historyItem?.originalAmount} ${historyItem?.originalCurrency.sign}`}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                )}

              {["transfer-out", "transfer-in"].includes(historyItem?.type) &&
                historyItem.reason &&
                historyItem.reason !== '""' && (
                  <>
                    <Box display="flex" flexDirection="column" gap="4px">
                      <Typography
                        variant="text_3"
                        color={theme.primary.main.gray["300"]}
                      >
                        Сообщение
                      </Typography>
                      <Typography
                        variant="text_5"
                        fontWeight="500"
                        color={theme.primary.main.gray["800"]}
                      >
                        {historyItem.reason}
                      </Typography>
                    </Box>
                    <Divider />
                  </>
                )}
              {historyItem?.type === "transfer-out" && (
                <>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      variant="text_3"
                      color={theme.primary.main.gray["300"]}
                    >
                      Реквизиты получателя
                    </Typography>
                    <Typography
                      variant="text_5"
                      fontWeight="500"
                      color={theme.primary.main.gray["800"]}
                    >
                      {historyItem.receiver}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {[
                "cash-out",
                "cash-in",
                "cash-in-external",
                "transfer-out",
                "payment",
              ].includes(historyItem?.type) && (
                <>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      variant="text_3"
                      color={theme.primary.main.gray["300"]}
                    >
                      Сумма комиссии
                    </Typography>
                    <Typography
                      variant="text_5"
                      fontWeight="500"
                      color={theme.primary.main.gray["800"]}
                    >
                      {formatAmount(
                        historyItem?.fee,
                        historyItem?.currency?.sign || ""
                      )}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {[
                "salary",
                "other-in",
                "other-out",
                "other",
                "reverse",
                "transfer-in",
                "cash-out",
                "cash-in",
                "cash-in-external",
                "transfer-out",
                "payment",
              ].includes(historyItem?.type) && (
                <>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      variant="text_3"
                      color={theme.primary.main.gray["300"]}
                    >
                      Дата и время
                    </Typography>
                    <Box display="flex" flexDirection="row" gap="4px">
                      <Typography
                        variant="text_5"
                        fontWeight="500"
                        color={theme.primary.main.gray["800"]}
                      >
                        {new Date(historyItem?.time).toLocaleDateString(
                          "ru-RU",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }
                        )}
                      </Typography>
                      <Typography
                        variant="text_5"
                        fontWeight="500"
                        color={theme.primary.main.gray["800"]}
                      >
                        {new Date(historyItem?.time).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hourCycle: "h23",
                        })}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider />
                </>
              )}
              {historyItem?.type === "payment" && (
                <>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      variant="text_3"
                      color={theme.primary.main.gray["300"]}
                    >
                      MCC-код
                    </Typography>
                    <Typography
                      variant="text_5"
                      fontWeight="500"
                      color={theme.primary.main.gray["800"]}
                    >
                      {historyItem?.statementCategory?.name}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
              {[
                "other-in",
                "other-out",
                "other",
                "reverse",
                "payment",
              ].includes(historyItem?.type) && (
                <>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Typography
                      variant="text_3"
                      color={theme.primary.main.gray["300"]}
                    >
                      Название
                    </Typography>
                    <Typography
                      variant="text_5"
                      fontWeight="500"
                      color={theme.primary.main.gray["800"]}
                    >
                      {historyItem?.acquirer}
                    </Typography>
                  </Box>
                  <Divider />
                </>
              )}
            </Box>
            <Box
              width="100%"
              gap={12}
              mt={60}
              flexDirection="column"
              display="flex"
            >
              {["other-in", "transfer-out"].includes(historyItem?.type) &&
                canBeRepeated && (
                  <Button
                    sx={{ width: "100%" }}
                    variant="primary"
                    size="lg"
                    startIcon={<IconRefreshReverse />}
                    onClick={repeatPayment}
                    isLoading={repeatLoading}
                  >
                    Повторить
                  </Button>
                )}

              <Button
                onClick={() => downloadCheck()}
                sx={{ width: "100%" }}
                variant="secondary"
                size="lg"
                startIcon={<IconDownload />}
              >
                Скачать чек
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};
