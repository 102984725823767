import { FC } from "react";
import styled from "@emotion/styled/macro";
import theme from "theme";
import { ReactComponent as CloseIcon } from "../../../../assets/Close.svg";

const ResetButton = styled.div<Partial<OperationResetProps>>`
  background: ${(props) =>
    props.isChanged ? theme.palette.blue.b400 : theme.palette.gray.b50};
  padding: 10px 12px;
  border-radius: 12px;
  cursor: ${(props) => (props.isChanged ? "pointer" : "default")};
  color: ${(props) =>
    props.isChanged ? theme.primary.white : theme.palette.gray.b500};
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  align-items: center;
  & svg {
    width: 12px;
    height: 12px;
    & > path {
      fill: ${(props) =>
        props.isChanged ? theme.primary.white : theme.palette.gray.b500};
    }
  }
`;

interface OperationResetProps {
  isChanged: boolean;
  onReset: (fullReset: boolean) => void;
}

export const OperationReset: FC<OperationResetProps> = ({
  isChanged,
  onReset,
}) => (
  <ResetButton
    {...(isChanged && { onClick: () => onReset(true) })}
    isChanged={isChanged}
  >
    Сбросить <CloseIcon />
  </ResetButton>
);
