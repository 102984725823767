import * as React from "react";

const MiniatureCardBlocked = (props) => (
  <svg
    width={78}
    height={48}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <g filter="url(#b)">
        <path
          d="m6.023 36.545.08-2.187-1.85 1.173-.477-.835 1.948-1.014-1.948-1.014.477-.836 1.85 1.174-.08-2.188h.954l-.08 2.188 1.85-1.174.477.836-1.948 1.014 1.948 1.014-.477.835-1.85-1.173.08 2.188h-.954Zm7 0 .08-2.187-1.85 1.173-.477-.835 1.948-1.014-1.948-1.014.477-.836 1.85 1.174-.08-2.188h.954l-.08 2.188 1.85-1.174.477.836-1.948 1.014 1.948 1.014-.477.835-1.85-1.173.08 2.188h-.954Zm4.15 2.367v-1.014l4.474-7.08h.736v1.571h-.497l-3.38 5.35v.08h6.025v1.093h-7.358ZM21.965 41V30.818h1.174V41h-1.174Zm4.429 0v-.895l3.36-3.679c.395-.43.72-.805.975-1.123.255-.322.444-.623.567-.905a2.19 2.19 0 0 0 .189-.895c0-.358-.086-.668-.259-.93a1.676 1.676 0 0 0-.696-.606 2.257 2.257 0 0 0-.994-.214c-.391 0-.733.081-1.024.243-.289.16-.512.383-.671.672a2.102 2.102 0 0 0-.234 1.014h-1.173c0-.597.137-1.12.412-1.571.275-.451.65-.802 1.124-1.054a3.387 3.387 0 0 1 1.606-.378c.596 0 1.125.126 1.586.378.46.252.822.591 1.083 1.019.262.428.393.903.393 1.427 0 .374-.068.74-.204 1.099-.132.354-.364.75-.696 1.188-.328.434-.784.964-1.367 1.59l-2.287 2.447v.08h4.733V41h-6.423Zm8.795 0 4.553-9.009v-.08h-5.25v-1.093h6.523v1.154L36.481 41H35.19Zm8.271 0 4.554-9.009v-.08h-5.25v-1.093h6.523v1.154L44.753 41H43.46Z"
          fill="#fff"
        />
      </g>
      <g filter="url(#c)">
        <rect width={78} height={48} rx={5} fill="#FDA29B" fillOpacity={0.73} />
      </g>
      <circle cx={39} cy={24} r={12} fill="#fff" />
      <g clipPath="url(#d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.16 20.817c0-.744.2-1.23.467-1.522.255-.28.677-.495 1.374-.495.699 0 1.119.215 1.373.494.267.29.466.776.466 1.52v1.48h-3.68v-1.477Zm-1.902 1.51v-1.51c0-1.07.29-2.067.965-2.806.688-.751 1.662-1.113 2.778-1.113s2.09.361 2.777 1.112c.675.738.964 1.735.964 2.804v1.512a2.854 2.854 0 0 1 2.419 2.82v2.894a2.853 2.853 0 0 1-2.854 2.853h-6.614a2.853 2.853 0 0 1-2.853-2.853v-2.893a2.854 2.854 0 0 1 2.418-2.82Zm-.516 2.82c0-.525.426-.951.951-.951h6.614c.526 0 .952.426.952.95v2.894a.951.951 0 0 1-.952.951h-6.614a.951.951 0 0 1-.95-.95v-2.894Z"
          fill="#F97066"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <rect width={78} height={48} rx={6} fill="#fff" />
      </clipPath>
      <clipPath id="d">
        <rect x={29} y={14} width={20} height={20} rx={10} fill="#fff" />
      </clipPath>
      <filter
        id="b"
        x={-2}
        y={22}
        width={82}
        height={28}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={1} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_363_40254"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_363_40254"
          result="shape"
        />
      </filter>
      <filter
        id="c"
        x={-7}
        y={-7}
        width={92}
        height={62}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation={3.5} />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_363_40254"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_363_40254"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default MiniatureCardBlocked;
