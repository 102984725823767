import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  margin-top: 30px;
  width: 1200px;

  @media (max-width: 1440px) {
    width: 100%;
  }
`;

export const Title = styled.p`
  margin: 0;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  color: #393e46;
  width: 100%;
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
  }
`;

export const Description = styled.p`
  margin: 0;
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  color: #475467;
  text-align: center;

  @media (max-width: 767px) {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 16px;
  }
`;

export const Image = styled.img`
  margin: 0;
  width: 100%;
  max-width: 426px;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    width: 150px;
    height: 150px;
  }
`;

export const InfoBlock = styled.div`
  padding: 12px 0px;
  border-bottom: 1px solid #ebedf3;
  width: 100%;
  max-width: 420px;
`;

export const InfoBlockTitle = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #777e8a;
`;

export const InfoBlockDescription = styled.p`
  margin: 0px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #475467;
  word-break: break-all;
`;
