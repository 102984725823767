import { AuthContainer } from "containers";
import { FC, useEffect, useRef, useState } from "react";
import { PinPad, PinValue } from "./components";
import { PIN_LENGTH } from "./PinCode";
import { loginPin } from "store/slices/auth/asyncThunks";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsMobile } from "hooks/useIsMobile";
import { useIsPWA } from "hooks/useIsPWA";
import PinInput from "react-pin-input";
import {
  MobileTabVariants,
  TabVariants,
  systemActions,
} from "store/slices/system";
import {
  authActions,
  biometryAuth,
  biometryAuthConfirm,
} from "store/slices/auth";
import DeviceDetector from "device-detector-js";
import {
  parseRequestOptionsFromJSON,
  get,
} from "@github/webauthn-json/browser-ponyfill";
import { Box, Button, Typography } from "@mui/material";
import { alertsSelector, authSelector } from "store";
import { showErrorMessage } from "../../store/slices/alerts";
import { Loader } from "components";
import { profileActions } from "../../store/slices/profile";
import { deleteFCMToken, getFCMToken } from "../../firebase";
import { handleCheckPushStatus } from "store/slices/notifications";

export const PinEnter: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();
  const [pinCode, setPinCode] = useState("");
  const [value, setValue] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [error, setError] = useState("");
  const [useBiometry, setUseBiometry] = useState(
    Boolean(localStorage.getItem("useBiometry"))
  );
  const [biometryProcessing, setBiometryProcessing] = useState(false);
  const isPinComplete = pinCode.length === PIN_LENGTH;
  const { isLoading } = useSelector(authSelector);
  const { errorTitle } = useSelector(alertsSelector);
  let ele = useRef();
  const handleChange = (code?: string) => {
    setError("");
    setErrorStatus(false);
    setPinCode(code ? `${pinCode}${code}` : pinCode.slice(0, -1));
  };

  const handleChangeWeb = (code?: string) => {
    setError("");
    setErrorStatus(false);
    if (code?.length === PIN_LENGTH) {
      setPinCode(code ? `${pinCode}${code}` : pinCode.slice(0, -1));
    }
  };

  const deviceIdPWA = localStorage.getItem("PWADeviceId");

  useEffect(() => {
    if (errorTitle !== "Срок действия сессии истек") {
      setError(errorTitle);
    }
  }, [errorTitle]);

  useEffect(() => {
    if (isPinComplete) {
      setTimeout(() => {
        dispatch(loginPin({ pinCode, isPWA }))
          //@ts-ignore
          .unwrap()
          .then(() => {
            setPinCode("");
            dispatch(authActions.setIsPinAllowed(true));
            isMobile
              ? dispatch(
                  systemActions.setActiveTab({ tab: MobileTabVariants.main })
                )
              : dispatch(
                  systemActions.setActiveTab({ tab: TabVariants.transfers })
                );

            navigate("/", { replace: true });
          })
          .catch((err) => {
            setErrorStatus(true);
            setError(err.response.data.title);
            setPinCode("");
            if (err.response.status === 400) {
              setErrorStatus(true);
            }
            setValue("");
          });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPinComplete]);

  // useEffect(() => {
  //   getFCMToken().then((res) => {
  //     dispatch(handleCheckPushStatus({ fcm: res, deviceId: deviceIdPWA || "" }))
  //       //@ts-ignore
  //       .unwrap()
  //       .then((res) => {
  //         if (res.enabled) {
  //           localStorage.setItem("isPushOn", "true");
  //         } else {
  //           localStorage.removeItem("isPushOn");
  //         }
  //       });
  //   });
  // }, [dispatch, deviceIdPWA]);

  const onBiometricsSelect = async () => {
    const deviceId = isPWA
      ? localStorage.getItem("PWADeviceId")
      : localStorage.getItem("deviceId");
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const deviceData = deviceDetector.parse(userAgent);
    const device = {
      brand: deviceData.device?.brand,
      baseOs: deviceData.os?.name,
      deviceId: deviceId,
      manufacturer: deviceData.device?.brand || "—",
      deviceType: "W",
      systemName: deviceData.os?.name,
      systemVersion:
        deviceData.os?.version ||
        `${deviceData.os?.name} ${deviceData.os?.platform}`,
      deviceName: deviceData.client?.name,
      version: deviceData.client?.version,
    };
    const login = localStorage.getItem("login") || "";
    dispatch(
      biometryAuth({
        login: login,
        //@ts-ignore
        device: device,
      })
    )
      //@ts-ignore
      .unwrap()
      .then(async (res) => {
        const message = res.publicKeyCredentialRequestOptions;
        const assertionId = res.assertionId;
        const optionsJson = parseRequestOptionsFromJSON({
          publicKey: message,
        });
        const copyOpt = {
          ...optionsJson,
          publicKey: {
            ...optionsJson.publicKey,
            extensions: {
              appId: optionsJson.publicKey?.extensions?.appid,
            },
          },
        };
        try {
          //@ts-ignore
          const isCredetialsExists = await get(copyOpt);
          const params = {
            assertionToken: assertionId,
            credential: isCredetialsExists,
            timeZone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
            device: device,
          };
          // @ts-ignore
          dispatch(biometryAuthConfirm(params))
            //@ts-ignore
            .unwrap()
            .then(() => {
              dispatch(authActions.setIsPinAllowed(true));
              dispatch(profileActions.setBiometryConnected("logon"));
              navigate("/", { replace: true });
            });
        } catch (err: any) {
          dispatch(authActions.setIsLoading(false));
          setBiometryProcessing(false);
          dispatch(
            showErrorMessage({
              errorTitle: "Вход не удался",
              errorMessage:
                "При попытке входа с использованием биометрии произошла ошибка. Выполните вход другим способом",
            })
          );
        }
      });
  };

  useEffect(() => {
    setUseBiometry(Boolean(localStorage.getItem("useBiometry")));
    if (!biometryProcessing && useBiometry && isPWA) {
      setBiometryProcessing(true);
      onBiometricsSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = async () => {
    // dispatch(authActions.setIsPinForgotten(true));
    // dispatch(hideErrorMessage());
    // const deviceIdItem = localStorage.getItem("deviceId");
    // const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
    // localStorage.clear();
    // if (deviceIdItem !== null) {
    //   localStorage.setItem("deviceId", deviceIdItem);
    // }
    // if (PWAdeviceIdItem !== null) {
    //   localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
    // }
    // sessionStorage.clear();
    //TODO : ПОКА ЧТО ОСТАВИЛ ЛОГИКУ ВЫХОДА ТК ВОЗМОЖНО ДОБАВЯТ КНОПКУ СМЕНЫ ПОЛЬЗОВАТЕЛЯ
    dispatch(authActions.setIsCodeForgotten());
    navigate("/sign-in", { replace: true });
  };

  return (
    <>
      <AuthContainer
        isLeft={false}
        title="Вход"
        {...(errorStatus && { error: "Неверный код" })}
        subtitle="Введите код"
        error={error && error !== "Срок действия сессии истек" ? error : ""}
      >
        {isMobile ? (
          <>
            <PinValue
              isLoading={isLoading}
              value={pinCode}
              isError={errorStatus}
            />
            <PinPad
              isLoading={isLoading}
              {...(!isPinComplete && {
                onChange: handleChange,
                onBiometricsSelect:
                  useBiometry && isPWA ? onBiometricsSelect : undefined,
              })}
            />
          </>
        ) : (
          <>
            {isLoading ? (
              <Loader size={68} />
            ) : (
              <PinInput
                length={4}
                initialValue=""
                secret
                //@ts-ignore
                ref={ele}
                secretDelay={100}
                onChange={(value, index) => {
                  setValue(value);
                  setErrorStatus(false);
                }}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{
                  borderColor: errorStatus ? "#F97066" : "#D0D5DD",
                  borderRadius: 12,
                }}
                inputFocusStyle={{
                  borderColor: errorStatus ? "#F97066" : "#D0D5DD",
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            )}

            <Button
              //@ts-ignore
              disabled={value.length !== 4}
              onClick={() => {
                handleChangeWeb(value);
              }}
              variant="primary"
              size="lg"
              fullWidth
              sx={{ mt: 38, mb: 8 }}
            >
              Продолжить
            </Button>
          </>
        )}
        <Box onClick={handleLogout} sx={{ cursor: "pointer" }}>
          <Typography variant="text_5" color="#0082BA" fontWeight="500">
            Забыли код?
          </Typography>
        </Box>
      </AuthContainer>
    </>
  );
};
