import React from "react";
import { useTheme } from "@mui/material";

export const IconKeyClosed = ({ ...props }) => {
  const theme = useTheme();
  const { blue } = theme.palette;
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6779_11838)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 8.25372C9 7.12561 9.30323 6.33057 9.76392 5.82694C10.2126 5.33648 10.922 5 12.0019 5C13.0823 5 13.7905 5.33594 14.2379 5.82508C14.6974 6.32749 15 7.12138 15 8.25006V10H9V8.25372ZM7 10V8.25372C7 6.78216 7.39788 5.45034 8.2882 4.47703C9.19058 3.49055 10.4821 3 12.0019 3C13.521 3 14.8119 3.4893 15.7137 4.47529C16.6034 5.44801 17 6.77915 17 8.25006V10C18.6569 10 20 11.3432 20 13V18C20 19.6569 18.6569 21 17 21H7C5.34315 21 4 19.6569 4 18V13C4 11.3432 5.34315 10 7 10ZM6 13C6 12.4477 6.44772 12 7 12H17C17.5523 12 18 12.4477 18 13V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18V13Z"
          fill={props.color || blue.b400}
        />
      </g>
      <defs>
        <clipPath id="clip0_6779_11838">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconKeyClosed;
