import React from "react";

export const IconOutlineCard = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="56" height="56" rx="28" fill="white" />
      <path
        d="M13.333 25.3333H42.6663M13.333 21.3333C13.333 19.1242 15.1239 17.3333 17.333 17.3333H38.6663C40.8755 17.3333 42.6663 19.1242 42.6663 21.3333V36C42.6663 38.2091 40.8755 40 38.6663 40H17.333C15.1239 40 13.333 38.2091 13.333 36V21.3333Z"
        stroke="#0082BA"
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconOutlineCard;
