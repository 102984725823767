import React from "react";

const IconNoNotifications = (props) => {
  return (
    <svg
      width="200"
      height="150"
      viewBox="0 0 200 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M100.5 150C141.921 150 175.5 116.421 175.5 75C175.5 33.5786 141.921 0 100.5 0C59.0786 0 25.5 33.5786 25.5 75C25.5 116.421 59.0786 150 100.5 150Z"
        fill="url(#paint0_linear_8368_157395)"
      />
      <g filter="url(#filter0_d_8368_157395)">
        <path
          d="M146 10H11C8.23858 10 6 12.2386 6 15V40C6 42.7614 8.23858 45 11 45H146C148.761 45 151 42.7614 151 40V15C151 12.2386 148.761 10 146 10Z"
          fill="white"
        />
      </g>
      <path
        d="M75 18H49C47.3431 18 46 19.3431 46 21C46 22.6569 47.3431 24 49 24H75C76.6569 24 78 22.6569 78 21C78 19.3431 76.6569 18 75 18Z"
        fill="#EBEDF3"
      />
      <path
        d="M93 31H49C47.3431 31 46 32.3431 46 34C46 35.6569 47.3431 37 49 37H93C94.6569 37 96 35.6569 96 34C96 32.3431 94.6569 31 93 31Z"
        fill="#EBEDF3"
      />
      <path
        d="M33 13H14C11.2386 13 9 15.2386 9 18V37C9 39.7614 11.2386 42 14 42H33C35.7614 42 38 39.7614 38 37V18C38 15.2386 35.7614 13 33 13Z"
        fill="#0082BA"
      />
      <path
        d="M21.2222 30.7812H23.2222L23.2442 30.0112C23.2442 28.9992 23.8822 28.7352 24.7842 28.4492C25.689 28.1918 26.4854 27.6469 27.053 26.8967C27.6206 26.1465 27.9285 25.2319 27.9302 24.2912C27.95 23.7169 27.8504 23.1447 27.6376 22.6108C27.4248 22.077 27.1036 21.5931 26.6941 21.1899C26.2846 20.7866 25.7959 20.4727 25.2589 20.2681C24.7219 20.0635 24.1482 19.9727 23.5742 20.0012C22.9676 19.988 22.3647 20.0981 21.8019 20.3249C21.2392 20.5517 20.7284 20.8905 20.3005 21.3206C19.8726 21.7508 19.5365 22.2633 19.3126 22.8273C19.0888 23.3912 18.9818 23.9947 18.9982 24.6012H21.0662C21.0584 24.2663 21.1174 23.9333 21.24 23.6215C21.3625 23.3098 21.546 23.0256 21.7798 22.7857C22.0136 22.5458 22.2929 22.3549 22.6013 22.2243C22.9098 22.0938 23.2412 22.0261 23.5762 22.0252C23.8783 22.0077 24.1806 22.0554 24.4626 22.1652C24.7446 22.2749 24.9996 22.444 25.2105 22.6611C25.4213 22.8782 25.5829 23.1381 25.6843 23.4231C25.7857 23.7082 25.8246 24.0118 25.7982 24.3132C25.7849 24.8011 25.6152 25.2718 25.3142 25.656C25.0132 26.0402 24.5968 26.3176 24.1262 26.4472L23.4002 26.7352C22.6894 26.9065 22.0689 27.3384 21.6614 27.9455C21.254 28.5526 21.0894 29.2905 21.2002 30.0132L21.2222 30.7812ZM22.2122 35.5352C22.4081 35.5422 22.6033 35.5098 22.7864 35.44C22.9695 35.3702 23.1368 35.2644 23.2783 35.1288C23.4198 34.9933 23.5327 34.8307 23.6104 34.6508C23.688 34.4709 23.7287 34.2772 23.7302 34.0812C23.7285 33.8854 23.6875 33.692 23.6098 33.5123C23.532 33.3326 23.4191 33.1703 23.2776 33.0349C23.1361 32.8996 22.9689 32.7939 22.786 32.7243C22.603 32.6546 22.4079 32.6223 22.2122 32.6292C22.0165 32.6223 21.8215 32.6546 21.6385 32.7243C21.4555 32.7939 21.2883 32.8996 21.1468 33.0349C21.0053 33.1703 20.8924 33.3326 20.8147 33.5123C20.7369 33.692 20.696 33.8854 20.6942 34.0812C20.6957 34.2772 20.7365 34.4709 20.8141 34.6508C20.8917 34.8307 21.0046 34.9933 21.1461 35.1288C21.2876 35.2644 21.4549 35.3702 21.638 35.44C21.8211 35.5098 22.0164 35.5422 22.2122 35.5352V35.5352Z"
        fill="white"
      />
      <g filter="url(#filter1_d_8368_157395)">
        <path
          d="M54 53H189C190.326 53 191.598 53.5268 192.536 54.4645C193.473 55.4021 194 56.6739 194 58V83C194 84.3261 193.473 85.5979 192.536 86.5355C191.598 87.4732 190.326 88 189 88H54C52.6739 88 51.4021 87.4732 50.4645 86.5355C49.5268 85.5979 49 84.3261 49 83V58C49 56.6739 49.5268 55.4021 50.4645 54.4645C51.4021 53.5268 52.6739 53 54 53V53Z"
          fill="white"
        />
      </g>
      <path
        d="M118 61H92C90.3431 61 89 62.3431 89 64C89 65.6569 90.3431 67 92 67H118C119.657 67 121 65.6569 121 64C121 62.3431 119.657 61 118 61Z"
        fill="#EBEDF3"
      />
      <path
        d="M136 74H92C90.3431 74 89 75.3431 89 77C89 78.6569 90.3431 80 92 80H136C137.657 80 139 78.6569 139 77C139 75.3431 137.657 74 136 74Z"
        fill="#EBEDF3"
      />
      <path
        d="M76 56H57C54.2386 56 52 58.2386 52 61V80C52 82.7614 54.2386 85 57 85H76C78.7614 85 81 82.7614 81 80V61C81 58.2386 78.7614 56 76 56Z"
        fill="#0082BA"
      />
      <path
        d="M64.2222 73.7812H66.2222L66.2442 73.0112C66.2442 71.9992 66.8822 71.7352 67.7842 71.4492C68.689 71.1918 69.4854 70.6469 70.053 69.8967C70.6206 69.1465 70.9285 68.2319 70.9302 67.2912C70.95 66.7169 70.8504 66.1447 70.6376 65.6108C70.4248 65.077 70.1036 64.5931 69.6941 64.1899C69.2846 63.7866 68.7959 63.4727 68.2589 63.2681C67.7219 63.0635 67.1482 62.9727 66.5742 63.0012C65.9676 62.988 65.3647 63.0981 64.8019 63.3249C64.2392 63.5517 63.7284 63.8905 63.3005 64.3206C62.8726 64.7508 62.5365 65.2633 62.3126 65.8273C62.0888 66.3912 61.9818 66.9947 61.9982 67.6012H64.0662C64.0584 67.2663 64.1174 66.9333 64.24 66.6215C64.3625 66.3098 64.546 66.0256 64.7798 65.7857C65.0136 65.5458 65.2929 65.3549 65.6013 65.2243C65.9098 65.0938 66.2412 65.0261 66.5762 65.0252C66.8783 65.0077 67.1806 65.0554 67.4626 65.1652C67.7446 65.2749 67.9996 65.444 68.2105 65.6611C68.4213 65.8782 68.5829 66.1381 68.6843 66.4231C68.7857 66.7082 68.8246 67.0118 68.7982 67.3132C68.7849 67.8011 68.6152 68.2718 68.3142 68.656C68.0132 69.0402 67.5968 69.3176 67.1262 69.4472L66.4002 69.7352C65.6894 69.9065 65.0689 70.3384 64.6614 70.9455C64.254 71.5526 64.0894 72.2905 64.2002 73.0132L64.2222 73.7812ZM65.2122 78.5352C65.4081 78.5422 65.6033 78.5098 65.7864 78.44C65.9695 78.3702 66.1368 78.2644 66.2783 78.1288C66.4198 77.9933 66.5327 77.8307 66.6104 77.6508C66.688 77.4709 66.7287 77.2772 66.7302 77.0812C66.7285 76.8854 66.6875 76.692 66.6098 76.5123C66.532 76.3326 66.4191 76.1703 66.2776 76.0349C66.1361 75.8996 65.9689 75.7939 65.786 75.7243C65.603 75.6546 65.4079 75.6223 65.2122 75.6292C65.0165 75.6223 64.8215 75.6546 64.6385 75.7243C64.4555 75.7939 64.2883 75.8996 64.1468 76.0349C64.0053 76.1703 63.8924 76.3326 63.8147 76.5123C63.7369 76.692 63.696 76.8854 63.6942 77.0812C63.6957 77.2772 63.7365 77.4709 63.8141 77.6508C63.8917 77.8307 64.0046 77.9933 64.1461 78.1288C64.2876 78.2644 64.4549 78.3702 64.638 78.44C64.8211 78.5098 65.0164 78.5422 65.2122 78.5352V78.5352Z"
        fill="white"
      />
      <g filter="url(#filter2_d_8368_157395)">
        <path
          d="M11 96H146C147.326 96 148.598 96.5268 149.536 97.4645C150.473 98.4021 151 99.6739 151 101V126C151 127.326 150.473 128.598 149.536 129.536C148.598 130.473 147.326 131 146 131H11C9.67392 131 8.40215 130.473 7.46447 129.536C6.52678 128.598 6 127.326 6 126V101C6 99.6739 6.52678 98.4021 7.46447 97.4645C8.40215 96.5268 9.67392 96 11 96V96Z"
          fill="white"
        />
      </g>
      <path
        d="M75 104H49C47.3431 104 46 105.343 46 107C46 108.657 47.3431 110 49 110H75C76.6569 110 78 108.657 78 107C78 105.343 76.6569 104 75 104Z"
        fill="#EBEDF3"
      />
      <path
        d="M93 117H49C47.3431 117 46 118.343 46 120C46 121.657 47.3431 123 49 123H93C94.6569 123 96 121.657 96 120C96 118.343 94.6569 117 93 117Z"
        fill="#EBEDF3"
      />
      <path
        d="M33 99H14C11.2386 99 9 101.239 9 104V123C9 125.761 11.2386 128 14 128H33C35.7614 128 38 125.761 38 123V104C38 101.239 35.7614 99 33 99Z"
        fill="#0082BA"
      />
      <path
        d="M21.2222 116.781H23.2222L23.2442 116.011C23.2442 114.999 23.8822 114.735 24.7842 114.449C25.689 114.192 26.4854 113.647 27.053 112.897C27.6206 112.147 27.9285 111.232 27.9302 110.291C27.95 109.717 27.8504 109.145 27.6376 108.611C27.4248 108.077 27.1036 107.593 26.6941 107.19C26.2846 106.787 25.7959 106.473 25.2589 106.268C24.7219 106.064 24.1482 105.973 23.5742 106.001C22.9676 105.988 22.3647 106.098 21.8019 106.325C21.2392 106.552 20.7284 106.89 20.3005 107.321C19.8726 107.751 19.5365 108.263 19.3126 108.827C19.0888 109.391 18.9818 109.995 18.9982 110.601H21.0662C21.0584 110.266 21.1174 109.933 21.24 109.622C21.3625 109.31 21.546 109.026 21.7798 108.786C22.0136 108.546 22.2929 108.355 22.6013 108.224C22.9098 108.094 23.2412 108.026 23.5762 108.025C23.8783 108.008 24.1806 108.055 24.4626 108.165C24.7446 108.275 24.9996 108.444 25.2105 108.661C25.4213 108.878 25.5829 109.138 25.6843 109.423C25.7857 109.708 25.8246 110.012 25.7982 110.313C25.7849 110.801 25.6152 111.272 25.3142 111.656C25.0132 112.04 24.5968 112.318 24.1262 112.447L23.4002 112.735C22.6894 112.906 22.0689 113.338 21.6614 113.946C21.254 114.553 21.0894 115.291 21.2002 116.013L21.2222 116.781ZM22.2122 121.535C22.4081 121.542 22.6033 121.51 22.7864 121.44C22.9695 121.37 23.1368 121.264 23.2783 121.129C23.4198 120.993 23.5327 120.831 23.6104 120.651C23.688 120.471 23.7287 120.277 23.7302 120.081C23.7285 119.885 23.6875 119.692 23.6098 119.512C23.532 119.333 23.4191 119.17 23.2776 119.035C23.1361 118.9 22.9689 118.794 22.786 118.724C22.603 118.655 22.4079 118.622 22.2122 118.629C22.0165 118.622 21.8215 118.655 21.6385 118.724C21.4555 118.794 21.2883 118.9 21.1468 119.035C21.0053 119.17 20.8924 119.333 20.8147 119.512C20.7369 119.692 20.696 119.885 20.6942 120.081C20.6957 120.277 20.7365 120.471 20.8141 120.651C20.8917 120.831 21.0046 120.993 21.1461 121.129C21.2876 121.264 21.4549 121.37 21.638 121.44C21.8211 121.51 22.0164 121.542 22.2122 121.535V121.535Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_8368_157395"
          x="0"
          y="7"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8368_157395"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8368_157395"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_8368_157395"
          x="43"
          y="50"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8368_157395"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8368_157395"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_8368_157395"
          x="0"
          y="93"
          width="157"
          height="47"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8368_157395"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8368_157395"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8368_157395"
          x1="100.5"
          y1="-2.75704e-06"
          x2="173.768"
          y2="129.337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EEF0F5" />
          <stop offset="1" stopColor="#E5EEF2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IconNoNotifications;
