import * as React from "react";

export const IconHistoryTab = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 2.251c-5.385 0-9.75 4.366-9.75 9.75 0 5.385 4.365 9.75 9.75 9.75 5.384 0 9.75-4.365 9.75-9.75 0-5.384-4.366-9.75-9.75-9.75Zm4.5 11.25h-4.5a.75.75 0 0 1-.75-.75v-6.75a.75.75 0 1 1 1.5 0v6H17a.75.75 0 1 1 0 1.5Z"
      fill={props?.color || "#C2C9D6"}
    />
  </svg>
);
