export const IconBlockedWeb = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={376}
      height={150}
      fill="none"
    >
      <path fill="#D9D9D9" d="M0 0h376v150H0z" />
      <g clipPath="url(#a)">
        <path fill="#fff" d="M-532-251H908V783H-532z" />
        <g clipPath="url(#b)">
          <rect width={376} height={150} fill="#FFEEED" rx={15} />
          <path
            fill="#FFEEED"
            d="M188 150c41.421 0 75-33.579 75-75S229.421 0 188 0s-75 33.579-75 75 33.579 75 75 75Z"
          />
          <g filter="url(#c)">
            <rect width={241} height={121} x={67} y={34} fill="#fff" rx={7} />
            <rect
              width={232}
              height={114}
              x={72}
              y={38}
              fill="#FFEEED"
              rx={5}
            />
          </g>
          <path
            fill="#FDA29B"
            d="M188 123c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24Z"
          />
          <path
            fill="#fff"
            d="M197.524 108.527a2 2 0 0 1-2.828 0l-7.071-7.071-7.071 7.071a2 2 0 1 1-2.828-2.829l7.071-7.07-7.071-7.072a2 2 0 1 1 2.828-2.828l7.071 7.07 7.071-7.07a2 2 0 1 1 2.828 2.828l-7.071 7.071 7.071 7.071a1.999 1.999 0 0 1 0 2.829Z"
          />
          <rect width={11} height={2} x={181.5} y={44} fill="#fff" rx={1} />
          <rect width={2} height={2} x={193.5} y={44} fill="#fff" rx={1} />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M-532-251H908V783H-532z" />
        </clipPath>
        <clipPath id="b">
          <rect width={376} height={150} fill="#fff" rx={15} />
        </clipPath>
        <filter
          id="c"
          width={253}
          height={133}
          x={61}
          y={25}
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy={-3} />
          <feGaussianBlur stdDeviation={3} />
          <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9_8985"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_9_8985"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
