import { SelectHTMLAttributes, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import theme from "theme";
import classNames from "classnames";
import CheckIcon from "./assets/Check.svg";
import { ReactComponent as DownIcon } from "./assets/Down.svg";
import { ReactComponent as UpIcon } from "./assets/Up.svg";

export interface SelectOptionProps {
  [x: string]: string;
}

export interface SelectProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, "onChange"> {
  value: string;
  defaultValue?: string;
  error?: boolean;
  disabled?: boolean;
  className?: string;
  label?: string;
  hint?: string;
  options: SelectOptionProps[];
  optionKey?: keyof SelectOptionProps;
  optionValue?: string;
  onChange(option: string): void;
}

const SelectValue = styled.div`
  border: 1px solid ${theme.palette.gray.b300};
  font-size: 16px;
  padding: 14px;
  border-radius: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding-right: 50px;

  .placeholder {
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.palette.gray.b400};
  }

  &:focus:not(.with-error):not(.disabled) {
    border-color: ${theme.palette.indigo.b300};
  }
`;

const SelectContainer = styled.div`
  font-family: Inter;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  position: relative;
  cursor: pointer;
  label {
    font-size: 14px;
    color: ${theme.palette.gray.b700};
  }

  .hint {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${theme.palette.gray.b500};
  }

  &.disabled {
    cursor: default;
    ${SelectValue} {
      color: ${theme.palette.gray.b400};
      background-color: ${theme.palette.gray.b50};
    }
    svg {
      z-index: 0;
    }
    .hint {
      color: ${theme.palette.gray.b400};
    }
  }

  &.error {
    ${SelectValue} {
      border-color: ${theme.palette.red.b300};
      padding-right: 50px;
    }
    .hint {
      color: ${theme.palette.red.b500};
    }
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  svg {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -4px;
    z-index: -1;
  }
`;

const SelectDropdown = styled.div`
  background: ${theme.primary.white};
  border-radius: 16px;
  overflow: hidden;
  position: absolute;
  top: calc(100% + 14px);
  width: 100%;
  box-shadow: 0px 2px 16px -4px rgba(16, 24, 40, 0.1),
    0px 4px 6px -2px rgba(16, 24, 40, 0.05);
  z-index: 1;
`;

const DropdownOption = styled.div<{ isSelected: boolean }>`
  position: relative;
  padding: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 50px;
  width: calc(100% - 63px);
  color: ${theme.palette.gray.b900};
  background: ${(props) =>
    props.isSelected ? theme.palette.indigo.b25 : "transparent"};
  &:after {
    position: absolute;
    content: "";
    right: 18px;
    top: 50%;
    margin-top: -5px;
    width: 16px;
    height: 11px;
    background: ${(props) =>
      props.isSelected ? `url(${CheckIcon}) no-repeat center center` : "none"};
  }
  &:hover {
    background: ${theme.palette.indigo.b50};
    color: ${theme.palette.indigo.b600};
  }
`;

export const Select: React.FC<SelectProps> = ({
  value,
  label,
  hint,
  error,
  className,
  defaultValue,
  options,
  placeholder,
  disabled,
  optionKey = "id",
  optionValue = "title",
  onChange,
}: SelectProps) => {
  useEffect(() => {
    defaultValue && onChange?.(defaultValue);
  }, [onChange, defaultValue]);

  const [isDropped, setDropped] = useState<boolean>(false);
  const selected = options?.find((option) => option[optionKey] === value);
  const isSelected = (option: SelectOptionProps) =>
    option[optionKey] === selected?.[optionKey];

  const handleMouseDown = () => {
    !disabled && setDropped(!isDropped);
  };
  const handleClickOutside = () => {
    document.removeEventListener("mousedown", handleClickOutside);
    setDropped(false);
  };
  const handleMouseLeave = () => {
    document.addEventListener("mousedown", handleClickOutside);
  };
  const handleMouseEnter = () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
  const handleOptionSelect = (option: string) => {
    setDropped(false);
    onChange?.(option);
  };

  return (
    <SelectContainer className={classNames(className, { error, disabled })}>
      {label && <label>{label}</label>}
      <SelectWrapper
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter}
      >
        {isDropped ? <UpIcon /> : <DownIcon />}
        <SelectValue
          tabIndex={0}
          onMouseDown={handleMouseDown}
          className={classNames({ "with-error": error, disabled })}
        >
          {selected
            ? selected?.[optionValue]
            : placeholder && <div className="placeholder">{placeholder}</div>}
        </SelectValue>
        {isDropped && (
          <SelectDropdown>
            {options?.map((option) => (
              <DropdownOption
                isSelected={isSelected(option)}
                key={option[optionKey]}
                onClick={() => handleOptionSelect(option[optionKey])}
              >
                {option[optionValue]}
              </DropdownOption>
            ))}
          </SelectDropdown>
        )}
      </SelectWrapper>
      {hint && <div className="hint">{hint}</div>}
    </SelectContainer>
  );
};
