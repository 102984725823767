import React from "react";
import { useTheme } from "@mui/material";

const IconVisibilityOff = (props) => {
  const theme = useTheme();
  const { gray } = theme.palette;
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M11.921 12.865A7.336 7.336 0 0 1 .787 8a7.321 7.321 0 0 1 2.227-4.044L.928 1.872 1.87.929l13.2 13.2-.944.942-2.207-2.206h.001ZM3.956 4.9A5.977 5.977 0 0 0 2.148 8a6.004 6.004 0 0 0 8.801 3.892L9.597 10.54A3 3 0 0 1 5.46 6.403L3.956 4.9ZM8.61 9.552 6.448 7.391a1.667 1.667 0 0 0 2.16 2.16l.001.001Zm5.262 1.51-.954-.954A5.956 5.956 0 0 0 13.851 8a6.004 6.004 0 0 0-7.483-4.441L5.316 2.507A7.336 7.336 0 0 1 15.213 8a7.297 7.297 0 0 1-1.342 3.061ZM7.815 5.004a3 3 0 0 1 3.18 3.18l-3.18-3.18Z"
        fill={gray.b400}
      />
    </svg>
  );
};

export default IconVisibilityOff;
