import SuccessImage from "./assets/Success.png";
import MobileSuccessImage from "./assets/MobileSuccess.png";
import ErrorImage from "./assets/Error.png";
import MobileErrorImage from "./assets/MobileError.png";
import {
  Container,
  Description,
  Image,
  InfoBlock,
  InfoBlockDescription,
  InfoBlockTitle,
  Title,
  Wrapper,
} from "./components";
import { OrderInfo, OrderStatus } from "api/account";
import { format } from "date-fns";
import { useIsMobile } from "hooks/useIsMobile";

interface RefillInfoCardProps {
  refill: OrderInfo | null;
}

const createAmount = (amount: string) => {
  const amountArr = amount.split("");
  amountArr.splice(amountArr.length - 2, 0, ",");
  return amountArr.join("");
};

const RefillInfoCard = ({ refill }: RefillInfoCardProps) => {
  const { isMobile } = useIsMobile();
  const isSuccess = refill?.status === OrderStatus.Approved;
  const successImage = isMobile ? MobileSuccessImage : SuccessImage;
  const errorImage = isMobile ? MobileErrorImage : ErrorImage;

  const amount = refill?.amount ? createAmount(refill.amount) : "";
  const hideAmount = !amount || amount === "0" || amount === ",0";

  const date = refill?.createDate
    ? format(new Date(refill.createDate), "dd.MM.yyyy")
    : "";

  const title = isSuccess
    ? "Карта успешно пополнена"
    : "Ошибка. Операция отклонена";

  const description = isSuccess
    ? "Баланс в приложении отобразится в течение дня"
    : "";

  return (
    <Wrapper>
      <Container>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        <Image src={isSuccess ? successImage : errorImage} />
        {!hideAmount && (
          <InfoBlock>
            <InfoBlockTitle>Сумма</InfoBlockTitle>
            <InfoBlockDescription>{amount}₽</InfoBlockDescription>
          </InfoBlock>
        )}
        <InfoBlock>
          <InfoBlockTitle>Дата выполнения операции</InfoBlockTitle>
          <InfoBlockDescription>{date}</InfoBlockDescription>
        </InfoBlock>
        <InfoBlock>
          <InfoBlockTitle>ID сессии</InfoBlockTitle>
          <InfoBlockDescription>{refill?.sessionId}</InfoBlockDescription>
        </InfoBlock>
        <InfoBlock>
          <InfoBlockTitle>ID заказа</InfoBlockTitle>
          <InfoBlockDescription>{refill?.id}</InfoBlockDescription>
        </InfoBlock>
      </Container>
    </Wrapper>
  );
};

export default RefillInfoCard;
