import { Box } from "@mui/material";
import { CARD_STATUS } from "components/CardsInfo/model";
import IconKeyClosed from "components/Icons/IconKeyClosed";
import IconSnow from "components/Icons/IconSnow";
import { useEffect, useMemo, useState } from "react";
// @ts-ignore
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import BackSideCard from "components/CardFullSize/BackSideCard";
import { CARD_ACTION, setScreen } from "../../../../store/slices/cardsSlice";

import { CardButtons } from "./CardButtons";
import { CardsSlider } from "./CardsSlider";
import { CardsName } from "./CardsName";

export const CardInfo = () => {
  const [isIndex, setIsIndex] = useState(0);
  const {
    cards: { allCards, chosenCardIndex },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const handleClick = (id: CARD_ACTION) => {
    dispatch(setScreen(id));
  };

  const dataIcon1 = useMemo(
    () => [
      {
        id: CARD_ACTION.LOST,
        title: "Заблокировать",
        svg: (color: string) => <IconKeyClosed color={color} />,
      },
      {
        id: CARD_ACTION.TEMPORARY,
        title: "Заморозить",
        svg: (color: string) => <IconSnow color={color} />,
      },
    ],
    []
  );

  const [dataIcon, setDataIcon] = useState(dataIcon1);

  useEffect(() => {
    if (
      allCards[isIndex].status === CARD_STATUS.Lost ||
      allCards[isIndex].status === CARD_STATUS.Stolen ||
      allCards[isIndex].status === CARD_STATUS.Temporary
    ) {
      setDataIcon(
        dataIcon.filter((el) => {
          el.title !== "Заморозить";
          el.title !== "Заблокировать";
        })
      );
    }
    if (allCards[isIndex].status === CARD_STATUS.Active) {
      setDataIcon(dataIcon1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataIcon1, allCards, isIndex]);

  return (
    <Box
      p={{ sm: "0px 20px", lg: "32px" }}
      display="flex"
      flexDirection="column"
    >
      <CardsName card={allCards[isIndex || chosenCardIndex]} />
      <CardsSlider
        chosenCardIndex={chosenCardIndex}
        setIsIndex={setIsIndex}
        allCards={allCards}
      />
      <CardButtons
        card={allCards[isIndex || chosenCardIndex]}
        dataIcon={dataIcon}
        handleClick={handleClick}
      />
      {/* @ts-ignore  */}
      <BackSideCard card={allCards[isIndex || chosenCardIndex]} />
    </Box>
  );
};
