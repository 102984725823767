import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";

import { Modal as ModalUI } from "molecules/Modal/Modal";
import Modal from "@mui/material/Modal";
import { ProfileIcon } from "./assets/Profile";
import { KeyIcon } from "./assets/Key";
import { LogoutIcon } from "./assets/Logout";
import { LoginModal, LogoutModal } from "./components";
import { authSelector, profileSelector, store } from "store";
import { profileActions } from "store/slices/profile";
import { useAuthCommonActions } from "hooks";
import { UpdatePassword } from "organisms/ModalContent";
import { Button, Loader, SkeletonContainer } from "../../components";
import { ProfileModalType } from "./models";
import { ProfileOption } from "./ProfileOption";
import { useIsMobile } from "hooks/useIsMobile";
import { IconPhone } from "./assets/IconPhone";
import { formatPhoneNumber } from "utils/formatPhoneNumber";
import { systemActions } from "../../store/slices/system";
import { News as NewsIcon } from "./assets/News";
import NotificationIcon from "./assets/NotificationIcon.png";
import { Notifications as NotificationsIcon } from "./assets/Notifications";
import { ProfileToggle } from "./ProfileToggle";
import { PinCodeIcon } from "./assets/PinCode";
import UpdatePinCode from "organisms/ModalContent/ModalProfile/UpdatePinCode";
import CreatePinCode from "organisms/ModalContent/ModalProfile/CreatePinCode";
import { useIsPWA } from "hooks/useIsPWA";
import { ProfileToggleBiometry } from "./ProfileToggleBiometry";
import { authWebApi } from "api";
import { authActions } from "store/slices/auth";
import { ProfileTogglePush } from "./ProfileTogglePush";
import { ProfileTogglesRender } from "./ProfileTogglesRender";

const ProfileWrapper = styled.div`
  font-family: "Inter";
  font-style: normal;
`;

const ProfileContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const AlertLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AlertLoginContent = styled.div`
  padding: 20px 20px 28px 20px;
  border-radius: 12px;
  background: #f9fafb;
  width: 100%;
  max-width: 450px;

  @media (max-width: 767px) {
    max-width: 300px;
  }
`;

const NotificationIconImg = styled.img`
  width: 93px;
  height: 90px;
`;

const AlertTitle = styled.p`
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: rgba(57, 62, 70, 1);

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const AlertDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(71, 84, 103, 1);
  margin-top: -10px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const AlertButtonsBlock = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const options = Object.freeze([
  {
    id: ProfileModalType.LOGIN,
    title: "Изменить логин",
    icon: <ProfileIcon />,
  },
  {
    id: ProfileModalType.PASSWORD,
    title: "Изменить пароль",
    icon: <KeyIcon />,
  },
  {
    id: ProfileModalType.PINCODE,
    title: "Создать код для входа",
    icon: <PinCodeIcon />,
  },
  {
    id: ProfileModalType.LOGOUT,
    title: "Выйти из аккаунта",
    icon: <LogoutIcon />,
  },
  {
    id: ProfileModalType.LOGOUT,
    title: "Выйти из аккаунта",
    icon: <ProfileTogglePush />,
  },
  {
    id: ProfileModalType.LOGOUT,
    title: "Выйти из аккаунта",
    icon: <ProfileToggleBiometry />,
  },
]);

const newsAndNotifications = Object.freeze([
  {
    id: ProfileModalType.news,
    title: "Новости",
    icon: <NewsIcon />,
  },
  {
    id: ProfileModalType.notifications,
    title: "Уведомления по заявкам",
    icon: <NotificationsIcon />,
  },
]);

export const Profile = ({ phoneNumber }: { phoneNumber: string }) => {
  const { isProfileLoading, user } = useSelector(profileSelector);
  const { isLoading, elseDevice, isPinAllowed, isPinUpdated } =
    useSelector(authSelector);
  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();
  const { handleLogout } = useAuthCommonActions();
  const dispatch = useDispatch();

  const [modalType, setModalType] = useState<ProfileModalType | null>(null);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);
  const handleCloseModal = useCallback(() => {
    setModalType(null);
  }, []);

  const handleOptionClick = (id: ProfileModalType) => {
    if (id === ProfileModalType.LOGIN) {
      setShowLoginAlert(true);
    }
    if (id === ProfileModalType.PASSWORD) {
      setShowPasswordAlert(true);
    }
    setModalType(id);
  };
  const handleMenuClick = (id: ProfileModalType) =>
    // @ts-ignore
    dispatch(systemActions.setActiveTab({ tab: id }));

  const handleClose = useCallback(() => {
    handleCloseModal();
    dispatch(profileActions.setIsLoginUpdated(false));
    dispatch(profileActions.setIsPasswordUpdated(false));
    dispatch(authActions.setIsPinUpdated(false));
  }, [dispatch, handleCloseModal]);

  const content = useMemo(
    () => ({
      [ProfileModalType.LOGIN]: (
        <LoginModal login={user?.login} onClose={handleClose} />
      ),
      [ProfileModalType.PASSWORD]: <UpdatePassword onClose={handleClose} />,
      [ProfileModalType.LOGOUT]: (
        <LogoutModal onClick={handleLogout} onCancel={handleCloseModal} />
      ),
      [ProfileModalType.PINCODE]:
        isPinAllowed && !elseDevice ? (
          <UpdatePinCode onClose={handleClose} />
        ) : (
          <CreatePinCode onClose={handleClose} />
        ),
    }),

    [
      handleClose,
      handleCloseModal,
      handleLogout,
      user?.login,
      isPinAllowed,
      elseDevice,
    ]
  );

  const alertBtnStyle = useMemo(
    () => ({
      width: isMobile ? "141px" : "200px",
      height: isMobile ? "36px" : "52px",
      borderRadius: "9px",
    }),
    [isMobile]
  );

  useEffect(() => {
    const checkPin = async () => {
      const {
        data: { pinAllowed },
      } = await authWebApi.checkPinAllowed({
        deviceId:
          (isPWA
            ? localStorage.getItem("PWADeviceId")
            : localStorage.getItem("deviceId")) || "",
      });
      dispatch(authActions.setIsPinAllowed(pinAllowed || false));
    };
    checkPin();
  }, [dispatch, isPWA]);
  const notificationsAllowed = true;
  return (
    <ProfileWrapper>
      {isLoading && isPWA && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#8DC3DB",
            zIndex: 100,
            opacity: 0.5,
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            verticalAlign: "center",
          }}
        >
          <Box
            sx={{
              height: isMobile ? 38 : 66,
              width: isMobile ? 38 : 66,
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Loader size={isMobile ? 38 : 66} color="#0082BA" />
          </Box>
        </Box>
      )}

      <ModalUI
        title={
          isMobile && modalType === ProfileModalType.PINCODE
            ? isPinAllowed && !elseDevice
              ? isPinUpdated
                ? ""
                : "Изменение короткого кода"
              : "Создайте короткий код для быстрого входа"
            : isPWA
            ? ""
            : "Профиль"
        }
        isOpen={Boolean(modalType)}
        onClose={handleClose}
        callback={handleClose}
        buttonVisible={true}
        centerText={modalType === ProfileModalType.PINCODE}
      >
        <Modal open={showLoginAlert}>
          <AlertLogin>
            <AlertLoginContent>
              <NotificationIconImg src={NotificationIcon} />
              <AlertTitle>
                После смены логина необходимо будет заново войти в приложение
              </AlertTitle>
              <AlertDescription>
                Нужно будет заново войти в личный кабинет с новым логином. На
                других устройствах в личный кабинет нужно будет также войти
                заново.
              </AlertDescription>
              <AlertButtonsBlock>
                <Button
                  size="sm"
                  variant="secondary"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    ...alertBtnStyle,
                  }}
                  onClick={() => {
                    setShowLoginAlert(false);
                    setModalType(null);
                  }}
                >
                  Отмена
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  sx={{ ...alertBtnStyle }}
                  onClick={() => setShowLoginAlert(false)}
                >
                  Продолжить
                </Button>
              </AlertButtonsBlock>
            </AlertLoginContent>
          </AlertLogin>
        </Modal>
        <Modal open={showPasswordAlert}>
          <AlertLogin>
            <AlertLoginContent>
              <NotificationIconImg src={NotificationIcon} />
              <AlertTitle>
                После смены пароля необходимо будет заново войти в приложение
              </AlertTitle>
              <AlertDescription>
                Нужно будет заново войти в личный кабинет с новым паролем. На
                других устройствах в личный кабинет нужно будет также войти
                заново.
              </AlertDescription>
              <AlertButtonsBlock>
                <Button
                  size="sm"
                  variant="secondary"
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    ...alertBtnStyle,
                  }}
                  onClick={() => {
                    setShowPasswordAlert(false);
                    setModalType(null);
                  }}
                >
                  Отмена
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  sx={{ ...alertBtnStyle }}
                  onClick={() => setShowPasswordAlert(false)}
                >
                  Продолжить
                </Button>
              </AlertButtonsBlock>
            </AlertLoginContent>
          </AlertLogin>
        </Modal>
        {modalType && content[modalType]}
      </ModalUI>
      <ProfileContent p={{ xs: "0 20px", xl: "0 32px" }}>
        {isMobile && (
          <>
            <Typography
              variant="text_12"
              color="gray.b900"
              fontWeight={500}
              mt={4}
            >
              Новости и уведомления
            </Typography>
            <Box display="flex" flexDirection="column" rowGap={12} mt={24}>
              {newsAndNotifications.map(({ title, id, icon }) => (
                <ProfileOption
                  key={id}
                  title={title}
                  id={id}
                  onClick={() => handleMenuClick(id)}
                  icon={icon}
                />
              ))}
            </Box>
          </>
        )}
        <Typography
          variant="text_12"
          color="gray.b900"
          fontWeight={500}
          mt={{ xs: 20, xl: isMobile ? 28 : 40 }}
        >
          Настройки профиля
        </Typography>
        <SkeletonContainer
          isLoading={isProfileLoading}
          sx={{ marginTop: "24px" }}
        >
          <Box display="flex" flexDirection="column" rowGap={12} mt={24}>
            <ProfileTogglesRender handleOptionClick={handleOptionClick} />
            <Box
              sx={{
                borderRadius: 16,
                backgroundColor: "#F2F4F9",
              }}
              p={{ xs: 12, xl: 20 }}
              display="flex"
              gap={12}
              alignItems="flex-start"
            >
              <Box
                sx={{
                  borderRadius: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 36,
                  height: 36,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <IconPhone />
              </Box>
              <Box display="flex" flexDirection="column" flex={1}>
                <Typography variant="text_5" color="#393E46" flex={1}>
                  {formatPhoneNumber(phoneNumber || "")}
                </Typography>
                <Typography variant="text_5" color="#8993A4" flex={1}>
                  Ваш профиль привязан к этому номеру телефона
                </Typography>
              </Box>
            </Box>
          </Box>
        </SkeletonContainer>
      </ProfileContent>
    </ProfileWrapper>
  );
};
