export const IconBlockedCardHuge = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="426"
      height="150"
      fill="none"
      viewBox="0 0 426 150"
    >
      <rect width="426" height="150" fill="#FFEEED" rx="15"></rect>
      <path
        fill="#fff"
        d="M297 110.5a8 8 0 01-8 8H137a8 8 0 01-8-8V42.504a8.001 8.001 0 017.999-8c56.93-.02 110.279.036 151.998.01a7.998 7.998 0 018.003 8V110.5z"
      ></path>
      <path
        fill="#EBEDF3"
        d="M278.936 90.025h-20.823c-1.326 0-2.402 1.913-2.402 4.272s1.076 4.27 2.402 4.27h20.823c1.326 0 2.402-1.911 2.402-4.27 0-2.36-1.076-4.272-2.402-4.272zM210.154 90.025h-62.645a4.272 4.272 0 000 8.543h62.645a4.272 4.272 0 000-8.543z"
      ></path>
      <path
        fill="#FDA29B"
        d="M213 71c13.255 0 24-10.745 24-24s-10.745-24-24-24-24 10.745-24 24 10.745 24 24 24z"
      ></path>
      <path
        fill="#fff"
        d="M222.526 56.527a2 2 0 01-2.828 0l-7.071-7.071-7.071 7.071a2 2 0 01-2.829-2.828l7.072-7.071-7.072-7.072a2 2 0 112.829-2.828l7.071 7.071 7.071-7.07a2 2 0 112.828 2.828l-7.071 7.07 7.071 7.072a2 2 0 010 2.828z"
      ></path>
    </svg>
  );
};
