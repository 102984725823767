export const PushIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={151}
      height={150}
      fill="none"
    >
      <rect width={136} height={134} x={8} y={8} fill="#F2F4F9" rx={39} />
      <path
        fill="#0082BA"
        d="M75 101.25a9.39 9.39 0 0 0 8.396-5.21.289.289 0 0 0-.265-.415H66.87a.289.289 0 0 0-.265.416A9.386 9.386 0 0 0 75 101.25ZM93.948 81.512a10.362 10.362 0 0 1-2.073-6.217v-3.64c0-8.258-3.206-15.452-11.25-17.281l-.166-1.001a5.533 5.533 0 0 0-10.917 0l-.167 1.001c-8.072 1.83-11.25 8.995-11.25 17.282v.843a18.75 18.75 0 0 1-3.75 11.25l-1.03 1.375a4.219 4.219 0 0 0 3.374 6.75h36.563a4.219 4.219 0 0 0 3.375-6.75l-2.71-3.612Z"
      />
    </svg>
  );
};
