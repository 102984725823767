import React from "react";
import { useTheme } from "@mui/material";

const IconVisibility = (props) => {
  const theme = useTheme();
  const { blue } = theme.primary.brand;
  return (
    <svg
      width={16}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 12"
      {...props}
    >
      <path
        d="M8 0a7.336 7.336 0 0 1 7.212 6A7.336 7.336 0 0 1 .787 6 7.336 7.336 0 0 1 8 0Zm0 10.667A6.004 6.004 0 0 0 13.85 6 6.003 6.003 0 0 0 2.148 6 6.003 6.003 0 0 0 8 10.667ZM8 9a3 3 0 1 1 0-6 3 3 0 0 1 0 6Zm0-1.333a1.667 1.667 0 1 0 0-3.334 1.667 1.667 0 0 0 0 3.334Z"
        fill={blue[400]}
      />
    </svg>
  );
};

export default IconVisibility;
