import { useNavigate } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { IconSuccess } from "atoms/IconSuccess";
import { NotificationResult } from "molecules/NotificationResult";
import { useDispatch } from "react-redux";
import { authActions } from "../../../store/slices/auth";

const FifthScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/sign-in");
    dispatch(authActions.setRestoreScreen("initial"));
  };
  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      component="main"
      container
      px={{ xl: 86 }}
      justifyContent="center"
      fontFamily="Inter"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
      minHeight="calc(100vh - 390px)"
    >
      <Box
        p={{ xs: "80px 0 180px", md: "32px 30.5px 80px" }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <NotificationResult
          pageTitle="Восстановление пароля"
          title="Пароль восстановлен"
          subtitle={`Теперь вы можете продолжить работу\n в личном кабинете`}
          icon={<IconSuccess />}
          buttons={[
            {
              name: "Войти в аккаунт",
              variant: "primary",
              size: "lg",
              onClick: handleClick,
            },
          ]}
        />
      </Box>
    </Grid>
  );
};

export default FifthScreen;
