import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { statementApi } from "../../api";
import {
  GetListRequest,
  StatementListResponse,
  StatementFull,
  ListExpenseStatementRequest,
  ListIncomeStatementRequest,
  ListStatementRequest,
  StatementCategoryFull,
} from "api/account";
import { getAllPromotions } from "./promotion";
import { transformOperationType } from "utils/transformOperationType";

export enum HISTORY_TABS {
  EXPENSES = "Расходы",
  INCOMES = "Поступления",
  ALL_OPERATIONS = "Все операции",
}

export type HistoryState = {
  isLoading: boolean;
  isItemLoading: boolean;
  historyList: Array<StatementListResponse>;
  expenseList: Array<StatementListResponse>;
  incomeList: Array<StatementListResponse>;
  historyItem: StatementFull | null;
  activeTab: HISTORY_TABS;
  widgetData?: Array<StatementCategoryFull>;
  isWidgetDataLoading: boolean;
  showMobileFinancialAnalysisPage: boolean;
  activeCategoryId: number | null;
  hideWidget: boolean;
  date: {
    from: string;
    to: string;
  };
  isReportLoading: boolean;
};

const dateNow = new Date();
const firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);

const initialState: HistoryState = {
  isLoading: false,
  isItemLoading: false,
  historyList: [],
  expenseList: [],
  incomeList: [],
  historyItem: null,
  activeTab: HISTORY_TABS.EXPENSES,
  widgetData: [],
  isWidgetDataLoading: true,
  showMobileFinancialAnalysisPage: false,
  activeCategoryId: null,
  hideWidget: false,
  date: {
    from: firstDayMonth.toISOString(),
    to: dateNow.toISOString(),
  },
  isReportLoading: false,
};

export const getListReport = createAsyncThunk(
  "getListReport",
  async (payload: ListStatementRequest, { rejectWithValue }) => {
    try {
      const operationFilterType = payload.operationFilterType
        ? transformOperationType(payload.operationFilterType)
        : "";

      const response = await statementApi.getListReport(
        "",
        // @ts-ignore
        payload.operationViewType || "",
        payload.cardId || "",
        payload.acctId || "",
        payload.from || "",
        payload.to || "",
        payload.fromAmount || "",
        payload.toAmount || "",
        payload.search || "",
        payload.forceRefresh || "",
        payload.categoryIds || "",
        operationFilterType
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHistoryList = createAsyncThunk(
  "getHistoryList",
  async (payload: GetListRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListV2("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const appendHistoryList = createAsyncThunk(
  "appendHistoryList",
  async (payload: GetListRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListV2("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHistoryItem = createAsyncThunk(
  "getHistoryItem",
  async (historyId: string, { rejectWithValue }) => {
    try {
      const response = await statementApi.getDetailsV2("string", historyId);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCategoryExpense = createAsyncThunk(
  "getCategoryExpense",
  async (payload: ListExpenseStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getCategoryExpense("string", {
        ...payload,
        toAmount: payload.toAmount || undefined,
        fromAmount: payload.fromAmount || undefined,
        operationFilterType: payload.operationFilterType || undefined,
        page: 0,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCategoryIncome = createAsyncThunk(
  "getCategoryIncome",
  async (payload: ListIncomeStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getCategoryIncome("string", {
        ...payload,
        toAmount: payload.toAmount || undefined,
        fromAmount: payload.fromAmount || undefined,
        operationFilterType: payload.operationFilterType || undefined,
        page: 0,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getExpenseList = createAsyncThunk(
  "getExpenseList",
  async (payload: ListExpenseStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListExpense("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const appendExpenseList = createAsyncThunk(
  "appendExpenseList",
  async (payload: ListExpenseStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListExpense("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIncomeList = createAsyncThunk(
  "getIncomeList",
  async (payload: ListIncomeStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListIncome("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const appendIncomeList = createAsyncThunk(
  "appendIncomeList",
  async (payload: ListIncomeStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListIncome("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    resetHistoryStore: (state) => {
      state = initialState;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setShowMobileFinancialAnalysisPage: (state, { payload }) => {
      state.showMobileFinancialAnalysisPage = payload;
    },
    setDate: (state, { payload }) => {
      state.date = payload;
    },
    setActiveCategory: (state, { payload }) => {
      state.activeCategoryId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPromotions.rejected, (state) => {
      state.hideWidget = true;
    });
    builder.addCase(getAllPromotions.fulfilled, (state) => {
      state.hideWidget = false;
    });
    builder.addCase(getCategoryExpense.pending, (state) => {
      state.isWidgetDataLoading = true;
    });
    builder.addCase(getCategoryIncome.pending, (state) => {
      state.isWidgetDataLoading = true;
    });
    builder.addCase(getCategoryExpense.fulfilled, (state, { payload }) => {
      if (!payload.categories?.length) {
        state.widgetData = [];
      } else {
        state.widgetData = payload.categories?.map((item) => {
          return {
            ...item,
            sum: item.sum ? Math.abs(item.sum) : 0,
          };
        });
      }
      state.isWidgetDataLoading = false;
    });
    builder.addCase(getCategoryIncome.fulfilled, (state, { payload }) => {
      if (!payload.categories?.length) {
        state.widgetData = [];
      } else {
        state.widgetData = payload.categories?.map((item) => {
          return {
            ...item,
            sum: item.sum ? Math.abs(item.sum) : 0,
          };
        });
      }
      state.isWidgetDataLoading = false;
    });
    builder.addCase(getHistoryList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExpenseList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExpenseList.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getIncomeList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIncomeList.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(appendHistoryList.fulfilled, (state, action) => {
      const historyList = [...state.historyList, ...action.payload].reduce(
        (acc: any[], cur: any) => {
          const existsIndex = acc.findIndex((item) => item.date === cur.date);
          if (existsIndex === -1) {
            acc.push(cur);
          } else {
            acc[existsIndex].statements = Object.values([
              ...acc[existsIndex].statements,
              ...cur.statements,
            ]).reduce((acc, cur) => {
              acc.push(cur);
              return acc;
            }, []);
          }
          return acc;
        },
        []
      );
      state.historyList = historyList;
    });
    builder.addCase(appendExpenseList.fulfilled, (state, action) => {
      const expenseList = [...state.expenseList, ...action.payload].reduce(
        (acc: any[], cur: any) => {
          const existsIndex = acc.findIndex((item) => item.date === cur.date);
          if (existsIndex === -1) {
            acc.push(cur);
          } else {
            acc[existsIndex].statements = Object.values([
              ...acc[existsIndex].statements,
              ...cur.statements,
            ]).reduce((acc, cur) => {
              acc.push(cur);
              return acc;
            }, []);
          }
          return acc;
        },
        []
      );
      state.expenseList = expenseList;
    });
    builder.addCase(appendIncomeList.fulfilled, (state, action) => {
      const incomeList = [...state.incomeList, ...action.payload].reduce(
        (acc: any[], cur: any) => {
          const existsIndex = acc.findIndex((item) => item.date === cur.date);
          if (existsIndex === -1) {
            acc.push(cur);
          } else {
            acc[existsIndex].statements = Object.values([
              ...acc[existsIndex].statements,
              ...cur.statements,
            ]).reduce((acc, cur) => {
              acc.push(cur);
              return acc;
            }, []);
          }
          return acc;
        },
        []
      );
      state.incomeList = incomeList;
    });
    builder.addCase(getHistoryList.fulfilled, (state, action) => {
      state.historyList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getExpenseList.fulfilled, (state, action) => {
      state.expenseList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getIncomeList.fulfilled, (state, action) => {
      state.incomeList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getHistoryItem.fulfilled, (state, action) => {
      state.historyItem = action.payload;
      state.isItemLoading = false;
    });
    builder.addCase(getHistoryItem.pending, (state) => {
      state.isItemLoading = true;
    });
    builder.addCase(getListReport.pending, (state) => {
      state.isReportLoading = true;
    });
    builder.addCase(getListReport.rejected, (state) => {
      state.isReportLoading = false;
    });
    builder.addCase(getListReport.fulfilled, (state) => {
      state.isReportLoading = false;
    });
  },
});

export const {
  resetHistoryStore,
  setActiveTab,
  setShowMobileFinancialAnalysisPage,
  setDate,
  setActiveCategory,
} = historySlice.actions;

export default historySlice.reducer;
