export const IconAccountRubHuge = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    fill="none"
    viewBox="0 0 44 44"
  >
    <rect width="44" height="44" fill="#0082BA" rx="13"></rect>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M31.6 10.267c.589 0 1.067.477 1.067 1.066v19.2c0 .59-.478 1.067-1.067 1.067h-1.067v1.067a1.067 1.067 0 11-2.133 0V31.6H15.6v1.067a1.067 1.067 0 11-2.133 0V31.6H12.4c-.59 0-1.067-.478-1.067-1.067v-19.2c0-.589.478-1.066 1.067-1.066h19.2zM30.533 12.4H13.467v17.067h17.066V12.4zm-7.645 10.045c1.941 0 3.556-1.615 3.556-3.556 0-1.935-1.677-3.555-3.624-3.555h-2.625c-.492 0-.89.399-.889.89l.012 4.443h-.874a.889.889 0 100 1.778h.88l.002.895h-1.015a.756.756 0 000 1.511h1.02l.002.71a.889.889 0 001.778-.005l-.002-.705h1.646a.756.756 0 000-1.51h-1.651l-.003-.896h1.788zm-1.792-1.778l-.01-3.556h1.734c.979 0 1.846.839 1.846 1.778 0 .959-.819 1.778-1.777 1.778h-1.793z"
      clipRule="evenodd"
    ></path>
  </svg>
);
