export const StatusIconView = () => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.44228 0.234314L3.06895 4.60765L1.36569 2.90438C1.05327 2.59196 0.546734 2.59196 0.234315 2.90438C-0.0781045 3.2168 -0.0781051 3.72333 0.234314 4.03575L3.06274 6.86418L4.04196 5.88496L4.04817 5.89117L8.57365 1.36569C8.88607 1.05327 8.88607 0.546734 8.57365 0.234315C8.26124 -0.0781048 7.7547 -0.0781049 7.44228 0.234314ZM7.90519 7.08152L7.85794 7.12877L6.06794 5.33877L7.19932 4.2074L8.00092 5.00901L11.4423 1.56765C11.7547 1.25523 12.2612 1.25523 12.5737 1.56765C12.8861 1.88007 12.8861 2.3866 12.5737 2.69902L8.04817 7.2245L7.90519 7.08152Z"
      fill="#32D583"
    />
  </svg>
);
