import { Box } from "@mui/system";
import { CARD_STATUS } from "components/CardsInfo/model";
import IconBigKey from "components/Icons/IconBigKey";
import IconSnow from "components/Icons/IconSnow";
import IconClosed from "components/Icons/IconClosed";
import IconClosedHuge from "components/Icons/IconClosedHuge";

export const RenderStatus = ({
  status,
  width = "100%",
  height = "100%",
  iconHeight = 56,
  iconWidth = 56,
  radius = 11,
  size = "small",
}: {
  status: any;
  width?: string;
  height?: string;
  iconHeight?: number;
  iconWidth?: number;
  radius?: number;
  size?: string;
}) => {
  const renderBg = (status: any) => {
    switch (status) {
      case CARD_STATUS.Temporary:
        return "rgba(255, 255, 255, 0.77)";
      case CARD_STATUS.Lost:
        return "rgba(255, 238, 237, 0.77)";
      case CARD_STATUS.Closed:
        return "rgba(170, 176, 206, 0.59)";
      case CARD_STATUS.Stolen:
        return "rgba(255, 238, 237, 0.77)";
    }
  };

  const renderIconBg = (status: any) => {
    switch (status) {
      case CARD_STATUS.Temporary:
        return "#fff";
      case CARD_STATUS.Lost:
        return "#F97066";
      case CARD_STATUS.Stolen:
        return "#F97066";
      case CARD_STATUS.Closed:
        return "#fff";
    }
  };

  const renderIcon = (status: any, size: any) => {
    switch (status) {
      case CARD_STATUS.Temporary:
        return <IconSnow width={32} height={32} />;
      case CARD_STATUS.Lost:
        return <IconBigKey />;
      case CARD_STATUS.Stolen:
        return <IconBigKey />;
      case CARD_STATUS.Closed:
      case size === "huge":
        return size === "huge" ? <IconClosedHuge /> : <IconClosed />;
    }
  };

  return (
    <Box
      width={width}
      height={height}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      zIndex={1}
      sx={{
        borderRadius: radius,
        backgroundColor: renderBg(status),
        // backdropFilter: "blur(3.5px)",
      }}
    >
      <Box
        width={iconWidth}
        height={iconHeight}
        borderRadius="50%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: renderIconBg(status),
        }}
      >
        {renderIcon(status, size)}
      </Box>
    </Box>
  );
};
