const BlockedCardConfirm = () => {
  return (
    <svg
      width="426"
      height="150"
      viewBox="0 0 426 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="426" height="150" rx="15" fill="#FFEEED" />
      <path
        d="M297 110.5C297 114.918 293.418 118.5 289 118.5H137C132.582 118.5 129 114.918 129 110.5V42.5038C129 38.087 132.582 34.5058 136.999 34.5043C193.929 34.4848 247.278 34.5396 288.997 34.5137C293.418 34.5109 297 38.0938 297 42.5149V110.5Z"
        fill="white"
      />
      <path
        d="M278.936 90.0254H258.114C256.788 90.0254 255.712 91.9377 255.712 94.2966C255.712 96.6555 256.788 98.5678 258.114 98.5678H278.936C280.263 98.5678 281.339 96.6555 281.339 94.2966C281.339 91.9377 280.263 90.0254 278.936 90.0254Z"
        fill="#EBEDF3"
      />
      <path
        d="M210.153 90.0254H147.508C145.15 90.0254 143.237 91.9377 143.237 94.2966C143.237 96.6555 145.15 98.5678 147.508 98.5678H210.153C212.511 98.5678 214.424 96.6555 214.424 94.2966C214.424 91.9377 212.511 90.0254 210.153 90.0254Z"
        fill="#EBEDF3"
      />
      <path
        d="M213 71C226.255 71 237 60.2548 237 47C237 33.7452 226.255 23 213 23C199.745 23 189 33.7452 189 47C189 60.2548 199.745 71 213 71Z"
        fill="#F97066"
      />
      <g clipPath="url(#clip0_28_10123)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M207.333 39.7243C207.333 37.7413 207.873 36.2457 208.786 35.2547C209.687 34.2757 211.065 33.6667 213.003 33.6667C214.942 33.6667 216.318 34.2746 217.218 35.2515C218.128 36.2406 218.667 37.7345 218.667 39.7182V43.3333H207.333V39.7243ZM205.333 43.3333V39.7243C205.333 37.3921 205.971 35.3589 207.315 33.8999C208.669 32.4288 210.626 31.6667 213.003 31.6667C215.38 31.6667 217.336 32.4269 218.689 33.8968C220.031 35.3547 220.667 37.3866 220.667 39.7182V43.3333H222.667C224.324 43.3333 225.667 44.6765 225.667 46.3333V57.3333C225.667 58.9902 224.324 60.3333 222.667 60.3333H203.333C201.677 60.3333 200.333 58.9902 200.333 57.3333V46.3333C200.333 44.6765 201.677 43.3333 203.333 43.3333H205.333ZM202.333 46.3333C202.333 45.781 202.781 45.3333 203.333 45.3333H222.667C223.219 45.3333 223.667 45.781 223.667 46.3333V57.3333C223.667 57.8856 223.219 58.3333 222.667 58.3333H203.333C202.781 58.3333 202.333 57.8856 202.333 57.3333V46.3333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_28_10123">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(193 26)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlockedCardConfirm;
