import React from "react";

const IconChat = ({ count = 0 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <rect width="56" height="56" fill="#0082BA" rx="22"></rect>
      {count > 0 ? (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          x="35"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="20" height="20" rx="10" fill="#FF7438" />
          <text
            fill="white"
            x={count < 10 || count > 99 ? "6" : "0"}
            y="15"
            fontSize="15"
          >
            {count > 99 ? "∞" : count}
          </text>
        </svg>
      ) : null}
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M18.278 35.154c.069-.253-.083-.604-.228-.858a2.612 2.612 0 00-.146-.219A11.527 11.527 0 0116 27.731C15.98 21.255 21.35 16 27.99 16c5.791 0 10.625 4.012 11.755 9.337.17.79.255 1.595.255 2.403 0 6.485-5.163 11.823-11.803 11.823-1.056 0-2.481-.266-3.258-.483a21.819 21.819 0 01-1.753-.584 1.795 1.795 0 00-1.34.021l-3.913 1.413a.921.921 0 01-.27.07.554.554 0 01-.551-.562.913.913 0 01.034-.19l1.132-4.094z"
      ></path>
    </svg>
  );
};

export default IconChat;
