import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum MobileTabVariants {
  main = "main",
  transfers = "transfers",
  history = "history",
  limits = "limits",
  chat = "chat",
}

export const mobileTabLabels: Record<MobileTabVariants, string> = {
  [MobileTabVariants.main]: "Главная",
  [MobileTabVariants.transfers]: "Переводы",
  [MobileTabVariants.history]: "История",
  [MobileTabVariants.limits]: "Лимиты",
  [MobileTabVariants.chat]: "Чат",
};

export enum TabVariants {
  main = "main",
  transfers = "transfers",
  limits = "limits",
  history = "history",
  news = "news",
  notifications = "notifications",
}

export enum AnotherPages {
  promotion = "promotion",
}

export const tabLabels: Record<TabVariants, string> = {
  [TabVariants.main]: "Главная",
  [TabVariants.transfers]: "Переводы",
  [TabVariants.limits]: "Лимиты по карте",
  [TabVariants.history]: "История операций",
  [TabVariants.news]: "Новости",
  [TabVariants.notifications]: "Уведомления",
};

const allTabVariants = {
  ...MobileTabVariants,
  ...TabVariants,
} as const;
export type AllTabVariants = MobileTabVariants | TabVariants | AnotherPages;

export enum HeightsVariants {
  "100vh" = "100vh",
  none = "none",
}

export interface ITransferState {
  pan?: string;
  amount?: number;
  reason?: string;
}

export interface ICardState {
  titleShort?: string;
  descriptionShort?: string;
  srcImgLenta?: string;
  srcImgDetail?: string;
  id?: number;
  category?: string;
}

export type ActiveTabState = ITransferState;

export type SystemState = {
  activeTab: AllTabVariants;
  previousTab: AllTabVariants;
  needToScrollToTabs: boolean;
  bodyMaxHeight: HeightsVariants;
  activeTabState: ActiveTabState;
  chatIsOpen: boolean;
  newsIsOpen: boolean;
  notificationsIsOpen: boolean;
};

const initialState: SystemState = {
  activeTab: "main" as AllTabVariants,
  needToScrollToTabs: false,
  bodyMaxHeight: "none" as HeightsVariants,
  activeTabState: {},
  previousTab: "main" as AllTabVariants,
  chatIsOpen: false,
  newsIsOpen: false,
  notificationsIsOpen: false,
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
    setActiveTab: (
      state,
      {
        payload,
      }: PayloadAction<{
        tab: AllTabVariants;
        state?: ActiveTabState;
      }>
    ) => {
      state.previousTab = state.activeTab;
      state.activeTab = payload.tab;
      state.activeTabState = payload.state || {};
    },
    setBack: (state) => {
      state.activeTab = state.previousTab;
    },
    setChatOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.chatIsOpen = payload;
    },
    setNewsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.newsIsOpen = payload;
    },
    setNotificationsOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.notificationsIsOpen = payload;
    },
    setInitialTab: (
      state,
      {
        payload,
      }: PayloadAction<{
        isMobile?: boolean;
      }>
    ) => {
      state.activeTab = payload.isMobile
        ? MobileTabVariants.main
        : TabVariants.main;
    },
    setNeedScrollState: (state, { payload }: PayloadAction<boolean>) => {
      state.needToScrollToTabs = payload;
    },
    setBodyMaxHeight: (state, { payload }: PayloadAction<HeightsVariants>) => {
      state.bodyMaxHeight = payload;
    },
  },
  extraReducers: {},
});

export default systemSlice;
