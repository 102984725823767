import { Middleware } from "@reduxjs/toolkit";
import { RootState, ThunkAppDispatch } from "../../index";
import { showErrorMessage, hideErrorMessage } from "../alerts";
import { IFourHundredErr } from "api/types";
import { TRANSFER_ERROR_CODE } from "../transferSlice";

export const defaultErrorTitle = "Произошла ошибка при выполнении запроса";
export const defaultErrorMessage = "Попробуйте позднее";

export const handleErrorMiddleWare: Middleware<
  RootState,
  unknown,
  ThunkAppDispatch
> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (
      action.type.includes("rejected") &&
      action.type !== "checkCvv/rejected" &&
      action.type !== "verifyPinCode/rejected" &&
      action.type !== "updatePinCode/rejected" &&
      action.type !== "sendChatMessage/rejected" &&
      action.type !== "updatePassword/rejected" &&
      action.type !== "getChatMessages/rejected" &&
      action.type !== "updatePassword/rejected" &&
      action.type !== "getChatMessages/rejected" &&
      action.type !== "getRefillStatus/rejected" &&
      action.type !== "createTransferToCard/rejected" &&
      !(
        action.type === "confirmTransferToCard/rejected" &&
        (action.payload.response.data.code ===
          TRANSFER_ERROR_CODE.PAYMENT_ERROR ||
          action.payload.response.data.code ===
            TRANSFER_ERROR_CODE.PAYMENT_RESPONSE_ERROR)
      ) &&
      !(
        (action.type === "register/rejected" ||
          action.type === "resetPassword/rejected") &&
        action.payload.response.data.code === "CARD_DISABLED"
      )
    ) {
      const { response } = action.payload as IFourHundredErr;
      if (
        response.status >= 400 &&
        response.status < 500 &&
        response.status !== 406
      ) {
        if (
          //@ts-ignore
          response.status === 401 &&
          !response?.request?.responseURL.includes("web/user/pin") &&
          !response?.request?.responseURL.includes("auth/v3/user")
        ) {
          dispatch(hideErrorMessage());
          dispatch(
            showErrorMessage({
              errorTitle: "Срок действия сессии истек",
            })
          );
        } else {
          dispatch(
            showErrorMessage({
              errorTitle: response?.data?.title,
              errorMessage: response?.data?.subtitle,
            })
          );
        }
      } else {
        dispatch(
          showErrorMessage({
            errorTitle: defaultErrorTitle,
            errorMessage: defaultErrorMessage,
          })
        );
      }
    } else if (
      action.type.includes("fulfilled") &&
      !action.type.includes("hideErrorMessage") &&
      !action.type.includes("showError")
    ) {
      dispatch(hideErrorMessage());
    }
    return next(action);
  };
