import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box } from "@mui/material";
import theme from "theme";
import { Button } from "components";
import { AuthContainer } from "containers";
import { getSecondsPluralForms } from "utils";

import { useCounter } from "hooks/useCounter";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "store";
import { loginConfirm, retryOneTimePassword } from "store/slices/auth";
import { SmsInput } from "molecules/SmsInput/SmsInput";
import { useIsMobile } from "hooks/useIsMobile";
import {
  systemActions,
  TabVariants,
  MobileTabVariants,
} from "store/slices/system";
import {
  BackButtonContainer,
  SmallBackButton,
} from "components/SmallBackButton";
import { useIsPWA } from "hooks/useIsPWA";

const Confirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pinCode, setCode] = useState<string>("");
  const { count, restartCounter } = useCounter();
  const secondsWord = getSecondsPluralForms(count);
  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();

  const { isLoading, error, elseDevice } = useSelector(authSelector);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setCode(e.target.value);
  };

  const handleRetryOneTimePassword = () => {
    dispatch(retryOneTimePassword());
    restartCounter();
  };

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    dispatch(loginConfirm({ pinCode, isPWA }))
      //@ts-ignore
      .unwrap()
      .then(() => {
        const nextPage = elseDevice ? "/" : "/pin-code";
        const tabVariant = isMobile
          ? MobileTabVariants.main
          : TabVariants.transfers;
        navigate(nextPage, { replace: true });
        dispatch(systemActions.setActiveTab({ tab: tabVariant }));
      })
      .catch(() => {});
  };

  return (
    <>
      <BackButtonContainer>
        <SmallBackButton clickHandler={() => navigate("/sign-in")} />
      </BackButtonContainer>
      <AuthContainer
        title="Подтверждение входа"
        subtitle={`На указанный номер телефона\n был отправлен код подтверждения`}
      >
        <Box
          component="form"
          autoComplete="off"
          sx={{
            backgroundColor: theme.palette.gray.b50,
          }}
          px={{ xs: 20, lg: 30.5 }}
          py={32}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          boxSizing="border-box"
          border={`1px solid ${theme.palette.gray.b100}`}
          borderRadius={24}
          height={count > 0 ? 328 : 300}
        >
          <Box>
            <SmsInput
              placeholder="Введите код"
              label="Код"
              name="code"
              value={pinCode}
              onChange={handleChange}
              // @ts-ignore
              error={error}
            />
          </Box>
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              rowGap={count > 0 ? 44 : 12}
            >
              {count === 0 ? (
                <Button
                  onClick={handleRetryOneTimePassword}
                  variant="secondary"
                  size="lg"
                  fullWidth
                >
                  Отправить код повторно
                </Button>
              ) : (
                <Box minHeight={48}>
                  <Typography variant="text_5" color="gray.b400">
                    Вы сможете отправить код повторно через{" "}
                    <Typography variant="text_5" color="blue.b400">
                      {count} {secondsWord}.
                    </Typography>
                  </Typography>
                </Box>
              )}
              <Button
                type="submit"
                onClick={(e) => handleConfirm(e)}
                variant="primary"
                size="lg"
                fullWidth
                isLoading={isLoading}
                disabled={pinCode.length < 4}
              >
                Продолжить
              </Button>
            </Box>
          </Box>
        </Box>
      </AuthContainer>
    </>
  );
};

export default Confirmation;
