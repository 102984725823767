import { Box, Grid, Link, Typography } from "@mui/material";
import { IconBlocked } from "atoms/IconBlocked";
import { NotificationResult } from "molecules/NotificationResult";
import { useDispatch } from "react-redux";
import { authActions } from "store/slices/auth";

const BlockedScreen = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(authActions.setAccountIsBlocked(false));
  };
  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      component="main"
      container
      px={{ xs: 16, xl: 86 }}
      justifyContent="center"
      fontFamily="Inter"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <Box p={{ xs: "80px 0 180px", md: "32px 30.5px" }} component="div">
        <NotificationResult
          title="Ваш аккаунт заблокирован!"
          subtitle={
            <Box>
              <Typography variant="text_5" color="gray.b500" fontWeight={500}>
                Для разблокировки обратитесь в горячую линию по телефону
              </Typography>
              <Link
                href="tel:+78002004567"
                variant="text_5"
                color="blue.b400"
                fontWeight={400}
                component="div"
                sx={{
                  textDecoration: "none",
                }}
              >
                + 7 (800) 200-45-67
              </Link>
            </Box>
          }
          icon={<IconBlocked />}
          buttons={[
            {
              name: "Вернуться на экран входа",
              variant: "primary",
              size: "lg",
              onClick: handleClick,
            },
          ]}
        />
      </Box>
    </Grid>
  );
};

export default BlockedScreen;
