import Box from "@mui/material/Box";
import {
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import IconVisibilityOff from "atoms/IconVisibilityOff/IconVisibilityOff";
import IconVisibility from "atoms/IconVisibility/IconVisibility";
import { forwardRef, useState } from "react";
import { Label } from "molecules/Label/Label";
import theme from "theme";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "&.MuiOutlinedInput-root": {
      color: "#101828",
      background: "#ffffff",
      borderRadius: "16px",
      paddingRight: 0,
      "&.Mui-focused fieldset": {
        border: "1px solid #A4BCFD",
      },
    },
    "&.MuiOutlinedInput-root input": {
      padding: "14px 36px 14px 18px",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    "&.MuiOutlinedInput-root input::placeholder": {
      textAlign: "initial",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #000000",
    },
    "&.MuiOutlinedInput-root .MuiInputAdornment-root": {
      position: "absolute",
      right: "16px",
    },
    "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FDA29B",
    },
  },
  errorRoot: {
    "&.Mui-error": {
      color: "#F04438",
      fontSize: 14,
      lineHeight: "20px",
    },
  },
}));

export const PasswordInput = forwardRef(function PasswordInput(
  {
    maxLength = 18,
    value,
    onChange,
    autoComplete = "new-password",
    label,
    name,
    error,
    placeholder = "",
    ...props
  },
  ref
) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getErrorMessage = (error) => {
    if (+error.code >= 500) {
      return error.message;
    } else {
      return error.message || error.comment;
    }
  };

  return (
    <Box getInputRef={ref} {...props}>
      <Label
        label={label}
        htmlFor={name}
        style={{ color: theme.primary.gray[900] }}
      />
      <OutlinedInput
        classes={{ root: classes.inputRoot }}
        type={showPassword ? "text" : "password"}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        error={!value ? false : !!error}
        autoComplete={autoComplete}
        inputProps={{
          maxLength: maxLength,
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              aria-label="toggle password visibility"
              edge="end"
            >
              {showPassword ? <IconVisibility /> : <IconVisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        id={name}
        fullWidth
      />
      {!!error && (
        <FormHelperText classes={{ root: classes.errorRoot }} error>
          {error || getErrorMessage(error)}
        </FormHelperText>
      )}
    </Box>
  );
});
