import theme from "theme";

type Props = {
  disabled?: boolean;
};

export const SendMessageIcon = ({ disabled }: Props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill={disabled ? theme.palette.gray.b200 : theme.primary.brand.blue["400"]}
  >
    <g id="IONIcon/P/paper/plane">
      <path
        id="Vector"
        d="M22.1719 1.83169C22.0185 1.67835 21.8241 1.57262 21.612 1.52721C21.3999 1.4818 21.1792 1.49866 20.9765 1.57575L2.22514 8.67309H2.22139C2.00519 8.75625 1.81992 8.90412 1.6909 9.09651C1.56188 9.28889 1.49539 9.51642 1.50052 9.74801C1.50565 9.9796 1.58214 10.204 1.71955 10.3904C1.85696 10.5769 2.04859 10.7165 2.26827 10.79L2.28749 10.7961L8.72342 13.5443C8.84895 13.5824 8.98228 13.587 9.1101 13.5574C9.23792 13.5279 9.35576 13.4654 9.45186 13.3761L19.7812 3.75122C19.812 3.72044 19.8485 3.69603 19.8888 3.67937C19.929 3.66271 19.9721 3.65414 20.0156 3.65414C20.0591 3.65414 20.1022 3.66271 20.1424 3.67937C20.1827 3.69603 20.2192 3.72044 20.25 3.75122C20.2808 3.782 20.3052 3.81854 20.3218 3.85875C20.3385 3.89897 20.3471 3.94207 20.3471 3.98559C20.3471 4.02912 20.3385 4.07222 20.3218 4.11244C20.3052 4.15265 20.2808 4.18919 20.25 4.21997L10.6247 14.5447C10.5354 14.6407 10.4728 14.7586 10.4433 14.8864C10.4138 15.0142 10.4183 15.1476 10.4564 15.2731L13.2056 21.7128C13.2084 21.7221 13.2112 21.7306 13.2145 21.7395C13.3645 22.174 13.7442 22.4806 14.2031 22.5012C14.2233 22.5012 14.2303 22.5012 14.25 22.5012C14.4817 22.5025 14.7084 22.4341 14.9006 22.3049C15.0929 22.1756 15.2419 21.9915 15.3281 21.7765L22.4245 3.03028C22.5027 2.82744 22.5205 2.6063 22.4756 2.39358C22.4308 2.18087 22.3253 1.9857 22.1719 1.83169Z"
        fill="#0082BA"
      />
    </g>
  </svg>
);
