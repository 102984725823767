export const DocumentIcon = () => {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3335 1.66669H2.50016C2.05814 1.66669 1.63421 1.84228 1.32165 2.15484C1.00909 2.4674 0.833496 2.89133 0.833496 3.33335V16.6667C0.833496 17.1087 1.00909 17.5326 1.32165 17.8452C1.63421 18.1578 2.05814 18.3334 2.50016 18.3334H12.5002C12.9422 18.3334 13.3661 18.1578 13.6787 17.8452C13.9912 17.5326 14.1668 17.1087 14.1668 16.6667V7.50002M8.3335 1.66669L14.1668 7.50002M8.3335 1.66669V7.50002H14.1668"
        stroke="#C2C9D6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
