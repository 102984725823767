import { TextField } from "@mui/material";
import Box from "@mui/material/Box";

import { Label } from "molecules/Label/Label";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .MuiOutlinedInput-root": {
      color: "#101828",
      background: "#ffffff",
      borderRadius: "16px",
      "&.Mui-focused fieldset": {
        border: "1px solid #A4BCFD",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#FDA29B !important",
      },
      "& .MuiOutlinedInput-input": {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "14px 18px",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 500,
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "#667085",
      fontSize: "14px",
      lineHeight: "20px",
    },
  },
  errorRoot: {
    "&.Mui-error": {
      color: "#F04438",
      fontSize: 14,
      lineHeight: "20px",
    },
  },
}));

export const SmsInput = ({
  name,
  label,
  value,
  onChange,
  placeholder,
  error = undefined,
  maxLength = 4,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Label label={label} htmlFor={name} />
      <TextField
        classes={{ root: classes.inputRoot }}
        name={name}
        type="tel"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="off"
        inputProps={{ maxLength: maxLength }}
        id={name}
        fullWidth
        error={error}
      />
    </Box>
  );
};
