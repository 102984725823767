import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";

import { LoginInput } from "molecules/LoginInput/LoginInput";
import { PasswordInput } from "molecules/PasswordInput/PasswordInput";
import { AuthContainer } from "containers";
import { Button } from "components";
import theme from "theme";
import { authSelector } from "store";
import { authActions, registerConfirm } from "store/slices/auth";
import { loginPattern, passwordPattern } from "constants/regexp";
import { hideErrorMessage } from "store/slices/alerts";
import { Typography } from "@mui/material";

const FourthScreen = () => {
  const dispatch = useDispatch();

  const [isInitialLoginInput, setIsInitialLoginInput] = useState(false);
  const [isInitialPasswordInput, setIsInitialPasswordInput] = useState(false);

  const { isLoading } = useSelector(authSelector);
  const { error } = useSelector(authSelector);

  const {
    control,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<{ login: string; password: string; confirmPassword: string }>({
    mode: "onChange",
    defaultValues: {
      login: "",
      password: "",
      confirmPassword: "",
    },
  });

  const [login, password, confirmPassword] = watch([
    "login",
    "password",
    "confirmPassword",
  ]);

  const isDisabled =
    Object.keys(errors).length !== 0 || !login || !password || !confirmPassword;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    dispatch(
      registerConfirm({
        login,
        password,
      })
    );
  };

  const handleGoBack = () => {
    dispatch(hideErrorMessage());
    dispatch(authActions.resetError());
    dispatch(authActions.setRegisterScreen("initial"));
  };

  const processingInputField = (e: any) => {
    if (e.target.getAttribute("name") === "login") {
      if (!login) {
        setIsInitialLoginInput(true);
      }
      setIsInitialPasswordInput(false);
    } else if (e.target.getAttribute("name") === "password") {
      if (!password) {
        setIsInitialPasswordInput(true);
      }
      setIsInitialLoginInput(false);
    } else if (e.target.getAttribute("name") === "confirmPassword") {
      !login ? setIsInitialLoginInput(true) : setIsInitialPasswordInput(false);
      !password
        ? setIsInitialPasswordInput(true)
        : setIsInitialLoginInput(false);
    }
  };

  useEffect(() => {
    if (!login && !password) {
      setIsInitialLoginInput(false);
      setIsInitialPasswordInput(false);
    } else if (!login) {
      setIsInitialLoginInput(true);
      setIsInitialPasswordInput(false);
    } else if (!password) {
      setIsInitialPasswordInput(false);
      setIsInitialLoginInput(true);
    }
  }, [login, password]);

  return (
    <AuthContainer
      title="Создание аккаунта"
      subtitle="Придумайте логин и пароль"
    >
      <Box
        p={{ md: "32px 30.5px" }}
        mb={26}
        sx={{
          backgroundColor: theme.palette.gray.b50,
        }}
        border={`1px solid ${theme.palette.gray.b100}`}
        borderRadius={24}
        component="form"
        autoComplete="off"
        textAlign="start"
      >
        <Box
          padding={{ xs: "32px 27.5px", md: 0 }}
          component="form"
          autoComplete="off"
        >
          <Controller
            control={control}
            name="login"
            rules={{
              pattern: {
                value: loginPattern,
                message: "Логин не соответствует требованиям",
              },
            }}
            render={({ field }) => (
              <LoginInput
                {...field}
                name="login"
                label="Логин"
                value={login}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.target.value.trim());
                }}
                onClick={(e: any) => processingInputField(e)}
                // @ts-ignore
                error={
                  isInitialLoginInput
                    ? null
                    : errors.login?.message
                    ? errors.login?.message
                    : error
                    ? ""
                    : null
                }
              />
            )}
          />
          {isInitialLoginInput && (
            <Box mt={6}>
              <Typography variant="text_3" color={theme.palette.gray.b500}>
                Придумайте логин, состоящий из 6-16 символов латинского
                алфавита, цифр и/или специальных символов
              </Typography>
            </Box>
          )}

          <Box mt={20}>
            <Controller
              control={control}
              name="password"
              rules={{
                pattern: {
                  value: passwordPattern,
                  message: "Пароль не соответствует требованиям",
                },
              }}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  // @ts-ignore
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value !== confirmPassword && confirmPassword) {
                      setError("confirmPassword", {
                        type: "custom",
                        message: "Введенные пароли не совпадают",
                      });
                    } else {
                      clearErrors("confirmPassword");
                    }
                    field.onChange(e.target.value);
                  }}
                  onClick={(e: any) => processingInputField(e)}
                  label="Пароль"
                  error={
                    isInitialPasswordInput
                      ? null
                      : errors.password?.message
                      ? errors.password?.message
                      : error
                      ? ""
                      : null
                  }
                />
              )}
            />
          </Box>
          {isInitialPasswordInput && (
            <Box mt={6}>
              <Typography
                variant="text_3"
                color={theme.palette.gray.b500}
                mt={6}
              >
                Пароль должен содержать от 8 до 18 символов, в том числе
                латинские заглавные и строчные буквы, цифры и специальные
                символы
              </Typography>
            </Box>
          )}

          <Box mt={20}>
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                validate: (value) =>
                  value === watch("password") ||
                  "Введённые пароли не совпадают",
              }}
              render={({ field }) => (
                <PasswordInput
                  {...field}
                  // @ts-ignore
                  value={confirmPassword}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    field.onChange(e);
                  }}
                  onClick={(e: any) => processingInputField(e)}
                  label="Повторите пароль"
                  error={
                    errors.confirmPassword
                      ? errors.confirmPassword?.message
                      : error
                      ? ""
                      : null
                  }
                />
              )}
            />
          </Box>

          <Box display="flex" flexDirection="column" rowGap={12} mt={98}>
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isDisabled}
              fullWidth
              onClick={(e) => handleClick(e)}
              isLoading={isLoading}
            >
              Продолжить
            </Button>
            <Button
              variant="secondary"
              size="lg"
              fullWidth
              onClick={handleGoBack}
            >
              Назад
            </Button>
          </Box>
        </Box>
      </Box>
    </AuthContainer>
  );
};

export default FourthScreen;
