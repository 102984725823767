import { useState, useCallback } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "components";
import { CARD_STATUS } from "components/CardsInfo/model";
import IconAlert from "components/Icons/IconAlert";
import IconFire from "components/Icons/IconFire";
import { useIsMobile } from "hooks/useIsMobile";
import theme from "theme";
import { CARD_ACTION, unblockedCard } from "store/slices/cardsSlice";
import { cardsSelector } from "store";
import IconAlertGray from "components/Icons/IconAlertGray";
import RightIcon from "./assets/RightIcon.png";
import { useIsPWA } from "hooks/useIsPWA";

export const CardButtons = ({
  card,
  dataIcon,
  handleClick,
}: {
  card: any;
  dataIcon: any;
  handleClick: (action: CARD_ACTION) => void;
}) => {
  const [activeIndexItem, setActiveIndexItem] = useState<number | null>(null);
  const { blue } = theme.palette;
  const { isMobile, size } = useIsMobile();
  const isPWA = useIsPWA();
  const { isBlockLoading } = useSelector(cardsSelector);
  const dispatch = useDispatch();
  const unFreezeCard = () => dispatch(unblockedCard({ cardId: card?.cardId }));

  const getFrozenCardSubtitle = useCallback(() => {
    switch (size) {
      case "xl":
        return `Операции по карте приостановлены, ее нельзя пополнить, снять или перевести с нее деньги.\nДля продолжения операций по карте нажмите кнопку "Разморозить"`;
      case "sm":
        return `Операции по карте приостановлены, ее\nнельзя пополнить, снять или перевести\nс нее деньги. Для продолжения операций\nпо карте нажмите кнопку "Разморозить"`;
      case "xs":
        return `Операции по карте приостановлены, ее\nнельзя пополнить, снять или перевести\nс нее деньги. Для продолжения операций\nпо карте нажмите кнопку "Разморозить"`;
      default:
        return `Операции по карте приостановлены, ее нельзя пополнить, снять или перевести с нее деньги. Для продолжения операций по карте нажмите кнопку "Разморозить"`;
    }
  }, [size]);

  const cardIsBlocked = (status: string) => {
    return (
      <Box
        order={
          card.status === CARD_STATUS.Active
            ? { xs: 4, md: 4 }
            : { xs: 2, md: 4 }
        }
        bgcolor={status === CARD_STATUS.Closed ? "#F7F8FA" : "#FFEEED"}
        p={20}
        mt={{ xs: 0, md: 20 }}
        mb={{ xs: 32, md: 44 }}
        borderRadius={20}
        display="flex"
        flexDirection="column"
        alignItems="self-start"
      >
        {status === CARD_STATUS.Closed ? <IconAlertGray /> : <IconAlert />}
        <Typography
          variant="text_6"
          color={theme.palette.gray.b800}
          fontWeight={500}
          mt={12}
          mb={{ xs: 8, md: 20 }}
        >
          {status === CARD_STATUS.Closed
            ? "Карта закрыта"
            : " Ваша карта заблокирована"}
        </Typography>
        {status !== CARD_STATUS.Closed && (
          <Typography
            variant="text_4"
            color={theme.palette.gray.b800}
            fontWeight={400}
            mb={{ xs: 20, md: 16 }}
          >
            Для получения новой карты обратитесь
            <br /> в обслуживающее полевое учреждение
          </Typography>
        )}
      </Box>
    );
  };

  const cardIsFrozen = () => {
    return (
      <Box
        order={
          card.status === CARD_STATUS.Active
            ? { xs: 4, md: 4 }
            : { xs: 2, md: 4 }
        }
        bgcolor="#F3F5F8"
        p={12}
        borderRadius={20}
        display="flex"
        flexDirection="column"
        alignItems="self-start"
        mt={{ xs: 0, md: 20 }}
        mb={{ xs: 32, md: 44 }}
      >
        <Typography
          variant="text_6"
          color={theme.palette.gray.b800}
          fontWeight={400}
          mb={{ xs: 8, md: 16 }}
        >
          Карта заморожена
        </Typography>
        <Typography
          variant="text_4"
          color={theme.palette.gray.b800}
          fontWeight={400}
          whiteSpace="pre-wrap"
        >
          {getFrozenCardSubtitle()}
        </Typography>
        <Box
          order={{ xs: 4, md: 4 }}
          width="100%"
          mt={20}
          boxSizing="border-box"
        >
          <Button
            isLoading={isBlockLoading}
            startIcon={<IconFire />}
            onClick={unFreezeCard}
            variant="primary"
            fullWidth
            size="lg"
          >
            Разморозить карту
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {(card.status === CARD_STATUS.Lost ||
        card.status === CARD_STATUS.Stolen ||
        card.status === CARD_STATUS.Closed) &&
        cardIsBlocked(card.status)}

      {card.status === CARD_STATUS.Temporary && cardIsFrozen()}

      {card.status === CARD_STATUS.Active && (
        <Box
          width="100%"
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          boxSizing="border-box"
          columnGap={24}
          rowGap={12}
          order={{ xs: 2, md: 3 }}
        >
          {card?.status &&
            dataIcon?.map((cardItem) => {
              return (
                <Box
                  order={{ xs: 4, md: 4 }}
                  key={cardItem.id}
                  width="100%"
                  p={{ xs: "12px", md: "16px 0px" }}
                  mt={{ xs: 0, md: 20 }}
                  mb={{ xs: 0, md: 44 }}
                  bgcolor={
                    activeIndexItem === cardItem.id ? "#F1F7FF" : "#F2F4F9"
                  }
                  boxSizing="border-box"
                  display="flex"
                  alignItems="center"
                  borderRadius="16px"
                  flexDirection={{ xs: "row", md: "column" }}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => handleClick(cardItem.id)}
                  onMouseEnter={() => setActiveIndexItem(cardItem.id)}
                  onMouseLeave={() => setActiveIndexItem(null)}
                >
                  <IconButton
                    sx={{
                      backgroundColor:
                        activeIndexItem === cardItem.id
                          ? theme.palette.blue.b400
                          : "#fff",
                      borderRadius: isMobile ? 20 : 8,
                      width: isMobile ? 36 : 40,
                      height: isMobile ? 36 : 40,
                      marginBottom: isMobile ? 0 : 8,
                      marginRight: isMobile ? 12 : 0,
                    }}
                  >
                    {cardItem.svg(
                      activeIndexItem === cardItem.id ? "white" : blue.b400
                    )}
                  </IconButton>
                  <Box display="flex" alignItems="start">
                    <Typography
                      variant="text_5"
                      color={theme.primary.main.gray[800]}
                      fontWeight={400}
                    >
                      {cardItem.title}
                    </Typography>
                  </Box>
                  {(isMobile || isPWA) && (
                    <div
                      style={{
                        marginLeft: "auto",
                        marginRight: "10px",
                      }}
                    >
                      <img src={RightIcon} />
                    </div>
                  )}
                </Box>
              );
            })}
        </Box>
      )}
    </>
  );
};
