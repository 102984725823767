import { AuthContainer } from "containers";
import { FC, useState } from "react";
import { Alert, Box, Typography } from "@mui/material";
import { BiometryRegistration } from "atoms/BiometryIcons/BiometryRegistration";
import { Button, Loader } from "components";
import { useDispatch, useSelector } from "react-redux";
import {
  biometryRegConfirm,
  checkIsBiometryKeyExist,
  getBiometryCredentials,
  regBiometryToken,
} from "store/slices/auth";
import {
  create,
  parseCreationOptionsFromJSON,
} from "@github/webauthn-json/browser-ponyfill";
import { useNavigate } from "react-router-dom";
import { authSelector } from "store";
import { profileActions } from "store/slices/profile";
import { useIsPWA } from "hooks/useIsPWA";

export const Biometry: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPWA = useIsPWA();
  const [isToken, setIsToken] = useState(false);
  const { isLoading } = useSelector(authSelector);
  const setItemToStorage = () => {
    localStorage.setItem("useBiometry", "true");
  };

  const space = window.innerHeight - 636;
  const biometryAgree = () => {
    dispatch(regBiometryToken(isPWA))
      //@ts-ignore
      .unwrap()
      .then((data) => {
        dispatch(getBiometryCredentials(data.registrationAddToken))
          //@ts-ignore
          .unwrap()
          .then(async (data) => {
            const message = data.publicKeyCredentialCreationOptions;
            const optionsJson = parseCreationOptionsFromJSON({
              publicKey: message,
            });
            // @ts-ignore
            optionsJson.publicKey.extensions.appidExclude =
              // @ts-ignore
              optionsJson.publicKey.extensions.appidExclude.id;
            try {
              const credential = await create(optionsJson);
              const registrationToken = data.registrationId;
              //@ts-ignore
              dispatch(biometryRegConfirm({ registrationToken, credential }))
                //@ts-ignore
                .unwrap()
                .then(() => {
                  setItemToStorage();
                  navigate("/push-registration", { replace: true });
                  dispatch(profileActions.setBiometryConnected("connected"));
                })
                .catch((e) => {});
            } catch (err: any) {
              dispatch(checkIsBiometryKeyExist())
                // @ts-ignore
                .unwrap()
                .then((res) => {
                  if (res) {
                    setItemToStorage();
                    dispatch(profileActions.setBiometryConnected("connected"));
                  } else {
                    dispatch(profileActions.setBiometryConnected("error"));
                  }
                  navigate("/push-registration", { replace: true });
                });
            }
            // }
          });
      });
  };

  return (
    <AuthContainer
      title={`Использовать биометрические данные\nдля входа в приложение?`}
    >
      {isLoading && (
        <Box
          width="100%"
          height="calc(100% - 150px)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background: "azure",
            opacity: 0.4,
            zIndex: 10,
          }}
        >
          <Loader />
        </Box>
      )}

      {isToken && (
        <Alert
          color="info"
          action={
            <Button
              color="inherit"
              size="sm"
              onClick={() => {
                setIsToken(false);
              }}
            >
              Понятно
            </Button>
          }
        >
          У вас уже есть ключ биометрии. Вы будете перенаправлены на главную
          страницу
        </Alert>
      )}

      <Box mt={48} mb={32}>
        <BiometryRegistration />
      </Box>
      <Typography
        color="#475467"
        mb={space <= 0 ? 32 : space}
        maxWidth={400}
        whiteSpace="pre-wrap"
      >
        Вы сможете быстро и безопасно входить
        {"\n"}в личный кабинет с помощью
        {"\n"}Face и Touch ID
      </Typography>
      <Box>
        <Button
          type="submit"
          onClick={() => {
            biometryAgree();
          }}
          variant="primary"
          size="lg"
          fullWidth
          isLoading={false}
          disabled={false}
        >
          Использовать
        </Button>
        <Button
          type="submit"
          onClick={() => {
            navigate("/push-registration", { replace: true });
          }}
          variant="secondary"
          size="lg"
          fullWidth
          isLoading={false}
          disabled={false}
          style={{ marginTop: 12 }}
        >
          Напомнить позже
        </Button>
      </Box>
    </AuthContainer>
  );
};
