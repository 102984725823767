import { Grid, Typography, Box, Link } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { styled } from "@mui/material/styles";
import { URL_FOOTER_ADDRESSES, URL_FOOTER_SITE } from "constants/url";
import env from "../../env";

const Footer = () => {
  const { isMobile, size } = useIsMobile();

  const DivFlex = styled("div")({
    display: "flex",
    flexDirection: "column",
  });
  return (
    <>
      <Box
        bgcolor="#F9FAFB"
        justifyContent="center"
        display="flex"
        px={{ xs: 16, sm: 16, md: 34, lg: 34, xl: 120 }}
        pt={isMobile ? 25 : 48}
        pb={isMobile ? 136 : 48}
      >
        <Grid
          mt={0}
          ml="auto"
          maxWidth={isMobile ? "100%" : "66.66%"}
          container
          columns={isMobile ? 4 : 12}
          justifyContent={isMobile ? "start" : "space-evenly"}
          alignContent={isMobile ? "none" : "center"}
          rowSpacing={32}
          // spacing={isMobile ? 0 : 120}
          boxSizing="border-box"
          mr="auto"
        >
          <Grid
            pl="0px !important"
            item
            md={4}
            xs={size === "sm" ? 3.9 : 4}
            sm={4}
            order={{ sm: 1 }}
            minWidth="50%"
          >
            <DivFlex>
              <Link
                variant="text_5"
                color="gray.b900"
                fontWeight="500"
                underline="none"
                href="tel:+7 (800) 200-45-67"
              >
                +7 (800) 200-45-67
              </Link>
              <Typography
                variant="text_3"
                color="gray.b400"
                fontWeight="400"
                mt={6}
              >
                Телефон горячей линии для бесплатных звонков <br />с территории
                России
              </Typography>
            </DivFlex>
          </Grid>
          <Grid
            pl="0px !important"
            item
            md={4}
            xs={4}
            order={{ sm: 3, md: 2 }}
            minWidth="50%"
          >
            <DivFlex
              style={{
                alignItems: !isMobile ? "flex-start" : "none",
              }}
            >
              <Box display="flex" flexDirection="column">
                <Link
                  target="_blank"
                  href={URL_FOOTER_ADDRESSES}
                  variant="text_5"
                  color="gray.b900"
                  fontWeight="500"
                  underline="none"
                >
                  Адреса обслуживания
                </Link>
                <Typography
                  variant="text_3"
                  color="gray.b400"
                  fontWeight="400"
                  mt={6}
                >
                  Адреса обслуживания полевых учреждений Банка России
                </Typography>
              </Box>
            </DivFlex>
          </Grid>

          <Grid
            pl="0px !important"
            item
            md={4}
            xs={size === "sm" ? 3.8 : 4}
            sm={3}
            order={{ sm: 2, md: 4 }}
            minWidth="50%"
          >
            <DivFlex>
              <Link
                variant="text_5"
                color="gray.b900"
                fontWeight="500"
                underline="none"
                href="tel:+7 (812) 603-00-90"
              >
                +7 (812) 603-00-90
              </Link>
              <Typography
                variant="text_3"
                color="gray.b400"
                fontWeight="400"
                mt={6}
              >
                Телефон горячей линии для звонков за пределами России
              </Typography>
            </DivFlex>
          </Grid>
          <Grid
            pl="0px !important"
            item
            md={4}
            xs={4}
            order={{ sm: 4, md: 5 }}
            minWidth="50%"
          >
            <DivFlex
              style={{
                alignItems: !isMobile ? "flex-start" : "none",
              }}
            >
              <Box
                width={isMobile ? 280 : 400}
                display="flex"
                flexDirection="column"
                position="relative"
              >
                <Link
                  target="_blank"
                  href={URL_FOOTER_SITE}
                  variant="text_5"
                  color="gray.b900"
                  fontWeight="500"
                  underline="none"
                >
                  Перейти на сайт
                </Link>
                <Typography
                  variant="text_3"
                  color="gray.b400"
                  fontWeight="400"
                  mt={isMobile ? 32 : 6}
                >
                  Версия {env.REACT_APP_VERSION}
                </Typography>
              </Box>
            </DivFlex>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Footer;
