import * as React from "react";

export const IconMainTab = (props) => (
  <svg
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5 20c0 .265-.293.707-.293.707S20.765 21 20.5 21h-16a1 1 0 0 1-1-1V9.49c0-.092.037-.233.066-.33a.705.705 0 0 1 .1-.205c.059-.083.147-.199.22-.255l8-6.222a1 1 0 0 1 1.228 0l8 6.222c.12.093.284.35.284.35s.102.287.102.44V20Z"
      fill={props?.color || "#C2C9D6"}
    />
  </svg>
);
