import { useTheme } from "@mui/material";

export const IconSnow = ({ ...props }) => {
  const theme = useTheme();
  const { blue } = theme.palette;
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 1.5V22.5"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7057 3.74609C13.8891 4.23825 12.9535 4.49762 12.0001 4.49609C11.0466 4.49762 10.111 4.23825 9.29443 3.74609"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.29443 20.25C10.1116 19.7593 11.0469 19.5 12.0001 19.5C12.9533 19.5 13.8885 19.7593 14.7057 20.25"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0933 6.75L2.90674 17.25"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.497 10.2187C19.6632 9.75636 18.971 9.07584 18.4945 8.25009C18.0179 7.42434 17.775 6.48449 17.7918 5.53125"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.50293 13.7812C4.33669 14.2436 5.02892 14.9242 5.50546 15.7499C5.982 16.5757 6.22488 17.5155 6.20808 18.4687"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.90674 6.75L21.0933 17.25"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.50293 10.2187C4.33669 9.75636 5.02892 9.07584 5.50546 8.25009C5.982 7.42434 6.22488 6.48449 6.20808 5.53125"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.497 13.7812C19.6632 14.2436 18.971 14.9242 18.4945 15.7499C18.0179 16.5757 17.775 17.5155 17.7918 18.4687"
        stroke={props.color || blue.b400}
        strokeWidth="1.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconSnow;
