import theme from "../../../theme";

export const RemoveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 6.8688L6.3035 5.1717C5.99098 4.85907 5.48418 4.85902 5.1716 5.1716C4.85902 5.48418 4.85907 5.99098 5.1717 6.3035L6.8688 8L5.1717 9.6965C4.85907 10.009 4.85902 10.5158 5.1716 10.8284C5.48418 11.141 5.99098 11.1409 6.3035 10.8283L8 9.1312L9.6965 10.8283C10.009 11.1409 10.5158 11.141 10.8284 10.8284C11.141 10.5158 11.1409 10.009 10.8283 9.6965L9.1312 8L10.8283 6.3035C11.1409 5.99098 11.141 5.48418 10.8284 5.1716C10.5158 4.85902 10.009 4.85907 9.6965 5.1717L8 6.8688Z"
        fill={theme.primary.main.gray["300"]}
      />
    </svg>
  );
};
