export const IconBlockedCardSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="151"
      height="150"
      fill="none"
      viewBox="0 0 151 150"
    >
      <path
        fill="#FFEEED"
        d="M75.5 150c41.421 0 75-33.579 75-75s-33.579-75-75-75S.5 33.579.5 75s33.579 75 75 75z"
      ></path>
      <g filter="url(#filter0_d_1107_179121)">
        <mask
          id="mask0_1107_179121"
          style={{ maskType: "alpha" }}
          width="151"
          height="150"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="url(#paint0_linear_1107_179121)"
            d="M75.5 150c41.421 0 75-33.579 75-75s-33.579-75-75-75S.5 33.579.5 75s33.579 75 75 75z"
          ></path>
        </mask>
        <g mask="url(#mask0_1107_179121)">
          <path
            fill="#fff"
            d="M134 105a8 8 0 01-8 8H24a8 8 0 01-8-8V45.002a8 8 0 017.996-8c38.02-.013 73.653.03 102.001.012A7.998 7.998 0 01134 45.016V105z"
          ></path>
        </g>
      </g>
      <path
        fill="#FDA29B"
        d="M75 99c13.255 0 24-10.745 24-24S88.255 51 75 51 51 61.745 51 75s10.745 24 24 24z"
      ></path>
      <path
        fill="#fff"
        d="M84.526 84.527a2 2 0 01-2.828 0l-7.071-7.071-7.071 7.071a2 2 0 01-2.828-2.828l7.07-7.071-7.07-7.072a2 2 0 112.828-2.828l7.071 7.071 7.071-7.07a2 2 0 012.828 2.828l-7.07 7.07 7.07 7.072a2 2 0 010 2.828z"
      ></path>
      <defs>
        <filter
          id="filter0_d_1107_179121"
          width="130"
          height="88"
          x="10"
          y="28"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="-3"></feOffset>
          <feGaussianBlur stdDeviation="3"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1107_179121"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1107_179121"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_1107_179121"
          x1="75.5"
          x2="75.5"
          y1="0"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3ECFA"></stop>
          <stop offset="1" stopColor="#DAE7FF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
