import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ChatAttachmentDto } from "api/notification";
import theme from "theme";
import { AttachDocument, ImageAttach } from "widgets/ChatWidget/assets";
import { getFileType } from "widgets/ChatWidget/utils";

type FilesMessagesProps = {
  files: ChatAttachmentDto[];
  onClick: (file: ChatAttachmentDto) => () => void;
  user?: string;
};

const FilesMessages = ({ files, onClick, user }: FilesMessagesProps) => {
  if (!files.length) return null;

  return (
    <Box>
      {files.map((file) => (
        <Box
          sx={{ cursor: "pointer" }}
          onClick={onClick(file)}
          key={file.id}
          display="flex"
          gap={12}
        >
          <Box
            width={40}
            height={40}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius={13}
            sx={{
              backgroundColor: !user
                ? theme.primary.main.gray[75]
                : theme.primary.main.gray["0"],
            }}
          >
            {file.mimeType?.includes("image") ? (
              <ImageAttach />
            ) : (
              <AttachDocument />
            )}
          </Box>
          <Box>
            <Typography
              typography="text_5"
              color={
                !user
                  ? theme.primary.main.gray["0"]
                  : theme.primary.main.gray["800"]
              }
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                maxWidth: 150,
              }}
            >
              {file.fileName}
            </Typography>
            <Typography
              typography="text_1"
              color={theme.primary.main.gray["300"]}
              fontWeight={500}
            >
              {getFileType(file.mimeType)}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default FilesMessages;
