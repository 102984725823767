import { FC, useState } from "react";
import {
  GetListRequestOperationFilterTypeEnum,
  StatementType,
  OperationFilterType,
} from "api/account";
import { SearchFiltersValues } from "pages/home/Cards/History/History";
import Arrow from "./assets/arrow.png";
import Сheck from "./assets/check.png";
import {
  Container,
  DropdownArrow,
  DropdownCheck,
  DropdownItem,
  DropdownTitle,
  DropdownWrapper,
} from "./components";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  HISTORY_TABS,
  getCategoryExpense,
  getCategoryIncome,
} from "store/slices/historySlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";

const OperationTypeMapAll = {
  [GetListRequestOperationFilterTypeEnum.Cash]: "Наличные",
  [GetListRequestOperationFilterTypeEnum.Goods]: "Покупки и платежи",
  [GetListRequestOperationFilterTypeEnum.Incomes]: "Зачисления",
  [GetListRequestOperationFilterTypeEnum.Transfers]: "Переводы",
  [GetListRequestOperationFilterTypeEnum.WriteOffs]: "Списания",
};

const OperationTypeMapExpense = {
  [StatementType.CashOut]: "Снятие наличных",
  [GetListRequestOperationFilterTypeEnum.Goods]: "Покупки и платежи",
  [OperationFilterType.WriteOffsOther]: "Прочие списания",
  [StatementType.TransferOut]: "Исходящие переводы",
  [OperationFilterType.Commission]: "Комиссии",
};

const OperationTypeMapIncome = {
  [StatementType.CashIn]: "Внесение наличных",
  [StatementType.TransferIn]: "Входящие переводы",
  [OperationFilterType.Refund]: "Возврат",
  [OperationFilterType.IncomesOther]: "Прочие доходы",
};

const data = {
  [HISTORY_TABS.ALL_OPERATIONS]: OperationTypeMapAll,
  [HISTORY_TABS.EXPENSES]: OperationTypeMapExpense,
  [HISTORY_TABS.INCOMES]: OperationTypeMapIncome,
};

interface DropdownItem {
  id: GetListRequestOperationFilterTypeEnum | "";
  title: string;
}

interface DropdownProps {
  items: Array<DropdownItem>;
  onChange: (value: DropdownItem) => void;
  value: GetListRequestOperationFilterTypeEnum | "";
}

const ALL_OPERATIONS = "Все";

const Dropdown = ({ items, onChange, value }: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const closeDropdown = () => setOpen(false);
  const rootElement = useOutsideClick(closeDropdown);
  const { activeTab } = useSelector((state: RootState) => state.history);

  const clickHandler = (item: DropdownItem) => {
    onChange(item);
    closeDropdown();
  };

  return (
    <Container ref={rootElement}>
      <DropdownTitle onClick={() => setOpen((prevState) => !prevState)}>
        {data[activeTab][value] || ALL_OPERATIONS}
        <DropdownArrow src={Arrow} open={open} />
      </DropdownTitle>
      {open && (
        <DropdownWrapper>
          {items.map((item) => {
            return (
              <DropdownItem key={item.id} onClick={() => clickHandler(item)}>
                {item.title}
                {(data[activeTab][value] === item.title ||
                  (!value && item.title === ALL_OPERATIONS)) && (
                  <DropdownCheck src={Сheck} />
                )}
              </DropdownItem>
            );
          })}
        </DropdownWrapper>
      )}
    </Container>
  );
};

interface OperationTypeProps {
  value: GetListRequestOperationFilterTypeEnum | "";
  onChange: (values: Partial<SearchFiltersValues>) => void;
  filterValues: SearchFiltersValues;
}

export const OperationType: FC<OperationTypeProps> = ({
  onChange,
  value,
  filterValues,
}) => {
  const { activeTab } = useSelector((state: RootState) => state.history);
  const dispatch = useDispatch();

  const operationTypes = Object.keys(data[activeTab]).map((key) => ({
    id: key,
    title: data[activeTab][key],
  }));

  const handleChange = (value: DropdownItem) => {
    onChange({ operationFilterType: value.id });
    if (activeTab === HISTORY_TABS.EXPENSES) {
      dispatch(
        getCategoryExpense({
          ...filterValues,
          // @ts-ignore
          operationFilterType: value.id,
        })
      );
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      dispatch(
        getCategoryIncome({
          ...filterValues,
          // @ts-ignore
          operationFilterType: value.id,
        })
      );
    }
  };

  return (
    <Dropdown
      // @ts-ignore
      items={[{ id: "", title: ALL_OPERATIONS }, ...operationTypes]}
      onChange={(value) => handleChange(value)}
      value={value}
    />
  );
};
